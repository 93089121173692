import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { CustomButton, LinkCustomButton } from 'components/Button/CustomButton';
import { DepositFormVariant } from 'components/Dialogs/Deposit/DepositFormVariant';
import { CashDepositTransferDetails } from 'generated/graphql';
import { useContext } from 'react';
import { PaymentSummary } from '../PaymentSummary';
import { DepositVariantContext } from '../_shared/DepositVariantContext';

interface AcknowledgmentStepProps {
  amount: number;
  transferDetails: CashDepositTransferDetails;
  onClose: () => void;
}

export function AcknowledgmentStep({
  amount,
  transferDetails,
  onClose,
}: AcknowledgmentStepProps) {
  const depositVariant = useContext(DepositVariantContext);
  return (
    <>
      <DialogTitle disableTypography>
        <Typography>Your payment is on its way</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>
          Your payment has been requested and should appear in your account
          shortly. You can see details of the payment that was requested below
        </Typography>
        <PaymentSummary
          amount={amount}
          transferDetails={transferDetails}
          copyEnabled={false}
        />
      </DialogContent>
      <DialogActions>
        {depositVariant?.variant === DepositFormVariant.NewAccount ? (
          <>
            <LinkCustomButton fullWidth className="richBlue" to="/dashboard">
              Dashboard
            </LinkCustomButton>
            <LinkCustomButton fullWidth className="magenta" to="/funds">
              Browse funds
            </LinkCustomButton>
          </>
        ) : (
          <CustomButton onClick={() => onClose()} className="magenta" fullWidth>
            Close
          </CustomButton>
        )}
      </DialogActions>
    </>
  );
}
