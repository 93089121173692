import { useAuth } from 'context/AuthContext';
import { useUserProfileQuery } from 'generated/graphql';
import { subtractYears } from 'helpers/dateHelpers';

interface useIsSippAgeRestrictedProps {
  canOpenSipp: boolean;
}

export default function useIsSippAgeRestricted({
  canOpenSipp,
}: useIsSippAgeRestrictedProps) {
  const { signedIn } = useAuth();
  const userProfile = useUserProfileQuery(undefined, {
    enabled: signedIn,
  });

  const yearOfBirth = new Date(userProfile.data?.userProfile?.dateOfBirth!);
  const minDOBAllowed = subtractYears(new Date(), 75);

  return !canOpenSipp && yearOfBirth <= minDOBAllowed ? true : false;
}
