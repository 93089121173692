import { IconButton, useMediaQuery, useTheme } from '@material-ui/core';
import { Seo } from 'components/Seo/Seo';
import { useWhichBasketType } from 'components/feature/PortfolioBuilder/hooks/useWhichBasketType';
import { WrapperType } from 'generated/graphql';
import { Source } from 'pages/FundDetails/fundDetailsTypes';
import { HiChevronDoubleLeft } from 'react-icons/hi';
import { Filters } from './Filters/Filters';
import { FundsGridList } from './FundGridList/FundsGridList';
import { FundsGridListSearch } from './FundsGridListSearch/FundsGridListSearch';
import {
  CloseWrapper,
  FilterDesktopHeading,
  FiltersHeading,
  FiltersWrapper,
  FundFilterLayout,
} from './NewFundsPageLayout.styles';
import { useFundListOptions } from './hooks/useFundListOptions';

interface FundsListProps {
  selectedAccountType?: WrapperType;
  selectedAccountId?: string;
  source?: Source;
}

export function FundsLayout({
  selectedAccountType,
  selectedAccountId,
  source = 'funds',
}: FundsListProps) {
  const basketType = useWhichBasketType();

  const variant = source === 'funds' ? 'funds' : 'portfolio-builder';

  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const [options, setOptions] = useFundListOptions();

  return (
    <>
      <Seo title="Funds" description="Invest in funds picked by experts" />

      <FundsGridListSearch
        onFiltersClick={(newShowFilters) => {
          setOptions({
            ...options,
            showFilters: newShowFilters,
          });
        }}
        showFilters={options.showFilters}
      />

      <FundFilterLayout $showFilters={isMdUp && options.showFilters}>
        {isMdUp && options.showFilters && (
          <FiltersWrapper $variant={variant}>
            <FiltersHeading>
              <FilterDesktopHeading $noMargin>Filters</FilterDesktopHeading>
              <CloseWrapper>
                <IconButton
                  size="small"
                  color="default"
                  aria-label="Close Filters"
                  onClick={() => {
                    setOptions({
                      ...options,
                      showFilters: false,
                    });
                  }}
                >
                  <HiChevronDoubleLeft />
                </IconButton>
              </CloseWrapper>
            </FiltersHeading>

            <Filters variant="list" />
          </FiltersWrapper>
        )}
        <FundsGridList
          selectedAccountType={selectedAccountType}
          selectedAccountId={selectedAccountId}
          actionsOptions={
            source === 'regular-order'
              ? 'add_to_regular_order_only'
              : basketType === 'LocalBasket' || variant === 'portfolio-builder'
              ? 'add_to_basket_only'
              : 'any'
          }
          options={options}
          setOptions={setOptions}
          source={source}
        />
      </FundFilterLayout>
    </>
  );
}

export const NewFundsPageLayout = ({
  selectedAccountType,
  selectedAccountId,
}: FundsListProps) => {
  return (
    <>
      <Seo title="Funds" description="Invest in funds picked by experts" />
      <FundsLayout
        selectedAccountType={selectedAccountType}
        selectedAccountId={selectedAccountId}
      />
    </>
  );
};
