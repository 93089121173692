import { StyledLink } from 'components/design-system/Link';
import {
  StepIntroduction,
  StepIntroductionTypography,
  StepIntroductionWidth,
} from 'components/design-system/StepComponents/StepComponents';
import { NationalIdentifierStep } from 'components/feature/openAccount/steps/NationalIdentifierStep';
import { mifidToInvest } from 'paths';

interface MiFidRequiredProps {
  fundNamesString: string;
  onProceed: () => void;
  onBack: () => void;
}

export function MiFidRequired({
  fundNamesString,
  onProceed,
  onBack,
}: MiFidRequiredProps) {
  const mifidIntroduction = fundNamesString.length ? (
    <StepIntroduction mb={2} $width={StepIntroductionWidth.normal}>
      <StepIntroductionTypography>
        To trade the following funds, our regulator requires that we ask for
        your national identifier: {fundNamesString}.{' '}
        <StyledLink href={mifidToInvest} target="_blank">
          Learn more
        </StyledLink>
        .
      </StepIntroductionTypography>
    </StepIntroduction>
  ) : null;

  return (
    <NationalIdentifierStep
      onProceed={onProceed}
      onGoBack={onBack}
      introduction={mifidIntroduction}
      source="checkoutStep"
    />
  );
}
