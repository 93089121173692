import { yupResolver } from '@hookform/resolvers/yup';
import { TextField } from 'components/Form/TextField';
import { QueryState } from 'components/QueryState';
import {
  GoBackButton,
  StepActions,
  StepButton,
  StepContainer,
  StepFormContainer,
  StepIntroduction,
  StepIntroductionTypography,
  StepIntroductionWidth,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import { Text, TextSmall } from 'components/design-system/Text/Text';
import {
  MIN_CONTRIBUTION_AMOUNT,
  MIN_CONTRIBUTION_AMOUNT_SIPP,
} from 'components/feature/autoSaveInvest/autoSaveInvestConstant';
import { currencyFull } from 'formatting';
import {
  ActionType,
  WrapperType,
  useActionsQuery,
  useDepositDetailsByAccountQuery,
} from 'generated/graphql';
import { pensionTaxReliefHelpers } from 'helpers/pensionTaxReliefHelpers';
import { amount } from 'helpers/yupExtensions';
import max from 'lodash/max';
import { FormProvider, useForm } from 'react-hook-form';
import type { AccountsQueryAccount } from 'types/graphqlTypes';
import * as Yup from 'yup';
import Reference from 'yup/lib/Reference';

type DateFrequencyStepFormValues = {
  amount: number | null;
};

const depositSchema = Yup.object().shape({
  amount: amount()
    .label('Amount')
    .typeError('Please enter a valid number')
    .nullable()
    .required()
    .min(Yup.ref('$minAmount') as Reference<number>)
    .max(Yup.ref('$maxAmount') as Reference<number>),
});

interface AmountStepProps {
  account: AccountsQueryAccount;
  amount?: number;
  onProceed: (newDeposit: number) => void;
  onGoBack: () => void;
  wrapperType: WrapperType;
}

export function AmountStep({
  account,
  onProceed,
  amount,
  onGoBack,
  wrapperType,
}: AmountStepProps) {
  const depositContextQuery = useDepositDetailsByAccountQuery({
    id: account.id,
  });
  const validRange =
    depositContextQuery.data?.depositDetailsByAccount?.validRange;
  const minAmount = max([
    validRange?.minimumPaymentAmount,
    account.wrapperType === WrapperType.Sipp
      ? MIN_CONTRIBUTION_AMOUNT_SIPP
      : MIN_CONTRIBUTION_AMOUNT,
  ]);

  const actionsQuery = useActionsQuery();
  const regularDepositsAction = actionsQuery.data?.actions?.find(
    (action) => action.type === ActionType.SetupRegularDeposit
  );
  const regularDepositsActionAmount =
    regularDepositsAction?.amount ?? undefined;

  const methods = useForm<DateFrequencyStepFormValues>({
    resolver: yupResolver(depositSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    context: {
      minAmount,
      maxAmount: validRange?.maximumPaymentAmount,
    },
    defaultValues: {
      amount: amount ? amount : regularDepositsActionAmount,
    },
  });

  const onSubmit = async (formData: DateFrequencyStepFormValues) => {
    if (formData.amount === null) {
      return;
    }

    onProceed(formData.amount);
  };

  const { watch } = methods;
  const watchAmount = Number(watch('amount'));

  return (
    <FormProvider {...methods}>
      <QueryState {...depositContextQuery}>
        {({ data }) => {
          if (!minAmount) {
            return;
          }
          return (
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <StepContainer>
                <StepTitle>Amount</StepTitle>
                <StepIntroduction $width={StepIntroductionWidth.extraWide}>
                  <StepIntroductionTypography>
                    Enter the amount you want to deposit every month.
                  </StepIntroductionTypography>
                </StepIntroduction>

                <StepFormContainer>
                  <TextField
                    name="amount"
                    label="Amount"
                    startAdornment={<Text $noMargin>£</Text>}
                    helpText={`Minimum deposit: ${currencyFull(minAmount)}`}
                    inputProps={{
                      inputMode: 'decimal',
                    }}
                  />

                  {wrapperType === WrapperType.Sipp && (
                    <>
                      {typeof watchAmount === 'number' && watchAmount > 0 ? (
                        <TextSmall $noMargin>
                          We'll automatically claim basic rate tax relief of{' '}
                          {currencyFull(pensionTaxReliefHelpers(watchAmount))}{' '}
                          for this cash deposit.
                        </TextSmall>
                      ) : (
                        <TextSmall $noMargin>
                          We'll automatically claim basic rate tax relief for
                          this cash deposit.
                        </TextSmall>
                      )}
                    </>
                  )}
                </StepFormContainer>

                <StepActions>
                  <StepButton type="submit" className="magenta">
                    Continue
                  </StepButton>
                  <GoBackButton
                    onClick={() => {
                      onGoBack();
                    }}
                  />
                </StepActions>
              </StepContainer>
            </form>
          );
        }}
      </QueryState>
    </FormProvider>
  );
}
