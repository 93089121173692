import { SearchAssetsQuery } from 'generated/graphql';
import { InfiniteData } from 'react-query';
import { SearchAssetsQueryAssetMeta } from 'types/graphqlTypes';
import { TagProps } from '../FundGridCard/FundGridCard';
import { deriveMissingTags } from './deriveMissingTags';

type deriveFundsDataFromArrayArgs = {
  assets: SearchAssetsQueryAssetMeta[];
  searchTags: TagProps[];
};

function deriveFundsDataFromArray({
  assets,
  searchTags,
}: deriveFundsDataFromArrayArgs) {
  return assets.map((asset) => ({
    missingTags: deriveMissingTags({ searchTags, asset: asset.node }),
    missingFrontTags: deriveMissingTags({
      searchTags,
      asset: asset.node,
      countSameCatAsMissing: true,
    }),
    ...asset,
  }));
}

type deriveFundsDataArgs = {
  searchAssets: InfiniteData<SearchAssetsQuery>;
  searchTags: TagProps[];
};

export function deriveFundsData({
  searchAssets,
  searchTags,
}: deriveFundsDataArgs) {
  return searchAssets?.pages?.flatMap((page: SearchAssetsQuery) =>
    deriveFundsDataFromArray({ assets: page?.assets?.edges ?? [], searchTags })
  );
}
