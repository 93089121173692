import { Checkbox, FormControlLabel } from '@material-ui/core';
import { StyledLink } from 'components/design-system/Link';
import { UsPersonsDefinitionDialog } from 'components/Dialogs/UsPersonsDefinitionDialog';
import { StepProps } from 'components/feature/openAccount/steps/_shared/StepProps';

import { GaEventNames } from 'constants/gaConstants';
import {
  AccountType,
  AffirmationKind,
  useMakeAffirmationMutation,
  useUserProfileQuery,
} from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { useAffirmation } from 'hooks/useAffirmation';
import { useModal } from 'mui-modal-provider';
import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import {
  StepActions,
  StepButton,
  StepCheckboxesContainer,
  StepContainer,
  StepContent,
  StepIntroduction,
  StepIntroductionTypography,
  StepTitle,
} from '../../../../design-system/StepComponents/StepComponents';

interface BeforeWeGetStartedProps extends StepProps {
  isResuming: boolean | undefined;
  initialAccountType: AccountType | undefined;
}

export function BeforeWeGetStarted({
  onProceed,
  onGoBack,
  isResuming,
  initialAccountType,
}: BeforeWeGetStartedProps) {
  const ageAffirmation = useAffirmation(AffirmationKind.IsOver_18YearsOld);
  const taxDomicileAffirmation = useAffirmation(AffirmationKind.IsUkTaxPayer);
  const nonUsPersonAffirmation = useAffirmation(
    AffirmationKind.NotUsPersonForFatca
  );

  const [ageAssertion, setAgeAssertion] = useState(ageAffirmation);
  const [taxDomicileAssertion, setTaxDomicileAssertion] = useState(
    taxDomicileAffirmation
  );
  const [nonUsPersonAssertion, setNonUsPersonAssertion] = useState(
    nonUsPersonAffirmation
  );

  useEffect(() => {
    setAgeAssertion(ageAffirmation);
  }, [ageAffirmation]);

  useEffect(() => {
    setTaxDomicileAssertion(taxDomicileAffirmation);
  }, [taxDomicileAffirmation]);

  useEffect(() => {
    setNonUsPersonAssertion(nonUsPersonAffirmation);
  }, [nonUsPersonAffirmation]);

  const { showModal } = useModal();

  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useMakeAffirmationMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(useUserProfileQuery.getKey());
    },
  });

  const handleStart = async () => {
    await mutateAsync({
      input: {
        affirmations: [
          AffirmationKind.IsOver_18YearsOld,
          AffirmationKind.IsUkTaxPayer,
          AffirmationKind.NotUsPersonForFatca,
        ],
      },
    });
    onProceed();
  };

  return (
    <StepContainer>
      <StepContent>
        <StepTitle>
          {isResuming
            ? `Continue setting up your ${initialAccountType}`
            : 'Before we get started'}
        </StepTitle>

        <StepIntroduction mb={2}>
          <StepIntroductionTypography>
            We need to check you’re eligible to invest with us. Please confirm:
          </StepIntroductionTypography>
        </StepIntroduction>
        <StepCheckboxesContainer>
          <FormControlLabel
            disabled={nonUsPersonAffirmation}
            control={
              <Checkbox
                checked={nonUsPersonAssertion}
                onChange={(e) => setNonUsPersonAssertion(e.target.checked)}
              />
            }
            label={
              <>
                I am not a '
                <StyledLink
                  onClick={(e: React.MouseEvent) => {
                    e.preventDefault();
                    trackGa({
                      event: GaEventNames.selectContent,
                      content_type: 'link',
                      item_id: 'us person',
                    });
                    showModal(UsPersonsDefinitionDialog);
                  }}
                  href="#"
                >
                  US Person
                </StyledLink>
                ', as defined by the United States FATCA regulation.
              </>
            }
          />
          <FormControlLabel
            disabled={ageAffirmation}
            control={
              <Checkbox
                checked={ageAssertion}
                onChange={(e) => setAgeAssertion(e.target.checked)}
              />
            }
            label="I am over the age of 18"
          />
          <FormControlLabel
            disabled={taxDomicileAffirmation}
            control={
              <Checkbox
                checked={taxDomicileAssertion}
                onChange={(e) => setTaxDomicileAssertion(e.target.checked)}
              />
            }
            label="I am a UK taxpayer / UK tax resident"
          />
        </StepCheckboxesContainer>
      </StepContent>

      <StepActions>
        <StepButton
          disabled={!ageAssertion || !taxDomicileAssertion || isLoading}
          onClick={handleStart}
          className="magenta"
        >
          {isResuming ? 'Continue' : 'Start'}
        </StepButton>
      </StepActions>
    </StepContainer>
  );
}
