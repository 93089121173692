import * as Yup from 'yup';
import { TestFunction } from 'yup';

const MAX_FILE_SIZE_MB = 4;
const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1024 * 1024;
const MAX_FILE_SIZE_ERROR = `File size must be less than ${MAX_FILE_SIZE_MB}MB`;
const FILE_TYPE_ERROR_MESSAGE =
  'Supported file types are JPEG, BMP, PNG, TIFF and PDF';
// That's how Yup expects it
// eslint-disable-next-line no-template-curly-in-string
const REQUIRED_MESSAGE = '${path} is required';

const allowedFileTypes = [
  'image/jpeg',
  'image/bmp',
  'image/png',
  'image/tiff',
  'application/pdf',
];
export const allowedFileTypesStr = allowedFileTypes.join(',');

const fileSelected: TestFunction<FileList, any> = (value) => value.length > 0;
const maxFileSizeTest: TestFunction<FileList, any> = (value) =>
  value[0]?.size <= MAX_FILE_SIZE;
const fileTypeTest: TestFunction<FileList, any> = (value) =>
  allowedFileTypes.includes(value[0]?.type);

const documentTypeOptions = [
  { value: 'passport', label: 'Passport' },
  { value: 'driversLicence', label: 'Driving licence' },
  { value: 'nationalIdCard', label: 'National identity card' },
];

export const documentUploadSchema = Yup.object().shape({
  frontSide: Yup.mixed<FileList>().when(['$docsUploaded', '$isDirty'], {
    is: (docsUploaded: boolean, isDirty: boolean) => !docsUploaded || isDirty,
    then: (s) =>
      s
        .required()
        .when('documentType', {
          is: documentTypeOptions[0].value,
          then: (s) => s.label('Photo page'),
          otherwise: (s) => s.label('Front'),
        })
        .test('selected', REQUIRED_MESSAGE, fileSelected)
        .test('max-size', MAX_FILE_SIZE_ERROR, maxFileSizeTest)
        .test('file-type', FILE_TYPE_ERROR_MESSAGE, fileTypeTest),
  }),

  backSide: Yup.mixed<FileList>().when(['$docsUploaded', '$isDirty'], {
    is: (docsUploaded: boolean, isDirty: boolean) => !docsUploaded || isDirty,
    then: (s) =>
      s.when('documentType', {
        is: documentTypeOptions[2].value,
        then: (s) =>
          s
            .required()
            .label('Back')
            .test('selected', REQUIRED_MESSAGE, fileSelected)
            .test('max-size', MAX_FILE_SIZE_ERROR, maxFileSizeTest)
            .test('file-type', FILE_TYPE_ERROR_MESSAGE, fileTypeTest),
      }),
  }),
});

export type DocumentUploadFormValues = Yup.Asserts<typeof documentUploadSchema>;
