import { Fade, Paper, Slide } from '@material-ui/core';
import { colors } from 'constants/colors';
import PopperJs, { Modifiers } from 'popper.js';
import React, { useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Arrow, StyledPopper } from './BaseGraph.styles';

interface TooltipHandle {
  update: (x?: number, y?: number, content?: React.ReactNode) => void;
  setStyles: (props: TooltipStyleProps) => void;
  hide: () => void;
}

interface TooltipStyleProps {
  backgroundColor?: string;
  borderColor?: string;
  borderWidth?: string;
  textColor?: string;
}

interface MobileTooltipProps {
  boundariesElement: React.RefObject<Element>;
}

export const MobileTooltip = React.forwardRef(
  (
    { boundariesElement }: MobileTooltipProps,
    ref: React.ForwardedRef<TooltipHandle>
  ) => {
    const [visible, setVisible] = useState(false);
    const [content, setContent] = useState<React.ReactNode>(null);

    const [backgroundColor, setBackgroundColor] = useState<
      string | undefined
    >();
    const [borderColor, setBorderColor] = useState<string | undefined>();
    const [textColor, setTextColor] = useState<string | undefined>();

    const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null);
    const popper = useRef<PopperJs>(null);

    const anchorEl = useRef<SVGCircleElement>(null);

    const verticalLine = useRef<SVGLineElement>(null);

    useImperativeHandle(ref, () => ({
      update: (x: number, y: number, newContent: React.ReactNode) => {
        verticalLine.current!.setAttribute('x1', x.toString());
        verticalLine.current!.setAttribute('x2', x.toString());

        anchorEl.current!.setAttribute('cx', x.toString());
        anchorEl.current!.setAttribute('cy', y.toString());

        popper.current?.update();

        setContent(newContent);
        setVisible(true);
      },
      setStyles: (props: TooltipStyleProps) => {
        setBackgroundColor(props.backgroundColor);
        setBorderColor(props.borderColor);
        setTextColor(props.textColor);
      },
      hide: () => setVisible(false),
    }));

    const modifiers = useMemo(
      (): Modifiers => ({
        flip: {
          enabled: true,
        },
        preventOverflow: {
          enabled: true,
          boundariesElement: boundariesElement.current!,
        },
        arrow: {
          enabled: true,
          element: arrowRef!,
        },
      }),
      [arrowRef, boundariesElement]
    );

    const cssVars = {
      '--tooltip-background-color': backgroundColor,
      '--tooltip-border-color': borderColor,
      '--tooltip-text-color': textColor,
    } as React.CSSProperties;

    return (
      <g>
        <line
          ref={verticalLine}
          x1={0}
          x2={0}
          y1="0%"
          y2="100%"
          strokeWidth={2}
          stroke={visible ? colors.purple : 'none'}
        />
        <circle
          ref={anchorEl}
          cx={0}
          cy={0}
          r={5}
          fill={visible ? colors.white : 'none'}
          strokeWidth={2}
          stroke={visible ? colors.purple : 'none'}
        />
        <StyledPopper
          open={visible}
          placement="bottom"
          modifiers={modifiers}
          transition
          popperRef={popper}
          anchorEl={verticalLine.current}
          style={{
            ...cssVars,
            marginTop: verticalLine.current?.y2.animVal.value || 300 + 'px',
          }}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps}>
              <div style={{ position: 'relative', overflow: 'hidden' }}>
                <Paper elevation={0}>{content}</Paper>
                <Slide {...TransitionProps} direction="down">
                  <Arrow ref={setArrowRef} />
                </Slide>
              </div>
            </Fade>
          )}
        </StyledPopper>
      </g>
    );
  }
);
