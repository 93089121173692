import { StyledLink } from 'components/design-system/Link';
import {
  StepIntroduction,
  StepIntroductionTypography,
  StepIntroductionWidth,
} from 'components/design-system/StepComponents/StepComponents';
import { IsaDeclarationStep } from 'components/feature/openAccount/steps/IsaDeclarationStep/IsaDeclarationStep';
import { NationalIdentifierStep } from 'components/feature/openAccount/steps/NationalIdentifierStep';
import { NationalInsuranceNumberStep } from 'components/feature/openAccount/steps/NationalInsuranceNumberStep';
import { AnimatePresence } from 'framer-motion';
import {
  AffirmationKind,
  InSpecieSupport,
  TransferMethod,
  UserProfile,
  WrapperType,
  useUserProfileQuery,
} from 'generated/graphql';
import {
  dashboardTransfersPath,
  generateOpenAccountTypePath,
  mifidToTransfer,
} from 'paths';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Wrapper } from './Transfer.styles';
import { AboutTransferMethod } from './steps/aboutTransferMethod/AboutTransferMethod';
import { Confirm } from './steps/confirm/Confirm';
import { ConfirmDetailsStep } from './steps/confirmDetails/ConfirmDetails';
import { ErrorStep } from './steps/error/ErrorStep';
import {
  HowToTransfer,
  HowToTransferOnProceedValues,
} from './steps/howToTransfer/HowToTransfer';
import {
  PensionDetails,
  PensionDetailsOnProceedValues,
} from './steps/pensionDetails/PensionDetails';
import {
  ProviderDetails,
  ProviderDetailsOnProceedValues,
} from './steps/providerDetails/ProviderDetails';
import { Success } from './steps/success/Success';

interface TransferProps {
  wrapperType: WrapperType;
  onProceed?: () => void;
  source: 'openAccountStep' | 'startTransferStep';
  defaultValue?: number;
}

enum TransferFormSteps {
  IsaDeclaration = 'IsaDeclaration',
  OpenIsaNinoStep = 'OpenIsaNinoStep',
  TradingNinoStep = 'TradingNinoStep',
  ProviderDetails = 'ProviderDetails',
  PensionDetails = 'PensionDetails',
  HowToTransfer = 'HowToTransfer',
  AboutTransferMethod = 'AboutTransferMethod',
  MifidStep = 'MifidStep',
  ConfirmDetails = 'ConfirmDetails',
  Confirm = 'Confirm',
  Success = 'Success',
  Error = 'Error',
}

const tallForms: TransferFormSteps[] = [
  TransferFormSteps.IsaDeclaration,
  TransferFormSteps.ConfirmDetails,
  TransferFormSteps.Confirm,
];

function userHasAcknowledgedIsaDeclaration(userProfile?: UserProfile) {
  const affirmations =
    userProfile?.affirmations?.map((affirmation) => affirmation?.kind) || [];

  const hasReadAndUnderstoodIsaKfd = affirmations.includes(
    AffirmationKind.ReadAndUnderstoodIsaKfd
  );

  const hasNoExistingIsaThisTaxYear = affirmations.includes(
    AffirmationKind.NoExistingIsaThisTaxYear
  );

  return hasReadAndUnderstoodIsaKfd && hasNoExistingIsaThisTaxYear;
}

export function StartTransferForm({
  wrapperType,
  onProceed,
  source,
  defaultValue,
}: TransferProps) {
  const history = useHistory();
  const [activeStep, setActiveStep] = useState<TransferFormSteps | undefined>(
    wrapperType === WrapperType.Isa
      ? undefined
      : TransferFormSteps.ProviderDetails
  );
  const [
    providerDetailsValues,
    setProviderDetailsValues,
  ] = useState<ProviderDetailsOnProceedValues>();
  const [
    howToTransferValues,
    setHowToTransferValues,
  ] = useState<HowToTransferOnProceedValues>();
  const [
    pensionDetailsValues,
    setPensionDetailsValues,
  ] = useState<PensionDetailsOnProceedValues>();

  const userProfileQuery = useUserProfileQuery(undefined, {
    refetchOnMount: true,
    staleTime: 0,
    onSuccess: (data) => {
      if (activeStep !== undefined || wrapperType !== WrapperType.Isa) {
        return;
      }
      const userProfile = data?.userProfile as UserProfile;
      const hasAccounts = (data.clientSummary?.accounts?.length || 0) > 0;
      const hasIsaAccount = data.clientSummary?.accounts.some(
        (account) => account.wrapperType === WrapperType.Isa
      );

      if (!hasAccounts) {
        history.replace(generateOpenAccountTypePath({ type: 'transfer' }));
      }

      if (
        source === 'startTransferStep' &&
        wrapperType === WrapperType.Isa &&
        !hasIsaAccount
      ) {
        if (!userHasAcknowledgedIsaDeclaration(userProfile)) {
          setActiveStep(TransferFormSteps.IsaDeclaration);
          return;
        }
        if (!userProfile.nationalInsuranceNumberTokenised) {
          setActiveStep(TransferFormSteps.OpenIsaNinoStep);
          return;
        }
      }

      setActiveStep(TransferFormSteps.ProviderDetails);
    },
  });

  const mifidSupplied =
    userProfileQuery.data?.userProfile?.mifidIdentifierSupplied;
  const ninoSupplied =
    userProfileQuery.data?.userProfile?.nationalInsuranceNumberTokenised;
  const nationalities = [
    userProfileQuery.data?.userProfile?.nationality,
    ...(userProfileQuery.data?.userProfile?.otherNationalities || []),
  ];
  const isUserGB = nationalities.includes('GB');

  const submissionPensionDetails = pensionDetailsValues
    ? {
        ...pensionDetailsValues,
        estimatedValue: providerDetailsValues?.estimatedValue ?? 0,
      }
    : undefined;

  return (
    <Wrapper isTallForm={activeStep && tallForms.includes(activeStep)}>
      <AnimatePresence>
        {activeStep === TransferFormSteps.IsaDeclaration && (
          <IsaDeclarationStep
            shouldSetInitialAccount={false}
            onProceed={() => {
              if (source === 'startTransferStep' && !ninoSupplied) {
                setActiveStep(TransferFormSteps.OpenIsaNinoStep);
              } else {
                setActiveStep(TransferFormSteps.ProviderDetails);
              }
            }}
            onGoBack={() => {
              history.push(dashboardTransfersPath);
            }}
          />
        )}

        {(activeStep === TransferFormSteps.OpenIsaNinoStep ||
          activeStep === TransferFormSteps.TradingNinoStep) &&
          userProfileQuery.data!.userProfile && (
            <NationalInsuranceNumberStep
              onProceed={() => {
                if (activeStep === TransferFormSteps.OpenIsaNinoStep) {
                  setActiveStep(TransferFormSteps.ProviderDetails);
                }

                if (activeStep === TransferFormSteps.TradingNinoStep) {
                  if (
                    source === 'startTransferStep' &&
                    !mifidSupplied &&
                    howToTransferValues?.transferMethod ===
                      TransferMethod.InSpecie
                  ) {
                    setActiveStep(TransferFormSteps.MifidStep);
                  } else {
                    setActiveStep(TransferFormSteps.ConfirmDetails);
                  }
                }
              }}
              onGoBack={() => {
                history.push(dashboardTransfersPath);
              }}
              userProfile={userProfileQuery.data!.userProfile}
              source="transferStep"
              introduction={
                activeStep === TransferFormSteps.TradingNinoStep ? (
                  <StepIntroduction
                    mb={2}
                    $width={StepIntroductionWidth.normal}
                  >
                    <StepIntroductionTypography>
                      To make your account transfer as smooth as possible we
                      need to ask for your National Insurance number.{' '}
                      <StyledLink href={mifidToTransfer} target="_blank">
                        Learn more
                      </StyledLink>
                      .
                    </StepIntroductionTypography>
                  </StepIntroduction>
                ) : undefined
              }
            />
          )}
        {activeStep === TransferFormSteps.ProviderDetails && (
          <ProviderDetails
            wrapperType={wrapperType}
            onProceed={(values) => {
              setProviderDetailsValues(values);
              if (wrapperType === WrapperType.Sipp) {
                setActiveStep(TransferFormSteps.PensionDetails);
              } else {
                if (
                  values.cedingProvider.inSpecieSupport ===
                  InSpecieSupport.NotSupported
                ) {
                  setHowToTransferValues({
                    transferMethod: TransferMethod.Cash,
                  });
                  setActiveStep(TransferFormSteps.AboutTransferMethod);
                } else {
                  setActiveStep(TransferFormSteps.HowToTransfer);
                }
              }
            }}
            providerDetails={providerDetailsValues}
            defaultValue={defaultValue}
          />
        )}
        {activeStep === TransferFormSteps.PensionDetails && (
          <PensionDetails
            onProceed={(values) => {
              setPensionDetailsValues(values);
              if (
                providerDetailsValues?.cedingProvider.inSpecieSupport ===
                InSpecieSupport.NotSupported
              ) {
                setHowToTransferValues({
                  transferMethod: TransferMethod.Cash,
                });
                setActiveStep(TransferFormSteps.AboutTransferMethod);
              } else {
                setActiveStep(TransferFormSteps.HowToTransfer);
              }
            }}
            pensionDetails={pensionDetailsValues}
            onGoBack={() => {
              setActiveStep(TransferFormSteps.ProviderDetails);
            }}
          />
        )}
        {activeStep === TransferFormSteps.HowToTransfer &&
          providerDetailsValues && (
            <HowToTransfer
              wrapperType={wrapperType}
              cedingProvider={providerDetailsValues.cedingProvider}
              onProceed={(values) => {
                setHowToTransferValues(values);
                setActiveStep(TransferFormSteps.AboutTransferMethod);
              }}
              onGoBack={() => {
                if (wrapperType === WrapperType.Sipp) {
                  setActiveStep(TransferFormSteps.PensionDetails);
                } else {
                  setActiveStep(TransferFormSteps.ProviderDetails);
                }
              }}
            />
          )}
        {activeStep === TransferFormSteps.AboutTransferMethod &&
          howToTransferValues?.transferMethod && (
            <AboutTransferMethod
              wrapperType={wrapperType}
              transferMethod={howToTransferValues?.transferMethod}
              cedingProvider={providerDetailsValues?.cedingProvider!}
              onGoBack={() => {
                if (
                  providerDetailsValues?.cedingProvider.inSpecieSupport ===
                  InSpecieSupport.NotSupported
                ) {
                  if (wrapperType === WrapperType.Sipp) {
                    setActiveStep(TransferFormSteps.PensionDetails);
                  } else {
                    setActiveStep(TransferFormSteps.ProviderDetails);
                  }
                } else {
                  setActiveStep(TransferFormSteps.HowToTransfer);
                }
              }}
              onProceed={() => {
                if (
                  howToTransferValues.transferMethod === TransferMethod.InSpecie
                ) {
                  if (!ninoSupplied && isUserGB) {
                    setActiveStep(TransferFormSteps.TradingNinoStep);
                    return;
                  }

                  if (source === 'startTransferStep' && !mifidSupplied) {
                    setActiveStep(TransferFormSteps.MifidStep);
                    return;
                  }
                }

                setActiveStep(TransferFormSteps.ConfirmDetails);
              }}
            />
          )}
        {activeStep === TransferFormSteps.MifidStep && (
          <NationalIdentifierStep
            source="startTransferStep"
            onProceed={() => {
              setActiveStep(TransferFormSteps.ConfirmDetails);
            }}
            onGoBack={() => {
              setActiveStep(TransferFormSteps.AboutTransferMethod);
            }}
            introduction={
              <StepIntroduction mb={2} $width={StepIntroductionWidth.normal}>
                <StepIntroductionTypography>
                  To make your account transfer as smooth as possible we need to
                  ask for your national identifier.{' '}
                  <StyledLink href={mifidToTransfer} target="_blank">
                    Learn more
                  </StyledLink>
                  .
                </StepIntroductionTypography>
              </StepIntroduction>
            }
          />
        )}
        {activeStep === TransferFormSteps.ConfirmDetails &&
          howToTransferValues &&
          providerDetailsValues && (
            <ConfirmDetailsStep
              wrapperType={wrapperType}
              cedingProvider={providerDetailsValues.cedingProvider}
              onProceed={() => {
                setActiveStep(TransferFormSteps.Confirm);
              }}
              onGoBack={() => {
                if (
                  source === 'startTransferStep' &&
                  !mifidSupplied &&
                  howToTransferValues.transferMethod === TransferMethod.InSpecie
                ) {
                  setActiveStep(TransferFormSteps.MifidStep);
                } else {
                  setActiveStep(TransferFormSteps.AboutTransferMethod);
                }
              }}
            />
          )}
        {activeStep === TransferFormSteps.Confirm &&
          howToTransferValues &&
          providerDetailsValues && (
            <Confirm
              wrapperType={wrapperType}
              transferMethod={howToTransferValues?.transferMethod}
              cedingProvider={providerDetailsValues.cedingProvider}
              cedingProviderAccountNumber={
                providerDetailsValues.cedingProviderAccountNumber
              }
              pensionDetails={submissionPensionDetails}
              onGoBack={() => {
                setActiveStep(TransferFormSteps.ConfirmDetails);
              }}
              onProceed={() => {
                if (onProceed) {
                  onProceed();
                } else {
                  setActiveStep(TransferFormSteps.Success);
                }
              }}
              onError={() => {
                setActiveStep(TransferFormSteps.Error);
              }}
            />
          )}
        {activeStep === TransferFormSteps.Success && (
          <Success
            wrapperType={wrapperType}
            onStartAnother={() => {
              setProviderDetailsValues(undefined);
              setHowToTransferValues(undefined);
              setPensionDetailsValues(undefined);
              setActiveStep(TransferFormSteps.ProviderDetails);
            }}
          />
        )}
        {activeStep === TransferFormSteps.Error && (
          <ErrorStep wrapperType={wrapperType} />
        )}
      </AnimatePresence>
    </Wrapper>
  );
}
