import { Variants, motion } from 'framer-motion';
import styled from 'styled-components';

const pageVariants: Variants = {
  initialFromLeft: {
    x: '-100%',
    opacity: 0,
  },
  initialFromRight: {
    x: '100%',
    opacity: 0,
  },
  in: {
    x: 0,
    opacity: 1,
    transition: {
      stiffness: 0,
      duration: 0.5,
      delay: 0,
      ease: 'easeInOut',
    },
  },
  outLeft: {
    x: '-100%',
    opacity: 0,
    position: 'absolute',
    transition: {
      stiffness: 0,
      duration: 0.5,
      delay: 0,
      ease: 'easeInOut',
    },
  },
  outRight: {
    x: '100%',
    opacity: 0,
    position: 'absolute',
    transition: {
      stiffness: 0,
      duration: 0.5,
      delay: 0,
      ease: 'easeInOut',
    },
  },
};

interface LandingMenuWrapperProps {
  $animateFrom: 'left' | 'right';
}

export const LandingMenuWrapper = styled(
  motion.div
).attrs<LandingMenuWrapperProps>(({ $animateFrom, ...rest }) => {
  return {
    initial:
      $animateFrom === 'left'
        ? pageVariants.initialFromLeft
        : pageVariants.initialFromRight,
    animate: 'in',
    exit:
      $animateFrom === 'left' ? pageVariants.outLeft : pageVariants.outRight,
    variants: pageVariants,
  };
})<LandingMenuWrapperProps>`
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 1280px;
  max-width: 100%;
  flex: 1;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    align-items: center;
  }
`;
