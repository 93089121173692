import { Checkbox, FormControlLabel } from '@material-ui/core';
import { TextField } from 'components/NewForm/TextField';
import React from 'react';
import { Control } from 'react-hook-form';

interface NationalIdentifierInputProps {
  itemName: string;
  label: string;
  identifierName: string;
  isSkipped?: boolean;
  defaultValue?: string;
  control?: Control;
  disabled?: boolean;
  onSkipChange?: (itemName: string, skip: boolean) => void;
}

export function NationalIdentifierInput({
  itemName,
  label,
  identifierName,
  defaultValue,
  isSkipped = false,
  control,
  disabled = false,
  onSkipChange,
}: NationalIdentifierInputProps) {
  const handleSkipChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSkipChange?.(itemName, event.target.checked);
  };

  return (
    <>
      <input
        type="hidden"
        name={`${itemName}.name`}
        ref={control?.register()}
        disabled={isSkipped}
        defaultValue={identifierName}
      />
      <TextField
        id={`${itemName}.value`}
        name={`${itemName}.value`}
        label={label}
        inputRef={control?.register()}
        disabled={isSkipped || disabled}
        defaultValue={defaultValue}
      />
      <FormControlLabel
        disabled={disabled}
        control={
          <Checkbox
            checked={isSkipped}
            onChange={handleSkipChange}
            disabled={disabled}
          />
        }
        label={"I don't have this"}
      />
    </>
  );
}
