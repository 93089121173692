import { QueryState } from 'components/QueryState';
import { StyledLink } from 'components/design-system/Link';
import {
  StepIntroduction,
  StepIntroductionTypography,
  StepIntroductionWidth,
} from 'components/design-system/StepComponents/StepComponents';
import type { Basket } from 'components/feature/PortfolioBuilder/hooks/useFundsBasket';
import { AboutYourOtherPensionsStep } from 'components/feature/openSipp/steps/AboutYourOtherPensionsStep/AboutYourOtherPensionsStep';
import { DeclarationStep } from 'components/feature/openSipp/steps/DeclarationStep';
import { FundingChoicesStep } from 'components/feature/openSipp/steps/FundingChoices/FundingChoicesStep';
import { IllustrationStep } from 'components/feature/openSipp/steps/IllustrationStep/IllustrationStep';
import { MoreAboutYouStep } from 'components/feature/openSipp/steps/MoreAboutYouStep/MoreAboutYouStep';
import { ReviewYourDecision } from 'components/feature/openSipp/steps/ReviewYourDecision/ReviewYourDecision';
import { GaEventNames } from 'constants/gaConstants';
import { AnimatePresence } from 'framer-motion';
import {
  AccountStatus,
  AccountType,
  AffirmationKind,
  IllustrationStatus,
  KycStatus,
  MifidReportingNationalityType,
  OnboardingStatus,
  Toggle,
  UserProfile,
  WrapperType,
  useAccountEligibilitiesQuery,
  useMifidReportingNationalityQuery,
  useOnboardingStatusQuery,
  useReferralCodeQuery,
  useUserProfileOnboardingQuery,
} from 'generated/graphql';
import { subtractYears } from 'helpers/dateHelpers';
import { trackGa } from 'helpers/track';
import {
  useHasOpenableAccount,
  useHasPendingAccount,
} from 'hooks/useAccountEligibility';
import { useToggle } from 'hooks/useFeatureToggle';
import { useGetEmployerCode } from 'hooks/useGetEmployerCode';
import { useOnboardingStatus } from 'hooks/useOnboardingStatus';
import { useOpenAccount } from 'hooks/useOpenAccount';
import { sum } from 'lodash';
import {
  dashboardPath,
  generateOpenAccountTypePath,
  mifidToInvest,
  openPensionPath,
} from 'paths';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import type {
  UserProfileOnboardingQueryIllustration,
  UserProfileOnboardingQueryUserProfile,
} from 'types/graphqlTypes';
import { useLocalStorage } from 'usehooks-ts';
import { AutoEscalationStep } from '../openSipp/steps/AutoEscalationStep/AutoEscalationStep';
import { BeneficiariesStep } from '../openSipp/steps/BeneficiariesStep/BeneficiariesStep';
import { EmployerContributionStep } from '../openSipp/steps/EmployerContributionStep/EmployerContributionStep';
import { FundingChoicesLegacyStep } from '../openSipp/steps/FundingChoicesLegacy/FundingChoicesLegacy';
import { WhatYourInvestedInStep } from '../openSipp/steps/WhatYourInvestedIn/WhatYourInvestedInStep';
import { YourEmployerStep } from '../openSipp/steps/YourEmployerStep/YourEmployerStep';
import { SetupMfaMethodForm } from '../setupMfa/SetupMfaMethodForm';
import { Wrapper } from './OpenAccountForm.style';
import { AddCashStep } from './steps/AddCashStep/AddCashStep';
import { AddressesStep } from './steps/AddressStepV2/AddressStepV2';
import { BankAccountStep } from './steps/BankAccountStep';
import { BeforeWeGetStarted } from './steps/BeforeWeGetStarted';
import { CannotOpenSipp } from './steps/CannotOpenSipp/CannotOpenSipp';
import { CheckoutStep } from './steps/CheckoutStep/CheckoutStep';
import { ChooseAccountType } from './steps/ChooseAccountType/ChooseAccountType';
import { ConfirmationStep } from './steps/ConfirmationStep';
import { ContactSupport } from './steps/ContactSupport';
import { DateOfBirthStep } from './steps/DateOfBirthStep/DateOfBirthStep';
import { DocumentUploadStep } from './steps/DocumentUploadStepV2/DocumentUploadStepV2';
import { Action, IDVStep } from './steps/IDVStep/IDVStep';
import { IsaDeclarationStep } from './steps/IsaDeclarationStep/IsaDeclarationStep';
import { NationalIdentifierStep } from './steps/NationalIdentifierStep';
import { NationalInsuranceNumberStep } from './steps/NationalInsuranceNumberStep';
import { NationalityStep } from './steps/NationalityStep';
import { PendingAccount } from './steps/PendingAccount';
import { SwitchAccountTypeStep } from './steps/SwitchAccountTypeStep/SwitchAccountTypeStep';
import { TransferAccountType } from './steps/TransferAccountType/TransferAccountType';
import { TransferStep } from './steps/TransferV2/Transfer';

enum MultistepFormSteps {
  PendingAccount = 'PendingAccount', // Not really a step -
  ChooseAccountType = 'ChooseAccountType',
  TransferAccountType = 'TransferAccountType', // Optional step - only display select transfer on the first step
  Transfer = 'Transfer', // Optional step - only display select transfer on the first step
  SwitchAccountType = 'SwitchAccountType', // Optional step - only display if switching account types
  IsaDeclaration = 'IsaDeclaration', // Optional step - only open ISA
  MobileMfa = 'MobileMfa', // Optional step - skipped if already done
  BeforeWeGetStarted = 'BeforeWeGetStarted',
  AddressesStep = 'AddressesStep',
  NationalityStep = 'NationalityStep',
  MiFidStep = 'MiFidStep', // to be optional, but currently required depending on the nationalities selected
  NationalInsuranceNumberStep = 'NationalInsuranceNumberStep',
  DateOfBirthStep = 'DateOfBirthStep',
  MoreAboutYouSipp = 'MoreAboutYou[SIPP]', // Optional step - only open SIPP
  Beneficiaries = 'Beneficiaries[SIPP]', // Optional step - only open SIPP
  AboutYourOtherPensionsSipp = 'AboutYourOtherPensions[SIPP]', // Optional step - only open SIPP
  EmployerContributions = 'EmployerContributions[SIPP]', // Optional step - only open SIPP
  YourEmployerPensionSipp = 'YourEmployer[SIPP]', // only open SIPP
  FundingChoicesSipp = 'FundingChoices[SIPP]', // Optional step - only open SIPP
  AutoEscalation = 'AutoEscalation[SIPP]', // Optional step - only open SIPP
  IllustrationSipp = 'Illustration[SIPP]', // Optional step - only open SIPP
  ReviewYourDecisionSipp = 'ReviewYourDecision[SIPP]', // Optional step - only open SIPP
  DeclarationSipp = 'Declaration[SIPP]', // Optional step - only open SIPP
  WhatYouAreInvestedInStep = '  WhatYouAreInvestedInStep[SIPP]', // Optional step - only open SIPP
  BankAccountStep = 'BankAccountStep',
  DocumentUploadStep = 'DocumentUploadStep',
  ConfirmationStep = 'ConfirmationStep',
  IDVStep = 'IDVStep', // @todo - rename/remove step?
  CheckoutStep = 'CheckoutStep', // Optional step - only display if basket is not empty
  AddCashStep = 'AddCashStep',
  CannotOpenSipp = 'CannotOpenSipp', // optional step when user is over the age of 75
}

/**
 * userInitialAccountTypeSelectedAccountTypeMismatch
 *
 * @param selectedAccountType The account type the user has selected they want to setup - (the 'open isa' button in the dashboard)
 * @param userInitialAccountType The account type the user is in the process of setting up.
 * @returns boolean
 */
function userInitialAccountTypeSelectedAccountTypeMismatch(
  selectedAccountType: string | undefined,
  userInitialAccountType: AccountType | undefined
) {
  return (
    userInitialAccountType &&
    userInitialAccountType !== AccountType.NotSpecified &&
    selectedAccountType !== AccountType.NotSpecified &&
    selectedAccountType !== userInitialAccountType
  );
}

/**
 * Determines if the user has acknowledged the ISA declaration.
 *
 * @param {UserProfile} userProfile - The user's profile.
 * @returns {boolean} Returns true if the user has acknowledged the ISA declaration, otherwise returns false.
 */
function userHasAcknowledgedIsaDeclaration(
  userProfile?: UserProfileOnboardingQueryUserProfile
) {
  const affirmations =
    userProfile?.affirmations?.map((affirmation) => affirmation?.kind) || [];

  const hasReadAndUnderstoodIsaKfd = affirmations.includes(
    AffirmationKind.ReadAndUnderstoodIsaKfd
  );

  const hasNoExistingIsaThisTaxYear = affirmations.includes(
    AffirmationKind.NoExistingIsaThisTaxYear
  );

  return hasReadAndUnderstoodIsaKfd && hasNoExistingIsaThisTaxYear;
}

function accountsNeedsNiNumber(accountType: AccountType | undefined) {
  return (
    accountType !== undefined &&
    [AccountType.Isa, AccountType.Sipp].includes(accountType)
  );
}

interface StepChecker {
  step: MultistepFormSteps;
  stepCompleted: (
    userProfile: UserProfileOnboardingQueryUserProfile,
    illustration: UserProfileOnboardingQueryIllustration,
    toggles: Toggle[]
  ) => boolean;
  appliesTo: AccountType[];
}

const pensionSteps: StepChecker[] = [
  {
    step: MultistepFormSteps.MoreAboutYouSipp,
    stepCompleted: (userProfile) => {
      return !!(
        userProfile.title &&
        userProfile.maritalStatus &&
        userProfile.employmentStatus &&
        userProfile.pensionDetails?.retirementAge
      );
    },
    appliesTo: [AccountType.Sipp],
  },

  {
    step: MultistepFormSteps.AboutYourOtherPensionsSipp,
    stepCompleted: (userProfile) => {
      const pensionDetails = userProfile.pensionDetails!;
      const accessedFieldComplete =
        pensionDetails.flexibleBenefitAccessed !== undefined;
      const dateFieldComplete =
        pensionDetails.flexibleBenefitAccessed === false ||
        !!pensionDetails.flexibleBenefitAccessedDate;
      const optedOutFieldComplete =
        pensionDetails.optedOutOfEmployerScheme !== undefined;
      return (
        accessedFieldComplete && dateFieldComplete && optedOutFieldComplete
      );
    },
    appliesTo: [AccountType.Sipp],
  },
  {
    step: MultistepFormSteps.EmployerContributions,
    stepCompleted: (userProfile, illustration, toggles) => {
      const improvedContributionsEnabled = toggles.find(
        (toggle) => toggle?.name === 'global-improved-contributions'
      )?.enabled;

      if (!improvedContributionsEnabled) {
        return true;
      }

      return (
        !!illustration?.monthlyEmployeeContribution?.amount ||
        !!illustration?.monthlyEmployeeViaPayrollContribution?.amount
      );
    },
    appliesTo: [AccountType.Sipp],
  },
  {
    step: MultistepFormSteps.YourEmployerPensionSipp,
    stepCompleted: (userProfile, illustration) => {
      return (
        (userProfile?.employments || []).length > 0 &&
        (!!illustration?.initialPayment?.amount ||
          !!illustration?.transferFromAnotherProvider?.amount ||
          !!illustration?.monthlyEmployeeContribution?.amount ||
          !!illustration?.monthlyEmployerContribution?.amount)
      );
    },
    appliesTo: [AccountType.Sipp],
  },
  {
    step: MultistepFormSteps.FundingChoicesSipp,
    stepCompleted: (userProfile, illustration) => {
      return (
        !!illustration?.initialPayment?.amount ||
        !!illustration?.transferFromAnotherProvider?.amount ||
        !!illustration?.monthlyEmployeeContribution?.amount ||
        !!illustration?.monthlyEmployerContribution?.amount
      );
    },
    appliesTo: [AccountType.Sipp],
  },
  {
    step: MultistepFormSteps.IllustrationSipp,
    stepCompleted: (userProfile, illustration) => {
      return (
        [IllustrationStatus.Generated].includes(illustration.status) &&
        (userProfile?.pensionDetails?.illustrationAccepted || false)
      );
    },
    appliesTo: [AccountType.Sipp],
  },
  {
    step: MultistepFormSteps.ReviewYourDecisionSipp,
    stepCompleted: (userProfile) => {
      return (
        !!userProfile?.pensionDetails?.illustrationAccepted &&
        (userProfile?.affirmations?.some(
          (a) => a?.kind === AffirmationKind.ReadAndUnderstoodSippKfd
        ) ??
          false)
      );
    },
    appliesTo: [AccountType.Sipp],
  },
  {
    step: MultistepFormSteps.DeclarationSipp,
    stepCompleted: (userProfile) => {
      // @todo - update query for this function to work
      return (userProfile.affirmations || []).some(
        (affirmation) =>
          affirmation?.kind === AffirmationKind.ReadAndUnderstoodSippKfd
      );
    },
    appliesTo: [AccountType.Sipp],
  },
  {
    step: MultistepFormSteps.WhatYouAreInvestedInStep,
    stepCompleted: (userProfile, illustration) => {
      return (
        !!userProfile?.pensionDetails?.illustrationAccepted &&
        userProfile?.pensionDetails?.defaultFundOptOut !== null
      );
    },
    appliesTo: [AccountType.Sipp],
  },
  {
    step: MultistepFormSteps.AutoEscalation,
    stepCompleted: (userProfile, illustration) => {
      return !!userProfile?.pensionDetails?.autoEscalation?.startDate;
    },
    appliesTo: [AccountType.Sipp],
  },
  {
    step: MultistepFormSteps.Beneficiaries,
    stepCompleted: (userProfile) => {
      return !!userProfile?.beneficiaries?.length;
    },
    appliesTo: [AccountType.Sipp],
  },
];

/**
 *
 * @param selectedAccountType The account type the user has selected they want to setup - (the 'open isa' button in the dashboard)
 * @param userInitialAccountType The account type the user is in the process of setting up.
 * @param paramType
 * @returns
 */
function deriveInitialStep(
  selectedAccountType:
    | AccountType.Isa
    | AccountType.Gia
    | AccountType.Sipp
    | AccountType.NotSpecified,
  paramType: string | undefined,
  userProfile: UserProfileOnboardingQueryUserProfile,
  illustration: UserProfileOnboardingQueryIllustration,
  reportingNationality: MifidReportingNationalityType | undefined,
  paymentId: string | undefined,
  globalDefaultFundOptOut: boolean,
  toggles: Toggle[]
) {
  const userInitialAccountType = userProfile?.initialAccountType;
  const hasCompletedTransferStep = !!userProfile?.transferIntent
    ?.cedingProviderAccountNumber;

  const affirmations = userProfile?.affirmations
    ? userProfile?.affirmations?.map((affirmation) => affirmation?.kind)
    : [];

  const hasEmployer = (userProfile?.employments || []).length > 0;

  if (userProfile?.dateOfBirth)
    if (userProfile?.kycStatus === KycStatus.Cancelled) {
      return MultistepFormSteps.AddressesStep;
    }

  const completedAffirmations =
    affirmations.includes(AffirmationKind.IsOver_18YearsOld) &&
    affirmations.includes(AffirmationKind.IsUkTaxPayer) &&
    affirmations.includes(AffirmationKind.NotUsPersonForFatca);

  if (
    ((userInitialAccountType === AccountType.NotSpecified ||
      userInitialAccountType === AccountType.Unknown) &&
      selectedAccountType === AccountType.NotSpecified) ||
    paramType === 'switch'
  ) {
    return MultistepFormSteps.ChooseAccountType;
  }

  const hasCompletedDataOfBirthStep = userProfile?.dateOfBirth;
  const yearOfBirth = new Date(userProfile?.dateOfBirth);
  const minDOBAllowed = subtractYears(new Date(), 75);
  const hasCompletedNationalInsuranceStep =
    userProfile?.nationalInsuranceNumberTokenised;
  const hasCompletedAddressStep = !!userProfile?.addressHistory?.length;
  const hasCompletedNationalityStep = userProfile?.nationality;
  const hasCompletedNationalIdentifierStep =
    userProfile?.nationalIdentifierTokenised;
  const hasCompletedBankAccountStep = userProfile?.bankAccountTokenised;
  const hasCompletedIdUploadStep = !!userProfile?.identityDocuments?.length;
  const hasAdditionalDocumentUploadsPending =
    userProfile?.pendingDocumentUploadRequestCount === 1;

  if (paymentId) {
    return MultistepFormSteps.AddCashStep;
  }

  if (
    userInitialAccountTypeSelectedAccountTypeMismatch(
      selectedAccountType,
      userInitialAccountType
    )
  ) {
    return MultistepFormSteps.SwitchAccountType;
  }

  if (
    userInitialAccountType === AccountType.Sipp &&
    yearOfBirth <= minDOBAllowed
  ) {
    return MultistepFormSteps.CannotOpenSipp;
  }

  // Check for presence of values in userProfile and return appropriate step
  // We used to check from last step to first but due to the different steps depending on the initial account type, this
  // caused issues so we now check from first to last

  // Handle IDV step
  if (hasAdditionalDocumentUploadsPending) return MultistepFormSteps.IDVStep;

  // Handle transfer types
  if (!hasCompletedTransferStep && paramType === 'transfer') {
    if (userInitialAccountType) {
      return MultistepFormSteps.Transfer;
    } else {
      return MultistepFormSteps.TransferAccountType;
    }
  }

  // Step 1 - Handle selected ISA / initial account ISA -
  if (
    userInitialAccountType === AccountType.Isa ||
    selectedAccountType === AccountType.Isa
  ) {
    if (!userHasAcknowledgedIsaDeclaration(userProfile)) {
      return MultistepFormSteps.IsaDeclaration;
    }
    if (!completedAffirmations) return MultistepFormSteps.BeforeWeGetStarted;
  } else {
    // Step 1 - Handle selected GIA or pension -
    if (!completedAffirmations) {
      return MultistepFormSteps.BeforeWeGetStarted;
    }
  }

  // Step 2 - Handle data of birth
  if (completedAffirmations && !hasCompletedDataOfBirthStep) {
    return MultistepFormSteps.DateOfBirthStep;
  }

  const selectedStep = pensionSteps
    .filter((step) => {
      if (step.step === MultistepFormSteps.AutoEscalation) {
        return hasEmployer;
      }
      if (step.step === MultistepFormSteps.WhatYouAreInvestedInStep) {
        return globalDefaultFundOptOut;
      }
      return true;
    })
    .find((step) => {
      return (
        step.appliesTo.includes(
          userInitialAccountType || selectedAccountType
        ) && !step.stepCompleted(userProfile!, illustration!, toggles)
      );
    });

  if (selectedStep) {
    return selectedStep.step;
  }

  // Step 3 - Handle National Insurance
  if (
    (accountsNeedsNiNumber(userInitialAccountType) ||
      accountsNeedsNiNumber(selectedAccountType)) &&
    !hasCompletedNationalInsuranceStep
  )
    return MultistepFormSteps.NationalInsuranceNumberStep;

  // Step 4 - Handle address
  if (!hasCompletedAddressStep) return MultistepFormSteps.AddressesStep;

  // Step 5 - Handle nationality
  if (!hasCompletedNationalityStep) return MultistepFormSteps.NationalityStep;

  // Step 6 - Handle MiFID
  if (
    reportingNationality?.identifiers.length &&
    !hasCompletedNationalIdentifierStep
  )
    return MultistepFormSteps.MiFidStep;

  // Step 7 - Handle bank account
  if (!hasCompletedBankAccountStep) return MultistepFormSteps.BankAccountStep;

  // Step 8 - Handle document upload
  if (!hasCompletedIdUploadStep) return MultistepFormSteps.DocumentUploadStep;

  const needsToCompleteBeneficiaries =
    userProfile.initialAccountType === AccountType.Sipp;
  const hasCompletedBeneficiariesStep =
    sum(
      (userProfile.beneficiaries ?? []).map(({ proportion }) => proportion)
    ) === 1;

  if (needsToCompleteBeneficiaries && !hasCompletedBeneficiariesStep) {
    return MultistepFormSteps.Beneficiaries;
  }

  // Step 9 - Handle confirmation
  return MultistepFormSteps.ConfirmationStep;
}

/**
 * Derives the account type from the given parameter type.
 *
 * @param {string | undefined} paramType - The type of the parameter.
 * @return {AccountType} The derived account type.
 */
function deriveAccountTypeFromParam(paramType: string | undefined) {
  if (paramType === 'isa') return AccountType.Isa;
  if (paramType === 'gia') return AccountType.Gia;
  if (paramType === 'pension') return AccountType.Sipp;
  return AccountType.NotSpecified;
}

const tallForms: MultistepFormSteps[] = [
  MultistepFormSteps.IsaDeclaration,
  MultistepFormSteps.TransferAccountType,
  MultistepFormSteps.Transfer,
  MultistepFormSteps.AddressesStep,
  MultistepFormSteps.DocumentUploadStep,
];

interface OpenAccountFormStepsProps {
  paymentId?: string;
}

function OpenAccountFormSteps({ paymentId }: OpenAccountFormStepsProps) {
  const {
    setOnboardingStatus,
    removeOnboardingAccountType,
  } = useOnboardingStatus();
  const [defaultFundOptOutToggle] = useToggle('global-default-fund-opt-out');
  const [improvedContributionsToggle] = useToggle(
    'global-improved-contributions'
  );

  const [basketLs] = useLocalStorage<Basket>('nonAccountBasket', {});
  const hasUnknownBasket = !!basketLs.unknown;

  const { type: paramType, resume: paramResume } = useParams<{
    type: string | undefined;
    resume: string | undefined;
  }>();
  const isResuming = paramResume === 'resume';

  const [showResumingMsg, setShowResumingMsg] = useState<boolean>(isResuming);
  const selectedAccountType = deriveAccountTypeFromParam(paramType);
  const [activeStep, setActiveStep] = useState<
    MultistepFormSteps | undefined
  >();
  const [accountId, setAccountId] = useState<string>();
  const [wrapperType, setWrapperType] = useState<WrapperType | undefined>();
  const [accountType, setAccountType] = useState<AccountType | undefined>();

  const { openAccount } = useOpenAccount();

  const userProfileQuery = useUserProfileOnboardingQuery(undefined, {
    onSuccess: (data) => {
      const initialAccountType = data?.userProfile?.initialAccountType;

      // Don't change the step if it is already set
      if (activeStep !== undefined) {
        return;
      }

      if (
        paymentId &&
        data.clientSummary?.accounts &&
        data.clientSummary?.accounts?.length === 1 &&
        data.clientSummary?.accounts[0].id
      ) {
        setAccountId(data.clientSummary.accounts[0].id);
      } else if (
        data.clientSummary?.accounts &&
        data.clientSummary?.accounts?.length > 0 &&
        !paymentId
      ) {
        history.push(dashboardPath);
        return;
      }

      if (
        (initialAccountType === AccountType.NotSpecified ||
          initialAccountType === AccountType.Unknown) &&
        selectedAccountType !== AccountType.NotSpecified
      ) {
        openAccount(selectedAccountType, '');
        return;
      }

      setAccountType(initialAccountType);

      // If the user profile contains nationality information, the useMifidReportingNationalityQuery will handle the set of the initial step
      // so that it can take care of nationality identifiers step
      if (
        data.userProfile?.nationality ||
        data.userProfile?.otherNationalities?.length
      ) {
        return;
      }

      const initialStep = deriveInitialStep(
        selectedAccountType,
        paramType,
        data.userProfile!,
        data.illustration!,
        undefined,
        paymentId!,
        defaultFundOptOutToggle?.enabled ?? false,
        [improvedContributionsToggle!]
      );

      setActiveStep(initialStep);
    },
  });

  const hasEmployer =
    (userProfileQuery.data?.userProfile?.employments || []).length > 0;

  const mifidReportingNationalityQuery = useMifidReportingNationalityQuery(
    {
      nationalities: [
        userProfileQuery.data?.userProfile?.nationality,
        ...(userProfileQuery.data?.userProfile?.otherNationalities || []),
      ] as Array<string>,
    },
    {
      enabled: !!userProfileQuery.data?.userProfile?.nationality,
      onSuccess: (data) => {
        const initialAccountType =
          userProfileQuery.data?.userProfile?.initialAccountType;
        // Don't change the step if it is already set
        if (activeStep !== undefined) {
          return;
        }
        if (
          paymentId &&
          userProfileQuery.data?.clientSummary?.accounts &&
          userProfileQuery.data?.clientSummary?.accounts?.length === 1 &&
          userProfileQuery.data?.clientSummary?.accounts[0].id
        ) {
          setAccountId(userProfileQuery.data?.clientSummary.accounts[0].id);
        } else if (
          userProfileQuery.data?.clientSummary?.accounts &&
          userProfileQuery.data?.clientSummary?.accounts?.length > 0 &&
          !paymentId
        ) {
          history.push(dashboardPath);
        }

        setAccountType(initialAccountType);

        const initialStep = deriveInitialStep(
          selectedAccountType,
          paramType,
          userProfileQuery.data?.userProfile!,
          userProfileQuery.data?.illustration!,
          data.mifidReportingNationality as MifidReportingNationalityType,
          paymentId,
          defaultFundOptOutToggle?.enabled ?? false,
          [improvedContributionsToggle!]
        );

        setActiveStep(initialStep);
      },
    }
  );

  const hasVerifiedPhoneNumber = userProfileQuery.data?.userProfile?.phoneNumbers?.some(
    (no) => no?.useForTwoFactorAuthentication && no?.isVerified
  );

  const isTransferIntent =
    !!userProfileQuery.data?.userProfile?.transferIntent ||
    paramType === 'transfer';

  const affirmations = userProfileQuery.data?.userProfile?.affirmations
    ? userProfileQuery.data?.userProfile?.affirmations?.map(
        (affirmation) => affirmation?.kind
      )
    : [];

  const completedAffirmations =
    affirmations.includes(AffirmationKind.IsOver_18YearsOld) &&
    affirmations.includes(AffirmationKind.IsUkTaxPayer) &&
    affirmations.includes(AffirmationKind.NotUsPersonForFatca);

  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  return (
    <Wrapper isTallForm={activeStep && tallForms.includes(activeStep)}>
      <QueryState {...userProfileQuery} errorComponent={<ContactSupport />}>
        {() => {
          return (
            <>
              <AnimatePresence>
                {activeStep === MultistepFormSteps.ChooseAccountType && (
                  <ChooseAccountType
                    onProceed={(selectedAccountType) => {
                      setAccountType(selectedAccountType);
                      if (
                        accountType &&
                        accountType !== AccountType.NotSpecified &&
                        accountType !== selectedAccountType &&
                        paramType !== 'switch'
                      ) {
                        setActiveStep(MultistepFormSteps.SwitchAccountType);
                        return;
                      }

                      if (
                        selectedAccountType === AccountType.Isa &&
                        userProfileQuery.data?.userProfile &&
                        !userHasAcknowledgedIsaDeclaration(
                          userProfileQuery.data?.userProfile as UserProfile
                        )
                      ) {
                        setActiveStep(MultistepFormSteps.IsaDeclaration);
                        return;
                      }

                      setActiveStep(MultistepFormSteps.BeforeWeGetStarted);
                    }}
                    onTransfer={() => {
                      setActiveStep(MultistepFormSteps.TransferAccountType);
                    }}
                  />
                )}
                {activeStep === MultistepFormSteps.TransferAccountType && (
                  <TransferAccountType
                    onProceed={(selectedAccountType) => {
                      setAccountType(selectedAccountType);
                      setActiveStep(MultistepFormSteps.Transfer);
                    }}
                  />
                )}
                {accountType && activeStep === MultistepFormSteps.Transfer && (
                  <>
                    <TransferStep
                      accountType={accountType}
                      onProceed={() => {
                        if (accountType === AccountType.Gia) {
                          setActiveStep(MultistepFormSteps.BeforeWeGetStarted);
                        }
                        if (accountType === AccountType.Isa) {
                          if (
                            !userHasAcknowledgedIsaDeclaration(
                              userProfileQuery.data?.userProfile as UserProfile
                            )
                          ) {
                            setActiveStep(MultistepFormSteps.IsaDeclaration);
                          } else {
                            setActiveStep(
                              MultistepFormSteps.BeforeWeGetStarted
                            );
                          }
                        }
                        if (accountType === AccountType.Sipp) {
                          setActiveStep(MultistepFormSteps.DateOfBirthStep);
                        }
                      }}
                    />
                  </>
                )}
                {accountType &&
                  activeStep === MultistepFormSteps.SwitchAccountType && (
                    <SwitchAccountTypeStep
                      currentAccountType={accountType}
                      newAccountType={selectedAccountType}
                      onProceed={(continueWithType) => {
                        if (continueWithType === AccountType.Isa) {
                          setAccountType(AccountType.Isa);
                          setActiveStep(MultistepFormSteps.IsaDeclaration);
                        }
                        if (continueWithType === AccountType.Gia) {
                          setAccountType(AccountType.Gia);
                          setActiveStep(MultistepFormSteps.BeforeWeGetStarted);
                        }
                        if (continueWithType === AccountType.Sipp) {
                          setAccountType(AccountType.Sipp);
                          setActiveStep(MultistepFormSteps.DateOfBirthStep);
                        }
                      }}
                    />
                  )}
                {activeStep === MultistepFormSteps.IsaDeclaration && (
                  <IsaDeclarationStep
                    onProceed={() => {
                      if (completedAffirmations) {
                        setActiveStep(MultistepFormSteps.DateOfBirthStep);
                      } else {
                        setActiveStep(MultistepFormSteps.BeforeWeGetStarted);
                      }
                    }}
                    onSwitchToGia={() => {
                      openAccount(AccountType.Gia, 'ISA Declaration');
                      // This is purely to get the state configured correctly for the
                      // DOB and subsequent steps and seems to be a bit of a hack;
                      // ideally we'd invalidate the profile query here too?
                      setAccountType(AccountType.Gia);
                      setActiveStep(MultistepFormSteps.DateOfBirthStep);
                    }}
                  />
                )}
                {activeStep === MultistepFormSteps.BeforeWeGetStarted && (
                  <BeforeWeGetStarted
                    isResuming={showResumingMsg}
                    initialAccountType={accountType}
                    onProceed={() => {
                      setActiveStep(MultistepFormSteps.DateOfBirthStep);
                      setShowResumingMsg(false);
                    }}
                    onGoBack={() => {
                      setActiveStep(MultistepFormSteps.ChooseAccountType);
                      setShowResumingMsg(false);
                    }}
                  />
                )}
                {activeStep === MultistepFormSteps.DateOfBirthStep && (
                  <DateOfBirthStep
                    isResuming={showResumingMsg}
                    initialAccountType={accountType}
                    onProceed={(dob) => {
                      if (accountType === AccountType.Sipp) {
                        const yearOfBirth = new Date(dob);
                        const minDOBAllowed = subtractYears(new Date(), 75);

                        if (yearOfBirth <= minDOBAllowed) {
                          setActiveStep(MultistepFormSteps.CannotOpenSipp);
                        } else {
                          setActiveStep(MultistepFormSteps.MoreAboutYouSipp);
                        }
                      } else {
                        setActiveStep(
                          MultistepFormSteps.NationalInsuranceNumberStep
                        );
                        setShowResumingMsg(false);
                      }
                    }}
                    onGoBack={() => {
                      setActiveStep(MultistepFormSteps.BeforeWeGetStarted);
                      setShowResumingMsg(false);
                    }}
                  />
                )}

                {activeStep === MultistepFormSteps.MoreAboutYouSipp && (
                  <MoreAboutYouStep
                    source="openAccountStep"
                    onProceed={() => {
                      setActiveStep(
                        MultistepFormSteps.AboutYourOtherPensionsSipp
                      );
                    }}
                    onGoBack={() => {
                      setActiveStep(MultistepFormSteps.DateOfBirthStep);
                    }}
                  />
                )}

                {activeStep ===
                  MultistepFormSteps.AboutYourOtherPensionsSipp && (
                  <AboutYourOtherPensionsStep
                    source="openAccountStep"
                    onProceed={() => {
                      if (improvedContributionsToggle) {
                        setActiveStep(MultistepFormSteps.EmployerContributions);
                      } else {
                        setActiveStep(MultistepFormSteps.FundingChoicesSipp);
                      }
                    }}
                    onGoBack={() => {
                      setActiveStep(MultistepFormSteps.MoreAboutYouSipp);
                    }}
                  />
                )}

                {!improvedContributionsToggle?.enabled &&
                  activeStep === MultistepFormSteps.FundingChoicesSipp && (
                    <FundingChoicesLegacyStep
                      source="openAccountStep"
                      onProceed={() => {
                        setActiveStep(MultistepFormSteps.IllustrationSipp);
                      }}
                      onGoBack={() => {
                        setActiveStep(
                          MultistepFormSteps.AboutYourOtherPensionsSipp
                        );
                      }}
                    />
                  )}

                {improvedContributionsToggle?.enabled &&
                  activeStep === MultistepFormSteps.EmployerContributions && (
                    <EmployerContributionStep
                      source="openAccountStep"
                      hasEmployer={hasEmployer}
                      onProceed={() => {
                        setActiveStep(
                          hasEmployer
                            ? MultistepFormSteps.FundingChoicesSipp
                            : MultistepFormSteps.YourEmployerPensionSipp
                        );
                      }}
                      onGoBack={() => {
                        setActiveStep(
                          MultistepFormSteps.AboutYourOtherPensionsSipp
                        );
                      }}
                    />
                  )}

                {improvedContributionsToggle?.enabled &&
                  activeStep === MultistepFormSteps.YourEmployerPensionSipp && (
                    <YourEmployerStep
                      onGoBack={() =>
                        setActiveStep(MultistepFormSteps.EmployerContributions)
                      }
                      onProceed={() =>
                        setActiveStep(MultistepFormSteps.FundingChoicesSipp)
                      }
                    />
                  )}

                {improvedContributionsToggle?.enabled &&
                  activeStep === MultistepFormSteps.FundingChoicesSipp && (
                    <FundingChoicesStep
                      source="openAccountStep"
                      onProceed={() => {
                        setActiveStep(MultistepFormSteps.IllustrationSipp);
                      }}
                      onGoBack={() => {
                        setActiveStep(MultistepFormSteps.EmployerContributions);
                      }}
                      hasEmployer={hasEmployer}
                    />
                  )}

                {activeStep === MultistepFormSteps.IllustrationSipp && (
                  <IllustrationStep
                    source="openAccountStep"
                    onProceed={() => {
                      setActiveStep(MultistepFormSteps.ReviewYourDecisionSipp);
                    }}
                    onGoBack={() => {
                      setActiveStep(MultistepFormSteps.FundingChoicesSipp);
                    }}
                  />
                )}

                {activeStep === MultistepFormSteps.ReviewYourDecisionSipp && (
                  <ReviewYourDecision
                    source="openAccountStep"
                    illustrationDownloadUrl={
                      userProfileQuery.data?.illustration?.downloadUrl ??
                      undefined
                    }
                    onProceed={() => {
                      setActiveStep(MultistepFormSteps.DeclarationSipp);
                    }}
                    onStartAgain={() => {
                      setActiveStep(MultistepFormSteps.MoreAboutYouSipp);
                    }}
                  />
                )}

                {activeStep === MultistepFormSteps.DeclarationSipp && (
                  <DeclarationStep
                    includeTransferInDeclarations={isTransferIntent}
                    variant="open-account"
                    onProceed={() => {
                      if (defaultFundOptOutToggle?.enabled) {
                        setActiveStep(
                          MultistepFormSteps.WhatYouAreInvestedInStep
                        );
                      } else {
                        if (hasEmployer) {
                          setActiveStep(MultistepFormSteps.AutoEscalation);
                        } else {
                          setActiveStep(MultistepFormSteps.Beneficiaries);
                        }
                      }
                    }}
                    onGoBack={() => {
                      setActiveStep(MultistepFormSteps.ReviewYourDecisionSipp);
                    }}
                  />
                )}

                {activeStep === MultistepFormSteps.WhatYouAreInvestedInStep && (
                  <WhatYourInvestedInStep
                    onProceed={() => {
                      if (hasEmployer) {
                        setActiveStep(MultistepFormSteps.AutoEscalation);
                      } else {
                        setActiveStep(MultistepFormSteps.Beneficiaries);
                      }
                    }}
                    onGoBack={() => {
                      setActiveStep(MultistepFormSteps.DeclarationSipp);
                    }}
                  />
                )}

                {activeStep === MultistepFormSteps.AutoEscalation &&
                  hasEmployer && (
                    <AutoEscalationStep
                      source="openAccountStep"
                      onProceed={() => {
                        setActiveStep(MultistepFormSteps.Beneficiaries);
                      }}
                      onGoBack={() => {
                        if (defaultFundOptOutToggle?.enabled) {
                          setActiveStep(
                            MultistepFormSteps.WhatYouAreInvestedInStep
                          );
                        } else {
                          setActiveStep(MultistepFormSteps.DeclarationSipp);
                        }
                      }}
                    />
                  )}

                {activeStep === MultistepFormSteps.Beneficiaries && (
                  <BeneficiariesStep
                    source="openAccountStep"
                    onProceed={() => {
                      setActiveStep(
                        MultistepFormSteps.NationalInsuranceNumberStep
                      );
                    }}
                    onGoBack={() => {
                      if (hasEmployer) {
                        setActiveStep(MultistepFormSteps.AutoEscalation);
                      } else {
                        if (defaultFundOptOutToggle?.enabled) {
                          setActiveStep(
                            MultistepFormSteps.WhatYouAreInvestedInStep
                          );
                        } else {
                          setActiveStep(MultistepFormSteps.DeclarationSipp);
                        }
                      }
                    }}
                  />
                )}

                {activeStep ===
                  MultistepFormSteps.NationalInsuranceNumberStep && (
                  <NationalInsuranceNumberStep
                    onProceed={() => {
                      setActiveStep(MultistepFormSteps.AddressesStep);
                    }}
                    onGoBack={() => {
                      if (accountType === AccountType.Sipp) {
                        setActiveStep(MultistepFormSteps.Beneficiaries);
                      } else {
                        setActiveStep(MultistepFormSteps.DateOfBirthStep);
                      }
                    }}
                    source="openAccountStep"
                    userProfile={userProfileQuery.data?.userProfile!}
                    canSkip={accountType === AccountType.Gia}
                    introduction={
                      accountType === AccountType.Gia ? (
                        <StepIntroduction
                          mb={2}
                          $width={StepIntroductionWidth.normal}
                        >
                          <StepIntroductionTypography>
                            Our regulator needs us to report your National
                            Insurance number when trading Investment Trusts or
                            ETFs.
                          </StepIntroductionTypography>
                          <StepIntroductionTypography>
                            You can skip this for now, but you’ll be asked for
                            this information again if you try to place a trade
                            in one of these investment types.{' '}
                            <StyledLink href={mifidToInvest} target="_blank">
                              Learn more
                            </StyledLink>
                            .
                          </StepIntroductionTypography>
                        </StepIntroduction>
                      ) : undefined
                    }
                  />
                )}
                {activeStep === MultistepFormSteps.AddressesStep && (
                  <AddressesStep
                    onProceed={() => {
                      setActiveStep(MultistepFormSteps.NationalityStep);
                    }}
                    onGoBack={() => {
                      setActiveStep(
                        MultistepFormSteps.NationalInsuranceNumberStep
                      );
                    }}
                  />
                )}
                {activeStep === MultistepFormSteps.NationalityStep && (
                  <NationalityStep
                    onProceed={() => {
                      setActiveStep(MultistepFormSteps.MiFidStep);
                    }}
                    onGoBack={() => {
                      setActiveStep(MultistepFormSteps.AddressesStep);
                    }}
                  />
                )}
                {activeStep === MultistepFormSteps.MiFidStep && (
                  <NationalIdentifierStep
                    source="openAccountStep"
                    canSkip
                    onProceed={() => {
                      setActiveStep(MultistepFormSteps.BankAccountStep);
                    }}
                    onGoBack={() => {
                      setActiveStep(MultistepFormSteps.NationalityStep);
                    }}
                    introduction={
                      <StepIntroduction
                        mb={2}
                        $width={StepIntroductionWidth.normal}
                      >
                        <StepIntroductionTypography>
                          Our regulator needs us to report your national
                          identifier when trading Investment Trusts or ETFs.
                        </StepIntroductionTypography>
                        <StepIntroductionTypography>
                          You can skip this for now, but you’ll be asked for
                          this information again if you try to place a trade in
                          one of these investment types.{' '}
                          <StyledLink href={mifidToInvest} target="_blank">
                            Learn more
                          </StyledLink>
                          .
                        </StepIntroductionTypography>
                      </StepIntroduction>
                    }
                  />
                )}
                {accountType &&
                  activeStep === MultistepFormSteps.BankAccountStep && (
                    <BankAccountStep
                      accountType={accountType}
                      onProceed={() => {
                        setActiveStep(MultistepFormSteps.DocumentUploadStep);
                      }}
                      onGoBack={() => {
                        if (
                          mifidReportingNationalityQuery.data
                            ?.mifidReportingNationality.identifiers.length
                        ) {
                          setActiveStep(MultistepFormSteps.MiFidStep);
                        } else {
                          setActiveStep(MultistepFormSteps.NationalityStep);
                        }
                      }}
                    />
                  )}
                {activeStep === MultistepFormSteps.DocumentUploadStep && (
                  <DocumentUploadStep
                    onProceed={() => {
                      setActiveStep(MultistepFormSteps.ConfirmationStep);
                    }}
                    onGoBack={() => {
                      setActiveStep(MultistepFormSteps.BankAccountStep);
                    }}
                  />
                )}

                {activeStep === MultistepFormSteps.ConfirmationStep && (
                  <ConfirmationStep
                    onProceed={() => {
                      setActiveStep(MultistepFormSteps.IDVStep);
                    }}
                    onGoBack={() => {
                      setActiveStep(MultistepFormSteps.DocumentUploadStep);
                    }}
                  />
                )}
                {activeStep === MultistepFormSteps.IDVStep && (
                  <IDVStep
                    proceedBtnText={
                      hasVerifiedPhoneNumber ? 'Add cash' : 'Continue'
                    }
                    onProceed={({
                      action,
                      createdAccountId,
                      createdAccountType,
                    } = {}) => {
                      setAccountId(createdAccountId);
                      setWrapperType(createdAccountType);
                      setOnboardingStatus(false);
                      removeOnboardingAccountType();

                      if (action === Action.Continue) {
                        if (!hasVerifiedPhoneNumber) {
                          setActiveStep(MultistepFormSteps.MobileMfa);
                        } else {
                          setActiveStep(MultistepFormSteps.AddCashStep);
                        }
                      } else if (action === Action.NeedMoreTime) {
                        history.push(`/`);
                      } else {
                        history.push('/');
                      }
                    }}
                  />
                )}
                {activeStep === MultistepFormSteps.MobileMfa && (
                  <SetupMfaMethodForm
                    proceedBtnText={hasUnknownBasket ? 'Continue' : 'Add cash'}
                    onProceed={async () => {
                      if (wrapperType && accountId && hasUnknownBasket) {
                        setActiveStep(MultistepFormSteps.CheckoutStep);
                      } else {
                        setActiveStep(MultistepFormSteps.AddCashStep);
                      }
                    }}
                  />
                )}
                {accountId &&
                  accountType &&
                  activeStep === MultistepFormSteps.CheckoutStep && (
                    <CheckoutStep
                      accountId={accountId}
                      accountType={accountType}
                    />
                  )}
                {activeStep === MultistepFormSteps.AddCashStep && accountId && (
                  <AddCashStep
                    accountType={accountType}
                    accountId={accountId}
                    paymentId={paymentId}
                    onSkipStep={() => {
                      trackGa({
                        event: GaEventNames.onboardingEnd,
                      });
                      history.push(dashboardPath);
                    }}
                  />
                )}
                {accountType &&
                  activeStep === MultistepFormSteps.CannotOpenSipp && (
                    <CannotOpenSipp
                      onProceed={() => {
                        setWrapperType(undefined);
                        setAccountType(AccountType.NotSpecified);
                        setActiveStep(MultistepFormSteps.ChooseAccountType);
                        history.push(
                          generateOpenAccountTypePath({ type: 'switch' })
                        );
                      }}
                      onGoBack={() => {
                        setActiveStep(MultistepFormSteps.DateOfBirthStep);
                      }}
                    />
                  )}
              </AnimatePresence>
            </>
          );
        }}
      </QueryState>
    </Wrapper>
  );
}

export function OpenAccountForm() {
  const history = useHistory();

  const location = useLocation();
  const { search } = location;

  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const paymentId = queryParams.has('paymentId')
    ? (queryParams.get('paymentId') as string)
    : undefined;

  const [hasAccountOnPageLoad, setHasAccountOnPageLoad] = useState<
    boolean | undefined
  >();
  const [
    hasPendingAccountOnPageLoad,
    setHasPendingAccountOnPageLoad,
  ] = useState<boolean | undefined>();
  const [anyCanOpenOnPageLoad, setAnyCanOpenOnPageLoad] = useState<
    boolean | undefined
  >();
  const [
    onboardingRequiresAdditionalDocumentUpload,
    setOnboardingRequiresAdditionalDocumentUpload,
  ] = useState<boolean | undefined>();

  const accountEligibilitiesQuery = useAccountEligibilitiesQuery();
  const accountEligibilities =
    accountEligibilitiesQuery.data?.accountEligibilities;
  const { referralCode } = useGetEmployerCode();

  const referralQuery = useReferralCodeQuery(
    { code: referralCode! },
    {
      enabled: referralCode != null,
    }
  );

  const referralCodeData = referralQuery.data?.referralCode;

  useEffect(() => {
    if (hasAccountOnPageLoad !== undefined) {
      return;
    }

    if (
      (referralQuery.isSuccess || !queryParams.has('referralCode')) &&
      accountEligibilitiesQuery.isSuccess
    ) {
      const hasAccount = accountEligibilities?.some(
        (accountEligibility) =>
          accountEligibility?.accountStatus === AccountStatus.Active
      );
      const hasActivePension = accountEligibilities?.some(
        (accountEligibility) =>
          accountEligibility?.accountStatus === AccountStatus.Active &&
          accountEligibility?.wrapperType === WrapperType.Sipp
      );

      if (
        !hasActivePension &&
        referralCodeData?.initialAccountType === AccountType.Sipp &&
        !paymentId
      ) {
        history.push(
          openPensionPath + `?referralCode=${referralCodeData.code}`
        );
        return;
      }

      if (hasAccount && !paymentId) {
        history.replace(dashboardPath);
        return;
      }

      setHasAccountOnPageLoad(hasAccount);
    }
  }, [
    referralQuery.isSuccess,
    accountEligibilities,
    accountEligibilitiesQuery.isSuccess,
    hasAccountOnPageLoad,
    history,
    paymentId,
    queryParams,
    referralCode,
    referralCodeData?.initialAccountType,
    referralCodeData?.code,
  ]);

  useOnboardingStatusQuery(undefined, {
    onSuccess: (x) =>
      setOnboardingRequiresAdditionalDocumentUpload(
        x.userProfile?.pendingDocumentUploadRequestCount === 1 &&
          x.userProfile?.onboardingDetails?.status ===
            OnboardingStatus.SupportNeeded
      ),
  });

  const hasPendingAccount = useHasPendingAccount();
  useEffect(() => {
    if (hasPendingAccountOnPageLoad === undefined) {
      setHasPendingAccountOnPageLoad(hasPendingAccount);
    }
  }, [
    hasPendingAccount,
    hasPendingAccountOnPageLoad,
    setHasPendingAccountOnPageLoad,
  ]);

  const anyCanOpen = useHasOpenableAccount();
  useEffect(() => {
    if (anyCanOpenOnPageLoad === undefined) {
      setAnyCanOpenOnPageLoad(anyCanOpen);
    }
  }, [anyCanOpen, anyCanOpenOnPageLoad, setAnyCanOpenOnPageLoad]);

  return (
    <QueryState {...accountEligibilitiesQuery}>
      {() => (
        <QueryState {...referralQuery}>
          {() => {
            if (onboardingRequiresAdditionalDocumentUpload) {
              return <OpenAccountFormSteps paymentId={paymentId} />;
            } else if (hasPendingAccountOnPageLoad && !paymentId) {
              return <PendingAccount />;
            } else if (anyCanOpenOnPageLoad) {
              return <OpenAccountFormSteps paymentId={paymentId} />;
            }

            return <ContactSupport />;
          }}
        </QueryState>
      )}
    </QueryState>
  );
}
