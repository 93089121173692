import { Text } from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import styled from 'styled-components';

export const FooterLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: grid;
    grid-template-areas: 'values actions';
    grid-template-columns: 1fr auto;
    gap: 1rem;
    width: 100%;
    padding-right: 3rem;
  }

  ${({ theme }) => theme.breakpoints.up(1400)} {
    max-width: 1600px;
    padding-right: 0;
  }
`;

export const OverAllocatedMsg = styled.div`
  ${Text} {
    color: ${colors.danger};
  }
`;

interface ManageDepositButtonProps {
  $needsAttention?: boolean;
}

export const ManageDepositButton = styled.button<ManageDepositButtonProps>`
  background: none;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 300;
  color: ${({ $needsAttention }) =>
    $needsAttention ? colors['danger'] : 'inherit'};
`;
