import { deriveIsAssetAvailableToBuy } from 'hooks/useIsAssetAvailableToBuy';
import { BackToResults } from 'pages/FundDetails/BackToResults';
import styled from 'styled-components';
import { AssetQueryAsset } from 'types/graphqlTypes';
import { FundActions } from '../FundActions';
import { ActionsContainer, Title } from '../Styles/FundDetails.style';

const ActionsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem 1rem;
  margin: 0 auto;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    max-width: 500px;
    flex-direction: row;
    justify-content: center;
    button {
      flex-basis: 50%;
    }
  }
`;

interface ActionsSliceProps {
  asset: AssetQueryAsset;
  selectedIsin: string;
  userHolds: boolean;
  handleInstrumentChange: (isin: string) => void;
}

export function ActionsSlice({
  asset,
  selectedIsin,
  userHolds,
  handleInstrumentChange,
}: ActionsSliceProps) {
  const isAssetAvailableToBuy = deriveIsAssetAvailableToBuy(asset);

  const { isDarkUniverse, isCustomUniverse } = asset;

  if (
    !isAssetAvailableToBuy ||
    (isDarkUniverse && !isCustomUniverse && !userHolds)
  ) {
    return null;
  }

  return (
    <ActionsContainer>
      <Title>Actions</Title>
      <div>
        <ActionsWrapper>
          <FundActions
            asset={asset}
            isin={selectedIsin}
            userHolds={userHolds}
            handleInstrumentChange={handleInstrumentChange}
          />
        </ActionsWrapper>
        <BackToResults />
      </div>
    </ActionsContainer>
  );
}
