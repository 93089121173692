import { colors } from 'constants/colors';
import styled from 'styled-components';
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import { Hexagon } from './Style/LandingMenu.style';

type ContainerType = {
  cat: string;
};

const SwiperContainer = styled(Swiper)<ContainerType>`
  width: 100%;
  padding-bottom: ${(p) => p.theme.spacing(6.25)}px !important;

  ${(p) => p.theme.breakpoints.up('md')} {
    padding-bottom: ${(p) => p.theme.spacing(3.75)}px !important;
  }

  .swiper-pagination {
    bottom: 10px !important;

    ${(p) => p.theme.breakpoints.up('md')} {
      display: none;
    }

    .swiper-pagination-bullet {
      width: 8px !important;
      height: 8px !important;
      margin: 0 3px !important;
      opacity: 0.5;

      background-color: ${(p) =>
        (p.cat === 'style' && colors.seaBlue) ||
        (p.cat === 'region' && colors.purple) ||
        (p.cat === 'structure' && colors.magenta) ||
        (p.cat === 'assetClass' && colors.mauve)};

      &.swiper-pagination-bullet-active {
        opacity: 1;
      }
    }
  }

  .swiper-wrapper {
    .swiper-slide {
      width: 100%;
      box-sizing: border-box;
      padding: ${(p) => p.theme.spacing(1.25)}px;
      transition: opacity 0.4s ease-in-out;
      &.swiper-slide-active {
        opacity: 1;
        > * {
          > * {
            top: 3px;
            left: 3px;
            bottom: 3px;
            right: 3px;
          }
        }
      }
      &.swiper-slide-prev,
      &.swiper-slide-next {
        opacity: 0.2;
      }
    }
  }
`;

const Title = styled.h2`
  font-weight: lighter;

  ${(p) => p.theme.breakpoints.down(400)} {
    font-size: 1.25rem;
  }
`;

SwiperCore.use([Pagination, Autoplay]);

type ClickData = {
  title: string;
  content: JSX.Element;
  url: string;
  cat: string;
};

interface IClick {
  (message: ClickData): void;
}

interface SwipeProps {
  slides: Array<ClickData>;
  category: 'style' | 'region' | 'structure' | 'assetClass' | '';
  onClick: IClick;
}

export function MobileSliders({ slides, category, onClick }: SwipeProps) {
  const getDelay = () => {
    return Math.floor(Math.random() * (7000 - 5000 + 1) + 5000);
  };

  return (
    <SwiperContainer
      spaceBetween={0}
      slidesPerView={1.6}
      pagination={{ clickable: true }}
      autoplay={{
        delay: getDelay(),
        disableOnInteraction: true,
      }}
      loop={true}
      centeredSlides={true}
      cat={category}
    >
      {slides.map((slideContent: ClickData, index: number) => (
        <SwiperSlide key={index}>
          <Hexagon
            className={slideContent.cat}
            onClick={() => {
              onClick(slideContent);
            }}
          >
            <div>
              <Title>{slideContent.title}</Title>
            </div>
          </Hexagon>
        </SwiperSlide>
      ))}
    </SwiperContainer>
  );
}
