import { colors } from 'constants/colors';
import styled, { css } from 'styled-components';

// Used style for stories only
// ===================================
type StyledCardProps = {
  background?: string;
};

export const StyledCard = styled.div<StyledCardProps>`
  width: max(33vw, 280px);
  min-height: max(60vh, 550px);
  align-items: center;
  background-color: ${colors.white};
  box-sizing: border-box;
  padding: 40px 20px;
  border-radius: 10px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  ${(p) =>
    p.background &&
    css`
      background-image: url(${p.background});
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    `}

  ${(p) => p.theme.breakpoints.up('md')} {
    padding: 60px 40px 40px 40px;
    min-height: max(55vh, 665px);
  }
`;
// ===================================

// Shared cards styles below
// ===================================
export const Title = styled.h1`
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.75rem;
  font-weight: normal;
  color: ${colors.magenta};

  ${(p) => p.theme.breakpoints.up('md')} {
    font-size: 2rem;
  }
`;

export const SubTitle = styled.h3`
  text-align: center;
  color: ${colors.richBlue};
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 1.25rem;
  font-weight: normal;

  ${(p) => p.theme.breakpoints.up('md')} {
    font-size: 1.5rem;
  }
`;
