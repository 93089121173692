import { CardsSwiper } from 'components/CardsSwiper/CardsSwiper';
import { FundGridCard } from 'components/feature/FundsList/FundGridCard';
import { TagProps } from 'components/feature/FundsList/FundGridCard/FundGridCard';
import { FundsGridCardOptions } from 'components/feature/FundsList/hooks/useFundListOptions';
import { AssetQueryAsset } from 'types/graphqlTypes';

interface FundSwiperProps {
  funds: AssetQueryAsset[];
  searchTags?: TagProps[];
  options: FundsGridCardOptions;
  setOptions: (options: FundsGridCardOptions) => void;
}

/**
 * Renders a swiper component from a list of assets provided.
 *
 * @param props - The properties for the FundsSwiper component.
 * @param props.assets - The list of assets.
 * @param props.selectedAccountType - The selected account type.
 * @param props.selectedAccountId - The selected account ID.
 * @param props.options - The options for the FundsSwiper component.
 * @param props.setOptions - The function to set the options.
 * @return The rendered FundsSwiper component.
 */
export function FundsSwiper({
  funds,
  searchTags,
  options,
  setOptions,
}: FundSwiperProps) {
  return (
    <>
      <CardsSwiper
        slides={funds.map((asset, index: number) => {
          if (asset === undefined) {
            return null;
          }

          return (
            <FundGridCard
              key={asset.id}
              asset={asset}
              forceExpanded={false}
              index={index}
              missingTags={[]}
              missingFrontTags={[]}
              searchTags={searchTags || []}
              options={options}
              setOptions={setOptions}
              canHoverTags={false}
              source="similar"
            />
          );
        })}
        pagination
      />
    </>
  );
}
