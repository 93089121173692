import { colors } from 'constants/colors';
import { transparentize } from 'polished';
import React from 'react';
import styled from 'styled-components';

export enum ToggleColorOptions {
  default = 'default',
  primary = 'primary',
  secondary = 'secondary',
  primaryAlt = 'primaryAlt',
}

type ToggleColor = keyof typeof ToggleColorOptions;

const toggleContainerColorLookup = {
  [ToggleColorOptions.default]: colors.grey,
  [ToggleColorOptions.primary]: transparentize(0.9, colors.magenta),
  [ToggleColorOptions.secondary]: transparentize(0.9, colors.richBlue),
  [ToggleColorOptions.primaryAlt]: colors['magenta-50'],
};

const toggleBackgroundColorLookup = {
  [ToggleColorOptions.default]: colors.white,
  [ToggleColorOptions.primary]: colors.magenta,
  [ToggleColorOptions.secondary]: colors.richBlue,
  [ToggleColorOptions.primaryAlt]: colors.white,
};

const toggleTextColorLookup = {
  [ToggleColorOptions.default]: colors.richBlack,
  [ToggleColorOptions.primary]: colors.white,
  [ToggleColorOptions.secondary]: colors.white,
  [ToggleColorOptions.primaryAlt]: colors.richBlack,
};

const toggleTextColorInActiveLookup = {
  [ToggleColorOptions.default]: colors.richBlack,
  [ToggleColorOptions.primary]: colors.richBlack,
  [ToggleColorOptions.secondary]: colors.richBlack,
  [ToggleColorOptions.primaryAlt]: colors['mauve-500'],
};

type ColorType = {
  $colorType: ToggleColor;
};

const ToggleContainer = styled.div<ColorType>`
  display: inline-flex;
  align-items: center;
  height: 1.5rem;
  background-color: ${({ $colorType }) =>
    toggleContainerColorLookup[$colorType]};
  border-radius: 1rem;
  padding: 2px;
`;

type ToggleButtonProps = {
  $isActive: boolean;
  $colorType: ToggleColor;
};
const ToggleButton = styled.button<ToggleButtonProps>`
  border: none;
  background-color: ${({ $isActive, $colorType }) =>
    $isActive ? toggleBackgroundColorLookup[$colorType] : 'transparent'};
  color: ${({ $isActive, $colorType }) =>
    $isActive
      ? toggleTextColorLookup[$colorType]
      : toggleTextColorInActiveLookup[$colorType]};
  height: 1.25rem;
  border-radius: 1rem;
  padding: 0 1.5rem;
  font-size: 0.75rem;
  cursor: pointer;
`;

type Option<Values> = {
  value: Values;
  label: React.ReactNode;
};

interface ToggleProps<Values> {
  options: Option<Values>[];
  value: string;
  $color?: ToggleColor;
  onClick: (
    value: Values,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
}
export const Toggle = <Values extends string>({
  value,
  options,
  onClick,
  $color = ToggleColorOptions.default,
}: ToggleProps<Values>) => {
  return (
    <ToggleContainer $colorType={$color}>
      {options.map((option) => {
        return (
          <ToggleButton
            type="button"
            key={option.value}
            $isActive={value === option.value}
            onClick={(event) => onClick(option.value, event)}
            $colorType={$color}
          >
            {option.label}
          </ToggleButton>
        );
      })}
    </ToggleContainer>
  );
};
