import { Card } from 'components/design-system/Card/Card';
import { colors } from 'constants/colors';
import styled from 'styled-components';

interface DarkUniverseProps {
  $isDarkUniverse: boolean;
}

export const StyledCard = styled(Card)<DarkUniverseProps>`
  background-color: ${({ $isDarkUniverse }) =>
    $isDarkUniverse ? colors.black : colors.white};
`;

interface RemoveButtonProps {
  $isActive?: boolean;
  $isDarkUniverse?: boolean;
}

export const RemoveButton = styled.button<RemoveButtonProps>`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  padding: 0;
  align-self: start;
  justify-self: end;
  opacity: ${({ $isActive }) => ($isActive ? 1 : 0.5)};
  transition: opacity 0.25s ease-in-out;
  color: ${({ $isDarkUniverse }) =>
    $isDarkUniverse ? colors.white : colors.black};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding-left: 10px;
  }
`;
