import {
  ResponsiveDialog,
  ResponsiveDialogProps,
} from 'components/ResponsiveDialog';
import React from 'react';
import { IcReportForm } from '../form/IcReportForm';

interface IcReportDialogProps extends ResponsiveDialogProps {
  reportUrl: string;
}

export function IcReportDialog({
  onClose,
  reportUrl,
  ...props
}: IcReportDialogProps) {
  return (
    <ResponsiveDialog {...props} onClose={onClose}>
      <IcReportForm reportUrl={reportUrl} onClose={onClose} />
    </ResponsiveDialog>
  );
}
