import { H5 } from 'components/design-system/Heading/Heading';
import { InfoPopoverV2 } from 'components/design-system/InfoPopoverV2/InfoPopoverV2';
import { Tabs } from 'components/design-system/Tabs/Tabs';
import {
  FontWeight,
  Text,
  TextNormal,
  TextSmall,
} from 'components/design-system/Text/Text';
import { Toggle } from 'components/design-system/Toggle/Toggle';
import type { GetAssetGroupString } from 'components/feature/PortfolioBuilder/hooks/useGetBreakdowns/breakdownGroupingHelpers';
import { assetClassColors, colors } from 'constants/colors';
import { GaEventNames } from 'constants/gaConstants';
import { WrapperType } from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { useState } from 'react';
import { useFundsBasket } from '../../hooks/useFundsBasket';
import {
  getAssetClassOfAsset,
  getRegionOfAsset,
} from '../../hooks/useGetBreakdowns';
import { useGetBasketBreakdown } from '../../hooks/useGetBreakdowns/useGetBasketBreakdown';
import {
  ChartTypeSelectorContainer,
  DrillDownContainer,
  DrillDownHeader,
  NoBreakdownMessageEl,
  StackedBarComparisonContainer,
} from '../InteractiveBreakdown.styles';
import {
  StackedBarChart,
  StackedBarChartKey,
} from '../_shared/StackedBarChart';
import { BasketOnlyBreakdown, GroupingOption } from './BasketOnlyBreakdown';
import { BasketOnlyHoldingsTab } from './BasketOnlyHoldingsTab';

const colorLookup = [
  colors['purple-600'],
  colors['purple-400'],
  colors['purple-200'],
  colors['purple-700'],
  colors['purple-500'],
  colors['purple-300'],
  colors['purple-100'],
];

interface NameColorLookupType {
  [key: string]: string;
}

const nameColorLookup: NameColorLookupType = {
  ...assetClassColors,
  'Pending buys': colors.lightGrey,
};

const NoBreakdownMessage = () => {
  return (
    <NoBreakdownMessageEl>
      <H5 $noMargin>Want to visualise your portfolio?</H5>
      <TextSmall>
        Add funds to your basket to see a breakdown of your portfolio.
      </TextSmall>
    </NoBreakdownMessageEl>
  );
};

interface BreakdownStackedBarComparisonProps {
  getAssetGroupString: GetAssetGroupString;
  isInteractive: boolean;
  selectedAccountType: WrapperType;
  groupings: (displayMoreInfo: string) => GroupingOption[];
}

const BasketOnlyAllocationChart = ({
  getAssetGroupString,
  isInteractive,
  selectedAccountType,
  groupings,
}: BreakdownStackedBarComparisonProps) => {
  const [activeBreakdown, setActiveBreakdown] = useState<string | null>(null);

  const { basketSummary } = useFundsBasket({ selectedAccountId: null });

  const { basketBreakdowns: breakdowns } = useGetBasketBreakdown(
    null,
    getAssetGroupString,
    () => true
  );

  const activeCurrentBreakdown = breakdowns.find(
    ({ name }) => name === activeBreakdown
  );

  const breakdownItemColors = breakdowns.map((item, key) => ({
    name: item.name,
    color: nameColorLookup[item.name] || colorLookup[key % colorLookup.length],
  }));

  const [barChartHovered, setBarChartHovered] = useState(false);
  const handleMouseEnterBarChart = () => {
    setBarChartHovered(true);
  };

  const handleMouseLeaveBarChart = () => {
    setBarChartHovered(false);
  };

  if (breakdowns.length === 0 && breakdowns.length === 0) {
    return <NoBreakdownMessage />;
  }

  return (
    <>
      <StackedBarComparisonContainer>
        {breakdowns.length > 0 && (
          <>
            <div>
              <TextSmall $noMargin $fontWeight={FontWeight.medium}>
                Current allocation{' '}
                <InfoPopoverV2
                  openOn="hover"
                  color="primary"
                  size="small"
                  disableMargin
                  content={
                    <div>
                      <Text $fontWeight={FontWeight.medium}>
                        Current allocation
                      </Text>
                      <TextSmall>
                        Sign in to your TILLIT account to unlock the current
                        allocation of your portfolio.
                      </TextSmall>
                    </div>
                  }
                />
              </TextSmall>

              <StackedBarChart
                breakdowns={[
                  {
                    name: 'Example allocation',
                    amount: 100,
                    percentage: 100,
                  },
                ]}
                breakdownItemColors={[
                  {
                    name: 'Example allocation',
                    color: assetClassColors.Cash,
                  },
                ]}
                disabled={basketSummary.orderCount === 0}
                faded
              />
            </div>
            <div>
              <TextSmall $noMargin $fontWeight={FontWeight.medium}>
                Allocation of the trades in your basket
              </TextSmall>
              <StackedBarChart
                breakdowns={breakdowns}
                isHovered={barChartHovered}
                onMouseEnter={handleMouseEnterBarChart}
                onMouseLeave={handleMouseLeaveBarChart}
                breakdownItemColors={breakdownItemColors}
                disabled={basketSummary.orderCount === 0}
                onClick={
                  isInteractive
                    ? ({ name }) => {
                        if (activeBreakdown === name) {
                          trackGa({
                            event: GaEventNames.selectContent,
                            content_type:
                              'interactive breakdown - basket allocation',
                            item_id: 'unselect',
                          });
                          setActiveBreakdown(null);
                        } else {
                          trackGa({
                            event: GaEventNames.selectContent,
                            content_type:
                              'interactive breakdown - basket allocation',
                            item_id: name,
                          });
                          setActiveBreakdown(name);
                        }
                      }
                    : undefined
                }
              />
              <StackedBarChartKey breakdownItemColors={breakdownItemColors} />
            </div>
          </>
        )}
      </StackedBarComparisonContainer>
      {activeBreakdown && activeCurrentBreakdown && (
        <DrillDownContainer>
          <Tabs
            title={
              <DrillDownHeader>
                <TextNormal $noMargin>{activeCurrentBreakdown.name}</TextNormal>
              </DrillDownHeader>
            }
            onClick={(tabTitle) => {
              trackGa({
                event: GaEventNames.selectContent,
                content_type:
                  'interactive breakdown - basket only allocation - drill down tab',
                item_id: tabTitle,
              });
            }}
            tabs={[
              {
                title: 'Holdings',
                content: (
                  <BasketOnlyHoldingsTab
                    activeBreakdown={activeCurrentBreakdown}
                    selectedAccountType={selectedAccountType}
                    includeAsset={(asset) =>
                      getAssetGroupString(asset) === activeCurrentBreakdown.name
                    }
                  />
                ),
              },
              {
                title: 'Breakdown',
                content: (
                  <BasketOnlyBreakdown
                    groupings={groupings(activeBreakdown)}
                    activeBreakdown={activeCurrentBreakdown}
                    selectedAccountType={selectedAccountType}
                    includeAsset={(asset) =>
                      getAssetGroupString(asset) === activeCurrentBreakdown.name
                    }
                  />
                ),
              },
            ]}
          />
        </DrillDownContainer>
      )}
    </>
  );
};

interface InteractiveBreakdownProps {
  isInteractive?: boolean;
  variant?: 'full' | 'compact';
  selectedAccountId: string;
  selectedAccountType: WrapperType;
}

export const BasketOnlyAllocation = ({
  isInteractive = true,
  variant = 'full',
  selectedAccountId,
  selectedAccountType,
}: InteractiveBreakdownProps) => {
  const [activeBreakdown, setActiveBreakdown] = useState('assetClass');
  return (
    <div>
      <ChartTypeSelectorContainer $variant={variant}>
        <Toggle
          onClick={(value) => {
            trackGa({
              event: GaEventNames.selectContent,
              content_type: 'interactive breakdown - change breakdown',
              item_id: value,
            });
            setActiveBreakdown(value);
          }}
          options={[
            { value: 'assetClass', label: 'Asset class' },
            { value: 'region', label: 'Region' },
          ]}
          value={activeBreakdown}
        />
      </ChartTypeSelectorContainer>
      {activeBreakdown === 'assetClass' && (
        <BasketOnlyAllocationChart
          getAssetGroupString={getAssetClassOfAsset}
          isInteractive={isInteractive}
          selectedAccountType={selectedAccountType}
          groupings={(displayMoreInfo: string) => {
            const groupings: GroupingOption[] = [
              { value: 'region', label: 'Region' },
            ];
            // if (displayMoreInfo === 'Equities') {
            //   groupings.push({ value: 'equityStyle', label: 'Equity Style' });
            // }
            if (displayMoreInfo === 'Bonds') {
              groupings.push({ value: 'bondStyle', label: 'Bond Style' });
            }
            return groupings;
          }}
        />
      )}
      {activeBreakdown === 'region' && (
        <BasketOnlyAllocationChart
          groupings={() => [{ value: 'assetClass', label: 'Asset class' }]}
          getAssetGroupString={getRegionOfAsset}
          isInteractive={isInteractive}
          selectedAccountType={selectedAccountType}
        />
      )}
    </div>
  );
};
