import { yupResolver } from '@hookform/resolvers/yup';
import { Radio } from 'components/design-system/FormElements/Radio';
import { H3, H5 } from 'components/design-system/Heading/Heading';
import {
  GoBackButton,
  StepButton,
} from 'components/design-system/StepComponents/StepComponents';
import { TextNormal } from 'components/design-system/Text/Text';
import { InstrumentSelectorDetails } from 'components/feature/FundDetails/InstrumentSelector/InstrumentSelector';
import { WrapperType } from 'generated/graphql';
import {
  getShortNameForWrapperType,
  getNameForWrapperType,
} from 'helpers/accountHelpers';
import { generateFundDetailsPath } from 'paths';
import { useForm } from 'react-hook-form';
import { HiExternalLink } from 'react-icons/hi';
import {
  AccountsQueryAccountPosition,
  AssetQueryAsset,
} from 'types/graphqlTypes';
import * as yup from 'yup';
import {
  ButtonContainer,
  ErrorMessage,
  FundDoLink,
  Pill,
  PillContainer,
  Section,
} from '../AddToBasketDialog.style';
import {
  InstrumentSelectorWrapper,
  InstrumentWrapper,
  StyledInstrumentSelector,
} from './VerifyInstrumentSelection.styles';

const VerifyInstrumentSelectionSchema = yup.object().shape({
  instrumentSelector: yup
    .string()
    .required()
    .typeError('Please select a vintage'),
});

interface VerifyInstrumentSelectionFormValues {
  instrumentSelector: string;
}

interface VerifyInstrumentSelectionStepBuyProps {
  asset: AssetQueryAsset;
  selectedAccountType?: WrapperType;
  heldPositions?: AccountsQueryAccountPosition[];
  selectedIsin?: string;
  flow?: 'BUY' | 'Recurring order';
  handleInstrumentChange?: (isin: string) => void;
  onProceed: () => void;
  onGoBack?: () => void;
}

export function VerifyInstrumentSelectionStepBuy({
  asset,
  selectedAccountType,
  selectedIsin,
  heldPositions,
  flow = 'BUY',
  handleInstrumentChange,
  onProceed,
  onGoBack,
}: VerifyInstrumentSelectionStepBuyProps) {
  const selectedInstrument = asset.instruments.nodes.find(
    (instrument) => instrument.isin === selectedIsin
  );

  const {
    formState: { isSubmitting },
    register,
    handleSubmit,
    errors,
  } = useForm<VerifyInstrumentSelectionFormValues>({
    defaultValues: {
      instrumentSelector: selectedIsin,
    },
    resolver: yupResolver(VerifyInstrumentSelectionSchema),
  });

  const onSubmit = (data: VerifyInstrumentSelectionFormValues) => {
    if (data.instrumentSelector) {
      handleInstrumentChange?.(data.instrumentSelector);
      onProceed();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PillContainer>
        <Pill $filled $color="buy">
          {flow}
        </Pill>
        {selectedAccountType && selectedAccountType !== WrapperType.Unknown && (
          <Pill>{getShortNameForWrapperType(selectedAccountType)}</Pill>
        )}
      </PillContainer>
      <div>
        <H3>{asset.name}</H3>
        <FundDoLink
          to={{
            pathname: generateFundDetailsPath({
              id: asset?.id!,
              slug: asset?.slug!,
            }),
            state: {
              shouldGoBack: true,
            },
          }}
          target="_blank"
          // onClick={() => trackFundOpen('link', asset, index)}
        >
          What does this fund do? <HiExternalLink />
        </FundDoLink>
      </div>
      <Section>
        <TextNormal $noMargin>
          You already have a holding in this fund in your{' '}
          {getNameForWrapperType(selectedAccountType!)} in a different share
          class. Please review your options below before proceeding.
        </TextNormal>
      </Section>

      <StyledInstrumentSelector>
        <div>
          <H5>You have selected</H5>
          <InstrumentWrapper>
            <Radio
              name={'instrumentSelector'}
              value={selectedIsin}
              ref={register}
              label={
                <InstrumentSelectorDetails
                  instruments={asset.instruments.nodes}
                  instrument={selectedInstrument!}
                  showInstrumentName={true}
                />
              }
              wrapLabel={false}
            />
          </InstrumentWrapper>
        </div>
        <div>
          <H5>You currently hold</H5>
          <InstrumentSelectorWrapper>
            {heldPositions?.map((position) => (
              <InstrumentWrapper>
                <Radio
                  key={position.isin}
                  name={'instrumentSelector'}
                  value={position.isin}
                  ref={register}
                  label={
                    <InstrumentSelectorDetails
                      instruments={asset.instruments.nodes}
                      instrument={position.instrument!}
                      showInstrumentName={true}
                    />
                  }
                  wrapLabel={false}
                />
              </InstrumentWrapper>
            ))}
          </InstrumentSelectorWrapper>
        </div>
      </StyledInstrumentSelector>

      {errors.instrumentSelector && (
        <ErrorMessage>{errors.instrumentSelector.message}</ErrorMessage>
      )}

      <ButtonContainer>
        <StepButton className="magenta" type="submit" disabled={isSubmitting}>
          Continue
        </StepButton>
        {onGoBack && <GoBackButton onClick={onGoBack} />}
      </ButtonContainer>
    </form>
  );
}
