import { yupResolver } from '@hookform/resolvers/yup';
import { FormHelperText } from '@material-ui/core';
import { ComboBoxInput } from 'components/Form/ComboBoxInput';
import { QueryState } from 'components/QueryState';
import { StyledLink } from 'components/design-system/Link';
import {
  GoBackLink,
  StepActions,
  StepButton,
  StepContainer,
  StepContent,
  StepContentWidth,
  StepFormContainer,
  StepIntroductionTypography,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import { GaEventNames } from 'constants/gaConstants';
import { WrapperType, useTransferProvidersQuery } from 'generated/graphql';
import { isNumberInputValid } from 'helpers/inputHelpers';
import { trackGa } from 'helpers/track';
import { dashboardTransfersPath } from 'paths';
import { Controller, useForm } from 'react-hook-form';
import { useIntercom } from 'react-use-intercom';
import * as yup from 'yup';
import { CedingProvider } from '../../types';
import { wrapperNameFriendly } from '../_shared/WrapperNameUtils';
import {
  AccountNumberHelpText,
  CantFindYourProvider,
  NoOptionCopy,
  StyledInput,
} from './ProviderDetails.styles';

const CEDING_PROVIDER_ACCOUNT_NUMBER__MIN_LENGTH = 2;
const CEDING_PROVIDER_ACCOUNT_NUMBER__MAX_LENGTH = 20;

const accountNumberSchema = yup
  .string()
  .trim()
  .required('Please provide your account number')
  .min(
    CEDING_PROVIDER_ACCOUNT_NUMBER__MIN_LENGTH,
    `Please enter at least ${CEDING_PROVIDER_ACCOUNT_NUMBER__MIN_LENGTH} characters`
  )
  .max(
    CEDING_PROVIDER_ACCOUNT_NUMBER__MAX_LENGTH,
    `Please enter a maximum of ${CEDING_PROVIDER_ACCOUNT_NUMBER__MAX_LENGTH} characters`
  );

const transferSchema = yup.object().shape({
  cedingProvider: yup
    .object()
    .shape({
      id: yup.string(),
      name: yup.string(),
      helpText: yup.string().nullable(),
    })
    .typeError('Please select a provider')
    .required('Please select a provider'),
  cedingProviderAccountNumber: accountNumberSchema,
  estimatedValue: yup.number().when('$wrapperType', {
    is: (wrapperType: WrapperType) => wrapperType === WrapperType.Sipp,
    then: (schema) =>
      schema
        .required(
          'Please enter the approximate value of the account being transferred'
        )
        .typeError('Please enter a number')
        .positive('Please enter a positive number'),
    otherwise: (schema) => schema.nullable(),
  }),
});

interface ProviderDetailsFormValues {
  cedingProviderAccountNumber: string;
  cedingProvider: CedingProvider | null;
  estimatedValue: number;
}

export interface ProviderDetailsOnProceedValues {
  cedingProviderAccountNumber: string;
  cedingProvider: CedingProvider;
  estimatedValue: number;
}

type OnProceedCallback = (values: ProviderDetailsOnProceedValues) => void;

interface ProviderDetailsProps {
  wrapperType: WrapperType;
  onProceed: OnProceedCallback;
  providerDetails?: ProviderDetailsFormValues;
  defaultValue?: number;
}

export function ProviderDetails({
  wrapperType,
  onProceed,
  providerDetails,
  defaultValue,
}: ProviderDetailsProps) {
  const methods = useForm<ProviderDetailsFormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(transferSchema),
    context: { wrapperType },
    defaultValues: {
      cedingProvider: null,
      ...providerDetails,
      estimatedValue: defaultValue,
    },
  });

  const { register, handleSubmit, control, watch, errors } = methods;

  const onSubmit = async (data: ProviderDetailsFormValues) => {
    if (data.cedingProvider === null) {
      return;
    }

    onProceed({
      cedingProvider: data.cedingProvider,
      cedingProviderAccountNumber: data.cedingProviderAccountNumber,
      estimatedValue: data.estimatedValue,
    });
  };

  const { showNewMessages } = useIntercom();
  const transferProvidersQuery = useTransferProvidersQuery({ wrapperType });

  const cedingProvider = watch('cedingProvider');
  const cedingProviderHelpText = cedingProvider?.helpText || '';

  const handleCantFindYourProviderButton = () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'talk to support',
      item_id: `transfer dialog - provider details - can't find provider`,
    });
    showNewMessages(
      `I need help with my ${wrapperNameFriendly(
        wrapperType
      )} transfer, I can't find my provider`
    );
  };

  const handleContactSupportPartialTransfer = () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'talk to support',
      item_id: `transfer dialog - provider details - partial transfer`,
    });
    showNewMessages(
      `I want to make a partial transfer into my ${wrapperNameFriendly(
        wrapperType
      )}`
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <StepContainer>
        <StepContent width={StepContentWidth.extraWide}>
          <StepTitle>
            Transfer my {wrapperNameFriendly(wrapperType)} to TILLIT
          </StepTitle>
          <StepIntroductionTypography>
            Can't find your provider?{' '}
            <CantFindYourProvider
              type="button"
              onMouseDown={handleCantFindYourProviderButton}
            >
              Let us know
            </CantFindYourProvider>{' '}
            where you want to transfer from and our Transfers Concierge team
            will look into this for you.
          </StepIntroductionTypography>
          <StepFormContainer>
            <QueryState {...transferProvidersQuery}>
              {() => (
                <Controller
                  control={control}
                  id="cedingProvider"
                  name="cedingProvider"
                  render={({ onChange, value }) => {
                    return (
                      <div>
                        <ComboBoxInput
                          label="Provider name"
                          options={
                            transferProvidersQuery.data?.transferProviders!
                          }
                          value={value}
                          optionLabel={(option) => option.name}
                          onChange={(ev, option) => {
                            onChange(option);
                          }}
                          noOptionText={
                            <NoOptionCopy>
                              Can't find your provider?{' '}
                              <CantFindYourProvider
                                type="button"
                                onMouseDown={handleCantFindYourProviderButton}
                              >
                                Let us know
                              </CantFindYourProvider>{' '}
                              where you want to transfer from and our Transfers
                              Concierge team will look into this for you.
                            </NoOptionCopy>
                          }
                        />
                        {errors.cedingProvider && (
                          <FormHelperText id="cedingProvider-helper-text" error>
                            {errors.cedingProvider.message}
                          </FormHelperText>
                        )}
                      </div>
                    );
                  }}
                />
              )}
            </QueryState>
            <div>
              <StyledInput
                inputRef={register}
                id="cedingProviderAccountNumber"
                name="cedingProviderAccountNumber"
                label="Account number"
                fullWidth={true}
              />
              {errors.cedingProviderAccountNumber && (
                <FormHelperText
                  id="cedingProviderAccountNumber-helper-text"
                  error
                >
                  {errors.cedingProviderAccountNumber.message}
                </FormHelperText>
              )}
              {cedingProviderHelpText && (
                <AccountNumberHelpText>
                  {cedingProviderHelpText}
                </AccountNumberHelpText>
              )}
            </div>
            {wrapperType === WrapperType.Sipp && (
              <div>
                <StyledInput
                  inputRef={register}
                  id="estimatedValue"
                  name="estimatedValue"
                  label="Estimated pension value (£)"
                  fullWidth={true}
                  onKeyDown={(ev) => {
                    if (!isNumberInputValid(ev)) {
                      ev.preventDefault();
                    }
                  }}
                />
                {errors.estimatedValue && (
                  <FormHelperText id="estimatedValue-helper-text" error>
                    {errors.estimatedValue.message}
                  </FormHelperText>
                )}
                <AccountNumberHelpText>
                  This should be the current total value of the pension being
                  transferred, which providers sometimes use to verify transfer
                  requests. If you want to transfer only part of the total
                  value, please{' '}
                  <StyledLink onClick={handleContactSupportPartialTransfer}>
                    contact support
                  </StyledLink>
                  .
                </AccountNumberHelpText>
              </div>
            )}
          </StepFormContainer>
        </StepContent>
        <StepActions>
          <StepButton type="submit" className="magenta">
            Continue
          </StepButton>
          <GoBackLink to={dashboardTransfersPath} />
        </StepActions>
      </StepContainer>
    </form>
  );
}
