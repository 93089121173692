import { Radio } from 'components/design-system/FormElements/Radio';
import { InfoPopoverV2 } from 'components/design-system/InfoPopoverV2/InfoPopoverV2';
import { ExclusivePill } from 'components/design-system/Pill/Pill';
import { FontSize, TextSmall } from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import * as format from 'formatting';
import { HedgingStatus, WrapperType } from 'generated/graphql';
import { getShortNameForWrapperType } from 'helpers/accountHelpers';
import {
  getInstrumentSelectorLabel,
  InstrumentLike,
} from 'helpers/instrumentNaming';
import _, { orderBy } from 'lodash';
import { UseFormMethods } from 'react-hook-form';
import Select from 'react-select';
import { AccountsQueryAccount } from 'types/graphqlTypes';
import {
  InstrumentDetailsColumn,
  InstrumentDetailsWrapper,
  InstrumentDetailWrapper,
  InstrumentExtraDetails,
  InstrumentName,
  StyledRadioGroup,
  StyledRadioWrapper,
} from './InstrumentSelector.styles';

const getHelperText = ({ incomeAccumulationFlag, hedging }: InstrumentLike) => {
  const lookup = {
    ['Accumulation' +
    HedgingStatus.Unhedged]: 'The accumulation share class is for investors wanting income generated by their investment to be automatically reinvested.',
    ['Accumulation' +
    HedgingStatus.Hedged]: 'The accumulation share class is for investors wanting income generated by their investment to be automatically reinvested. Hedged share classes remove the exchange movements between the currency of the share class and the currency of the underlying investments. Hedged share classes may be more expensive than unhedged share classes.',
    ['Income' +
    HedgingStatus.Unhedged]: 'When choosing this share class any income generated by the fund is paid out to you in cash. This share class can be useful for an investor who wants a regular income, such as in retirement.',
    ['Income' +
    HedgingStatus.Hedged]: 'When choosing this share class any income generated by the fund is paid out to you in cash. This share class can be useful for an investor who wants a regular income, such as in retirement. Hedged share classes remove the exchange movements between the currency of the share class and the currency of the underlying investments. Hedged share classes may be more expensive than unhedged share classes.',
  };

  return lookup[(incomeAccumulationFlag ?? '') + hedging];
};

interface InstrumentSelectorDetailsProps {
  accountType?: WrapperType;
  instrument: InstrumentLike;
  instruments: InstrumentLike[];
  showHelper?: boolean;
  highlighted?: boolean;
  currentValue?: number;
  showInstrumentName?: boolean;
  compact?: boolean;
}

export function InstrumentSelectorDetails({
  instrument,
  accountType,
  instruments,
  showHelper = true,
  highlighted = false,
  currentValue,
  showInstrumentName = true,
  compact = false,
}: InstrumentSelectorDetailsProps) {
  const hasCurrentValue = currentValue && currentValue > 0;
  const showExtraDetails = hasCurrentValue;

  return (
    <InstrumentDetailsWrapper $compact={compact} $highlighted={highlighted}>
      <InstrumentDetailsColumn invertOrder={!showInstrumentName}>
        {showInstrumentName && (
          <InstrumentName>
            {instrument.name}{' '}
            {instrument.isCustomUniverse && (
              <ExclusivePill $fontSize={FontSize.small} $canHover={false}>
                Employer Exclusive
              </ExclusivePill>
            )}
          </InstrumentName>
        )}
        {showExtraDetails && (
          <InstrumentExtraDetails>
            {hasCurrentValue &&
              `Current ${
                !!accountType
                  ? getShortNameForWrapperType(accountType) + ' holding'
                  : 'value'
              }: ${format.currencyFull(currentValue)}`}
          </InstrumentExtraDetails>
        )}
      </InstrumentDetailsColumn>
      <InstrumentDetailsColumn $align="left">
        <InstrumentDetailWrapper>
          <TextSmall $noMargin>
            {getInstrumentSelectorLabel(instrument, instruments, false)}{' '}
          </TextSmall>
          {showHelper && (
            <InfoPopoverV2
              size="small"
              placement="top"
              $width="wide"
              disableMargin
              content={getHelperText(instrument)}
            />
          )}
          {!showInstrumentName && instrument.isCustomUniverse && (
            <ExclusivePill $fontSize={FontSize.small} $canHover={!compact}>
              {!compact ? 'Employer Exclusive' : ''}
            </ExclusivePill>
          )}
        </InstrumentDetailWrapper>
      </InstrumentDetailsColumn>
    </InstrumentDetailsWrapper>
  );
}

interface InstrumentSelectorProps extends InstrumentSelectorDetailsProps {
  account?: AccountsQueryAccount;
}

interface InstrumentSelectorRadioProps
  extends Omit<InstrumentSelectorProps, 'instrument'> {
  value?: string;
  defaultValue?: string;
  $compact?: boolean;
  $isWide?: boolean;
  $withBorders?: boolean;
  onChange: (isin: string) => void;
  account?: AccountsQueryAccount;
  register?: UseFormMethods['register'];
}

export function InstrumentSelectorRadio({
  instruments,
  value,
  defaultValue,
  account,
  showInstrumentName = true,
  $compact = false,
  $isWide = true,
  $withBorders = true,
  onChange,
  register,
}: InstrumentSelectorRadioProps) {
  const orderedInstruments = orderBy(instruments, [
    (i) => i.isCustomUniverse,
    (i) => i.isDarkUniverse,
    (i) => i.hedging === HedgingStatus.Hedged,
    (i) => i.incomeAccumulationFlag,
  ]);

  return (
    <StyledRadioGroup $compact={$compact} $isWide={$isWide}>
      {orderedInstruments.map((instrument) => {
        const userHolds = account?.positions.find(
          (position) => instrument.isin === position.isin
        );

        return (
          <StyledRadioWrapper key={instrument.isin} $border={$withBorders}>
            <Radio
              name={'instrument-selector'}
              value={instrument.isin}
              checked={value ? instrument.isin === value : undefined}
              defaultChecked={
                defaultValue ? instrument.isin === defaultValue : undefined
              }
              ref={register}
              onChange={onChange}
              label={
                <InstrumentSelectorDetails
                  key={instrument.isin}
                  accountType={account?.wrapperType}
                  instruments={instruments}
                  instrument={instrument}
                  currentValue={userHolds?.currentValue || undefined}
                  showInstrumentName={showInstrumentName}
                />
              }
              wrapLabel={false}
            />
          </StyledRadioWrapper>
        );
      })}
    </StyledRadioGroup>
  );
}

interface OptionType {
  value: string;
  label: string | JSX.Element | React.ReactNode;
  isin: string;
}

interface InstrumentSelectorSelectProps extends InstrumentSelectorProps {
  account?: AccountsQueryAccount;
  defaultValue?: string;
  value?: string;
  $color?: 'primary' | 'secondary';
  onChange: (isin: string) => void;
}

export function InstrumentSelectorSelect({
  instruments,
  account,
  defaultValue,
  value,
  $color = 'primary',
  onChange,
}: InstrumentSelectorSelectProps) {
  const selectOption = _(instruments)
    .orderBy([
      (i) => i.isCustomUniverse,
      (i) => i.hedging === HedgingStatus.Hedged,
      (i) => i.incomeAccumulationFlag,
    ])
    .map((instrument) => {
      const userHolds = account?.positions.find(
        (position) => instrument.isin === position.isin
      );
      return {
        label: (
          <InstrumentSelectorDetails
            key={instrument.isin}
            accountType={account?.wrapperType}
            instruments={instruments}
            instrument={instrument}
            showHelper={false}
            currentValue={userHolds?.currentValue || undefined}
            showInstrumentName={false}
            compact={true}
          />
        ),
        isin: instrument?.isin,
        value: instrument?.isin,
        helpText: getHelperText(instrument),
      };
    })
    .value();

  return (
    <>
      <Select<OptionType>
        onChange={({ isin }: OptionType) => onChange?.(isin)}
        options={selectOption}
        defaultValue={
          defaultValue
            ? selectOption.find(({ isin }) => isin === defaultValue)
            : undefined
        }
        value={
          value ? selectOption.find(({ isin }) => isin === value) : undefined
        }
        placeholder="Select instrument"
        isSearchable={false}
        styles={{
          dropdownIndicator: (baseStyles) => ({
            ...baseStyles,
            color: colors.richBlack,
          }),
          control: (baseStyles) => ({
            ...baseStyles,
            backgroundColor: colors.white,
            borderRadius: '10px',
            width: '100%',
            borderColor:
              $color === 'primary' ? colors['magenta-100'] : colors['grey-600'],
            outline: 'none',
            boxShadow: 'none',
            cursor: 'pointer',
            '&:hover': {
              borderColor:
                $color === 'primary'
                  ? colors['magenta-300']
                  : colors['grey-800'],
            },
          }),
          indicatorSeparator: () => ({
            display: 'none',
          }),
          menu: (baseStyles) => ({
            ...baseStyles,
            borderRadius: '1rem',
            overflow: 'hidden',
          }),
          option: (baseStyles, state) => ({
            ...baseStyles,
            cursor: 'pointer',
            backgroundColor: state.isSelected
              ? colors['magenta-50']
              : colors.white,
            '&:hover': {
              backgroundColor: colors['magenta-25'],
            },
          }),
        }}
      />
    </>
  );
}
