import '@tillitinvest/video.js/dist/video-js.css';
import { TextAlign } from 'components/design-system/Heading/Heading';
import { Text } from 'components/design-system/Text/Text';
import { Chapter } from 'generated/graphql';
import React, { useEffect, useRef, useState } from 'react';
import { HiOutlineCheck, HiOutlineEye } from 'react-icons/hi';
import { CSSProperties } from 'styled-components';
import {
  ActiveIndicator,
  ChapterItem,
  ChaptersInner,
  ChaptersWrapper,
  IconContainer,
  StyledChaptersContainer,
} from './VideoPlayer.styles';

interface ChapterIconProps {
  viewed: boolean;
  viewing: boolean;
}

function ChapterIcon({ viewed, viewing }: ChapterIconProps) {
  if (viewed && !viewing) return <HiOutlineCheck />;
  return <HiOutlineEye />;
}

interface SingleChapterProps {
  currentTime: number;
  startTime: number;
  endTime: number;
  children: React.ReactNode | JSX.Element | string;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  onViewed?: () => void;
}

const SingleChapter = React.forwardRef<HTMLDivElement, SingleChapterProps>(
  (
    {
      currentTime,
      startTime,
      endTime,
      children,
      onClick,
      onViewed,
    }: SingleChapterProps,
    ref
  ) => {
    const [viewed, setViewed] = useState<boolean>(false);
    const [viewing, setViewing] = useState<boolean>(false);
    const active = currentTime >= startTime && currentTime < endTime;

    const iconClasses = () => {
      let classes = '';
      if (viewed && !viewing) classes += ' viewed';
      if (viewing) classes += ' viewing';
      return classes;
    };

    useEffect(() => {
      if (active && !viewed) {
        setViewed(true);
      }
      if (active) {
        setViewing(true);
      } else {
        setViewing(false);
      }
    }, [active, viewed]);

    useEffect(() => {
      if (active) onViewed?.();
    }, [active, onViewed]);

    return (
      <ChapterItem
        className={viewing ? 'active' : ''}
        ref={ref}
        onClick={(e) => onClick(e)}
      >
        <IconContainer className={iconClasses()}>
          <ChapterIcon viewed={viewed} viewing={viewing} />
        </IconContainer>
        {children}
      </ChapterItem>
    );
  }
);

interface ChaptersContainerProps {
  chapters: Chapter[];
  currentTime: number;
  videoHeight: number;
  onClick: (jumpTo: number, title: string) => void;
}

export function ChaptersContainer({
  chapters,
  currentTime = 0,
  videoHeight,
  onClick,
  ...props
}: ChaptersContainerProps) {
  const ChapterWrapperRef = useRef<HTMLDivElement | null>(null);
  const ActiveChapterRef = useRef<HTMLDivElement | null>(null);
  const [activeIndicatorStyling, setActiveIndicatorStyling] = useState<
    CSSProperties | undefined
  >(undefined);

  const handleClick = (
    e: React.MouseEvent<HTMLDivElement>,
    start: number,
    title: string
  ) => {
    onClick(start, title);
    if (e.currentTarget) {
      setActiveIndicatorStyling({
        top: e.currentTarget.offsetTop + 'px',
        height: e.currentTarget.clientHeight + 'px',
      });
    }
  };

  const handleOnView = () => {
    if (ActiveChapterRef.current && ChapterWrapperRef.current) {
      ChapterWrapperRef.current.scrollTo({
        top:
          ActiveChapterRef.current.offsetTop -
          ChapterWrapperRef.current.clientHeight / 2,
        behavior: 'smooth',
      });

      setActiveIndicatorStyling({
        top: ActiveChapterRef.current.offsetTop + 'px',
        height: ActiveChapterRef.current.clientHeight + 'px',
      });
    }
  };

  const activeChapterIndex = chapters.findIndex(
    (chapter) => currentTime >= chapter.start && currentTime < chapter.end
  );

  return (
    <StyledChaptersContainer {...props} style={{ height: videoHeight + 'px' }}>
      <ChaptersWrapper ref={ChapterWrapperRef}>
        <ActiveIndicator style={activeIndicatorStyling} />
        <ChaptersInner>
          {chapters.map((chapter, i) => {
            const active = activeChapterIndex === i;

            return (
              <SingleChapter
                key={i}
                ref={active ? ActiveChapterRef : null}
                currentTime={currentTime}
                onClick={(e) => handleClick(e, chapter.start, chapter.title)}
                startTime={chapter.start}
                endTime={chapter.end}
                onViewed={handleOnView}
              >
                <Text $textAlign={TextAlign.left} $noMargin>
                  {i + 1} - {chapter.title}
                </Text>
              </SingleChapter>
            );
          })}
        </ChaptersInner>
      </ChaptersWrapper>
    </StyledChaptersContainer>
  );
}
