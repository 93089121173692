import {
  ResponsiveDialog,
  ResponsiveDialogProps,
} from 'components/ResponsiveDialog';
import { Withdraw } from './Withdraw';

interface WithdrawDialogProps extends ResponsiveDialogProps {
  accountId: string;
}

export function WithdrawDialog({
  accountId,
  onClose,
  ...props
}: WithdrawDialogProps) {
  return (
    <ResponsiveDialog {...props} onClose={onClose}>
      <Withdraw accountId={accountId} onClose={() => onClose?.()} />
    </ResponsiveDialog>
  );
}
