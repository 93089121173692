import { QueryState } from 'components/QueryState';
import { useAssetsTotalCountQuery } from 'generated/graphql';
import { Highlighted, ViewCountContainer } from './ViewCount.style';

type ViewCountProps = {
  viewing: number;
  total: number;
};

export function ViewCount({ viewing, total }: ViewCountProps) {
  const assetsTotalCount = useAssetsTotalCountQuery();

  return (
    <QueryState {...assetsTotalCount}>
      {() => (
        <ViewCountContainer>
          {total > 12 ? (
            <p>
              Showing <Highlighted> {viewing}</Highlighted> out of
              <Highlighted> {total} </Highlighted> funds
            </p>
          ) : (
            <p>
              Showing <Highlighted> {viewing}</Highlighted> funds
            </p>
          )}
        </ViewCountContainer>
      )}
    </QueryState>
  );
}
