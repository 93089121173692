import { QueryState } from 'components/QueryState';
import { H1Large, TextAlign } from 'components/design-system/Heading/Heading';
import { TabNav } from 'components/design-system/Tabs/TabNav/TabNav';
import { Text } from 'components/design-system/Text/Text';
import { FundsLayout } from 'components/feature/FundsList/NewFundsPageLayout';
import { useMode } from 'components/feature/mode/useMode';
import {
  WrapperType,
  useDepositDetailsByAccountQuery,
  useUserProfileQuery,
} from 'generated/graphql';
import { getPathSegmentForWrapperType } from 'helpers/accountHelpers';
import {
  autoSaveInvestPath,
  autoSaveInvestPathAddFundsPath,
  autoSaveInvestPathMyFundsPath,
  generateAutoSaveInvestPathPath,
  generateAutoSaveInvestSubPath,
} from 'paths';
import { useEffect } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import type { AccountsQueryAccount } from 'types/graphqlTypes';
import { useEffectOnce } from 'usehooks-ts';
import { useAutoSaveInvestState } from '../AutoSaveInvestContext';
import { CantDeposit } from '../newDeposit/steps/CantDeposit';
import { MyFundsTab } from './MyFundsTab/MyFundsTab';
import { SelectFundsSelectedFundsForm } from './OrderTab/SelectFundsSelectedFundsForm';
import {
  HeaderWrapper,
  MyFundsContainer,
  SelectFundsPercentagesStepContainer,
  TabsWrapper,
} from './SelectFundsPercentages.styles';

interface SelectFundsPercentagesStepProps {
  account: AccountsQueryAccount;
}

function SelectFundsPercentagesInner({
  account,
}: SelectFundsPercentagesStepProps) {
  return (
    <>
      <SelectFundsPercentagesStepContainer>
        <HeaderWrapper>
          <H1Large $textAlign={TextAlign.center}>
            Regular deposit & invest plan
          </H1Large>
          <Text $textAlign={TextAlign.center}>
            Select the funds, and an amount for each fund you want to regularly
            invest in.
          </Text>
        </HeaderWrapper>

        <TabsWrapper>
          <TabNav
            navItems={[
              {
                label: 'My order',
                href: generateAutoSaveInvestPathPath({
                  wrapperType: getPathSegmentForWrapperType(
                    account.wrapperType
                  ),
                }),
              },
              {
                label: 'My funds',
                href: generateAutoSaveInvestSubPath({
                  wrapperType: getPathSegmentForWrapperType(
                    account.wrapperType
                  ),
                  action: 'my-funds',
                }),
              },
              {
                label: 'Add funds',
                href: generateAutoSaveInvestSubPath({
                  wrapperType: getPathSegmentForWrapperType(
                    account.wrapperType
                  ),
                  action: 'add-funds',
                }),
              },
            ]}
          />
        </TabsWrapper>
        <Switch>
          <Route path={autoSaveInvestPathAddFundsPath}>
            <FundsLayout
              selectedAccountType={account.wrapperType}
              selectedAccountId={account.id ?? undefined}
              source="regular-order"
            />
          </Route>
          <Route path={autoSaveInvestPathMyFundsPath}>
            <MyFundsContainer>
              <MyFundsTab account={account} />
            </MyFundsContainer>
          </Route>
          <Route path={autoSaveInvestPath}>
            <SelectFundsSelectedFundsForm account={account} />
          </Route>
        </Switch>
      </SelectFundsPercentagesStepContainer>
    </>
  );
}

export function SelectFundsPercentages({
  account,
}: SelectFundsPercentagesStepProps) {
  const [mode, setMode] = useMode();
  const userProfileQuery = useUserProfileQuery();

  const depositDetailsByAccountQuery = useDepositDetailsByAccountQuery(
    {
      id: account.id!,
    },
    { enabled: typeof account.id === 'string' }
  );
  const depositDetailsByAccount =
    depositDetailsByAccountQuery.data?.depositDetailsByAccount;

  const canDeposit = depositDetailsByAccount?.canDeposit;
  const reason = depositDetailsByAccount?.statusReason;

  const { state } = useAutoSaveInvestState(account.id);
  const history = useHistory();

  useEffectOnce(() => {
    if (
      state?.deposits?.length === 0 &&
      account.wrapperType !== WrapperType.Sipp
    ) {
      history.push({
        pathname: generateAutoSaveInvestSubPath({
          wrapperType: getPathSegmentForWrapperType(account.wrapperType),
          action: 'create-deposit',
        }),
        state: { from: 'auto-save-invest--redirect' },
      });
    }
  });

  const isAddFundsPath = useRouteMatch(autoSaveInvestPathAddFundsPath);

  useEffect(() => {
    if (
      (mode !== null || isAddFundsPath) &&
      mode?.mode !== 'resume' &&
      account.wrapperType !== mode?.wrapperType
    ) {
      setMode({
        wrapperType: account.wrapperType,
        mode: 'autoSaveInvest',
      });
    }
  }, [account.wrapperType, setMode, isAddFundsPath, mode]);

  return (
    <QueryState
      {...depositDetailsByAccountQuery}
      dataTestid="depositDetailsByAccountQuery"
    >
      {() => {
        if (!canDeposit && reason) {
          return <CantDeposit reason={reason} />;
        }

        return (
          <QueryState {...userProfileQuery}>
            {() => <SelectFundsPercentagesInner account={account} />}
          </QueryState>
        );
      }}
    </QueryState>
  );
}
