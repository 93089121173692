import { ButtonsWrapper } from 'components/ButtonsWrapper';
import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import { Card } from 'components/design-system/Card/Card';
import { H4, TextAlign } from 'components/design-system/Heading/Heading';
import { useFundsBasket } from 'components/feature/PortfolioBuilder/hooks/useFundsBasket';
import {
  CreatePortfolioRebalancingMutation,
  UpdatePortfolioRebalancingMutation,
  WrapperType,
  usePortfolioRebalancingsQuery,
} from 'generated/graphql';
import { getPathSegmentForWrapperType } from 'helpers/accountHelpers';
import { generateCheckoutPath } from 'paths';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { InteractiveBreakdown } from '../../InteractiveBreakdown/InteractiveBreakdown';

function isCreatePortfolioRebalancingMutation(
  portfolioRebalancingMutation:
    | CreatePortfolioRebalancingMutation
    | UpdatePortfolioRebalancingMutation
): portfolioRebalancingMutation is CreatePortfolioRebalancingMutation {
  return (
    (portfolioRebalancingMutation as CreatePortfolioRebalancingMutation)
      .createPortfolioRebalancing !== undefined
  );
}

interface PreviewStepProps {
  selectedAccountType: WrapperType;
  selectedAccountId: string;
  onProceed: () => void;
}

export const PreviewStep = ({
  selectedAccountType,
  selectedAccountId,
  onProceed,
}: PreviewStepProps) => {
  const { syncToServer } = useFundsBasket({ selectedAccountId });
  const history = useHistory();
  const queryClient = useQueryClient();
  return (
    <div>
      <H4 $textAlign={TextAlign.center}>Order added to your basket</H4>
      <Card elevation={0}>
        <InteractiveBreakdown
          selectedAccountType={selectedAccountType}
          selectedAccountId={selectedAccountId}
          isInteractive={false}
          variant="compact"
        />
      </Card>

      <ButtonsWrapper
        $isHorizontal={true}
        $stretchButtons={true}
        style={{ marginTop: '3.5rem' }}
      >
        <CustomButtonV2
          onClick={async () => {
            const res = await syncToServer();

            if (!res) {
              return;
            }

            let id: string;
            if (!res) {
              return;
            }
            if (isCreatePortfolioRebalancingMutation(res)) {
              id = res.createPortfolioRebalancing.id;
            } else {
              id = res.updatePortfolioRebalancing.id;
            }

            await queryClient.removeQueries(
              usePortfolioRebalancingsQuery.getKey({
                filter: { accountId: selectedAccountId },
              })
            );

            history.push(
              generateCheckoutPath({
                wrapperType: getPathSegmentForWrapperType(selectedAccountType),
                selectedRebalancingId: id,
              })
            );
          }}
        >
          Checkout now
        </CustomButtonV2>
        <CustomButtonV2
          $color="secondary"
          onClick={() => {
            onProceed();
          }}
        >
          Continue browsing
        </CustomButtonV2>
      </ButtonsWrapper>
    </div>
  );
};
