import { Text } from 'components/design-system/Text/Text';
import find from 'lodash/find';

export function getGlossaryDataForCode(code: string) {
  return find(
    GlossaryData.defs.flatMap((glossaryDataCategory) =>
      glossaryDataCategory.data.map((tag) => ({
        ...tag,
        categoryCode: glossaryDataCategory.code,
      }))
    ),
    (menuData) => menuData.code === code
  );
}

export const GlossaryData = {
  title: 'Glossary',
  defs: [
    {
      title: 'Investment goal',

      code: 'INVGOAL',

      data: [
        {
          title: 'Investing for decades',
          code: 'INVFORDECADES',
          content: (
            <Text>
              There are some investment opportunities that are particularly
              well-suited to a long time horizon due to how, where or in what
              they invest. Over the long-term these funds have the potential to
              deliver high returns, but in the short-term they can experience a
              lot of volatility.
            </Text>
          ),
        },
        {
          title: 'Shield and protect',
          code: 'SHIELDPROTECT',
          content: (
            <Text>
              Sometimes, growing your money isn’t the primary, or at least the
              only, investment goal. For some (or in some circumstances), it’s
              more important to preserve the value of your money and shield it
              from things like inflation, etc.
            </Text>
          ),
        },
        {
          title: 'Returns led by values',
          code: 'RETURNSLEDBYVAL',
          content: (
            <Text>
              Investing doesn’t have to be about only maximising financial
              returns, it can also be a tool to drive change in society in line
              with your values. Or perhaps you’re keen to invest in funds run by
              women. Regardless of what matters to you, these funds enable you
              to invest in line with your values.
            </Text>
          ),
        },
        {
          title: 'Income boosters',
          code: 'INCBOOSTERS',
          content: (
            <Text>
              Income investing allows you to get some of your investment returns
              paid out regularly as you go, rather than reinvest them. This can
              be particularly helpful if you want to supplement your main source
              of income. Most people think of bonds when they think of income
              investing, but you can actually achieve this investment goal in a
              number of ways.
            </Text>
          ),
        },
        {
          title: 'Ready-made',
          code: 'READYMADE',
          content: (
            <Text>
              Sometimes you want to pick your own funds but you don’t want to
              make decisions on asset allocation, or perhaps you just want broad
              access to ‘the stock market’. ‘Ready-made’ solutions can be great
              building blocks in any portfolio and sometimes even make up the
              entire portfolio.
            </Text>
          ),
        },
        {
          title: 'Something different',
          code: 'DIFFERENT',
          content: (
            <Text>
              One of the benefits of picking your own funds is that you can
              invest in things that reflect your personal interests,
              preferences, or even hopes for the future. In this collection of
              funds, you will typically find things that carry a bit more (or at
              least different) risk compared to more mainstream funds.
            </Text>
          ),
        },
      ],
    },
    {
      title: 'Active/passive',

      code: 'ACTIVE',

      data: [
        {
          title: 'Active',

          code: 'ACTIVE',
          content: (
            <Text>
              Funds that are managed by one or more fund managers who use their
              judgement to pick which investments to hold in the fund. An active
              fund should be judged against a benchmark and aims to deliver a
              higher return than that benchmark after fees.
            </Text>
          ),
        },
        {
          title: 'Passive',
          code: 'PASSIVE',
          content: (
            <Text>
              Funds that aim to replicate an index or otherwise predefined group
              of assets. Passive funds are usually cheaper than active funds as
              there are no research and management costs involved.
            </Text>
          ),
        },
      ],
    },
    {
      title: 'Fund structure',
      code: 'STRUCTURE',
      data: [
        {
          title: 'Open-ended funds',
          code: 'OPEN',
          content: (
            <Text>
              This includes Open Ended Investment Companies (OEICs) and Unit
              Trusts. This is a common structure for funds based in the UK and
              it allows investors to pool their money in order to invest in a
              particular country, sector or style. The price an investor pays
              reflects the price of the underlying investments at a fixed point
              every day. These funds can provide exposure to a wide range of
              markets and asset classes.
            </Text>
          ),
        },
        {
          title: 'Investment trust',
          listPageTitle: 'Investment trusts',
          code: 'INVTRUST',
          content: (
            <Text>
              Funds that are structured in what is often referred to as
              ‘closed-ended’. An investment trust is a company whose shares are
              listed on the stock exchange and which can be bought and sold like
              those of any other company. It can invest in different assets,
              private or publicly listed, and can take on debt, which is
              expressed as gearing. The value of the underlying investments
              minus the debt is called the net asset value (NAV). The share
              price of the trust is determined by the supply and demand of the
              trust’s shares and can either trade at a premium or discount to
              the NAV.
            </Text>
          ),
        },
        {
          title: 'ETF',
          listPageTitle: 'Exchange Traded Funds',
          code: 'ETC',
          content: (
            <Text>
              Funds that are structured as an Exchange-Traded Fund (ETF). This
              type of fund trades on an exchange, just like equities. They are
              passive investments which automatically track an index or a
              pre-set group of assets. Exchange-Traded Commodities (ETCs) is a
              common type of ETF for investors who want exposure to commodities
              such as gold and silver.
            </Text>
          ),
        },
      ],
    },
    {
      title: 'Asset class',
      code: 'CLASS',
      data: [
        {
          title: 'Equities',
          listPageTitle: 'Equity funds',
          code: 'EQUITIES',
          content: (
            <Text>
              Funds that invest in companies whose shares are listed on an
              exchange. Equities is another term commonly used for stocks and
              shares. Equities tend to be higher risk and with the potential for
              higher returns than assets such as bonds. They are generally
              considered appropriate for long-term investing.
            </Text>
          ),
        },
        {
          title: 'Bonds',
          listPageTitle: 'Bond funds',
          code: 'BONDS',
          content: (
            <Text>
              Funds that invest in bonds issued by companies or governments, or
              both. A bond is similar to a loan between a borrower (a company or
              government) and a lender (the investor). Just like a loan, the
              bond typically pays the investor interest, which is called a
              coupon. Bonds are also categorised as investment-grade or
              high-yield bonds depending on the ability and willingness of the
              borrower to repay the debt in full. Bonds are often referred to as
              fixed income investments.
            </Text>
          ),
        },
        {
          title: 'Property',
          code: 'PROPERTY',
          content: (
            <Text>
              Funds that invest in property. Property funds can come in both
              ‘open-ended’ (typically OEIC) and closed-ended (typically REITs)
              structures and can invest in different types of properties, either
              in one specific country or across different regions. They may be
              able to invest either directly in property and/or through listed
              property companies.
            </Text>
          ),
        },
        {
          title: 'Alternative assets',
          listPageTitle: 'Alternative asset funds',
          code: 'ALTS',
          content: (
            <Text>
              Funds that invest in areas outside of the mainstream asset classes
              such as equities, bonds and property. Examples include
              commodities, infrastructure and private equity.
            </Text>
          ),
        },
        {
          title: 'Multi-asset',
          code: 'MULTI',
          content: (
            <Text>
              Funds that invest in more than one asset class. Most funds are
              dedicated to investing in one type of asset class. Equity funds
              invest in equities/stocks/shares, bond funds invest in bonds and
              so on. This combination of various asset classes can increase
              diversification and reduce volatility because different asset
              classes tend to perform differently.
            </Text>
          ),
        },
      ],
    },
    {
      title: 'Return profile ',
      code: 'PROFILE',
      data: [
        {
          title: 'Capital growth',
          // cspell:disable-next-line
          code: 'CAPGROWTH',
          content: (
            <Text>
              Funds that aim to grow capital over time, typically equity funds.
            </Text>
          ),
        },
        {
          title: 'Income',
          code: 'INCOME',
          content: (
            <Text>
              Funds that have an explicit aim to generate income for the
              investor, typically bonds funds or equity funds with a focus on
              generating income.
            </Text>
          ),
        },
        {
          title: 'Total return',
          // cspell:disable-next-line
          code: 'TOTALRET',
          content: (
            <Text>
              Funds that focus on generating a positive total return regardless
              of the market conditions. These funds are typically measured
              against cash and tend to be multi-asset funds.
            </Text>
          ),
        },
        {
          title: 'Capital preservation',
          // cspell:disable-next-line
          code: 'CAPPRES',
          content: (
            <Text>
              Funds that have an explicit aim to preserve capital in real terms,
              typically conservatively managed funds, including multi-asset
              funds. However, this aim doesn’t mean that capital preservation is
              guaranteed.
            </Text>
          ),
        },
      ],
    },
    {
      title: 'Region',
      code: 'GEOG',
      data: [
        {
          title: 'Global',
          code: 'GLOBAL',
          content: (
            <Text>
              Funds that invest in assets across the globe. These funds can be
              focused on any kind of asset class, but the common denominator is
              that they can hunt for ideas and invest all over the world.
            </Text>
          ),
        },
        {
          title: 'UK',
          code: 'UK',
          content: (
            <Text>
              Funds that invest predominantly in UK assets. These funds can be
              focused on any kind of asset class, but the common denominator is
              that they invest in assets predominantly listed in, or exposed to,
              the UK.
            </Text>
          ),
        },
        {
          title: 'US',
          code: 'US',
          content: (
            <Text>
              Funds that invest predominantly in US assets. These funds can be
              focused on any kind of asset class, but the common denominator is
              that they invest in assets predominantly listed in, or exposed to,
              the US.
            </Text>
          ),
        },
        {
          title: 'Europe',
          code: 'EUROPE',
          content: (
            <Text>
              Funds that invest in assets in Europe. These funds can be focused
              on any kind of asset class, but the common denominator is that
              they hunt for ideas and invest all over Europe. Some European
              funds exclude the UK.
            </Text>
          ),
        },
        {
          title: 'Japan',
          code: 'JAPAN',
          content: (
            <Text>
              Funds that invest predominantly in Japanese assets. These funds
              can be focused on any kind of asset class, but the common
              denominator is that they invest in assets predominantly listed in,
              or exposed to, Japan.
            </Text>
          ),
        },
        {
          title: 'Asia',
          // cspell:disable-next-line
          code: 'ASIA',
          content: (
            <Text>
              Funds that invest in assets in Asia. These funds can be focused on
              any kind of asset class, but the common denominator is that they
              hunt for ideas and invest all over Asia.
            </Text>
          ),
        },
        {
          title: 'Asia ex-Japan',
          // cspell:disable-next-line
          code: 'ASIAEXJAP',
          content: (
            <Text>
              Funds that invest in assets in Asia. These funds can be focused on
              any kind of asset class, but the common denominator is that they
              hunt for ideas and invest all over Asia, apart from Japan.
            </Text>
          ),
        },
        {
          title: 'Emerging markets',
          listPageTitle: 'Emerging market funds',
          code: 'EMERGING',
          content: (
            <Text>
              Funds that invest in assets in emerging markets. An emerging
              market is a country that is like a developed market (such as the
              US and UK) but is less advanced. Usually, regulation and market
              liquidity tend to be less developed than in developed markets.
              But, as emerging markets tend to grow faster than developed
              markets, they have the potential to deliver higher returns (and
              risk) for investors. These funds can be focused on any kind of
              asset class, but the common denominator is that they hunt for
              ideas and invest across emerging markets. Current examples include
              China and India.
            </Text>
          ),
        },
        {
          title: 'Frontier markets',
          listPageTitle: 'Frontier market funds',
          code: 'FRONTIER',
          content: (
            <Text>
              Funds that invest in assets in frontier markets. A frontier market
              is a country that is like an emerging market (such as China and
              India) but less advanced and is sometimes called a ‘pre-emerging
              market’. It tends to be smaller, less liquid and less accessible
              for investors and normally riskier than an emerging market. Growth
              and development in these countries tend to be volatile so if you
              are thinking about investing in these countries you should take a
              very long-term view (sometimes several decades) to have time to
              ride out any volatility. These funds can be focused on any kind of
              asset class, but the common denominator is that they hunt for
              ideas and invest across frontier markets. Current examples include
              Vietnam and Kenya.
            </Text>
          ),
        },
      ],
    },
    {
      title: 'Equity style',
      // cspell:disable-next-line
      code: 'EQSTYLE',
      data: [
        {
          title: 'High Growth',
          // cspell:disable-next-line
          code: 'HIGROWTH',
          content: (
            <Text>
              Funds that invest in line with Growth style investing, but
              specifically focus on identifying and investing in companies with
              particularly high growth potential. These can be small cap funds,
              or any other funds focused on investing in companies that are
              fundamentally changing industries.
            </Text>
          ),
        },
        {
          title: 'Growth',
          code: 'GROWTH',
          content: (
            <Text>
              Funds that invest in line with Growth style investing. In simple
              terms, this means that the manager or team focuses on buying
              stocks of companies which have the potential to grow their sales
              or profits much faster than their competitors or the overall
              market, regardless of the economic backdrop.
            </Text>
          ),
        },
        {
          title: 'Value',
          code: 'VALUE',
          content: (
            <Text>
              Funds that invest in line with Value style investing. In simple
              terms, this means that the manager or team typically focuses on
              buying stocks whose share price appears to be below the intrinsic
              value of the company, as calculated from its profits or its assets
              and liabilities.
            </Text>
          ),
        },
        {
          title: 'Deep Value ',
          // cspell:disable-next-line
          code: 'DEEPVALUE',
          content: (
            <Text>
              Funds that invest in line with Value style investing, but
              specifically focused on identifying and investing in companies
              that seem particularly undervalued and shunned by the wider
              market.
            </Text>
          ),
        },
        {
          title: 'Flexible',
          listPageTitle: 'Flexible equity funds',
          // cspell:disable-next-line
          code: 'FLEXEQ',
          content: (
            <Text>
              Funds that don’t invest according to one specific style, such as
              Growth or Value but instead take a flexible approach across styles
              depending on where the fund manager(s) is finding attractive
              investment opportunities at any given point in time.
            </Text>
          ),
        },
        {
          title: 'All-cap',
          // cspell:disable-next-line
          code: 'ALLCAP',
          content: (
            <Text>
              Funds that can invest across the market cap spectrum. Market cap
              is short for “market capitalisation” and means the total value of
              a company’s outstanding shares. It’s calculated by multiplying the
              share price with the total number of shares outstanding. Funds
              that invest across the market cap spectrum therefore tend to
              invest in companies of all sizes. The definition of “All-cap”
              varies, so we have classified the funds based on the definitions
              from the asset managers.
            </Text>
          ),
        },
        {
          title: 'Large-cap',
          // cspell:disable-next-line
          code: 'LARGECAP',
          content: (
            <Text>
              Funds that invest in companies with a large market cap. Market cap
              is short for “market capitalisation” and means the total value of
              a company’s outstanding shares. It’s calculated by multiplying the
              share price with the total number of shares outstanding. Large-cap
              companies tend to be (but not always) large companies. The
              definition of “Large-cap” varies, so we have classified the funds
              based on the definitions from the asset managers.
            </Text>
          ),
        },
        {
          title: 'Mid-cap',
          // cspell:disable-next-line
          code: 'MIDCAP',
          content: (
            <Text>
              Funds that invest in companies with a mid-sized market cap. Market
              cap is short for “market capitalisation” and means the total value
              of a company’s outstanding shares. It’s calculated by multiplying
              the share price with the total number of shares outstanding. The
              definition of “Mid-cap” varies, so we have classified the funds
              based on the definitions from the asset managers.
            </Text>
          ),
        },
        {
          title: 'Small-cap',
          // cspell:disable-next-line
          code: 'SMALLCAP',
          content: (
            <Text>
              Funds that invest in companies with a small market cap. Market cap
              is short for “market capitalisation” and means the total value of
              a company’s outstanding shares. It’s calculated by multiplying the
              share price with the total number of shares outstanding. Small-cap
              companies tend to be (but not always) small companies. The
              definition of “Small-cap” varies, so we have classified the funds
              based on the definitions from the asset managers.
            </Text>
          ),
        },
      ],
    },
    {
      title: 'Bond style',
      // cspell:disable-next-line
      code: 'BONDSTYLE',
      data: [
        {
          title: 'Government bonds',
          listPageTitle: 'Government bond funds',
          // cspell:disable-next-line
          code: 'GOVBOND',
          content: (
            <Text>
              Funds that invest in bonds issued by governments, either by one
              specific government or a mixture. Most bonds are fixed income
              assets and are similar to a loan between a borrower (in this case,
              a government) and a lender (the investor). Just like a loan, the
              bond typically pays the investor interest, which is called a
              coupon. Bonds are also categorised as investment-grade or
              high-yield bonds depending on the ability and willingness of the
              borrower to repay the debt in full.
            </Text>
          ),
        },
        {
          title: 'Corporate bonds',
          listPageTitle: 'Corporate bond funds',
          // cspell:disable-next-line
          code: 'CORPBOND',
          content: (
            <Text>
              Funds that invest in bonds issued by companies, either private or
              public. Most bonds are fixed income assets and are similar to a
              loan between a borrower (in this case, a company) and a lender
              (the investor). Just like a loan, the bond typically pays the
              investor interest, which is called a coupon. Bonds are also
              categorised as investment-grade or high-yield bonds depending on
              the ability and willingness of the borrower to repay the debt in
              full.
            </Text>
          ),
        },
        {
          title: 'Investment-grade',
          listPageTitle: 'Investment-grade bond funds',
          // cspell:disable-next-line
          code: 'INVGRADE',
          content: (
            <Text>
              Funds that invest in bonds issued by companies or governments that
              are rated “investment-grade”, which is determined by one or more
              credit rating agencies. This rating suggests that there is a lower
              risk of the company or government not being able or willing, to
              pay back the bond than high-yield rated bonds. Because of this
              relatively low risk, these bonds tend to offer a lower yield than
              high-yield bonds.
            </Text>
          ),
        },
        {
          title: 'High-yield',
          listPageTitle: 'High-yield bond funds',
          // cspell:disable-next-line
          code: 'HIGHYIELD',
          content: (
            <Text>
              Funds that invest in bonds issued by companies or governments that
              are rated “high-yield”, which is determined by one or more credit
              rating agencies. High-yield bonds were historically also referred
              to as “junk bonds”. This rating suggests that there is more risk
              of the company or government not being able or willing, to pay
              back the bond than for investment-grade rated bonds. To compensate
              the investor for this extra risk, these bonds tend to have a
              higher yield than investment-grade bonds.
            </Text>
          ),
        },
        {
          title: 'Flexible',
          listPageTitle: 'Flexible bond funds',
          // cspell:disable-next-line
          code: 'FLEXBOND',
          content: (
            <Text>
              Funds that may be able to invest across corporate and government
              bonds and/or across high-yield and investment-grade bonds. The
              specific bonds picked for investment instead depend on where the
              fund manager(s) is finding attractive investment opportunities at
              any given point in time.
            </Text>
          ),
        },
      ],
    },
    {
      title: 'Theme',
      code: 'SPECIALIST',
      data: [
        {
          title: 'Technology',
          code: 'TECH',
          content: (
            <Text>
              Funds that invest in stocks of technology companies, potentially
              all over the world. These are specialist funds focused only on the
              technology sector, or technology as a theme.
            </Text>
          ),
        },
        {
          title: 'Healthcare',
          code: 'HEALTH',
          content: (
            <Text>
              Funds that invest in stocks of healthcare companies, potentially
              all over the world. These are specialist funds focused only on the
              healthcare sector and sometimes even specific categories of that
              sector, such as biotech.
            </Text>
          ),
        },
        {
          title: 'Infrastructure',
          // cspell:disable-next-line
          code: 'INFRA',
          content: (
            <Text>
              Funds that invest in infrastructure, either in a particular region
              or globally. These are specialist funds focused only on investing
              in infrastructure projects such as transportation, schools and
              other public buildings, energy distribution and waste management.
            </Text>
          ),
        },
        {
          title: 'Commodities',
          listPageTitle: 'Commodity funds',
          code: 'COMMODITY',
          content: (
            <Text>
              Funds that invest purely in commodities, such as gold and silver,
              or in the shares of companies linked to natural resources.
            </Text>
          ),
        },
        {
          title: 'Environment',
          code: 'ENVIRONMENT',
          content: (
            <Text>
              Funds that invest in assets that benefit the environment, either
              in a particular region or globally.
            </Text>
          ),
        },
        {
          title: 'Other',
          listPageTitle: 'Miscellaneous funds',
          code: 'OTHER',
          content: (
            <Text>
              Funds that invest in other sectors or specialist areas, either in
              a particular region or globally.
            </Text>
          ),
        },
      ],
    },
    {
      title: 'Sustainability',
      // cspell:disable-next-line
      code: 'SUST',
      data: [
        {
          title: 'Fossil fuels prohibited',
          listPageTitle: 'Funds not allowed to invest in fossil fuels',
          // cspell:disable-next-line
          code: 'NOFOSSIL',
          content: (
            <Text>
              Funds that invest with sustainability in mind. These funds are
              formally not allowed to invest in fossil fuels. Views on how to
              treat fossil fuel investments vary across funds and asset
              managers. Not all sustainable funds are banned from investing in
              fossil fuels, and not all non-sustainable funds invest in fossil
              fuels. These funds can be focused on any kind of asset class, but
              the common denominator is that they don’t invest in fossil fuels.
            </Text>
          ),
        },
        {
          title: 'Exclusions-based',
          listPageTitle: 'Exclusions-based sustainable funds',
          code: 'EXCLUDES',
          content: (
            <Text>
              Funds that invest with sustainability in mind. These funds
              explicitly exclude certain industries, determined by the mandate
              of the fund, from investment. These industries are excluded based
              on one or more of Environmental, Social, or Governance (ESG)
              related reasons. Sectors that are typically excluded include
              tobacco, weaponry, fossil fuels, etc. These funds can be focused
              on any kind of asset class, but the common denominator is that
              they exclude certain sectors of the market.
            </Text>
          ),
        },
        {
          title: 'Positive change',
          // cspell:disable-next-line
          code: 'POSCHANGE',
          content: (
            <Text>
              Funds that invest with sustainability in mind. These funds focus
              on investing in companies and assets that aim to make a positive
              impact on society and/or the environment. These funds can be
              focused on any kind of asset class, but the common denominator is
              that they focus on investing in assets with clear positive impact
              in the world.
            </Text>
          ),
        },
        {
          title: 'Measurable impact',
          code: 'IMPACT',
          content: (
            <Text>
              Funds that invest with sustainability in mind. These funds take
              “Positive change” one step further by producing regular
              qualitative and quantitative reports detailing how their
              investments have made a positive impact in the world. These funds
              can be focused on any kind of asset class, but the common
              denominator is that they focus on investing in assets with clear
              positive impact in the world, and they regularly measure that
              impact and share the results publicly.
            </Text>
          ),
        },
      ],
    },
    {
      title: 'Other',
      code: 'OTHER',
      data: [
        {
          title: 'Concentrated',
          // cspell:disable-next-line
          code: 'CONC',
          content: (
            <Text>
              Funds that invest in a concentrated way. At TILLIT, we categorise
              equity funds with a maximum of 45 holdings, and bond funds with a
              maximum of 100 holdings, as concentrated.
            </Text>
          ),
        },
        {
          title: 'Can invest in private companies/assets',
          listPageTitle: 'Funds that can invest in private companies or assets',
          code: 'PRIVATE',
          content: (
            <Text>
              Funds that have the ability to invest in private companies or
              other unlisted assets. The proportion of the fund invested in
              private assets can vary from 100% to single-digit percentages. For
              equity funds it’s common to have a limit on how much of the fund
              can be invested in private companies.
            </Text>
          ),
        },
        {
          title: 'Can invest in cryptocurrencies',
          listPageTitle: 'Funds that can invest in cryptocurrencies',
          code: 'CRYPTO',
          content: (
            <Text>
              Funds that have the ability to invest directly in
              cryptocurrencies.
            </Text>
          ),
        },
        {
          title: 'At least one female fund manager',
          listPageTitle: 'Funds with at least one female manager',
          // cspell:disable-next-line
          code: 'FEMALEMGR',
          content: (
            <Text>
              Funds that have at least one named female fund manager as a
              decision maker on the fund.
            </Text>
          ),
        },
        {
          title: 'Wildcards',
          listPageTitle: 'Wildcards',
          code: 'WILDCARD',
          content: (
            <>
              <Text>
                Wildcards is a collection of niche, esoteric funds with a
                higher-risk profile compared to many other funds on the
                platform.
              </Text>
              <Text>
                These funds may invest in different asset classes, but the
                common denominator is that they offer exposure to an area of the
                market or investment style that is a bit off the beaten track.
                These funds typically carry a higher level of risk and should be
                considered in the context of a diversified portfolio.
              </Text>
            </>
          ),
        },
      ],
    },
  ],
};
