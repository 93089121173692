import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles, Typography } from '@material-ui/core';
import { StepProps } from 'components/feature/openAccount/steps/_shared/StepProps';
import { FormState } from 'components/Form/FormState';
import { GaEventNames, OnboardingStepNames } from 'constants/gaConstants';
import { mask } from 'formatting';
import {
  useMifidReportingNationalityQuery,
  UserProfileQuery,
  useUpdateUserProfileMutation,
  useUserProfileQuery,
} from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { get } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import * as Yup from 'yup';
import {
  GoBackButton,
  SkipButton,
  StepActions,
  StepButton,
  StepContainer,
  StepContent,
  StepContentWidth,
  StepFormContainer,
  StepTitle,
} from '../../../../design-system/StepComponents/StepComponents';
import { ServerError } from '../_shared/ServerError';
import { NationalIdentifierCollection } from './NationalIdentifierCollection';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
}));

const nationalIdentifierFieldSchema = Yup.object()
  .shape({
    name: Yup.string(),
    value: Yup.string(),
  })
  .test('valid-identifier', '', (value, testContext) => {
    // don't validate undefined fields
    if (value.name === undefined && value.value === undefined) {
      return true;
    }

    const index = testContext.parent?.indexOf(value);

    const dirtyFields = testContext.options.context?.formState?.dirtyFields;

    if (!dirtyFields?.nationalIdentifiers?.[index]?.value) {
      return true;
    }

    const identifier = testContext.options.context?.identifiers?.[index];

    if (
      !identifier.regex?.every((regExp: string) =>
        new RegExp(regExp!)?.test(value.value!)
      )
    ) {
      return testContext.createError({
        path: testContext.path + '.value',
        message: `Please enter a valid value, e.g. ${identifier.example}`,
      });
    }
    return true;
  });

const nationalIdentifierSchema = Yup.object().shape({
  nationalIdentifiers: Yup.array()
    .default([])
    .of(nationalIdentifierFieldSchema)
    .ensure()
    .test(
      'identifier-provided',
      '',
      (val, testContext) =>
        !testContext.options.context?.identifiers?.length ||
        val!.some((i) => i.name)
    ),
});

type NationalIdentifierFormValues = Yup.Asserts<
  typeof nationalIdentifierSchema
>;

type source =
  | 'nationalIdentifierDialog'
  | 'checkoutStep'
  | 'openAccountStep'
  | 'quickOrderStep'
  | 'startTransferStep';

interface NationalIdentifierStepProps extends StepProps {
  hideBackButton?: boolean;
  canSkip?: boolean;
  introduction?: JSX.Element | null;
  source: source;
}

export function NationalIdentifierStep({
  onProceed,
  onGoBack,
  canSkip,
  hideBackButton = false,
  introduction,
  source,
}: NationalIdentifierStepProps) {
  const queryClient = useQueryClient();
  const classes = useStyles();
  const validationContext = useRef<any>({});
  const [nationalities, setNationalities] = useState<Array<string>>([]);

  const userProfile = queryClient.getQueryData<UserProfileQuery>(
    useUserProfileQuery.getKey()
  )?.userProfile!;

  useEffect(() => {
    const nationalities = [
      userProfile.nationality,
      ...(userProfile.otherNationalities || []),
    ] as Array<string>;
    setNationalities(nationalities);
  }, [userProfile.nationality, userProfile.otherNationalities]);

  const defaultValues = nationalIdentifierSchema.cast({});

  const {
    mutateAsync,
    isLoading: mutatingUserProfile,
    isError,
  } = useUpdateUserProfileMutation();

  const validationSchema = nationalIdentifierSchema;

  const methods = useForm<NationalIdentifierFormValues>({
    defaultValues,
    resolver: yupResolver(validationSchema),
    context: validationContext.current,
    shouldUnregister: false,
  });

  const { formState, handleSubmit, setValue } = methods;
  const { isDirty, dirtyFields } = formState;

  validationContext.current.formState = formState;

  const {
    data: reportingNationality,
    isFetching: fetchingMifidReportingNationality,
  } = useMifidReportingNationalityQuery(
    {
      nationalities: nationalities,
    },
    {
      enabled: !!nationalities.length,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  const identifiers =
    reportingNationality?.mifidReportingNationality?.identifiers;

  validationContext.current.identifiers = identifiers;

  useEffect(() => {
    if (identifiers) {
      const value: Array<any> = [];
      const position = identifiers.findIndex(
        (i) =>
          i.name === userProfile.nationalIdentifierTokenised?.name &&
          reportingNationality?.mifidReportingNationality?.code ===
            userProfile.nationalIdentifierTokenised?.nationality
      );

      if (position >= 0) {
        for (let i = 0; i < position; i++) {
          value.push({
            name: identifiers[i].name,
            value: '',
          });
        }

        value.push({
          name: userProfile.nationalIdentifierTokenised?.name!,
          value: userProfile.nationalIdentifierTokenised?.value?.maskedValue!,
        });

        setValue('nationalIdentifiers', value, { shouldDirty: false });
      } else if (identifiers.length) {
        value.push({
          name: identifiers[0].name,
          value: '',
        });

        setValue('nationalIdentifiers', value, { shouldDirty: true });
      }
    }

    if (typeof identifiers !== 'undefined' && !identifiers.length) {
      onProceed();
    }
  }, [
    identifiers,
    setValue,
    userProfile.nationalIdentifierTokenised,
    reportingNationality?.mifidReportingNationality,
    onProceed,
  ]);

  const onSubmit = async (data: NationalIdentifierFormValues) => {
    if (isDirty) {
      const lastIndex = data.nationalIdentifiers.length - 1;
      const nationalIdentifier = data.nationalIdentifiers[lastIndex];

      const identifierDirty = get(
        dirtyFields,
        `nationalIdentifiers[${lastIndex}].value`
      );

      if (identifierDirty) {
        try {
          await queryClient.cancelQueries({
            queryKey: useUserProfileQuery.getKey(),
          });

          await mutateAsync({
            input: {
              nationalIdentifier: {
                identifierNationality: reportingNationality
                  ?.mifidReportingNationality.code!,
                identifierName: nationalIdentifier.name!,
                identifierValue: nationalIdentifier.value || null,
              },
            },
          });

          queryClient.setQueryData<UserProfileQuery>(
            useUserProfileQuery.getKey(),
            (oldData) => {
              return {
                clientSummary: oldData?.clientSummary!,
                userProfile: {
                  ...oldData?.userProfile!,
                  nationalIdentifierTokenised: identifierDirty
                    ? {
                        nationality: reportingNationality
                          ?.mifidReportingNationality.code!,
                        name: nationalIdentifier.name!,
                        value: {
                          maskedValue: nationalIdentifier.value
                            ? mask(nationalIdentifier.value, 3)
                            : '',
                        },
                      }
                    : oldData?.userProfile?.nationalIdentifierTokenised,
                },
              };
            }
          );
          if (source === 'openAccountStep') {
            trackGa({
              event: GaEventNames.onboarding,
              onboardingStep: OnboardingStepNames.nationalIdentifier,
            });
          }
          onProceed();
        } catch {
          // error handled by state
        }
      }
    } else {
      if (source === 'openAccountStep') {
        trackGa({
          event: GaEventNames.onboarding,
          onboardingStep: OnboardingStepNames.nationalIdentifier,
        });
      }
      onProceed();
    }
  };

  const handleBack = () => {
    onGoBack();
  };

  const isLoading = fetchingMifidReportingNationality || mutatingUserProfile;

  return (
    <>
      <StepContainer>
        <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
          <FormProvider {...methods}>
            {identifiers && (
              <>
                <StepContent width={StepContentWidth.wide}>
                  <StepTitle>What’s your national identifier?</StepTitle>
                  {introduction}
                  <ServerError isVisible={isError} />

                  <StepFormContainer>
                    {identifiers &&
                      !identifiers.every((i) => i.name === 'CONCAT') && (
                        <div>
                          <Typography>
                            Nationality:{' '}
                            {
                              reportingNationality?.mifidReportingNationality
                                ?.adjective
                            }
                          </Typography>
                          <NationalIdentifierCollection
                            name="nationalIdentifiers"
                            reportingNationality={
                              reportingNationality?.mifidReportingNationality
                            }
                            disabled={isLoading}
                          />
                        </div>
                      )}
                  </StepFormContainer>
                </StepContent>

                <StepActions>
                  <FormState>
                    {({ isSubmitting }) => (
                      <>
                        <StepButton
                          type="submit"
                          className="magenta"
                          disabled={isSubmitting || isLoading}
                        >
                          Continue
                        </StepButton>

                        {!hideBackButton && (
                          <GoBackButton
                            onClick={handleBack}
                            disabled={isSubmitting}
                          />
                        )}
                        {canSkip && (
                          <SkipButton
                            onClick={onProceed}
                            variant="outlined"
                            className="richBlue"
                          >
                            Skip
                          </SkipButton>
                        )}
                      </>
                    )}
                  </FormState>
                </StepActions>
              </>
            )}
          </FormProvider>
        </form>
      </StepContainer>
    </>
  );
}
