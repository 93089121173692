import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React from 'react';
import * as format from 'formatting';
import { CashPayment } from 'generated/graphql';
import { PartialDeep } from 'type-fest';
import styled from 'styled-components';

interface CashPaymentsTableProps {
  data: Array<PartialDeep<CashPayment>>;
}

const NoHorizontalPaddingTable = styled(Table)`
  & td:first-child,
  & th:first-child {
    padding-left: 0;
  }
  & td:last-child,
  & th:last-child {
    padding-right: 0;
  }
`;

export function CashPaymentsTable({ data }: CashPaymentsTableProps) {
  return (
    <NoHorizontalPaddingTable size="small">
      <TableHead>
        <TableRow>
          <TableCell>Reference ID</TableCell>
          <TableCell>Created</TableCell>
          <TableCell align="right">Amount</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((d) => (
          <TableRow key={d?.id}>
            <TableCell>{d?.id}</TableCell>
            <TableCell>
              {new Date(d?.createdDateUtc).toLocaleString()}
            </TableCell>
            <TableCell align="right">
              {format.currencyFull(d?.amount!, true)}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </NoHorizontalPaddingTable>
  );
}
