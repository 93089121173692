import { Form } from 'components/Form/Form';
import { FormState } from 'components/Form/FormState';
import { TextField } from 'components/Form/TextField';
import { GaEventNames, OnboardingStepNames } from 'constants/gaConstants';
import { trackGa } from 'helpers/track';
import * as Yup from 'yup';
import {
  GoBackButton,
  StepActions,
  StepButton,
  StepContainer,
  StepContent,
  StepContentWidth,
  StepFormContainer,
  StepIntroduction,
  StepIntroductionTypography,
  StepIntroductionWidth,
  StepTitle,
} from '../../../../design-system/StepComponents/StepComponents';

const emailFormSchema = Yup.object().shape({
  email: Yup.string().label('Email').required().min(3).email(),
});

interface EmailStepProps {
  onProceed: (values: EmailFormValues) => void;
  onGoBack: () => void;
  values?: EmailFormValues;
}

export interface EmailFormValues extends Yup.Asserts<typeof emailFormSchema> {}

export function EmailAddressStep({
  onProceed,
  onGoBack,
  values,
}: EmailStepProps) {
  const validationSchema = emailFormSchema;

  const onSubmit = async (data: EmailFormValues) => {
    trackGa({
      event: GaEventNames.onboarding,
      onboardingStep: OnboardingStepNames.username,
    });
    onProceed(data);
  };

  const handleBack = () => {
    onGoBack();
  };

  return (
    <Form<EmailFormValues>
      onSubmit={onSubmit}
      schema={validationSchema}
      defaultValues={values ? values : undefined}
    >
      <StepContainer>
        <StepContent width={StepContentWidth.extraWide}>
          <StepTitle>Email address</StepTitle>

          <StepIntroduction mb={2} $width={StepIntroductionWidth.normal}>
            <StepIntroductionTypography>
              Please supply a valid email address so that we can contact you
              with key information about your account.
            </StepIntroductionTypography>
          </StepIntroduction>

          <StepFormContainer>
            <TextField name="email" label="Email" fullWidth autoFocus />
          </StepFormContainer>
        </StepContent>
        <StepActions>
          <FormState>
            {({ isSubmitting }) => (
              <>
                <StepButton type="submit" className="magenta">
                  Continue
                </StepButton>
                <GoBackButton onClick={handleBack} disabled={isSubmitting} />
              </>
            )}
          </FormState>
        </StepActions>
      </StepContainer>
    </Form>
  );
}
