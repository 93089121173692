import { useAuth } from 'context/AuthContext';
import { useUserProfileQuery } from 'generated/graphql';
import Cookies from 'js-cookie';
import { createContext, useContext, useEffect, useState } from 'react';

export const CUserYearOfBirth = 'userYearOfBirth';
export const CUserIntendedRetirementAge = 'userIntendedRetirementAge';

type TargetDateInfo = {
  yearOfBirth?: number;
  intendedRetirementAge?: number;
};

type SavedTargetDateInfo = {
  yearOfBirth?: number;
  intendedRetirementAge?: number;
};

type TargetDateInfoReturnType = {
  savedTargetDateInfo?: SavedTargetDateInfo;
  targetDateInfo: TargetDateInfo | undefined;
  setTargetDateInfo: (targetDateInfo: TargetDateInfo) => void;
};

const targetDateInfoReturnContext = createContext<TargetDateInfoReturnType>(
  undefined!
);

interface TargetDateInfoReturnContextProviderProps {
  children: React.ReactNode;
}

export function TargetDateInfoReturnContextProvider({
  children,
}: TargetDateInfoReturnContextProviderProps) {
  const value = useTargetDateInfoContext();
  return (
    <targetDateInfoReturnContext.Provider value={value}>
      {children}
    </targetDateInfoReturnContext.Provider>
  );
}

export function useTargetDateInfo() {
  const { savedTargetDateInfo, targetDateInfo, setTargetDateInfo } = useContext(
    targetDateInfoReturnContext
  );

  return { savedTargetDateInfo, targetDateInfo, setTargetDateInfo };
}

/**
 * Custom hook which will read from, and store data to localStorage under the
 * `userTargetDateInfo` key.
 *
 * usage:
 * ```
 * import {useTargetDateInfo} from ...
 * const {targetDateInfo, setTargetDateInfo} = useTargetDateInfo();
 * ```
 */
const useTargetDateInfoContext = (): TargetDateInfoReturnType => {
  const { signedIn } = useAuth();
  const { data, isFetched } = useUserProfileQuery(undefined, {
    enabled: signedIn,
  });

  const dob = data?.userProfile?.dateOfBirth || undefined;
  const dobYear = new Date(dob).getFullYear();
  const retirementAge =
    data?.userProfile?.pensionDetails?.retirementAge || undefined;

  const yearOfBirthCookieValue =
    Number(Cookies.get(CUserYearOfBirth)) || undefined;
  const userIntendedRetirementAgeCookieValue =
    Number(Cookies.get(CUserIntendedRetirementAge)) || undefined;

  const [yearOfBirth, setYearOfBirth] = useState(
    yearOfBirthCookieValue || (dob ? dobYear : undefined)
  );
  const [intendedRetirementAge, setIntendedRetirementAge] = useState(
    userIntendedRetirementAgeCookieValue || retirementAge
  );

  const setTargetDateInfo = ({
    yearOfBirth,
    intendedRetirementAge,
  }: TargetDateInfo) => {
    if (yearOfBirth) {
      setYearOfBirth(yearOfBirth);
      Cookies.set(CUserYearOfBirth, yearOfBirth.toString());
    }

    if (intendedRetirementAge) {
      setIntendedRetirementAge(intendedRetirementAge);
      Cookies.set(CUserIntendedRetirementAge, intendedRetirementAge.toString());
    }
  };

  useEffect(() => {
    if (yearOfBirthCookieValue) {
      setYearOfBirth(yearOfBirthCookieValue);
    } else if (dob) {
      setYearOfBirth(dobYear);
    }
  }, [dob, dobYear, yearOfBirthCookieValue]);

  useEffect(() => {
    if (userIntendedRetirementAgeCookieValue) {
      setIntendedRetirementAge(userIntendedRetirementAgeCookieValue);
    } else if (retirementAge) {
      setIntendedRetirementAge(retirementAge);
    }
  }, [retirementAge, userIntendedRetirementAgeCookieValue]);

  const isDataAvailable = !signedIn || isFetched;

  return {
    savedTargetDateInfo: {
      yearOfBirth: dobYear,
      intendedRetirementAge: retirementAge,
    },
    targetDateInfo: isDataAvailable
      ? { yearOfBirth, intendedRetirementAge }
      : undefined,
    setTargetDateInfo,
  };
};
