import { Box, Typography, useMediaQuery } from '@material-ui/core';
import { QueryState } from 'components/QueryState';
import { colors } from 'constants/colors';
import * as d3 from 'd3';
import { currencyFull, date, percent } from 'formatting';
import {
  OrderDirection,
  PerformanceComparisonPeriod,
  PerformanceSeriesKind,
  useOrdersByPositionQuery,
  usePositionPerformanceQuery,
} from 'generated/graphql';
import _ from 'lodash';
import numeral from 'numeral';
import { useMemo } from 'react';
import { BookCost } from 'strings/tooltips';
import { useTheme } from 'styled-components';
import {
  AreaSeries,
  BaseGraph,
  ExtrasRenderFunction,
  LineSeries,
} from './BaseGraph/BaseGraph';
import { Disclaimer, TooltipContent } from './BaseGraph/BaseGraph.styles';
import { TooltipHandle } from './BaseGraph/Tooltip';

interface PositionPerformanceGraphProps {
  accountId: string;
  isin: string;
  period: PerformanceComparisonPeriod;
  kind: PerformanceSeriesKind;
}

export function PositionPerformanceGraph({
  accountId,
  isin,
  period,
  kind,
}: PositionPerformanceGraphProps) {
  const muiTheme = useTheme();
  const atLeastSm = useMediaQuery(muiTheme.breakpoints.up('sm'));
  const atLeastLaptop = useMediaQuery(muiTheme.breakpoints.up('md'));

  const performanceQuery = usePositionPerformanceQuery(
    {
      isin,
      accountId,
      period,
    },
    {
      enabled: !!isin,
    }
  );

  const ordersQuery = useOrdersByPositionQuery(
    {
      isin,
      accountId,
    },
    {
      enabled: !!isin,
    }
  );

  const ordersByDay = useMemo(() => {
    if (ordersQuery.data?.ordersByPosition) {
      return _.groupBy(
        ordersQuery.data?.ordersByPosition.filter((o) => o.settlementDate),
        (o) => new Date(o.settlementDate).setHours(0, 0, 0, 0).toString()
      );
    }
    return {};
  }, [ordersQuery.data?.ordersByPosition]);

  const series = useMemo(
    () => _.keyBy(performanceQuery.data?.positionPerformance?.series, 'kind'),
    [performanceQuery.data?.positionPerformance?.series]
  );

  const bookCost = series[PerformanceSeriesKind.BookCost];

  const areaData: AreaSeries =
    bookCost && kind === PerformanceSeriesKind.ClosingValue
      ? {
          ...bookCost,
          curveFactory: d3.curveStepAfter,
          description: BookCost,
          fill: muiTheme.darkUniverse
            ? colors['grey-500']
            : colors['purple-50'],
          legendEntry: (
            <div
              style={{
                width: '100%',
                height: '100%',
                backgroundColor: muiTheme.darkUniverse
                  ? colors['grey-500']
                  : colors['purple-50'],
              }}
            />
          ),
        }
      : series[kind];

  const lines: Array<LineSeries> = useMemo(
    () =>
      kind === PerformanceSeriesKind.ClosingValue && bookCost
        ? [
            {
              ...series[kind],
              stroke: colors.richBlue,
              strokeWidth: '2px',
            },
          ]
        : [],
    [bookCost, kind, series]
  );

  const labels = useMemo(
    () =>
      performanceQuery.data?.positionPerformance?.labels?.map((label) => {
        const dateParts = label!.split('/').map((p) => parseInt(p));
        return new Date(dateParts[2], dateParts[1] - 1, dateParts[0]).getTime();
      }) ?? [],
    [performanceQuery.data?.positionPerformance?.labels]
  );

  const renderTooltip = (tooltip: TooltipHandle, index: number) => {
    const orders = ordersByDay[labels[index].toString()];

    const buyAmount = orders
      ?.filter((o) => o.direction === OrderDirection.Buy)
      ?.reduce((result, o) => result + o.amount!, 0);
    const sellAmount = orders
      ?.filter((o) => o.direction === OrderDirection.Sell)
      ?.reduce((result, o) => result + o.amount!, 0);

    tooltip.setStyles({
      backgroundColor: muiTheme.darkUniverse
        ? colors.richBlack
        : series[PerformanceSeriesKind.GrowthProportion].data[index]! < 0
        ? colors.seaBlue
        : colors.purple,
    });

    return (
      <TooltipContent>
        <Typography>{date(new Date(labels[index]))}</Typography>
        <dl>
          <dt>Growth:</dt>
          <dd>
            {series[PerformanceSeriesKind.GrowthProportion].data[index] !== null
              ? percent(
                  series[PerformanceSeriesKind.GrowthProportion].data[index]!
                )
              : '-'}
          </dd>
          <dt>Value:</dt>
          <dd>
            {series[PerformanceSeriesKind.ClosingValue].data[index] !== null
              ? currencyFull(
                  series[PerformanceSeriesKind.ClosingValue].data[index]!
                )
              : '-'}
          </dd>
          {bookCost ? (
            <>
              <dt>Book cost:</dt>
              <dd>
                {bookCost.data[index] !== null
                  ? currencyFull(bookCost.data[index]!)
                  : '-'}
              </dd>
            </>
          ) : null}
        </dl>
        {orders?.length ? (
          <>
            <Typography>Orders</Typography>
            <dl>
              {buyAmount ? (
                <>
                  <dt>Bought:</dt>
                  <dd>{currencyFull(buyAmount)}</dd>
                </>
              ) : null}
              {sellAmount ? (
                <>
                  <dt>Sold:</dt>
                  <dd>{currencyFull(sellAmount)}</dd>
                </>
              ) : null}
            </dl>
          </>
        ) : null}
      </TooltipContent>
    );
  };

  const tickFormat = (value: number, numDecimals: number) =>
    kind === PerformanceSeriesKind.GrowthProportion
      ? numeral(value).format(
          `0,0.${'0'.repeat(Math.max(numDecimals - 2, 0))}%`
        )
      : numeral(value).format('$0,0' + (numDecimals > 0 ? '.00' : ''));

  const getHeight = () => {
    if (atLeastLaptop) {
      return 310;
    }

    if (atLeastSm) {
      return 330;
    }

    return 300;
  };

  const asOfValuationDate = performanceQuery.data?.positionPerformance?.labels
    ?.length
    ? performanceQuery.data.positionPerformance.labels[
        performanceQuery.data.positionPerformance.labels.length - 1
      ]
    : null;

  const renderOrders: ExtrasRenderFunction = (x, y) => {
    return ordersQuery.data?.ordersByPosition
      ?.filter((o) => new Date(o.settlementDate).getTime() >= labels[0])
      ?.map((o) => {
        const settlementDate = new Date(o.settlementDate).setHours(0, 0, 0, 0);
        const index = d3.bisectCenter(labels, settlementDate);

        return (
          <circle
            key={o.id!}
            fill={colors.magenta}
            fillOpacity={0.3}
            cx={x(labels[index]!)}
            cy={y(areaData.data[index]!)}
            r="5"
          />
        );
      });
  };

  return (
    <Box position="relative" minHeight={getHeight()}>
      <QueryState
        {...performanceQuery}
        isError={
          (performanceQuery.isError ||
            !Object.keys(series).length ||
            !labels.length) as any
        }
        loadingAbsolute
      >
        {() => (
          <>
            <BaseGraph
              labels={labels}
              area={areaData}
              lines={lines}
              tickFormat={tickFormat}
              tooltipReference={series[kind]}
              renderTooltipContent={renderTooltip}
              graphSettings={{
                height: getHeight(),
              }}
              renderExtras={renderOrders}
              tolerance={
                kind === PerformanceSeriesKind.GrowthProportion ? 0.01 : 1
              }
              legendAlignment={'center'}
              YAxisFontSize={'large'}
              YAxisMaxTicksHints={4}
              showMobileSpecificTooltip={bookCost && true}
              graphLocation="DashBoard"
              graphType="Holdings Performance"
            />
            <Disclaimer>
              Performance charts show data supplied by Seccl Custody.{' '}
              {asOfValuationDate
                ? `Chart valuations are as of market close on ${asOfValuationDate} and delays may cause them to differ from other valuations displayed on this page.`
                : 'Delays may cause chart valuations to differ from other valuations displayed on this page.'}
            </Disclaimer>
          </>
        )}
      </QueryState>
    </Box>
  );
}
