import {
  FontSize,
  FontWeight,
  Text,
  TextAlign,
  TextNormal,
  TextSmall,
  TextXS,
} from 'components/design-system/Text/Text';
import {
  MinusIcon,
  PlusIcon,
  ShoppingBasketIcon,
} from 'components/feature/PortfolioBuilder/_shared/DpcIcons';
import { IncludeAssetType } from 'components/feature/PortfolioBuilder/hooks/useGetBreakdowns/includeAssetHelpers';
import * as format from 'formatting';
import { WrapperType } from 'generated/graphql';
import { AssetBreakdownComparison } from '../../helpers/deriveAssetBreakdownComparison';
import {
  CombinedBreakdown,
  useCombinedFundBreakdown,
} from '../../hooks/useGetBreakdowns';
import {
  CardTableContainer,
  HoldingsTabInfo,
  HoldingsTabInner,
  IconWrapper,
} from '../_shared/DrillDownTab.styles';
import { NameTdInner, Table, Td, Th, Tr } from '../_shared/Table.styles';
import { EstimatedStatBoxes } from './_shared/EstimatedStatBoxes';

type includeAsset = IncludeAssetType;

interface HoldingsTableProps {
  combinedFundBreakdown: CombinedBreakdown['combinedBreakdown'];
}

function HoldingsTable({ combinedFundBreakdown }: HoldingsTableProps) {
  return (
    <Table>
      <thead>
        <tr>
          <Th>
            <TextSmall $noMargin $fontWeight={FontWeight.medium}>
              Name
            </TextSmall>
          </Th>
          <Th>
            <Text
              $fontSize={FontSize.small}
              $fontWeight={FontWeight.medium}
              $textAlign={TextAlign.right}
              $noMargin
            >
              Estimated position (%)
            </Text>
          </Th>
          <Th>
            <Text
              $fontSize={FontSize.small}
              $fontWeight={FontWeight.medium}
              $textAlign={TextAlign.right}
              $noMargin
            >
              Est. value (£)
            </Text>
          </Th>
          <Th>
            <Text
              $fontSize={FontSize.small}
              $fontWeight={FontWeight.medium}
              $textAlign={TextAlign.right}
              $noMargin
            >
              Change (£)
            </Text>
          </Th>
        </tr>
      </thead>
      <tbody>
        {combinedFundBreakdown.map((breakdown) => {
          return (
            <Tr
              status={
                breakdown.amount > breakdown.existingAmount
                  ? 'buying'
                  : breakdown.amount < breakdown.existingAmount
                  ? 'selling'
                  : 'neutral'
              }
            >
              <Td>
                <NameTdInner>
                  {breakdown.amount > breakdown.existingAmount && (
                    <IconWrapper>
                      <ShoppingBasketIcon $type="buy" />
                      <PlusIcon />
                    </IconWrapper>
                  )}
                  {breakdown.amount < breakdown.existingAmount && (
                    <IconWrapper>
                      <ShoppingBasketIcon $type="sell" />
                      <MinusIcon />
                    </IconWrapper>
                  )}
                  <TextXS $noMargin>{breakdown.name}</TextXS>
                </NameTdInner>
              </Td>
              <Td>
                <Text
                  $fontSize={FontSize.xs}
                  $fontWeight={FontWeight.light}
                  $textAlign={TextAlign.right}
                  $noMargin
                >
                  {format.percent(breakdown.percentage / 100)}
                </Text>
              </Td>
              <Td>
                <Text
                  $fontSize={FontSize.xs}
                  $fontWeight={FontWeight.light}
                  $textAlign={TextAlign.right}
                  $noMargin
                >
                  {format.currencyFull(breakdown.amount)}
                </Text>
              </Td>
              <Td>
                <Text
                  $fontSize={FontSize.xs}
                  $fontWeight={FontWeight.light}
                  $textAlign={TextAlign.right}
                  $noMargin
                >
                  {format.currencyFull(
                    breakdown.amount - breakdown.existingAmount
                  )}
                </Text>
              </Td>
            </Tr>
          );
        })}
      </tbody>
    </Table>
  );
}

interface HoldingsTabProps {
  selectedAccountType: WrapperType;
  includeAsset: includeAsset;
  activeBreakdownComparison: AssetBreakdownComparison;
  selectedAccountId: string;
}

export function HoldingsTab({
  selectedAccountId,
  selectedAccountType,
  includeAsset,
  activeBreakdownComparison,
}: HoldingsTabProps) {
  const { combinedBreakdown: combinedFundBreakdown } = useCombinedFundBreakdown(
    selectedAccountId,
    selectedAccountType,
    includeAsset
  );

  return (
    <div>
      <TextNormal>Holdings</TextNormal>
      <HoldingsTabInner>
        <CardTableContainer>
          <HoldingsTable combinedFundBreakdown={combinedFundBreakdown} />
        </CardTableContainer>
        <HoldingsTabInfo>
          <EstimatedStatBoxes
            activeBreakdownComparison={activeBreakdownComparison}
          />
        </HoldingsTabInfo>
      </HoldingsTabInner>
    </div>
  );
}
