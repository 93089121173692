import { TextNormal, TextSmall } from 'components/design-system/Text/Text';
import styled, { css } from 'styled-components';
import { TooltipLayout } from '../_shared/Breakdown.styles';

export const DonutBreakdownLayout = styled.div`
  position: relative;
  overflow: hidden;
  pointer-events: visible;
  margin: 0.5rem 1rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    flex-direction: row;
    overflow: visible;
  }
`;

export const DonutCenterTextLayout = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: center;

  p {
    font-size: 1rem;
    line-height: 1.5rem;
    b {
      font-size: 1.5rem;
    }
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    max-width: 250px;
    gap: 1.5rem;
    p {
      font-size: 2rem;
      line-height: 2rem;
      b {
        font-size: 2.5rem;
      }
    }
  }
`;

export const LegendContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 0.75rem 1rem;
  margin: 0 0.5rem;
  align-self: center;

  max-width: 100%;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    max-width: 400px;
    margin: 1rem;
  }
`;

export const LegendLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  gap: 0.75rem 1rem;
  max-width: 100%;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    max-width: 400px;
    gap: 1rem;
  }
`;

export const LegendItemLayout = styled.div`
  display: grid;
  gap: 0.75rem;
  align-items: flex-start;
  grid-template-columns: auto 1fr;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    gap: 1rem;
  }
`;

export const LegendItemSvgContainer = styled.div`
  margin: 0 -3px;

  width: 1.625rem;
  height: 1.625rem;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin: -2px -3px;
    width: 2.25rem;
    height: 2.25rem;
  }
`;

interface LegendCircleProps {
  $fillColor: string;
}
export const LegendCircle = styled.div<LegendCircleProps>`
  border-radius: 100%;
  width: 20px;
  height: 20px;
  background: ${({ $fillColor }) => $fillColor};
  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: 30px;
    height: 30px;
  }
`;

export const LegendCircleStripped = styled(LegendCircle)<LegendCircleProps>`
  background: ${({ $fillColor }) => `repeating-linear-gradient(
    -45deg,
    #ffffff 0px,
    #ffffff 2px,
    ${$fillColor} 3px,
    ${$fillColor} 10px,
    #ffffff 11px,
    #ffffff 15px
  );`};
`;

export const LegendTextLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-items: flex-start;
`;

interface LegendNameProps {
  $isHeading?: boolean;
}

export const LegendName = styled(TextSmall)<LegendNameProps>`
  width: 100%;
  margin: 0;
  font-weight: 400;
  display: flex;
  align-items: flex-start;
  justify-content: ${({ $isHeading }) =>
    $isHeading ? 'flex-start' : 'space-between'};
`;
export const LegendValue = styled(TextNormal)`
  margin: 0;
  font-weight: 500;
`;

export const DonutTooltipLayout = styled(TooltipLayout)`
  ${({ theme, $hasDrillDown, $isTall }) =>
    $hasDrillDown &&
    css`
      position: relative;
      width: 70vw;
      transform: translateX(-50%) translateY(-50%);
      padding: 1rem 0 0 0;
      overflow: hidden;
      pointer-events: auto;

      > p {
        flex-direction: column;
        font-size: 1.5rem;
        gap: 0.5rem;
        margin-bottom: 0.5rem;
      }

      ${theme.breakpoints.up('md')} {
        width: 360px;

        > p {
          font-size: 2rem;
          gap: 1rem;
          margin-bottom: 1rem;
        }
      }

      ${$isTall &&
      css`
        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1rem;
          background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 1) 25%,
            rgba(255, 255, 255, 0) 100%
          );
        }
      `}
    `}
`;

export const MapSvg = styled.div`
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
`;
