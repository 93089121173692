import { useMediaQuery } from '@material-ui/core';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import React, { useEffect, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { useSwipeable } from 'react-swipeable';
import useMeasure from 'react-use-measure';
import { useTheme } from 'styled-components';
import { Actions, Container, Front } from './SwipeToReveal.styles';

interface SwipeToRevealProps {
  breakpoint?: Breakpoint;
  actions: Array<React.ReactElement>;
  children: React.ReactNode;
  enabled?: boolean;
  className?: string;
}

export const SwipeToReveal = React.forwardRef(
  (
    {
      breakpoint = 'sm',
      children,
      actions,
      enabled = true,
      className,
    }: SwipeToRevealProps,
    ref: React.ForwardedRef<any>
  ) => {
    const theme = useTheme();
    const breakpointMatch = useMediaQuery(theme.breakpoints.up(breakpoint));
    const [open, setOpen] = useState(false);

    const handlers = useSwipeable({
      onSwipedLeft: () => setOpen(true),
      onSwipedRight: () => setOpen(false),
      trackMouse: false,
      trackTouch: enabled && !breakpointMatch,
      preventDefaultTouchmoveEvent: true,
    });

    const [contentRef, { height }] = useMeasure();

    const offset = (height + theme.spacing(1)) * actions.length;
    const { x } = useSpring({ x: open ? -offset : 0 });

    useEffect(() => {
      if (!enabled || breakpointMatch) {
        setOpen(false);
      }
    }, [breakpointMatch, enabled]);

    return (
      <Container ref={ref}>
        <Actions>
          {actions.map((action, i) => (
            <animated.div
              key={i}
              style={{
                x: x.to(
                  [-offset, -offset * 0.5, 0],
                  [0, (offset * 1) / (i + 2), offset - theme.spacing(1) + 1]
                ),
                width: height,
              }}
            >
              {action}
            </animated.div>
          ))}
        </Actions>
        <Front {...handlers} style={{ x }}>
          <div className={className} ref={contentRef}>
            {children}
          </div>
        </Front>
      </Container>
    );
  }
);
