import { colors } from 'constants/colors';
import styled from 'styled-components';

export const Badge = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 0.65rem;
  position: absolute;
  top: -0.25rem;
  right: -0.25rem;
  width: 1rem;
  height: 1rem;
  color: ${colors.white};
  background-color: ${colors.magenta};
  border-radius: 0.5rem;
  pointer-events: none;
  ${({ theme }) => theme.breakpoints.up('md')} {
    top: -0.5rem;
    right: -0.75rem;
  }
`;
