import { yupResolver } from '@hookform/resolvers/yup';
import { TextField } from 'components/Form/TextField';
import { QueryState } from 'components/QueryState';
import { Alert, Severity } from 'components/design-system/Alert/Alert';
import { H4 } from 'components/design-system/Heading/Heading';
import { StyledLink } from 'components/design-system/Link';
import {
  StepButton,
  StepFormContainer,
} from 'components/design-system/StepComponents/StepComponents';
import { Text, TextSmall } from 'components/design-system/Text/Text';
import {
  ButtonContainer,
  Pill,
  PillContainer,
  Section,
} from 'components/feature/PortfolioBuilder/AddToBasket/AddToBasketDialog.style';
import {
  MIN_CONTRIBUTION_AMOUNT,
  MIN_CONTRIBUTION_AMOUNT_SIPP,
} from 'components/feature/autoSaveInvest/autoSaveInvestConstant';
import { currencyFull } from 'formatting';
import {
  WrapperType,
  useDepositDetailsByAccountQuery,
} from 'generated/graphql';
import {
  getPathSegmentForWrapperType,
  getShortNameForWrapperType,
} from 'helpers/accountHelpers';
import { amount } from 'helpers/yupExtensions';
import max from 'lodash/max';
import { generateAutoSaveInvestSubPath } from 'paths';
import { FormProvider, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import {
  AccountsQueryAccount,
  RecurringTransactionsQueryRecurringTransactionsDeposit,
  UpdateAutoSaveInvestDraftStateMutationVariablesInputStateDeposit,
  UserProfileQueryClientSummaryAccount,
} from 'types/graphqlTypes';
import * as Yup from 'yup';
import Reference from 'yup/lib/Reference';
import { useAutoSaveInvestState } from '../../AutoSaveInvestContext';

interface RegularDepositEditDialogFormValues {
  dayOfTheMonth: number;
  amount: number;
  autoInvest: boolean;
}

const depositSchema = Yup.object().shape({
  dayOfTheMonth: Yup.number()
    .label('Day of the month')
    .typeError('Please enter a valid number')
    .nullable()
    .required()
    .min(1)
    .max(28),
  amount: amount()
    .label('Amount')
    .typeError('Please enter a valid number')
    .nullable()
    .required()
    .min(Yup.ref('$minAmount') as Reference<number>)
    .max(Yup.ref('$maxAmount') as Reference<number>),
});

interface RegularDepositEditFormProps {
  deposit:
    | RecurringTransactionsQueryRecurringTransactionsDeposit
    | UpdateAutoSaveInvestDraftStateMutationVariablesInputStateDeposit;
  index: number;
  account: AccountsQueryAccount | UserProfileQueryClientSummaryAccount;
  onComplete: () => void;
  variant?: 'dashboard' | 'autoSaveInvest';
  showNoDepositsMessage?: boolean;
}

export function RegularDepositEditForm({
  deposit,
  index,
  account,
  showNoDepositsMessage,
  onComplete,
}: RegularDepositEditFormProps) {
  const {
    state,
    setState,
    updateAutoSaveInvestDraftState,
    autoSaveInvestDraftStateQuery,
  } = useAutoSaveInvestState(account.id);

  const depositDetailsByAccountQuery = useDepositDetailsByAccountQuery(
    {
      id: account.id as string,
    },
    { enabled: typeof account.id === 'string' }
  );
  const depositDetailsByAccount =
    depositDetailsByAccountQuery.data?.depositDetailsByAccount;
  const validRange = depositDetailsByAccount?.validRange;
  const minAmount = max([
    validRange?.minimumPaymentAmount,
    account.wrapperType === WrapperType.Sipp
      ? MIN_CONTRIBUTION_AMOUNT_SIPP
      : MIN_CONTRIBUTION_AMOUNT,
  ]);

  const methods = useForm<RegularDepositEditDialogFormValues>({
    resolver: yupResolver(depositSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    context: {
      minAmount,
      maxAmount: validRange?.maximumPaymentAmount,
    },
  });

  const onSubmit = async (formData: RegularDepositEditDialogFormValues) => {
    const existingDeposit = state?.deposits[0];
    try {
      await setState({
        ...(state ?? { orders: [] }),
        deposits: [
          {
            ...existingDeposit,
            isEdited: true,
            paymentDate: formData.dayOfTheMonth,
            amount: formData.amount,
          },
        ],
      });
      onComplete();
    } catch (e) {
      //error handled by state
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <QueryState {...depositDetailsByAccountQuery}>
          {() => {
            if (!minAmount) {
              return;
            }
            return (
              <div>
                <PillContainer>
                  <Pill $filled $color="standingOrder">
                    Regular deposit
                  </Pill>
                  <Pill>{getShortNameForWrapperType(account.wrapperType)}</Pill>
                </PillContainer>
                <H4>Edit regular deposit</H4>
                {showNoDepositsMessage && (
                  <>
                    <TextSmall>
                      Your regular deposit isn't set up for auto investment.
                    </TextSmall>
                    <TextSmall>
                      If you want to set up auto investment, you need to turn on
                      auto invest for this deposit or{' '}
                      <StyledLink
                        as={Link}
                        to={generateAutoSaveInvestSubPath({
                          wrapperType: getPathSegmentForWrapperType(
                            account.wrapperType
                          ),
                          action: 'create-deposit',
                        })}
                      >
                        setup a new regular deposit
                      </StyledLink>
                      .
                    </TextSmall>
                  </>
                )}
                <Section>
                  <StepFormContainer>
                    <TextField
                      name="dayOfTheMonth"
                      label="Day of the month"
                      defaultValue={deposit.paymentDate}
                      helpText={`Please note the day of the month must be between the 1st and the 28th of every month.`}
                    />
                    <TextField
                      name="amount"
                      label="Amount"
                      defaultValue={deposit.amount}
                      startAdornment={<Text $noMargin>£</Text>}
                      inputProps={{
                        inputMode: 'decimal',
                      }}
                      helpText={`Minimum deposit: ${currencyFull(minAmount)}`}
                    />
                  </StepFormContainer>
                </Section>
                {updateAutoSaveInvestDraftState.error && (
                  <div>
                    <Alert severity={Severity.error}>
                      <Text $noMargin>Something went wrong</Text>
                    </Alert>
                  </div>
                )}
                <ButtonContainer>
                  <StepButton
                    type="submit"
                    className="magenta"
                    disabled={
                      updateAutoSaveInvestDraftState.isLoading ||
                      autoSaveInvestDraftStateQuery.isFetching
                    }
                  >
                    Continue
                  </StepButton>
                </ButtonContainer>
              </div>
            );
          }}
        </QueryState>
      </form>
    </FormProvider>
  );
}
