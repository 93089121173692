import { useAuth } from 'context/AuthContext';
import {
  AccountStatus,
  WrapperType,
  useUserProfileQuery,
} from 'generated/graphql';

function useAccountStatus(accountType: WrapperType) {
  const { signedIn } = useAuth();
  const userProfileQuery = useUserProfileQuery(undefined, {
    enabled: signedIn,
  });

  if (!signedIn) return false;

  return userProfileQuery.data?.clientSummary?.accounts?.find(
    (acc) => acc.wrapperType === accountType
  )?.status;
}

function useHasAccountWithStatus(status: AccountStatus) {
  const { signedIn } = useAuth();
  const userProfileQuery = useUserProfileQuery(undefined, {
    enabled: signedIn,
  });

  if (!signedIn) return false;

  return userProfileQuery.data?.clientSummary?.accounts?.some(
    (account) => account.status === status
  );
}

/**
 * Checks if passed account type is closing.
 * @param {WrapperType} accountType - Account type to be checked
 * @return {boolean}
 */
export function useIsAccountClosing(accountType: WrapperType) {
  const status = useAccountStatus(accountType);
  return status === AccountStatus.Closing;
}

/**
 * Checks if passed account type is closed.
 * @param {WrapperType} accountType - Account type to be checked
 * @return {boolean}
 */
export function useIsAccountClosed(accountType: WrapperType) {
  const status = useAccountStatus(accountType);
  return status === AccountStatus.Closed;
}

/**
 * Checks if user has any account that is active
 * @return {boolean}
 */
export function useHasActiveAccount() {
  return useHasAccountWithStatus(AccountStatus.Active);
}
