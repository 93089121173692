import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import { GaEventNames } from 'constants/gaConstants';
import { trackGa } from 'helpers/track';
import { useAssetsFilter } from 'hooks/useAssetsFilter';
import { useMemo, useState } from 'react';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { useTheme } from 'styled-components';
import {
  AccordionContainer,
  ContentCopy,
  DetailsContainer,
  FilterToggle,
  Heading,
  Icon,
  SubTitle,
  SummaryContainer,
  Title,
} from './Style/GlossaryAccordion.style';

interface dataAccordion {
  title: string;
  name: string;
  code: string;
  content: JSX.Element;
}

interface IGlossaryAccordion {
  title: string;
  data: dataAccordion[];
  expanded: boolean;
  onChange: (isExpanded: boolean) => void;
  onChangeFilter: (value: string[]) => void;
}

export function GlossaryAccordion({
  title,
  data,
  expanded,
  onChange,
  onChangeFilter,
}: IGlossaryAccordion) {
  return (
    <AccordionContainer
      expanded={expanded}
      onChange={(_, expanded) => onChange(expanded)}
    >
      <SummaryContainer
        expandIcon={<Icon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Title>{title}</Title>
      </SummaryContainer>
      <DetailsContainer>
        {data.map((item) => (
          <GlossaryAccordionContent
            key={item.title}
            title={item.title}
            name={item.name}
            code={item.code}
            content={item.content}
            groupTitle={title}
            onChange={onChangeFilter}
          />
        ))}
      </DetailsContainer>
    </AccordionContainer>
  );
}

interface IAccordionContent {
  title: string;
  name: string;
  code: string;
  groupTitle: string;
  content: JSX.Element;
  onChange: (value: string[]) => void;
}

function GlossaryAccordionContent({
  title,
  name,
  code,
  content,
  groupTitle,
  onChange,
}: IAccordionContent) {
  const muiTheme = useTheme();
  const { variables } = useAssetsFilter();
  const [hovered, setHovered] = useState(false);
  const matches = useMediaQuery(muiTheme.breakpoints.up('md'));
  const open = clsx((hovered || matches) && 'open');
  const isSelected = useMemo(() => {
    if (variables.tags?.length && variables.tags.includes(code)) return true;
    return false;
  }, [variables, code]);

  const trackFilters = (direction: string, tags: Array<string>) => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: `fund filter - ${direction}`,
      item_id: title,
      filter_group: groupTitle,
    });
  };

  const handleClick = () => {
    const tags = variables.tags || [];

    if (isSelected) {
      const newTags = tags?.filter((t) => t !== code) || [];
      trackFilters('Removed', newTags);
      onChange(newTags);
    } else {
      const newTags = [...tags, code];
      trackFilters('Added', newTags);
      onChange(newTags);
    }
  };

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const selector = () => {
    if (isSelected) {
      return (
        <FilterToggle className={clsx(open, 'remove')}>
          <AiOutlineMinus />
          <label>Remove filter</label>
        </FilterToggle>
      );
    } else {
      return (
        <FilterToggle className={clsx(open, 'add')}>
          <AiOutlinePlus />
          <label>Add filter</label>
        </FilterToggle>
      );
    }
  };

  return (
    <ContentCopy>
      <Heading
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      >
        <SubTitle>{title}</SubTitle>
        {selector()}
      </Heading>
      {content}
    </ContentCopy>
  );
}
