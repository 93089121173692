import {
  ResponsiveDialog,
  ResponsiveDialogProps,
} from 'components/Dialogs/ResponsiveDialog';
import { QueryState } from 'components/QueryState';
import { StyledLink } from 'components/design-system/Link';
import {
  StepIntroduction,
  StepIntroductionTypography,
  StepIntroductionWidth,
} from 'components/design-system/StepComponents/StepComponents';
import { useMode } from 'components/feature/mode/useMode';
import { NationalInsuranceNumberStep } from 'components/feature/openAccount/steps/NationalInsuranceNumberStep';
import { useAuth } from 'context/AuthContext';
import {
  AccountAssetBuyStatus,
  Position,
  WrapperType,
  useAccountQuery,
  useAccountsQuery,
  useAssetQuery,
  usePortfolioRebalancingQuery,
} from 'generated/graphql';
import { matchesHeldAsset } from 'helpers/matchesHeldAsset';
import { useToggle } from 'hooks/useFeatureToggle';
import { useIsNinoRequired } from 'hooks/useIsNinoRequired';
import { useMatchesAllocatedVintage } from 'hooks/useMatchesAllocatedVintage';
import { mifidToInvest } from 'paths';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { PartialDeep } from 'type-fest';
import {
  AccountsQueryAccount,
  AccountsQueryAccountPosition,
  AssetQueryAsset,
} from 'types/graphqlTypes';
import {
  AddBuyOrderToBasketStep,
  OnProceedAction,
} from './steps/AddBuyOrderToBasketStep';
import { MifidStep } from './steps/MifidStep';
import { PreviewStep } from './steps/PreviewStep';
import { QuickOrderConfirm } from './steps/QuickOrderConfirm';
import { QuickOrderReceived } from './steps/QuickOrderReceived';
import { SelectAccountStepBuy } from './steps/SelectAccountStepBuy';
import { VerifyInstrumentSelectionStepBuy } from './steps/VerifyInstrumentSelection';
import { VerifyVintageSelectionStepBuy } from './steps/VerifyVintageSelection';

interface AddBuyOrderToBasketFullFlowDialogProps extends ResponsiveDialogProps {
  openDialog: () => void;
  closeDialog: () => void;
  open: boolean;
  assetId: string;
  selectedAccountType?: WrapperType;
  selectedAccountId?: string;
  selectedPosition?: PartialDeep<Position>;
  selectedIsin?: string;
  handleInstrumentChange?: (isin: string) => void;
}

interface AddBuyOrderToBasketFullFlowFormProps {
  closeDialog: () => void;
  asset: AssetQueryAsset;
  selectedAccountType?: WrapperType;
  selectedAccountId?: string;
  accounts: AccountsQueryAccount[];
  selectedPosition?: PartialDeep<Position>;
  selectedIsin?: string;
  handleInstrumentChange?: (isin: string) => void;
  amountStepGoBack?: () => void;
}

interface SelectedAccount {
  selectedAccountType: WrapperType;
  selectedAccountId: string;
  selectedPosition?: PartialDeep<Position>;
}

type steps =
  | 'selectAccount'
  | 'verifyVintageSelection'
  | 'verifyPositionSelection'
  | 'selectAmount'
  | 'postSelectAmount'
  | 'ninoRequired'
  | 'mifidRequired'
  | 'quickOrderConfirm'
  | 'quickOrderReceived'
  | 'preview';

export function AddBuyOrderToBasketFullFlowForm({
  closeDialog,
  asset,
  selectedAccountType,
  selectedAccountId,
  accounts,
  selectedPosition,
  selectedIsin,
  handleInstrumentChange,
  amountStepGoBack,
}: AddBuyOrderToBasketFullFlowFormProps) {
  const [verifyVintageStepToggle] = useToggle('global-verify-vintage-step');
  const [verifyInstrumentStepToggle] = useToggle(
    'global-verify-instrument-step'
  );
  const [activeStep, setActiveStep] = useState<steps | null>(null);
  const [activeAccount, setActiveAccount] = useState<SelectedAccount | null>(
    null
  );
  const [quickOrderId, setQuickOrderId] = useState<string | undefined>();
  const [mode] = useMode();

  const {
    matches,
    targetDateInstrument,
    selectedInstrument,
  } = useMatchesAllocatedVintage({
    isin: selectedIsin,
    asset,
  });

  const positions = accounts.find((account) => account.id === selectedAccountId)
    ?.positions as AccountsQueryAccountPosition[];

  const { userHoldsPosition, heldPositionsByAsset } = matchesHeldAsset({
    isin: selectedIsin,
    asset,
    positions: positions,
  });

  useEffect(() => {
    if (activeStep !== null || !asset) {
      return;
    }

    if (
      asset.isTargetDateFund &&
      selectedAccountType &&
      selectedAccountId &&
      !matches &&
      verifyVintageStepToggle?.enabled
    ) {
      setActiveStep('verifyVintageSelection');
      setActiveAccount({
        selectedAccountType,
        selectedAccountId,
      });
      return;
    }

    if (
      selectedAccountType &&
      selectedAccountId &&
      !userHoldsPosition &&
      !asset.isTargetDateFund &&
      heldPositionsByAsset.length > 0 &&
      verifyInstrumentStepToggle
    ) {
      setActiveStep('verifyPositionSelection');
      setActiveAccount({
        selectedAccountType,
        selectedAccountId,
      });
      return;
    }

    if (selectedAccountType && selectedAccountId) {
      setActiveStep('selectAmount');
      setActiveAccount({
        selectedAccountType,
        selectedAccountId,
      });
      return;
    }

    if (accounts && accounts?.length === 1 && accounts[0].id) {
      setActiveStep('selectAmount');
      setActiveAccount({
        selectedAccountType: accounts[0].wrapperType,
        selectedAccountId: accounts[0].id,
        selectedPosition: accounts[0].positions?.[0],
      });
      return;
    }

    if (mode !== null && mode.mode !== 'resume' && mode.wrapperType) {
      setActiveStep('selectAmount');
      const account = accounts!.find(
        ({ wrapperType }) => wrapperType === mode.wrapperType
      );
      setActiveAccount({
        selectedAccountType: mode.wrapperType,
        selectedAccountId: account?.id!,
      });
      return;
    }

    if (accounts && accounts?.length > 1) {
      setActiveStep('selectAccount');
    }
  }, [
    accounts,
    activeStep,
    selectedAccountType,
    selectedAccountId,
    asset,
    mode,
    matches,
    verifyVintageStepToggle?.enabled,
    userHoldsPosition,
    heldPositionsByAsset.length,
    verifyInstrumentStepToggle,
    selectedIsin,
  ]);

  useEffect(() => {}, [quickOrderId]);

  const isNinoRequired = useIsNinoRequired();

  const portfolioRebalancingQuery = usePortfolioRebalancingQuery(
    {
      id: quickOrderId as string, // if the query is enabled this will be a string.
    },
    {
      onSuccess: (data) => {
        if (activeStep === 'postSelectAmount') {
          if (
            data.portfolioRebalancing?.buyOrders[0].context?.status ===
            AccountAssetBuyStatus.RequiresMifidIdentifier
          ) {
            if (isNinoRequired) {
              setActiveStep('ninoRequired');
            } else {
              setActiveStep('mifidRequired');
            }
          } else {
            setActiveStep('quickOrderConfirm');
          }
        }
      },
      enabled: !!quickOrderId,
    }
  );
  const quickBuyOrderRequiresMifid =
    portfolioRebalancingQuery?.data?.portfolioRebalancing?.buyOrders[0].context
      ?.status === AccountAssetBuyStatus.RequiresMifidIdentifier;

  return (
    <>
      {activeStep === 'selectAccount' && (
        <SelectAccountStepBuy
          onProceed={({ selectedAccountType, selectedAccountId }) => {
            setActiveAccount({
              selectedAccountType,
              selectedAccountId,
            });

            if (
              asset.isTargetDateFund &&
              !matches &&
              verifyVintageStepToggle?.enabled
            ) {
              setActiveStep('verifyVintageSelection');
              return;
            }

            if (
              !userHoldsPosition &&
              heldPositionsByAsset.length > 0 &&
              verifyInstrumentStepToggle
            ) {
              setActiveStep('verifyPositionSelection');
              return;
            }

            setActiveStep('selectAmount');
          }}
          asset={asset}
        />
      )}
      {activeStep === 'verifyVintageSelection' &&
        activeAccount &&
        verifyVintageStepToggle?.enabled && (
          <VerifyVintageSelectionStepBuy
            selectedIsin={selectedIsin}
            targetDateInstrument={targetDateInstrument}
            selectedInstrument={selectedInstrument}
            handleInstrumentChange={handleInstrumentChange}
            onProceed={() => {
              setActiveStep('selectAmount');
            }}
            onGoBack={() => {
              if (amountStepGoBack) {
                amountStepGoBack();
                return;
              }

              if (!selectedAccountId && accounts && accounts.length > 1) {
                setActiveStep('selectAccount');
                return;
              }
              closeDialog();
            }}
            asset={asset}
            {...activeAccount}
          />
        )}
      {activeStep === 'verifyPositionSelection' &&
        activeAccount &&
        verifyInstrumentStepToggle?.enabled && (
          <VerifyInstrumentSelectionStepBuy
            asset={asset}
            selectedAccountType={activeAccount.selectedAccountType}
            selectedIsin={selectedIsin}
            heldPositions={heldPositionsByAsset}
            handleInstrumentChange={handleInstrumentChange}
            onProceed={() => {
              setActiveStep('selectAmount');
            }}
            onGoBack={() => {
              if (amountStepGoBack) {
                amountStepGoBack();
                return;
              }
              if (!selectedAccountId && accounts && accounts.length > 1) {
                setActiveStep('selectAccount');
                return;
              }
              closeDialog();
            }}
          />
        )}
      {activeStep === 'selectAmount' && activeAccount && (
        <AddBuyOrderToBasketStep
          flow="fullFlow"
          selectedPosition={selectedPosition}
          selectedIsin={selectedIsin}
          handleInstrumentChange={handleInstrumentChange}
          onProceed={(action, orderId) => {
            if (action === OnProceedAction.addToBasket) {
              closeDialog();
            } else if (action === OnProceedAction.quickOrder) {
              setQuickOrderId(orderId);
              setActiveStep('postSelectAmount');
            }
          }}
          onGoBack={() => {
            if (
              selectedAccountType &&
              selectedAccountId &&
              !userHoldsPosition &&
              !asset.isTargetDateFund &&
              heldPositionsByAsset.length > 0 &&
              verifyInstrumentStepToggle
            ) {
              setActiveStep('verifyPositionSelection');
              return;
            }

            if (
              asset.isTargetDateFund &&
              selectedAccountType &&
              selectedAccountId &&
              !matches &&
              verifyVintageStepToggle?.enabled
            ) {
              setActiveStep('verifyVintageSelection');
              return;
            }

            if (amountStepGoBack) {
              amountStepGoBack();
              return;
            }
            if (!selectedAccountId && accounts && accounts.length > 1) {
              setActiveStep('selectAccount');
            } else {
              closeDialog();
            }
          }}
          asset={asset}
          {...activeAccount}
        />
      )}
      {activeStep === 'postSelectAmount' && (
        <QueryState {...portfolioRebalancingQuery}>{() => null}</QueryState>
      )}
      {activeStep === 'ninoRequired' && quickOrderId && (
        <NationalInsuranceNumberStep
          onProceed={() =>
            setActiveStep(
              quickBuyOrderRequiresMifid ? 'mifidRequired' : 'quickOrderConfirm'
            )
          }
          onGoBack={() => setActiveStep('selectAmount')}
          source="quickOrderStep"
          introduction={
            <StepIntroduction $width={StepIntroductionWidth.normal} mb={2}>
              <StepIntroductionTypography>
                To trade{' '}
                {
                  portfolioRebalancingQuery.data?.portfolioRebalancing
                    ?.buyOrders[0].instrument.name
                }
                , our regulator requires that we ask for your National Insurance
                number.{' '}
                <StyledLink href={mifidToInvest} target="_blank">
                  Learn more
                </StyledLink>
                .
              </StepIntroductionTypography>
            </StepIntroduction>
          }
        />
      )}
      {activeStep === 'mifidRequired' && quickOrderId && (
        <MifidStep
          quickOrderId={quickOrderId}
          onProceed={() => setActiveStep('quickOrderConfirm')}
          onGoBack={() => setActiveStep('selectAmount')}
        />
      )}
      {activeStep === 'quickOrderConfirm' && quickOrderId && activeAccount && (
        <QuickOrderConfirm
          quickOrderId={quickOrderId}
          {...activeAccount}
          onProceed={() => {
            setActiveStep('quickOrderReceived');
          }}
          onGoBack={() => {
            setActiveStep('selectAmount');
          }}
        />
      )}
      {activeStep === 'quickOrderReceived' && quickOrderId && activeAccount && (
        <QuickOrderReceived
          type="buy"
          quickOrderId={quickOrderId}
          {...activeAccount}
          onProceed={() => {
            closeDialog();
          }}
        />
      )}
      {activeStep === 'preview' && activeAccount && (
        <PreviewStep
          {...activeAccount}
          onProceed={() => {
            closeDialog();
          }}
        />
      )}
    </>
  );
}

export function AddBuyOrderToBasketFullFlowDialog({
  closeDialog,
  open,
  assetId,
  selectedAccountType,
  selectedAccountId,
  selectedPosition,
  selectedIsin,
  handleInstrumentChange,
}: AddBuyOrderToBasketFullFlowDialogProps) {
  const { signedIn } = useAuth();

  const userAccounts = useAccountsQuery(undefined, {
    enabled: signedIn,
  });

  const accounts = userAccounts.data?.accounts ?? [];

  const assetQuery = useAssetQuery(
    { id: parseInt(assetId) },
    { enabled: open }
  );
  const asset = assetQuery.data?.asset;

  const queryClient = useQueryClient();

  const handleCloseDialog = () => {
    // invalidate so dashboard updates
    queryClient.invalidateQueries(
      useAccountQuery.getKey({ id: selectedAccountId! })
    );
    // invalidate so basket updates
    queryClient.invalidateQueries(useAccountsQuery.getKey());

    closeDialog();
  };
  return (
    <QueryState {...userAccounts}>
      {() => (
        <>
          {(!selectedAccountId || (accounts && accounts?.length > 0)) && (
            <ResponsiveDialog open={open} onClose={handleCloseDialog}>
              {open && (
                <QueryState {...assetQuery}>
                  {() =>
                    asset ? (
                      <AddBuyOrderToBasketFullFlowForm
                        closeDialog={handleCloseDialog}
                        asset={asset}
                        selectedAccountId={selectedAccountId}
                        selectedAccountType={selectedAccountType}
                        selectedPosition={selectedPosition}
                        selectedIsin={selectedIsin}
                        handleInstrumentChange={handleInstrumentChange}
                        accounts={accounts}
                      />
                    ) : null
                  }
                </QueryState>
              )}
            </ResponsiveDialog>
          )}
        </>
      )}
    </QueryState>
  );
}

export const useDialog = () => {
  const [open, setOpen] = useState(false);

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  return { open, openDialog, closeDialog };
};
