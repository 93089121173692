// External Paths:
export const tillitHomepage = 'https://tillitinvest.com';
export const tillitAboutUsPath = `${tillitHomepage}/about`;
export const tillitInTheNews = `${tillitHomepage}/tillit-in-the-news/`;
export const tillitUniversePath = `${tillitHomepage}/investment-committee`;
export const tillitContactPath = `${tillitHomepage}/contact`;
export const tillitEmployersPath = `${tillitHomepage}/employers`;
export const tillitInsightsPath = `${tillitHomepage}/insights`;
export const tillitPensionGenderGapPath = `${tillitHomepage}/pension-gender-gap`;
export const tillitFeesPath = `${tillitHomepage}/fees`;
export const tillitJargonPath = `${tillitHomepage}/jargon-flashcards`;
export const tillitCulturePath = `${tillitHomepage}/our-culture`;
export const tillitOpenPositionsPath = `${tillitHomepage}/our-culture#positions`;
export const tillitFAQs = `${tillitHomepage}/faqs`;
export const tillitISAProductPagePath = `${tillitHomepage}/products/stocks-shares-isa`;
export const tillitGIAProductPagePath = `${tillitHomepage}/products/general-investment-account`;
export const tillitSIPPProductPagePath = `${tillitHomepage}/products/pension`;
export const transfersWebPage = `${tillitHomepage}/transfer-concierge`;
export const termsAndConditions = `${tillitHomepage}/terms-and-conditions`;
export const privacyPolicy = `${tillitHomepage}/privacy`;
export const complaintsPolicy = `${tillitHomepage}/complaints-policy`;
export const tillitDarkUniversePath = `${tillitHomepage}/the-dark-universe`;
export const pensionIllustrationHelpLandingPage = `${tillitHomepage}/lp-product-illustration-guide`;
export const feedbackForm = 'https://form.typeform.com/to/lIIy43xN';
export const secclOrderExecutionPolicy = 'https://seccl.tech/order-execution/';
export const mifidToInvest =
  'https://knowledge.tillitinvest.com/faqs#why-do-you-need-my-national-identifier-to-make-some-kinds-of-investment';
export const mifidToTransfer =
  'https://knowledge.tillitinvest.com/faqs#why-do-you-need-my-national-identifier-to-start-an-in-specie-transfer-answer';
export const faqLeaveCashUninvested =
  'https://knowledge.tillitinvest.com/faqs#why-might-my-order-leave-cash-uninvested';
export const faqSdr = 'https://knowledge.tillitinvest.com/faqs';

// Social Paths:
export const linkedInPath = 'https://www.linkedin.com/company/tillitinvest/';
export const twitterPath = 'https://twitter.com/tillitinvest';
export const instagramPath = 'https://www.instagram.com/tillitinvest/';
export const youTubePath =
  'https://www.youtube.com/channel/UC9EyCFEoVqBDFsNoK3MDllA';

export const getSustainableInvestingTypeFormLinkPath =
  'https://form.typeform.com/to/BJY4XWEo';
export const insightArticleMultiAssetFundsPath =
  'https://knowledge.tillitinvest.com/insights/multi-asset-funds-the-ready-meal-approach-to-investing';
export const insightIncomeInvestingPath =
  'https://knowledge.tillitinvest.com/insights/income-investing';
export const insightSurgingInflationExploringPath =
  'https://knowledge.tillitinvest.com/insights/surging-inflation-exploring-what-rising-costs-mean-for-savers-and-investors';

export const fcaSustainableInvestmentLabelsGreenwashing =
  'https://www.fca.org.uk/consumers/sustainable-investment-labels-greenwashing';
