import { Tooltip } from '@material-ui/core';
import { Container } from 'components/Container/Container.style';
import { ChangePasswordDialog } from 'components/Dialogs/ChangePassword/ChangePasswordDialog';
import { ReadOnlyInput } from 'components/Input';
import { QueryState } from 'components/QueryState';
import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import { GaEventNames } from 'constants/gaConstants';
import { MfaMethod, useUserProfileQuery } from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { useState } from 'react';
import { FiHelpCircle } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { Base } from './Base';
import {
  DoubleCol,
  HelpIconTooltip,
  MfaExplanationWrapper,
  SectionTitle,
  SetupMfaWrapper,
} from './MyDetails.style';

interface PureLoginDetailsProps {
  email: string;
  phone: string | number;
  mfaMethod: MfaMethod;
  mfaMethodFriendly: string;
  canChangeMfa: boolean;
  onClick: () => void;
  handleMfaClick: () => void;
}

const MfaExplanation = () => (
  <MfaExplanationWrapper>
    Two-step verification helps secure your account by requiring something you{' '}
    <em>know</em> (your password) as well as something you <em>have</em> (your
    mobile phone) to log in. This secures your account even if someone managed
    to get hold of your password.
  </MfaExplanationWrapper>
);

// ts-unused-exports:disable-next-line
export function PureLoginDetails({
  email,
  phone,
  mfaMethod,
  mfaMethodFriendly,
  canChangeMfa,
  onClick,
  handleMfaClick,
}: PureLoginDetailsProps) {
  return (
    <Base title="Account security">
      <Container padding="none">
        <ReadOnlyInput
          id="email-read-only-input"
          label="Email"
          defaultValue={email}
        />

        <DoubleCol>
          <ReadOnlyInput
            id="pass-read-only-input"
            label="Password"
            defaultValue={'********'}
          />
          <CustomButtonV2 $color="primary" onClick={() => onClick?.()}>
            Change password
          </CustomButtonV2>
        </DoubleCol>

        <SectionTitle variant="h3">
          Two-step verification
          <Tooltip title={<MfaExplanation />}>
            <HelpIconTooltip>
              <FiHelpCircle />
            </HelpIconTooltip>
          </Tooltip>
        </SectionTitle>
        <DoubleCol>
          <ReadOnlyInput
            id="mfa-read-only-input"
            label="Two-step verification"
            value={mfaMethodFriendly}
          />
          {mfaMethod === MfaMethod.Sms && (
            <ReadOnlyInput
              id="phone-read-only-input"
              label="Mobile number"
              value={phone}
            />
          )}
        </DoubleCol>
        {(canChangeMfa || mfaMethod === MfaMethod.None) && (
          <SetupMfaWrapper>
            <CustomButtonV2
              $color="secondary"
              onClick={() => {
                handleMfaClick();
              }}
            >
              {mfaMethod !== MfaMethod.None
                ? 'Configure two-step verification'
                : 'Setup two-step verification'}
            </CustomButtonV2>
          </SetupMfaWrapper>
        )}
      </Container>
    </Base>
  );
}

export function LoginDetails() {
  const [
    showChangePasswordDialog,
    setShowChangePasswordDialog,
  ] = useState<boolean>(false);
  const userProfileQuery = useUserProfileQuery();
  const userProfile = userProfileQuery.data?.userProfile;

  const mfaMethodFriendlyNameLookup = {
    [MfaMethod.None]: 'Not set up',
    [MfaMethod.Sms]: 'Text message (SMS)',
    [MfaMethod.Totp]: 'Authenticator app',
  };
  const history = useHistory();

  const handleClick = () => {
    trackGa({
      event: GaEventNames.selectContent,
      item_id: 'dashboard - my details - login',
      content_type: `change password clicked`,
    });
    setShowChangePasswordDialog(true);
  };

  const handleMfaClick = () => {
    trackGa({
      event: GaEventNames.selectContent,
      item_id: 'dashboard - my details - login',
      content_type: `change mfa clicked`,
    });

    history.push('/setup-mfa');
  };

  return (
    <QueryState {...userProfileQuery}>
      {() => {
        return (
          <>
            {showChangePasswordDialog && (
              <ChangePasswordDialog
                open
                onClose={() => setShowChangePasswordDialog(false)}
              />
            )}
            <PureLoginDetails
              email={
                userProfile?.emailAddresses?.find((addr) => addr?.isVerified)
                  ?.address!
              }
              phone={
                userProfile?.phoneNumbers?.find((no) => no?.isVerified)
                  ?.number ?? 'Not provided'
              }
              mfaMethod={userProfile?.mfaMethod ?? MfaMethod.None}
              mfaMethodFriendly={
                mfaMethodFriendlyNameLookup[
                  userProfile?.mfaMethod ?? MfaMethod.None
                ]
              }
              canChangeMfa={true}
              onClick={handleClick}
              handleMfaClick={handleMfaClick}
            />
          </>
        );
      }}
    </QueryState>
  );
}
