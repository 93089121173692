import { SlideIn } from 'components/animations/SlideIn';
import { LocationKeyedSwitch } from 'components/LocationKeyedSwitch';
import { CashDepositTransferDetails } from 'generated/graphql';
import React from 'react';
import { Route } from 'react-router-dom';
import { AcknowledgmentStep } from './AcknowledgmentStep';
import { SummaryStep } from './SummaryStep';

interface ManualTransferRoutesProps {
  amount: number;
  transferDetails: CashDepositTransferDetails;
  onConfirmed: () => void;
  onDeferred: () => void;
  onClose: () => void;
  onBrowseFunds: () => void;
  onDashboard: () => void;
}

export function ManualTransferRoutes({
  amount,
  transferDetails,
  onConfirmed,
  onDeferred,
  onClose,
  onBrowseFunds,
  onDashboard,
}: ManualTransferRoutesProps) {
  return (
    <LocationKeyedSwitch>
      <Route path="/manual/summary">
        <SlideIn>
          <SummaryStep
            amount={amount}
            transferDetails={transferDetails}
            onConfirmed={onConfirmed}
            onDeferred={onDeferred}
          />
        </SlideIn>
      </Route>
      <Route path="/manual/acknowledgment">
        <SlideIn>
          <AcknowledgmentStep
            onClose={onClose}
            onBrowseFunds={onBrowseFunds}
            onDashboard={onDashboard}
          />
        </SlideIn>
      </Route>
    </LocationKeyedSwitch>
  );
}
