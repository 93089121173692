import { Popover } from 'components/design-system/InfoPopoverV2/InfoPopoverV2';
import { motion, useAnimation } from 'framer-motion';
import React, {
  MouseEventHandler,
  TouchEventHandler,
  forwardRef,
  useEffect,
} from 'react';
import { BsPinFill } from 'react-icons/bs';
import { CiStar } from 'react-icons/ci';
import { FaPlus, FaTimes } from 'react-icons/fa';
import { FontSize, TextAlign, TextSmall } from '../Text/Text';
import {
  ExclusivePillContainer,
  PillAction,
  PillContainer,
  PillTextWrapper,
} from './Pill.styles';
export { FontSize } from '../Text/Text';

export enum BorderStyle {
  None = 'none',
  Default = 'default',
  Minimal = 'minimal',
}

interface PillContentProps {
  $color?: string;
  $hoverColor?: string;
  $border?: BorderStyle;
  children: JSX.Element | React.ReactNode | string;
  $fontSize?: FontSize;
  $strikeThrough?: boolean;
  $noPadding?: boolean;
  $title?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onMouseDown?: MouseEventHandler<HTMLDivElement>;
  onTouchEnd?: TouchEventHandler<HTMLDivElement>;
  icon?: 'X' | '+' | 'pin';
}

const PillContent = forwardRef(function PillContent(
  {
    $color,
    $hoverColor,
    $border = BorderStyle.Minimal,
    children,
    $fontSize,
    $strikeThrough = false,
    $noPadding,
    $title,
    onClick,
    onMouseDown,
    onTouchEnd,
    icon,
    ...props
  }: PillContentProps,
  ref: React.Ref<HTMLDivElement>
) {
  return (
    <>
      <PillContainer
        $noMargin
        $color={$color}
        $hoverColor={$hoverColor}
        $border={$border}
        $fontSize={$fontSize}
        $strikeThrough={$strikeThrough}
        $noPadding={$noPadding}
        onClick={onClick}
        onMouseDown={onMouseDown}
        onTouchEnd={onTouchEnd}
        {...props}
        as={onClick ? 'button' : 'div'}
        ref={ref}
      >
        {icon ? <PillTextWrapper>{children}</PillTextWrapper> : children}
        {icon && (
          <PillAction>
            {icon === '+' && <FaPlus />}
            {icon === 'X' && <FaTimes />}
            {icon === 'pin' && <BsPinFill />}
          </PillAction>
        )}
      </PillContainer>
    </>
  );
});

interface PillProps {
  $color?: string;
  $hoverColor?: string;
  $border?: BorderStyle;
  children: JSX.Element | React.ReactNode | string;
  $fontSize?: FontSize;
  $strikeThrough?: boolean;
  $noPadding?: boolean;
  $title?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onMouseDown?: MouseEventHandler<HTMLDivElement>;
  onTouchEnd?: TouchEventHandler<HTMLDivElement>;
  icon?: 'X' | '+' | 'pin';
}

export const Pill = forwardRef(function Pill(
  {
    $color,
    $hoverColor,
    $border = BorderStyle.Minimal,
    children,
    $fontSize,
    $strikeThrough = false,
    $title,
    onClick,
    onMouseDown,
    onTouchEnd,
    icon,
    ...props
  }: PillProps,
  ref: React.Ref<HTMLDivElement>
) {
  if ($title) {
    return (
      <Popover
        popupId="tag-pill"
        openOn="hover"
        placement="bottom"
        $padding="narrow"
        content={
          <TextSmall $textAlign={TextAlign.center} $noMargin>
            {$title}
          </TextSmall>
        }
      >
        {(popoverProps: any) => {
          return (
            <PillContent
              $color={$color}
              $hoverColor={$hoverColor}
              $fontSize={$fontSize}
              $border={$border}
              $strikeThrough={$strikeThrough}
              onClick={onClick}
              onMouseDown={onMouseDown}
              onTouchEnd={onTouchEnd}
              icon={icon}
              ref={ref}
              {...props}
              {...popoverProps}
            >
              {children}
            </PillContent>
          );
        }}
      </Popover>
    );
  }
  return (
    <PillContent
      $color={$color}
      $hoverColor={$hoverColor}
      $fontSize={$fontSize}
      $strikeThrough={$strikeThrough}
      $border={$border}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onTouchEnd={onTouchEnd}
      icon={icon}
      ref={ref}
      {...props}
    >
      {children}
    </PillContent>
  );
});

interface ExclusivePillProps {
  $canHover?: boolean;
  $color?: string;
  $fontSize?: FontSize;
  children: JSX.Element | React.ReactNode | string;
  onClick?: () => void;
}

export function ExclusivePill({
  $canHover = true,
  $color = 'magenta',
  $fontSize = FontSize.small,
  children,
  onClick,
}: ExclusivePillProps) {
  const controls = useAnimation();

  const handleMouseIn = () => {
    if ($canHover) {
      controls.start({ width: 'auto', opacity: 1 });
    }
  };

  const handleMouseOut = () => {
    if ($canHover) {
      controls.start({ width: 0, opacity: 0 });
    }
  };

  useEffect(() => {
    $canHover
      ? controls.start({ width: 0, opacity: 0 })
      : controls.start({ width: 'auto', opacity: 1 });
  }, [$canHover, controls]);

  return (
    <ExclusivePillContainer
      $canHover={$canHover}
      $color={$color}
      $fontSize={$fontSize}
      $noMargin
      as={onClick ? 'button' : 'div'}
      onClick={onClick}
      onMouseEnter={handleMouseIn}
      onMouseLeave={handleMouseOut}
    >
      <CiStar />
      <motion.span
        initial={{ width: $canHover ? 0 : 'auto', opacity: $canHover ? 0 : 1 }}
        animate={controls}
      >
        {children}
      </motion.span>
    </ExclusivePillContainer>
  );
}
