import styled from 'styled-components';

export const ColumnHeading = styled.div`
  padding: 1.5rem 0;
`;

export const ColumnHeadingTabs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: space-between;

  ${(p) => p.theme.breakpoints.up('md')} {
    flex-direction: row;
    gap: 1rem;
  }
`;
