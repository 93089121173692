import styled from 'styled-components';

export const Actions = styled.div`
  margin-top: ${(p) => p.theme.spacing(7)}px;
  display: flex;
  flex-wrap: wrap;
  & > * {
    flex: 1;
  }
  & :first-child {
    margin-right: ${(p) => p.theme.spacing(0.5)}px;
  }
  & > * + * {
    margin-left: ${(p) => p.theme.spacing(0.5)}px;
  }

  &.stack {
    margin-top: ${(p) => p.theme.spacing(2)}px;
    flex-direction: column;
    & > * {
      margin-left: 0;
      margin-right: 0;
      margin-top: ${(p) => p.theme.spacing(1)}px;

      &:last-child {
        margin-top: ${(p) => p.theme.spacing(3.75)}px;
      }
    }
  }

  @media (max-width: 599.95px) {
    margin-top: ${(p) => p.theme.spacing(2)}px;
    &.stack-mobile {
      flex-direction: column;
      & > * {
        margin-left: 0;
        margin-right: 0;
        margin-top: ${(p) => p.theme.spacing(2)}px;
      }
    }
  }
`;
