import { useAuth } from 'context/AuthContext';
import {
  DraftRecurringDeposit,
  DraftRecurringOrder,
  WrapperType,
  useAutoSaveInvestDraftStateQuery,
  useRecurringTransactionsQuery,
  useUpdateAutoSaveInvestDraftStateMutation,
  useUserProfileQuery,
} from 'generated/graphql';
import { getWrapperTypeForPathSegment } from 'helpers/accountHelpers';
import {
  autoSaveInvestPath,
  autoSaveInvestPathAddFundsPath,
  autoSaveInvestPathMyFundsPath,
} from 'paths';
import { useQueryClient } from 'react-query';
import { useRouteMatch } from 'react-router-dom';
import { UpdateAutoSaveInvestDraftStateMutationVariablesInputState } from 'types/graphqlTypes';
import { useMode } from '../mode/useMode';

export type AutoSaveInvestDraftState = {
  deposits: Array<DraftRecurringDeposit>;
  orders: Array<DraftRecurringOrder>;
};

export interface AutoSaveInvestBasketSummary {
  accountId: string;
  wrapperType: WrapperType;
  depositsTotal: number | undefined;
  orders: number | undefined;
}

export function useAutoSaveInvestState(selectedAccountId?: string) {
  const queryClient = useQueryClient();
  const [mode] = useMode();

  const autoSaveInvestDraftStateQuery = useAutoSaveInvestDraftStateQuery(
    {
      accountId: selectedAccountId!,
    },
    { enabled: !!selectedAccountId }
  );

  const updateAutoSaveInvestDraftState = useUpdateAutoSaveInvestDraftStateMutation();

  const draftState = autoSaveInvestDraftStateQuery.data?.autoSaveInvestDraft;

  const setState = async (
    newState: UpdateAutoSaveInvestDraftStateMutationVariablesInputState
  ) => {
    queryClient.setQueryData(
      useAutoSaveInvestDraftStateQuery.getKey({
        accountId: selectedAccountId!,
      }),
      { autoSaveInvestDraft: newState }
    );
    await updateAutoSaveInvestDraftState.mutateAsync({
      input: {
        accountId: selectedAccountId!,
        state: newState,
      },
    });
    await queryClient.invalidateQueries(
      useAutoSaveInvestDraftStateQuery.getKey({
        accountId: selectedAccountId!,
      })
    );
  };

  const clearState = async () => {
    await updateAutoSaveInvestDraftState.mutate({
      input: {
        accountId: selectedAccountId!,
        state: null,
      },
    });
    await queryClient.invalidateQueries(
      useAutoSaveInvestDraftStateQuery.getKey({
        accountId: selectedAccountId!,
      })
    );
    const currentState = {
      deposits:
        recurringTransactionsQuery.data?.recurringTransactions?.deposits?.map(
          (deposit) => ({
            ...base,
            paymentDate: deposit.paymentDate,
            amount: deposit.amount,
          })
        ) || [],
      orders:
        recurringTransactionsQuery.data?.recurringTransactions?.orders?.map(
          (order) => ({
            ...base,
            isin: order.isin,
            proportion: order.proportion,
          })
        ) || [],
    };
    return currentState;
  };

  const getState = () => {};

  const autoSaveInvestPageMatch = useRouteMatch<{
    wrapperType: string;
  }>([
    autoSaveInvestPath,
    autoSaveInvestPathAddFundsPath,
    autoSaveInvestPathMyFundsPath,
  ]);

  const isAutoSaveInvestPage = autoSaveInvestPageMatch !== null;

  const activeWrapperType = isAutoSaveInvestPage
    ? getWrapperTypeForPathSegment(autoSaveInvestPageMatch?.params.wrapperType)
    : mode?.mode === 'resume'
    ? undefined
    : mode?.wrapperType;

  const { signedIn } = useAuth();
  const userProfileQuery = useUserProfileQuery(undefined, {
    enabled: signedIn,
  });

  const account = userProfileQuery.data?.clientSummary?.accounts.find(
    ({ wrapperType, id }) => {
      if (selectedAccountId) {
        return id === selectedAccountId;
      } else {
        return wrapperType === activeWrapperType;
      }
    }
  );
  const accountId = account?.id;

  const recurringTransactionsQuery = useRecurringTransactionsQuery(
    {
      accountId: accountId as string,
    },
    {
      enabled: !!accountId,
    }
  );

  const base = {
    isNew: false,
    isEdited: false,
    isDeleted: false,
  };

  const state: AutoSaveInvestDraftState =
    draftState !== null && draftState !== undefined
      ? { deposits: draftState.deposits, orders: draftState.orders }
      : {
          deposits:
            recurringTransactionsQuery.data?.recurringTransactions?.deposits?.map(
              (deposit) => ({
                ...base,
                paymentDate: deposit.paymentDate,
                amount: deposit.amount,
              })
            ) || [],
          orders:
            recurringTransactionsQuery.data?.recurringTransactions?.orders?.map(
              (order) => ({
                ...base,
                isin: order.isin,
                proportion: order.proportion,
              })
            ) || [],
        };

  const latestEmployerContribution =
    recurringTransactionsQuery.data?.recurringTransactions
      ?.latestEmployerContribution;

  const latestEmployerContributionAmount =
    latestEmployerContribution?.amount ?? 0;

  const totalRecurringDepositsAmount =
    state?.deposits
      .filter((deposit) => !deposit.isDeleted)
      ?.reduce<number>((total, deposit) => {
        return total + deposit.amount;
      }, 0 as number) ?? 0;

  const totalDepositAmount =
    totalRecurringDepositsAmount + latestEmployerContributionAmount;

  const totalAllocationProportion = state?.orders
    ? state.orders.reduce((total, field) => {
        return total + (field.proportion !== undefined ? field.proportion : 0);
      }, 0)
    : 0;

  const totalAllocationAmount = totalDepositAmount * totalAllocationProportion;

  return {
    state: state,
    setState: (
      state: UpdateAutoSaveInvestDraftStateMutationVariablesInputState
    ) => setState(state),
    getState,
    clearState: () => clearState(),
    latestEmployerContribution,
    totalDepositAmount,
    totalAllocationProportion,
    totalAllocationAmount,
    orderCount: state?.orders?.length,
    recurringTransactionsQuery,
    userProfileQuery,
    updateAutoSaveInvestDraftState,
    autoSaveInvestDraftStateQuery,
  };
}
