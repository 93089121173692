import { useTogglesQuery } from 'generated/graphql';
import { TogglesQueryToggle } from 'types/graphqlTypes';
import { useReadLocalStorage } from 'usehooks-ts';

export enum LocalFeatureToggle {
  betterSignoutHandling = 'betterSignoutHandling',
}

const localFeatureToggleDefaults: Partial<
  Record<LocalFeatureToggle, boolean>
> = {
  betterSignoutHandling: true,
};

/**
 * useFeatureToggle
 *
 * use for localStorage based feature toggling.
 *
 * Setting feature example:
 *   localStorage.setItem('tillit.dpcUnauthUsers', 'true');
 *   localStorage.removeItem('tillit.dpcUnauthUsers');
 */
export function useLocalFeatureToggle(toggleName: LocalFeatureToggle): boolean {
  const localStorageValue = useReadLocalStorage(`tillit.${toggleName}`);
  const defaultValue = localFeatureToggleDefaults[toggleName] ?? false;

  return localStorageValue === null ? defaultValue : localStorageValue === true;
}

type activeToggles =
  | 'global-auto-invest-remove-new-keep-cash'
  | 'global-checkout-handle-stale-bid-price'
  | 'global-default-fund-opt-out'
  | 'global-show-min-trade-unit-status'
  | 'global-suppress-time-weighted-growth-percentages'
  | 'global-truelayer-redirect-flow' // todo - remove
  | 'global-use-mifid-illustrations'
  | 'global-improved-contributions'
  | 'global-actions'
  | 'global-verify-vintage-step'
  | 'global-verify-instrument-step'
  | 'global-life-path-region'
  | 'x-ray-beta'
  | 'global-fund-details-breakdown-negative-numbers-donut';

/**
 * useToggle
 *
 * Hook used to query if a toggle is enabled.
 *
 * The toggle should be setup in unleash & have a simple tag 'fe-available'
 *
 * @example ```
 *   const [recurringDepositsToggle, toggleQuery] = useToggle('global-recurring-deposits');
 *   ;
 *   // ...
 *   <QueryState {...toggleQuery}>
 *     {() => (
 *       {recurringDepositsToggle.enabled && (
 *         <div>
 *           <h1>Recurring Deposits</h1>
 *           { / * ... * /}
 *         </div>
 *       )}
 *     )}
 *    </QueryState>
 * ```
 *
 * @param toggleName - the name of the toggle
 */
export function useToggle(
  toggleName: activeToggles
): [
  toggle: TogglesQueryToggle | undefined,
  toggleQuery: ReturnType<typeof useTogglesQuery>
] {
  const toggleQuery = useTogglesQuery(undefined, {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retryOnMount: false,
  });

  const toggle: TogglesQueryToggle | undefined = toggleQuery.data?.toggles.find(
    ({ name }) => name === toggleName
  );

  return [toggle, toggleQuery];
}
