import { colors } from 'constants/colors';
import styled, { css } from 'styled-components';

export const AmountInputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;

  min-width: 2rem;
`;

interface InputLabelProps {
  $isLarge?: boolean;
}

export const AmountInputLabel = styled.label<InputLabelProps>`
  position: absolute;
  font-size: ${({ $isLarge }) => ($isLarge ? '2.5rem' : '2rem')};
  bottom: ${({ $isLarge }) => ($isLarge ? '-5px' : '-0.25rem')};
  color: ${colors.richBlack};
`;

export const PercentageInputLabel = styled.label<InputLabelProps>`
  position: absolute;
  font-size: ${({ $isLarge }) => ($isLarge ? '2.5rem' : '2rem')};
  bottom: ${({ $isLarge }) => ($isLarge ? '-5px' : '-0.25rem')};
  right: 0.5rem;
  color: ${colors.richBlack};
`;

interface InputBaseProps {
  $isLarge?: boolean;
}

const inputBaseStyles = css<InputBaseProps>`
  border: none;
  outline: none;
  font-size: ${({ $isLarge }) => ($isLarge ? '2.5rem' : '2rem')};
  width: 100%;
  transition: background-color 0.25s ease-in-out, opacity 0.25s ease-in-out;

  &:read-only {
    background-color: ${colors.grey};
    border-radius: 0.5rem;
    opacity: 0.5;
  }
`;

export const AmountInputInput = styled.input<InputBaseProps>`
  padding: ${({ $isLarge }) => ($isLarge ? ' 0 0 0 1.5rem' : ' 0 0 0 1.25rem')};
  ${inputBaseStyles}
`;

export const PercentageInputInput = styled.input<InputBaseProps>`
  padding: 0 2.5rem 0 0;
  ${inputBaseStyles}
`;
