import {
  StepActions,
  StepButton,
  StepContainer,
  StepIntroductionTypography,
  StepText,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';

import {
  useAccountQuery,
  useAccountsQuery,
  useCashTransactionsQuery,
} from 'generated/graphql';
import { useQueryClient } from 'react-query';

interface ThankYouProps {
  onClose: () => void;
  accountId: string;
}

export function ThankYou({ onClose, accountId }: ThankYouProps) {
  const queryClient = useQueryClient();

  const handleClose = () => {
    queryClient.invalidateQueries({
      predicate: (q) =>
        q.queryKey[0] === useAccountsQuery.getKey()[0] ||
        q.queryKey[0] === useAccountQuery.getKey({ id: accountId })[0],
    });
    queryClient.invalidateQueries({
      predicate: (q) =>
        q.queryKey[0] === useCashTransactionsQuery.getKey({ id: accountId })[0],
    });
    onClose();
  };

  return (
    <StepContainer>
      <StepTitle>Thank you!</StepTitle>
      <StepText>
        <StepIntroductionTypography style={{ margin: '16px 0' }} align="center">
          We've received your instruction and your cash is on its way.
        </StepIntroductionTypography>
        <StepIntroductionTypography align="center">
          It may take 4 working days for the cash to appear in your account.
        </StepIntroductionTypography>
      </StepText>

      <StepActions>
        <StepButton type="button" className="richBlue" onClick={handleClose}>
          Close
        </StepButton>
      </StepActions>
    </StepContainer>
  );
}
