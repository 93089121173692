import { useMediaQuery, useTheme } from '@material-ui/core';
import { ReadOnlyInput } from 'components/Input';
import { QueryState } from 'components/QueryState';
import { StyledLink } from 'components/design-system/Link';
import { GaEventNames } from 'constants/gaConstants';
import { useUserProfileQuery } from 'generated/graphql';
import { isProbablyMobile } from 'helpers/isProbablyMobile';
import { trackGa } from 'helpers/track';
import { useModal } from 'mui-modal-provider';
import pluralize from 'pluralize';
import { Title } from '../Cards.styles';
import { QRCode } from './QRCode';
import { QRCodeDialog } from './QRCodeDialog';
import {
  CardButton,
  CardContainer,
  CodeContainer,
  ContentContainer,
} from './ReferralsCard.styles';
import { ShareDialog } from './ShareDialog';

interface ReferralsCardProps {
  className?: string;
  dataTestid: string;
}

export function ReferralsCard({ className, dataTestid }: ReferralsCardProps) {
  const { showModal } = useModal();
  const { breakpoints } = useTheme();
  const aboveSmall = useMediaQuery(breakpoints.up('sm'));

  const userProfileQuery = useUserProfileQuery();

  const url = userProfileQuery.data?.userProfile?.personalReferralLink;
  const referralsCount =
    userProfileQuery.data?.userProfile?.personalReferralsCount ?? 0;
  const shareMessage =
    'Join me and invest in handpicked funds on TILLIT. Capital at risk.';

  const handleQrCodeClick = () => {
    if (!aboveSmall) {
      showModal(QRCodeDialog, {
        url: url!,
      });
    }
  };

  const handleShare = async () => {
    const data = {
      url: url!,
      text: shareMessage,
    };

    if (isProbablyMobile() && !!navigator.share && navigator.canShare(data)) {
      trackGa({
        event: GaEventNames.selectContent,
        content_type: 'referral card',
        item_id: 'share',
      });
      await navigator.share(data);
    } else {
      trackGa({
        event: GaEventNames.selectContent,
        content_type: 'referral card',
        item_id: 'load referral dialog',
      });
      showModal(ShareDialog, {
        url: url!,
        shareMessage,
      });
    }
  };

  return (
    <>
      <CardContainer className={className} data-testid={dataTestid}>
        <QueryState {...userProfileQuery}>
          {() => (
            <>
              <Title>Skip a year!</Title>
              <ContentContainer>
                <CodeContainer onClick={handleQrCodeClick}>
                  <QRCode value={url!} size={aboveSmall ? 170 : 130} />
                </CodeContainer>

                {referralsCount > 0 && (
                  <p>
                    You have referred <span>{referralsCount}</span>{' '}
                    {pluralize('friends', referralsCount)}!
                  </p>
                )}

                <p>
                  When you refer a friend to TILLIT, you will both jump ahead on
                  our unique falling fee and receive a permanent fee reduction!{' '}
                  <StyledLink
                    $colorStyle="light"
                    href="https://d1ur1kq4xhe5pz.cloudfront.net/images/TILLIT-Invite-A-Friend-Terms-and-Conditions-2023.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Full terms and conditions apply
                  </StyledLink>
                  .
                </p>

                <ReadOnlyInput label={`Referral URL`} defaultValue={url} />
                <CardButton $color="primary" onClick={handleShare}>
                  Share referral link
                </CardButton>
              </ContentContainer>
            </>
          )}
        </QueryState>
      </CardContainer>
    </>
  );
}
