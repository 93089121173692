import { StyledComponentProps, Theme, withStyles } from '@material-ui/core';

import React from 'react';
import { Box } from './Styles/BoxContainer.styles';

interface BoxContainerProps extends StyledComponentProps {
  children: React.ReactNode;
}

const styles = (theme: Theme) => ({
  root: {
    '& hr': {
      border: 'none',
      borderBottom: '1px solid #cfd6d9',
    },
  },
});

export const BoxContainer = withStyles(styles)(
  ({ classes, children }: BoxContainerProps) => (
    <Box elevation={0} square className={classes!.root} component="section">
      {children}
    </Box>
  )
);
