import { AccordionDetails, AccordionSummary } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Accordion } from 'components/Accordion';
import { Form } from 'components/Form/Form';
import { FormState } from 'components/Form/FormState';
import { TextField } from 'components/Form/TextField';
import { QueryState } from 'components/QueryState';
import { H5 } from 'components/design-system/Heading/Heading';
import { ServerError } from 'components/design-system/ServerError/ServerError';
import {
  StepActions,
  StepButton,
  StepContainer,
  StepContent,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import { MonoDd, TextNormal } from 'components/design-system/Text/Text';
import * as format from 'formatting';
import {
  useAccountQuery,
  useAccountsQuery,
  useCashTransactionsQuery,
  useCreateWithdrawalMutation,
  useUserProfileQuery,
  useWithdrawalDetailsByAccountQuery,
} from 'generated/graphql';
import { amount } from 'helpers/yupExtensions';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import styled from 'styled-components';
import * as Yup from 'yup';
import Reference from 'yup/lib/Reference';
import { CashPaymentsTable } from '../../CashPaymentsTable';

const AccordionDetailsWide = styled(AccordionDetails)`
  padding-left: 0;
  padding-right: 0;
`;

const BankDetailsList = styled.dl`
  display: grid;
  grid-template-columns: auto 1fr;

  dt {
    font-weight: 600;
  }
`;

const Section = styled.div`
  width: 100%;
  margin-top: 1.5rem;
`;

interface CreateProps {
  accountId: string;
  onProceed: () => void;
}

const withdrawSchema = Yup.object().shape({
  amount: amount()
    .label('Amount')
    .typeError('Please enter a valid number')
    .required()
    .min(Yup.ref('$minAmount') as Reference<number>)
    .max(Yup.ref('$maxAmount') as Reference<number>),
});

interface WithdrawFormValues extends Yup.Asserts<typeof withdrawSchema> {}

export function Create({ accountId, onProceed }: CreateProps) {
  const queryClient = useQueryClient();

  const { mutateAsync, isError } = useCreateWithdrawalMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(useUserProfileQuery.getKey());
      queryClient.invalidateQueries({
        predicate: (q) =>
          q.queryKey[0] === useAccountsQuery.getKey()[0] ||
          q.queryKey[0] === useAccountQuery.getKey({ id: accountId })[0],
      });
      queryClient.invalidateQueries({
        predicate: (q) =>
          q.queryKey[0] ===
          useCashTransactionsQuery.getKey({ id: accountId })[0],
      });
    },
  });

  const withdrawalDetailsQuery = useWithdrawalDetailsByAccountQuery({
    id: accountId,
  });
  const accountQuery = useAccountQuery({ id: accountId });
  const [failed, setFailed] = useState(false);

  const onSubmit = async ({ amount }: WithdrawFormValues) => {
    try {
      await mutateAsync({
        input: {
          amount,
          accountId,
          idempotencyToken: withdrawalDetailsQuery?.data
            ?.withdrawalDetailsByAccount?.idempotencyToken!,
        },
      });

      onProceed();
    } catch {
      setFailed(true);
    }
  };

  return (
    <StepContainer>
      <QueryState {...withdrawalDetailsQuery}>
        {({ data }) =>
          data?.withdrawalDetailsByAccount &&
          (!data.withdrawalDetailsByAccount.canWithdraw ? (
            <Alert severity="info">
              <AlertTitle>Withdrawals disabled</AlertTitle>
              {data.withdrawalDetailsByAccount.statusReason}
            </Alert>
          ) : (
            <Form<WithdrawFormValues>
              onSubmit={onSubmit}
              schema={withdrawSchema}
              validationContext={{
                minAmount:
                  data.withdrawalDetailsByAccount?.validRange
                    ?.minimumPaymentAmount,
                maxAmount:
                  data.withdrawalDetailsByAccount?.validRange
                    ?.maximumPaymentAmount,
              }}
            >
              <StepContent>
                <StepTitle>Withdraw cash</StepTitle>
                <TextNormal>
                  Cash balance:{' '}
                  {format.currencyFull(
                    accountQuery?.data?.account?.valuationSummary
                      ?.uninvestedCash ?? 0
                  )}
                </TextNormal>

                <TextField
                  name="amount"
                  label="Amount"
                  startAdornment={
                    <TextNormal $noMargin as="span">
                      £
                    </TextNormal>
                  }
                  helpText={`Minimum withdraw amount: ${format.currencyFull(
                    data.withdrawalDetailsByAccount.validRange
                      ?.minimumPaymentAmount ?? 0
                  )}, Maximum withdraw amount: ${format.currencyFull(
                    data.withdrawalDetailsByAccount.validRange
                      ?.maximumPaymentAmount ?? 0
                  )}`}
                  inputProps={{
                    inputMode: 'decimal',
                  }}
                />

                {data.withdrawalDetailsByAccount.pendingWithdrawals?.length ? (
                  data.withdrawalDetailsByAccount.pendingWithdrawals?.length >
                  3 ? (
                    <Section>
                      <Accordion>
                        <AccordionSummary>
                          <H5>Pending withdrawals</H5>
                        </AccordionSummary>
                        <AccordionDetailsWide>
                          <CashPaymentsTable
                            data={
                              data.withdrawalDetailsByAccount.pendingWithdrawals
                            }
                          />
                        </AccordionDetailsWide>
                      </Accordion>
                    </Section>
                  ) : (
                    <Section>
                      <H5>Pending withdrawals</H5>
                      <CashPaymentsTable
                        data={
                          data.withdrawalDetailsByAccount.pendingWithdrawals
                        }
                      />
                    </Section>
                  )
                ) : null}

                <ServerError isVisible={failed || isError} />

                <Section>
                  <H5>Destination account</H5>
                  <BankDetailsList>
                    <dt>Account number</dt>
                    <MonoDd>
                      {
                        data.withdrawalDetailsByAccount
                          .targetAccountNumberMasked
                      }
                    </MonoDd>

                    <dt>Sort code</dt>
                    <MonoDd>
                      {data.withdrawalDetailsByAccount.targetSortCodeMasked}
                    </MonoDd>
                  </BankDetailsList>
                </Section>
              </StepContent>
              <StepActions>
                <FormState>
                  {({ isSubmitting }) => (
                    <>
                      <StepButton
                        type="submit"
                        variant="contained"
                        className="magenta"
                        disabled={isSubmitting}
                        fullWidth
                      >
                        Make withdrawal
                      </StepButton>
                    </>
                  )}
                </FormState>
              </StepActions>
            </Form>
          ))
        }
      </QueryState>
    </StepContainer>
  );
}
