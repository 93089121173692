import { H2, TextAlign } from 'components/design-system/Heading/Heading';
import {
  ToggleColorOptions,
  ToggleIconButtons,
} from 'components/design-system/ToggleIconButtons/ToggleIconButtons';
import { DonutBreakdown } from 'components/feature/FundDetails/Breakdown/DonutBreakdown/DonutBreakdown';
import { TableBreakdown } from 'components/feature/FundDetails/Breakdown/TableBreakdown/TableBreakdown';
import { NoDataCopy } from 'components/feature/FundDetails/Breakdown/_shared/Breakdown.styles';
import { Breakdown } from 'components/feature/FundDetails/Breakdown/breakdownTypes';
import { GaEventNames } from 'constants/gaConstants';
import { AssetClassesByIsin } from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { useToggle } from 'hooks/useFeatureToggle';
import { useState } from 'react';
import { AssetQueryAssetInstrument } from 'types/graphqlTypes';
import { PerformanceContainer } from '../Styles/FundDetails.style';
import {
  BreakdownLayout,
  BreakdownToggleWrapper,
  ChartContainer,
} from './_shared/BreakdownSlices.styles';

type SubAssetClassName =
  | 'Alternative assets'
  | 'Alternative investment strategies'
  | 'Property'
  | 'Bonds'
  | 'Money Market'
  | 'Equities'
  | 'Other';

/* cSpell:disable */
const specialCases: Record<string, SubAssetClassName> = {
  ALAS: 'Alternative assets',
  ALIS: 'Alternative investment strategies',
  CASH: 'Money Market',
  CSHE: 'Money Market',
  COEN: 'Alternative assets',
  EQUI: 'Equities',
  FOEX: 'Alternative investment strategies',
  FUTR: 'Alternative investment strategies',
  FWDS: 'Alternative investment strategies',
  FXJE: 'Equities',
  GHYI: 'Bonds',
  GIGI: 'Bonds',
  GLBQ: 'Equities',
  GSDI: 'Bonds',
  INIL: 'Bonds',
  ITLC: 'Equities',
  ITMC: 'Equities',
  OPTS: 'Alternative investment strategies',
  OTHR: 'Other',
  PREF: 'Equities',
  PROP: 'Property',
  PRVE: 'Equities',
  STRC: 'Alternative investment strategies',
  UKIL: 'Bonds',
};

const codeMapping: Record<string, SubAssetClassName> = {
  DQ: 'Equities',
  EQ: 'Equities',
  FI: 'Bonds',
  GO: 'Bonds',
  NG: 'Bonds',
  HY: 'Bonds',
  SH: 'Property',
};

const descriptions: Record<SubAssetClassName, string> = {
  'Alternative investment strategies':
    'Investment approaches include derivatives, hedging, foreign exchange, private assets, structured products, and alternative assets investments like property, infrastructure, royalties, and commodities.',
  Bonds: `
    Can also be referred to as fixed income investments and is an investment security that is similar to a loan between a borrower (a company or government) and a lender (the investor). 
    Just like a loan, the bond typically pays the investor interest in return.
  `,
  'Money Market':
    'Short-life (less than one year to maturity) low risk securities with high liquidity',
  Equities:
    'Can also be referred to as stocks and is an investment security that represents ownership in a company.',
  Other: 'Miscellaneous or additional categories not specified',
  'Alternative assets':
    'Non-traditional investments like property, infrastructure, royalties, derivatives, commodities or hedge funds',
  Property:
    'Directly in property or in listed property companies where an investor is exposed the assets rental income and property valuations',
};

type DisplayOptions = 'donut' | 'table';

interface AssetClassChartSliceProps {
  instrument: AssetQueryAssetInstrument;
}

export function AssetClassChartSlice({
  instrument,
}: AssetClassChartSliceProps) {
  const lines = instrument?.assetClassBreakdown?.nodes ?? [];

  const [negativeNumbersDonut] = useToggle(
    'global-fund-details-breakdown-negative-numbers-donut'
  );

  const breakdown = {
    name: 'Asset class',
    proportion: 1,
    lines: makeBreakdownModel(lines),
  };

  const donutSupportsData =
    negativeNumbersDonut?.enabled ||
    breakdown.lines.every((line) => line.proportion >= 0);

  const [activeDisplay, setActiveDisplay] = useState<DisplayOptions>(
    donutSupportsData ? 'donut' : 'table'
  );

  const breakdownHasData = !!breakdown.lines?.length;

  return (
    <PerformanceContainer>
      <H2 $textAlign={TextAlign.center}>Asset class</H2>
      {breakdownHasData ? (
        <BreakdownLayout>
          {donutSupportsData && (
            <BreakdownToggleWrapper>
              <ToggleIconButtons<DisplayOptions>
                onClick={(value) => {
                  trackGa({
                    event: GaEventNames.selectContent,
                    content_type: 'fund details - donut chart slice - display',
                    item_id: value,
                  });
                  setActiveDisplay(value);
                }}
                $color={ToggleColorOptions.purple}
                options={[
                  { value: 'donut', icon: 'donut' },
                  { value: 'table', icon: 'table' },
                ]}
                value={activeDisplay}
              />
            </BreakdownToggleWrapper>
          )}

          {activeDisplay === 'donut' && (
            <ChartContainer>
              <DonutBreakdown breakdown={breakdown} $assetClassColors />
            </ChartContainer>
          )}
          {activeDisplay === 'table' && (
            <ChartContainer>
              <TableBreakdown breakdown={breakdown} $descriptionHasPopover />
            </ChartContainer>
          )}
        </BreakdownLayout>
      ) : (
        <NoDataCopy>{breakdown.name} breakdown is not available.</NoDataCopy>
      )}
    </PerformanceContainer>
  );
}

const makeNewBreakdown = (
  name: string,
  description: string
): {
  name: string;
  description: string;
  proportion: number;
  lines: Breakdown[];
} => ({ name: name, description, proportion: 0, lines: [] });

const makeBreakdownModel = (
  assetClasses: Pick<AssetClassesByIsin, 'name' | 'proportion' | 'code'>[]
) => {
  const model: Breakdown[] = [];

  for (const assetClass of assetClasses) {
    if (!assetClass.code || assetClass.proportion === 0) {
      continue;
    }

    const parentClassName = getAssetMapping(assetClass.code);
    const hasBreakdownModel = model.some((obj) => obj.name === parentClassName);

    if (!hasBreakdownModel) {
      const description = descriptions[parentClassName];
      model.push(makeNewBreakdown(parentClassName, description));
    }
    const findIndex = model.findIndex((obj) => obj.name === parentClassName);
    if (findIndex >= 0) {
      model[findIndex].proportion += assetClass.proportion;
      model[findIndex].lines!.push({
        name: assetClass.name,
        proportion: assetClass.proportion,
      });
    }
  }

  return model;
};

export function getAssetMapping(code: string): SubAssetClassName {
  if (specialCases[code]) {
    return specialCases[code];
  }

  const firstTwo = code.slice(-2);
  if (codeMapping[firstTwo]) {
    return codeMapping[firstTwo];
  }

  return 'Other';
}
