import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSelectAssetColor } from 'hooks/useAssetBrandDefinition';
import React from 'react';
import { useTheme } from 'styled-components';
import { StyledTab, StyledTabs } from './HoldingTabs.style';

interface TabItem {
  label: React.ReactNode;
  value: string;
  disabled?: boolean;
}

interface PureHoldingTabsProps {
  assetClass: string;
  value: string;
  tabs: Array<TabItem>;
  onChange: (event: React.SyntheticEvent, newValue: string) => void;
  isDarkUniverse: boolean;
}

export function PureHoldingTabs({
  assetClass,
  value,
  tabs,
  onChange,
  isDarkUniverse,
}: PureHoldingTabsProps) {
  const muiTheme = useTheme();
  const isAtLeastDesktop = useMediaQuery(muiTheme.breakpoints.up('md'));
  const assetColor = useSelectAssetColor(assetClass);

  return (
    <StyledTabs
      value={value}
      color={assetColor}
      onChange={onChange}
      textColor="secondary"
      indicatorColor="secondary"
      orientation={isAtLeastDesktop ? 'vertical' : 'horizontal'}
    >
      {tabs.map((tab: TabItem) => (
        <StyledTab
          key={tab.value}
          color={assetColor}
          disabled={tab.disabled}
          value={tab.value}
          label={tab.label}
          $isDarkUniverse={isDarkUniverse}
        />
      ))}
    </StyledTabs>
  );
}
