import { styledLinkStyleOnly } from 'components/design-system/Link/Link.styles';
import { colors } from 'constants/colors';
import styled from 'styled-components';

export const DocumentTypeContainer = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  margin-bottom: 2rem;
  gap: 1rem;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    gap: 3rem;
  }
`;

export const DocumentType = styled.label`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  box-shadow: 10px 15px 30px rgba(166, 171, 189, 0.25);
  border-radius: 10px;
  height: 160px;
  width: 160px;
  border: none;
  background: white;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    height: 178px;
    width: 178px;
  }
  cursor: pointer;

  &.inactive {
    opacity: 0.25;
  }
`;

export const StyledButton = styled.button`
  ${styledLinkStyleOnly};
  border: none;
  background: white;
  padding: 0;
`;

export const SvgWrapper = styled.div`
  display: flex;
  align-items: center;
  svg {
    height: 50px;
    width: 50px;
  }
`;

export const UploadsContainer = styled.div`
  display: grid;
  border: 1.25px solid ${colors.grey};
  border-radius: 5px;
  padding: 1.5rem;
  gap: 2rem;
  margin-bottom: 2.5rem;
`;

export const NeedHelpWrapper = styled.div`
  display: inline-flex;
  margin: 1rem auto 0;
`;

export const PopoverContent = styled.div`
  display: grid;
  gap: 10px;
  margin-bottom: 10px;

  ul {
    margin: 0;
    padding-left: 25px;
    display: grid;
    gap: 5px;
  }
`;
