import { Typography } from '@material-ui/core';
import sectionBackground from 'assets/transfers_card_background.jpg';
import { colors } from 'constants/colors';
import { transparentize } from 'polished';
import styled from 'styled-components';

export const TransfersDetails = styled.div`
  background-color: ${colors.lightGrey};
  background-image: url(${sectionBackground});
  background-repeat: no-repeat;
  background-size: cover;
  padding: ${({ theme }) => theme.spacing(2.5)}px;
  border: 1px solid ${transparentize(0.98, colors.richBlack)};
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  gap: 1.4rem;

  ${(p) => p.theme.breakpoints.up('md')} {
    padding: ${(p) => p.theme.spacing(7.5, 4.5, 12.5, 4.5)};
  }
`;

export const Title = styled(Typography)`
  font-size: 1.75rem;
  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 2rem;
  }
`;

export const ActiveTransfersListWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: ${({ theme }) => theme.spacing(2, 0)};
`;

export const AccountsElsewhere = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  max-width: 650px;
  padding-top: 40px;

  div:last-child {
    max-width: 600px;
  }
`;

export const AccountsElsewhereTitle = styled(Typography)`
  font-size: 1.5rem;
`;

export const AccountsElsewhereText = styled(Typography)`
  font-weight: 300;
  color: ${colors.richBlack};
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;
