import { useAutoSaveInvestState } from 'components/feature/autoSaveInvest/AutoSaveInvestContext';
import { InstructionsStep } from 'components/feature/autoSaveInvest/newDeposit/steps/InstructionsStep';
import { PageContainer } from 'components/PageContainer';
import {
  ResponsiveDialog,
  ResponsiveDialogProps,
} from 'components/ResponsiveDialog';
import { WrapperType } from 'generated/graphql';

interface StandingOrderDetailsProps extends ResponsiveDialogProps {
  accountId: string;
  wrapperType: WrapperType;
}

export function StandingOrderDetails({
  accountId,
  wrapperType,
  onClose,
  ...props
}: StandingOrderDetailsProps) {
  const { state } = useAutoSaveInvestState(accountId);
  const recurringTransactionsDeposits = state?.deposits || [];

  return (
    <ResponsiveDialog {...props} onClose={onClose}>
      <PageContainer>
        <InstructionsStep
          accountId={accountId}
          wrapperType={wrapperType}
          deposits={recurringTransactionsDeposits}
          isDialog
        />
      </PageContainer>
    </ResponsiveDialog>
  );
}
