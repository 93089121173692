import { IconButton, useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { BodyOverflow } from 'App.style';
import { HeaderLogo } from 'components/TillitLogo/Logo';
import React, { useEffect, useRef, useState } from 'react';
import { AiOutlineMenu } from 'react-icons/ai';
import { ContextualButton } from './ContextualButton';
import {
  ActionsContainer,
  CloseIcon,
  HeaderContainer,
  HeaderInner,
  HeaderShimContainer,
  LogoContainer,
  MobileNavContainer,
} from './Header.styles';
import { Navigation } from './Navigation';

interface NavCloseIconProps {
  onClick: () => void;
}

function NavCloseIcon({ onClick }: NavCloseIconProps) {
  return (
    <CloseIcon
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <circle cx="28" cy="28" r="28" fill="white" fillOpacity="0.14" />
      <circle cx="28" cy="28" r="27.5" stroke="white" strokeOpacity="0.39" />
      <path
        d="M23 33L33 23M23 23L33 33"
        stroke="white"
        strokeOpacity="0.75"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </CloseIcon>
  );
}

export function Header() {
  const mobNavRef = useRef(null);
  const [navDrawer, setNavDrawer] = useState(false);
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const aboveLaptop = useMediaQuery(theme.breakpoints.up('md'));

  const handleDrawer = (isOpen: boolean) => {
    setNavDrawer(isOpen);
  };

  const handleCloseMobNav = (event: React.MouseEvent<HTMLElement>) => {
    if (event.target === mobNavRef.current) setNavDrawer(false);
  };

  const handleClickCloseMobNav = () => {
    setNavDrawer(false);
  };

  useEffect(() => {
    if (aboveLaptop) setNavDrawer(false);
  }, [aboveLaptop]);

  return (
    <>
      <BodyOverflow overflowHidden={navDrawer} />
      <HeaderContainer className={'app--header'}>
        <HeaderInner>
          <LogoContainer href="/">
            <HeaderLogo
              size={1.2}
              pad={false}
              compact={isExtraSmall}
              tooltip="Back to home"
            />
          </LogoContainer>

          {aboveLaptop && (
            <>
              <Navigation extraClass="header--nav" />
            </>
          )}

          <ActionsContainer>
            <ContextualButton />
          </ActionsContainer>

          {!aboveLaptop && (
            <IconButton
              color="inherit"
              aria-label="Open main navigation"
              onClick={() => handleDrawer(true)}
            >
              <AiOutlineMenu />
            </IconButton>
          )}
        </HeaderInner>
      </HeaderContainer>

      {!aboveLaptop && (
        <MobileNavContainer
          $open={navDrawer}
          ref={mobNavRef}
          onClick={handleCloseMobNav}
        >
          <Navigation extraClass="header--mobileNav" />
          <NavCloseIcon onClick={handleClickCloseMobNav} />
        </MobileNavContainer>
      )}
    </>
  );
}

export function HeaderShim() {
  return (
    <HeaderShimContainer>
      <span></span>
    </HeaderShimContainer>
  );
}
