import { useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { DropDownNav } from 'components/DropDownNav';
import React from 'react';
import { StyledTab, StyledTabs } from './InternalTabs.style';

interface TabItems {
  label: string;
  value: string;
  disabled?: boolean;
}

interface PureInternalTabsProps {
  value: string;
  tabs: Array<TabItems>;
  tabsPerColumn?: number;
  onChange: (event: React.SyntheticEvent, newValue: string) => void;
}

// ts-unused-exports:disable-next-line
export function PureInternalTabs({
  value,
  tabs,
  tabsPerColumn,
  onChange,
}: PureInternalTabsProps) {
  return (
    <StyledTabs
      value={value}
      onChange={onChange}
      textColor="secondary"
      indicatorColor="secondary"
    >
      {tabs.map((tab: TabItems) => (
        <StyledTab
          key={tab.value}
          size={tabsPerColumn}
          disabled={tab.disabled}
          value={tab.value}
          label={tab.label}
        />
      ))}
    </StyledTabs>
  );
}

interface InternalTabsProps {
  tabs: Array<TabItems>;
  value: string;
  tabsPerRow?: number;
  onChange: (event: React.SyntheticEvent, newValue: string) => void;
}

export function InternalTabs({
  tabs,
  tabsPerRow: tabsPerColumn,
  value,
  onChange,
}: InternalTabsProps) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  return isDesktop ? (
    <PureInternalTabs
      tabsPerColumn={tabsPerColumn}
      tabs={tabs}
      value={value}
      onChange={onChange}
    />
  ) : (
    <DropDownNav items={tabs} value={value} onChange={onChange} />
  );
}
