import { WrapperType } from 'generated/graphql';
import { isTypeSource } from 'pages/FundDetails/fundDetailsTypes';
import { FundsLayout } from './NewFundsPageLayout';

interface FundsListProps {
  selectedAccountType: WrapperType;
  selectedAccountId: string;
}

export function FundsListPortfolioBuilderLayout({
  selectedAccountType,
  selectedAccountId,
}: FundsListProps) {
  const source = `portfolio-builder-${selectedAccountType.toLowerCase()}`;
  return (
    <FundsLayout
      selectedAccountType={selectedAccountType}
      selectedAccountId={selectedAccountId}
      source={isTypeSource(source) ? source : undefined}
    />
  );
}
