import { AccountType } from 'generated/graphql';
import { PickAccountType } from '../_shared/PickAccountType';

type OnProceed = (accType: AccountType) => void;

interface ChooseAccountTypePreAuthProps {
  onProceed: OnProceed;
  onPromoCode: () => void;
  onTransfer?: () => void;
  promoCode?: string;
}

export function ChooseAccountTypePreAuth({
  onProceed,
  onTransfer,
  onPromoCode,
  promoCode,
}: ChooseAccountTypePreAuthProps) {
  return (
    <PickAccountType
      onProceed={onProceed}
      onTransfer={onTransfer}
      onPromoCode={onPromoCode}
      promoCode={promoCode}
    />
  );
}
