import { TextNormal } from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import { transparentize } from 'polished';
import { FaCheck, FaHourglassHalf, FaTimes } from 'react-icons/fa';
import { MdOpenInNew } from 'react-icons/md';
import styled, { css } from 'styled-components';

export const StepSection = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  margin: 1rem 0 0;
  max-width: 1120px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin: 2rem 0;
  }
`;

export const ActionsText = styled(TextNormal)`
  max-width: 640px;
  text-align: center;
`;

export const EditOrderContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 2rem 0 0;
`;

export const WideSection = styled.div`
  width: 100%;
  ${({ theme }) => theme.breakpoints.up('md')} {
    max-width: 1120px;
  }
`;

interface StyledChevronButtonProps {
  $completed?: boolean;
}
export const StyledChevronButton = styled.button<StyledChevronButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  width: 2rem;
  border: 0;
  padding: 0 0.25rem;
  border-radius: 100%;
  justify-self: flex-end;
  background-color: transparent;
  color: ${colors.richBlack};
  cursor: pointer;
  &:hover,
  &:focus {
    background-color: ${colors.grey};
    color: ${colors.richBlack};
    outline: none;
  }
  // for completed order lines
  ${({ $completed }) =>
    $completed &&
    css`
      color: ${transparentize(0.5, colors.richBlack)};
    `}
`;

interface FundNameWrapperProps {
  $noMargin?: boolean;
}

export const FundNameWrapper = styled.div<FundNameWrapperProps>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  ${(p) => (p.$noMargin ? 'margin: 0 0 0 0;' : 'margin: 0 0 2.5rem 0;')}
  text-decoration: none;

  ${({ theme }) => theme.breakpoints.down('md')} {
    grid-column: 1 / 1;
  }
`;

export const CompletedCheck = styled(FaCheck)`
  margin-right: 0.5rem;
  color: ${colors.magenta};
`;
export const CancelledCross = styled(FaTimes)`
  margin-right: 0.5rem;
  color: ${colors.magenta};
`;
export const InProgressHourglass = styled(FaHourglassHalf)`
  margin-right: 0.5rem;
  color: ${colors.magenta};
`;

export const OpenInNew = styled(MdOpenInNew)`
  color: ${transparentize(0.5, colors.magenta)};
  font-size: 0.75rem;
  margin-bottom: -2px;
`;

export const AlertContainer = styled.div`
  margin-bottom: 2rem;

  p {
    color: ${colors.danger};
  }
`;
