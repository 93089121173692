import { StepContent } from 'components/design-system/StepComponents/StepComponents';
import { TextSmall } from 'components/design-system/Text/Text';
import { ReadOnlyInput } from 'components/Input';
import { colors } from 'constants/colors';
import styled from 'styled-components';

export const ConfirmDetailsStepContent = styled(StepContent)`
  max-width: 36rem;
`;

export const StyledReadOnlyInput = styled(ReadOnlyInput)`
  &.MuiTextField-root {
    label {
      &.Mui-disabled {
        font-size: 0.85rem;
        color: ${colors['grey-800']};
      }
    }
    .MuiInput-root {
      &.Mui-disabled {
        color: ${colors.richBlack};
        input {
          font-size: 1rem;
          font-weight: 400;
        }
        &:before {
          border-bottom: 1px solid ${colors['grey-800']};
        }
      }
    }
  }
`;

export const AffirmationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1.5rem;
`;

export const ErrorMessage = styled(TextSmall)`
  color: ${colors.danger};
  margin-left: 2rem;
`;

export const ChangeDetailsButton = styled.button`
  background: none;
  border: none;
  color: ${colors.richBlack};
  padding: 0;
  text-decoration: underline;
  align-self: flex-end;
  font-size: 0.875rem;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
