import { colors } from 'constants/colors';
import styled, { css } from 'styled-components';

export const SearchForm = styled.form`
  width: 100%;
  padding: 0;
`;

interface FilterGridProps {
  $variant: 'portfolio-builder' | 'list' | 'slider';
}

export const FiltersGrid = styled.div<FilterGridProps>`
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr;
  ${(p) => p.theme.breakpoints.up('sm')} {
    grid-template-columns: 1fr 1fr 1fr;
  }
  ${(p) => p.theme.breakpoints.up('md')} {
    grid-template-columns: 1fr;

    ${({ $variant }) =>
      $variant === 'list' &&
      css`
        gap: 6px;
        overflow-y: auto;
        flex-grow: 1;
        padding-bottom: 10px;
      `}
  }
`;

export const FilterActions = styled.div<FilterGridProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-grow: 0;
  flex-shrink: 0;

  ${({ $variant }) =>
    $variant === 'list' &&
    css`
      &:after {
        content: '';
        position: absolute;
        top: -50px;
        width: 100%;
        height: 40px;
        background-color: ${colors.white};
        background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 255, 255, 0) 100%
        );
        pointer-events: none;
      }
    `}
`;

export const VideoAskSpacer = styled.div`
  height: 4rem;
`;
