import { TextNormal } from 'components/design-system/Text/Text';
import { assetClassColors, colors } from 'constants/colors';
import { transparentize } from 'polished';
import React from 'react';
import { MdOpenInNew } from 'react-icons/md';
import styled, { css } from 'styled-components';

interface AmountInputContainerProps {
  $error?: boolean;
  $readOnly?: boolean;
}

const AmountInputContainer = styled.div<AmountInputContainerProps>`
  border-radius: 0.25rem;
  display: flex;
  padding: 0.25rem 0.5rem;
  border: ${({ $error }) =>
    $error ? `1px solid ${colors.danger}` : '1px solid #ccc'};
  opacity: ${({ $readOnly }) => ($readOnly ? 0.5 : 1)};
  transition: opacity 0.25s ease-in-out;
  background-color: ${colors.white};
`;

const AmountInputInput = styled.input`
  border: none;
  outline: none;
  width: 100%;
`;

export const AmountInput = React.forwardRef<HTMLInputElement, any>(
  ({ $error, ...props }, ref) => (
    <AmountInputContainer $error={$error} $readOnly={props.readOnly}>
      £
      <AmountInputInput ref={ref} {...props} />
    </AmountInputContainer>
  )
);

export const FundBasketOrderLayout = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0.75rem 1.25rem;
`;

export const NameContainer = styled.div`
  grid-area: 1 / 1 / 3 / 6;
  a {
    text-decoration: none;
  }
`;

export const DetailsContainer = styled.div`
  grid-area: 2 / 6 / 3 / 7;
  display: flex;
  justify-content: flex-start;
  gap: 1.5rem;

  form {
    margin-left: auto;
    width: 100px;
    input {
      text-align: right;
    }
  }
`;

export const RemoveContainer = styled.div`
  grid-area: 1 / 6 / 2 / 7;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
`;

export const ErrorWrapper = styled.div`
  text-align: right;
  padding-top: 0.5rem;
  p {
    color: ${colors.danger};
  }
`;

interface MobileOrderProps {
  $isDarkUniverse?: boolean;
}
export const MobileOrder = styled.div<MobileOrderProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem 0;
  background-color: ${({ $isDarkUniverse }) =>
    $isDarkUniverse ? colors.richBlack : 'transparent'};
  border-bottom: 1px solid ${colors.midGrey};

  // The styling bellow ensures that the dark universe cards have a full width black background on mobile;
  ${({ $isDarkUniverse }) =>
    $isDarkUniverse &&
    css`
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        right: -100%;
        bottom: 0;
        background: ${colors.richBlack};
        z-index: -1;
      }
    `};
`;

interface MobileOrderNameRowProps {
  $isDarkUniverse?: boolean;
}

export const MobileOrderNameRow = styled.div<MobileOrderNameRowProps>`
  display: grid;
  grid-template-columns: 1fr 30px;
  align-items: center;

  a {
    color: ${({ $isDarkUniverse }) =>
      $isDarkUniverse ? colors.white : colors.richBlack};
    text-decoration: none;
  }
`;

interface OpenInNewProps {
  $isDarkUniverse?: boolean;
}

export const OpenInNew = styled(MdOpenInNew)<OpenInNewProps>`
  color: ${({ $isDarkUniverse }) =>
    transparentize(0.5, $isDarkUniverse ? colors.white : colors.magenta)};
  font-size: 0.75rem;
  margin-bottom: -2px;
`;

OpenInNew.defaultProps = {
  $isDarkUniverse: false,
};

interface MobileOrderNameProps {
  $isDarkUniverse?: boolean;
}

export const MobileOrderName = styled(TextNormal)<MobileOrderNameProps>`
  margin: 0;
  font-weight: 400;
  color: ${({ $isDarkUniverse }) =>
    $isDarkUniverse ? colors.white : colors.richBlack};
`;

MobileOrderName.defaultProps = {
  $isDarkUniverse: false,
};

type assetClasses = keyof typeof assetClassColors;

export const MobileOrderDetailsRow = styled.div`
  display: grid;
  grid-template-areas: 'pill . input';
  grid-template-columns: auto 2fr 1.5fr;
  align-items: center;
`;

interface AssetClassPillProps {
  $assetClass: assetClasses;
}
export const AssetClassPill = styled.div<AssetClassPillProps>`
  grid-area: pill;
  background-color: ${({ $assetClass }) =>
    transparentize(0.4, assetClassColors[$assetClass])};
  border-radius: 1.5rem;
  display: flex;
  align-items: center;
  justify-self: flex-start;
  padding: 0.15rem 1rem;
  p {
    color: white;
    margin: 0;
    font-size: 0.8rem;
    font-weight: 400;
  }
`;

export const MobileOrderInputWrapper = styled.div`
  grid-area: input;
  input {
    text-align: right;
  }
`;
