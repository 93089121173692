import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import { Card } from 'components/design-system/Card/Card';
import { H6, TextAlign } from 'components/design-system/Heading/Heading';
import {
  GoBackButton,
  StepActions,
  StepContainer,
  StepIntroduction,
  StepIntroductionTypography,
  StepIntroductionWidth,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import { Text } from 'components/design-system/Text/Text';
import styled from 'styled-components';

const CardButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  max-width: 45rem;
  margin-top: 1.5rem;
`;

const CardButton = styled(Card)`
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  justify-items: center;
  text-align: center;
`;

const Divider = styled.hr`
  width: 1.5rem;
  border-color: rgba(215, 22, 235, 0.25);
  border-width: 0 0 1px 0;
`;

interface InvestOrHoldAsCashStepProps {
  onProceed: (choice: 'holdAsCash' | 'autoInvest') => void;
  onGoBack: () => void;
}

export function InvestOrHoldAsCashStep({
  onProceed,
  onGoBack,
}: InvestOrHoldAsCashStepProps) {
  return (
    <StepContainer>
      <StepTitle>Invest or hold as cash?</StepTitle>
      <StepIntroduction $width={StepIntroductionWidth.extraWide}>
        <StepIntroductionTypography>
          What would you like to do with this deposit?
        </StepIntroductionTypography>
      </StepIntroduction>

      <CardButtonWrapper>
        <CardButton>
          <H6 $noMargin>Auto invest</H6>
          <Divider />
          <div>
            <Text $textAlign={TextAlign.center}>
              Pick one or more funds you would like (all or part of) your
              regular deposit to be invested into.
            </Text>
          </div>
          <CustomButtonV2
            onClick={() => {
              onProceed('autoInvest');
            }}
          >
            Choose funds
          </CustomButtonV2>
        </CardButton>

        <CardButton>
          <H6 $noMargin>Hold as cash</H6>
          <Divider />
          <div>
            <Text $textAlign={TextAlign.center}>
              Leave your deposit as cash in your account for you to invest when
              you are ready.
            </Text>
          </div>
          <CustomButtonV2
            onClick={() => {
              onProceed('holdAsCash');
            }}
          >
            Hold as cash
          </CustomButtonV2>
        </CardButton>
      </CardButtonWrapper>
      <StepActions>
        <GoBackButton
          onClick={() => {
            onGoBack();
          }}
        />
      </StepActions>
    </StepContainer>
  );
}
