import { useMediaQuery, useTheme } from '@material-ui/core';
import { TextSmall, TextXS } from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import { useAuth } from 'context/AuthContext';
import { currencyFull } from 'formatting';
import {
  useAutoSaveInvestDraftStateQuery,
  useUserProfileQuery,
} from 'generated/graphql';
import {
  getPathSegmentForWrapperType,
  getShortNameForWrapperType,
} from 'helpers/accountHelpers';
import {
  generateAutoSaveInvestPathPath,
  generateDynamicPortfolioConstructionBasketPath,
} from 'paths';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import type {
  PortfolioRebalancingsSummaryQueryRebalancingsSummary,
  UserProfileQueryClientSummaryAccount,
} from 'types/graphqlTypes';
import { useMode } from '../useMode';

interface ResumeBasketAutoSaveInvestProps {
  account: UserProfileQueryClientSummaryAccount;
  variant?: 'footer' | 'popover';
  isDarkBg: boolean;
}

const ResumeBasketAutoSaveInvest = ({
  account,
  variant,
  isDarkBg,
}: ResumeBasketAutoSaveInvestProps) => {
  const [, setMode] = useMode();
  const autoSaveInvestDraftStateQuery = useAutoSaveInvestDraftStateQuery({
    accountId: account.id,
  });
  const orderTotal =
    autoSaveInvestDraftStateQuery.data?.autoSaveInvestDraft?.deposits.reduce(
      (total, deposit) => deposit.amount + total,
      0
    ) || 0;

  return (
    <BasketDetails
      onClick={() => {
        setMode({
          mode: 'autoSaveInvest',
          wrapperType: account.wrapperType,
        });
      }}
      $variant={variant}
    >
      <TextSmall $noMargin $isDarkBg={isDarkBg}>
        <strong>{account.wrapperType}</strong> - Regular order
      </TextSmall>
      <TextXS $noMargin $isDarkBg={isDarkBg}>
        Order total {currencyFull(orderTotal)}{' '}
        <Link
          onClick={() => {
            setMode({
              mode: 'autoSaveInvest',
              wrapperType: account.wrapperType!,
            });
          }}
          to={generateAutoSaveInvestPathPath({
            wrapperType: getPathSegmentForWrapperType(account.wrapperType!),
          })}
        >
          View
        </Link>
      </TextXS>
    </BasketDetails>
  );
};

const horizontalLayout = css`
  border-top: none;
  border-left: solid 1px;
  border-left-color: ${colors['purple-100']};
  padding-top: 0;
  padding-left: 1.5rem;
  margin-top: 0;
  margin-left: 1.5rem;
`;

interface BasketDetailsProps {
  $variant?: 'footer' | 'popover';
}

const BasketDetails = styled.div<BasketDetailsProps>`
  border-top: solid 1px ${colors['purple-100']};
  padding-top: 0.25rem;
  margin-top: 0.25rem;

  ${({ theme }) => theme.breakpoints.up('md')} {
    ${({ $variant }) => $variant === 'footer' && horizontalLayout}
  }

  a {
    color: ${colors['purple-900']};
    text-decoration: underline;
  }
`;

interface ResumeBasketsListProps {
  draftPortfolioRebalancings?: PortfolioRebalancingsSummaryQueryRebalancingsSummary[];
  variant: 'footer' | 'popover';
  checkoutAccount?: UserProfileQueryClientSummaryAccount;
}

export const ResumeBasketsList = ({
  draftPortfolioRebalancings,
  variant,
  checkoutAccount,
}: ResumeBasketsListProps) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const [, setMode] = useMode();
  const { signedIn } = useAuth();
  const userProfileQuery = useUserProfileQuery(undefined, {
    enabled: signedIn,
  });

  const isDarkBg = variant === 'popover' && isMdUp;

  return (
    <>
      {draftPortfolioRebalancings &&
        draftPortfolioRebalancings.map((portfolioRebalancing) => {
          const buyOrderTotal = portfolioRebalancing.buyOrders.reduce(
            (acc, buyOrder) => {
              return acc + buyOrder.amount;
            },
            0
          );
          const sellOrderTotal = portfolioRebalancing.sellOrders.reduce(
            (acc, sellOrder) => {
              return acc + sellOrder.enteredAmount;
            },
            0
          );
          const totalOrder = buyOrderTotal - sellOrderTotal;

          const account = userProfileQuery.data?.clientSummary?.accounts.find(
            (account) => {
              return account.id === portfolioRebalancing.accountId;
            }
          );

          return (
            <BasketDetails
              onClick={() => {
                setMode({ mode: 'buy', wrapperType: account?.wrapperType });
              }}
              $variant={variant}
            >
              <TextSmall $noMargin $isDarkBg={isDarkBg}>
                <strong>
                  {getShortNameForWrapperType(account?.wrapperType!)}
                </strong>{' '}
                - One off order
              </TextSmall>
              <TextXS $noMargin $isDarkBg={isDarkBg}>
                Order total {currencyFull(totalOrder)}{' '}
                <Link
                  onClick={() => {
                    setMode({
                      mode: 'buy',
                      wrapperType: account?.wrapperType!,
                    });
                  }}
                  to={generateDynamicPortfolioConstructionBasketPath({
                    wrapperType: getPathSegmentForWrapperType(
                      account?.wrapperType!
                    ),
                  })}
                >
                  View
                </Link>
              </TextXS>
            </BasketDetails>
          );
        })}

      {userProfileQuery.data?.clientSummary?.accounts
        .filter(
          (account) =>
            account.hasAutoSaveInvestDraft &&
            (!checkoutAccount || checkoutAccount.id !== account.id)
        )
        ?.map((account) => {
          return (
            <ResumeBasketAutoSaveInvest
              account={account}
              isDarkBg={isDarkBg}
              variant={variant}
            />
          );
        })}
    </>
  );
};
