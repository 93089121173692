import { useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import { DropDownNav } from 'components/DropDownNav';
import { ItemProps } from 'components/DropDownNav/DropDownNav';
import { GaEventNames } from 'constants/gaConstants';
import { trackGa } from 'helpers/track';
import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  NavContainer,
  NavListItem,
  NavListItemCta,
  NavSeparator,
} from './DashboardNav.styles';

export interface NavItem {
  label: string;
  path: string;
  navType?: string;
}

interface DashboardNavProps {
  items: Array<NavItem>;
}

const trackNavClicks = (value: string) => {
  trackGa({
    event: GaEventNames.selectContent,
    content_type: 'dashboard navigation',
    item_id: value,
  });
};

export const DashboardNav = React.forwardRef(
  ({ items }: DashboardNavProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const history = useHistory();
    const location = useLocation();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    const handleClick = (item: NavItem) => {
      trackNavClicks(item.label);
      history.push(item.path);
    };

    const ctaItems = items.filter(({ navType }) => navType === 'cta');

    return (
      <div ref={ref} data-testid="dashboard-nav">
        {isDesktop ? (
          <NavContainer>
            {ctaItems.map((item) => (
              <NavListItemCta
                key={item.path}
                className={clsx({
                  active: location.pathname === item.path,
                })}
                onClick={() => {
                  handleClick(item);
                }}
              >
                {item.label}
              </NavListItemCta>
            ))}
            {ctaItems.length > 0 && <NavSeparator />}
            {items
              .filter(({ navType }) => navType !== 'cta')
              .map((item) => (
                <NavListItem
                  key={item.path}
                  className={clsx({
                    active: location.pathname === item.path,
                  })}
                  onClick={() => {
                    handleClick(item);
                  }}
                >
                  {item.label}
                </NavListItem>
              ))}
          </NavContainer>
        ) : (
          <MobileDashboardNav items={items} />
        )}
      </div>
    );
  }
);

interface MobileDashboardNavProps {
  items: Array<NavItem>;
}

function MobileDashboardNav({ items }: MobileDashboardNavProps) {
  const history = useHistory();
  const location = useLocation();

  const handleClick = (_: React.SyntheticEvent, value: string) => {
    trackNavClicks(value);
    history.push(value);
  };

  const dropdownItems: Array<ItemProps> = useMemo(() => {
    return items.map((i) => ({
      label: i.label,
      value: i.path,
    }));
  }, [items]);

  return (
    <DropDownNav
      items={dropdownItems}
      value={location.pathname}
      onChange={handleClick}
    />
  );
}
