import ClosedCardBackground from 'assets/backgrounds/Tillit_trust_background.jpg';
import CardBackground from 'assets/card_background.png';
import clsx from 'clsx';
import { Container } from 'components/Container/Container';
import { SecondAccountDialog } from 'components/Dialogs/SecondAccount/SecondAccountDialog';
import { WithdrawDialog } from 'components/Dialogs/Withdraw/WithdrawDialog';
import { InfoPopoverV2 } from 'components/design-system/InfoPopoverV2/InfoPopoverV2';
import { GaEventNames } from 'constants/gaConstants';
import { IntercomEventNames } from 'constants/intercomConstants';
import * as format from 'formatting';
import {
  AccountStatus,
  AccountType,
  AccountsQuery,
  Eligibility,
  WrapperType,
  useAccountEligibilitiesQuery,
  useUserProfileQuery,
} from 'generated/graphql';
import {
  getNameForWrapperType,
  getPathSegmentForWrapperType,
} from 'helpers/accountHelpers';
import { trackGa } from 'helpers/track';
import { useIsAccountClosed } from 'hooks/useAccountStatus';
import { useToggle } from 'hooks/useFeatureToggle';
import { useOpenAccount } from 'hooks/useOpenAccount';
import { useModal } from 'mui-modal-provider';
import { generateDynamicPortfolioConstructionPath } from 'paths';
import React, { useMemo } from 'react';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import {
  AccountLevelGrowthChangesExplained,
  AccountLevelGrowthExplained,
} from 'strings/tooltips';
import { Title } from '../../Cards.styles';
import { OpenAction, SupportAction, UnOpenAction } from './AccountActions';
import {
  ContentContainer,
  GrowthLabel,
  GrowthPopover,
  Label,
  StyledButtonsWrapper,
  TotalValue,
  Value,
  ValueContainer,
  WhyHasThisChangedTriggerWrapper,
} from './AccountCard.style';
import { PureAccountClosedCard } from './AccountClosedCard';
import { PureClosingAccountCard } from './AccountClosingCard';
import {
  CannotOpenSippAge,
  CannotOpenSippOther,
  PendingAccountContent,
  UnOpenedGiaContent,
  UnOpenedIsaContent,
  UnOpenedSippContent,
} from './AccountContent';
import useIsSippAgeRestricted from './hooks/useIsSippAgeRestricted';

/*
 * Different states described in:
 * https://docs.google.com/spreadsheets/d/1UXsJDFNijHumcxgj7X1BbOj9ecp5wX3vKgZl-zRqCFQ
 */

export type AccountData = Exclude<
  AccountsQuery['accounts'],
  undefined | null
>[number]['valuationSummary'];

interface CardTitleProps {
  accountType: WrapperType;
  className?: string;
}

export function CardTitle({ accountType, className }: CardTitleProps) {
  const title = getNameForWrapperType(accountType);
  return <Title className={className}>{title}</Title>;
}

interface PureAccountCardProps {
  accountId: string | undefined | null;
  accountType: WrapperType;
  eligibility?: Eligibility;
  accountData?: AccountData;
  transferActive?: boolean;
  regularInvestmentActive?: boolean;
  rebalancingActive?: boolean;
  suppressTimeWeightedGrowthPercentages: boolean;
  showTimeWeightingWhyChangedPrompt: boolean;
  onRegularInvestment?: () => void;
  onTransferAccount?: () => void;
  onFinishSetup: () => void;
  onOpenAccount: () => void;
  onRebalancing?: () => void;
  onSupport: () => void;
  onCannotOpen: () => void;
}

// ts-unused-exports:disable-next-line
export function PureAccountCard({
  accountId,
  accountType,
  eligibility,
  accountData,
  transferActive = false,
  regularInvestmentActive = false,
  suppressTimeWeightedGrowthPercentages,
  showTimeWeightingWhyChangedPrompt,
  onRegularInvestment,
  onOpenAccount,
  onFinishSetup,
  onTransferAccount,
  onRebalancing,
  onSupport,
  onCannotOpen,
}: PureAccountCardProps) {
  const isSippAgeRestricted = useIsSippAgeRestricted({
    canOpenSipp: eligibility === Eligibility.CannotOpen ? false : true,
  });

  return (
    <>
      <CardTitle accountType={accountType} />

      {/*Account is open*/}
      {accountData ? (
        <>
          <ContentContainer vAlign="center" imgUrl={CardBackground}>
            <ValueContainer>
              <TotalValue>
                {format.currencyFull(accountData?.marketValue ?? 0)}
              </TotalValue>
              <Label>Current value</Label>
            </ValueContainer>
            {suppressTimeWeightedGrowthPercentages ? (
              <ValueContainer>
                <Value size="small">
                  {format.currencyFull(accountData?.growthValue ?? 0)}
                </Value>
                <Label size="small">
                  <GrowthPopover>
                    <InfoPopoverV2
                      className="label-popover"
                      size="small"
                      $width="wide"
                      placement={'top-end'}
                      label={
                        <GrowthLabel>
                          Growth <HiOutlineInformationCircle />
                        </GrowthLabel>
                      }
                      content={AccountLevelGrowthExplained(
                        accountData?.growthProportion
                      )}
                    />
                  </GrowthPopover>
                  {showTimeWeightingWhyChangedPrompt && (
                    <WhyHasThisChangedTriggerWrapper>
                      <InfoPopoverV2
                        $width="wide"
                        placement={'top'}
                        content={AccountLevelGrowthChangesExplained}
                        label="Why has this changed?"
                      />
                    </WhyHasThisChangedTriggerWrapper>
                  )}
                </Label>
              </ValueContainer>
            ) : (
              <ValueContainer>
                <Value size="small">
                  {format.percent(accountData?.growthProportion ?? 0)}
                </Value>
                <Label size="small">Total return</Label>
              </ValueContainer>
            )}
            <ValueContainer>
              <Value size="small">
                {format.currencyFull(accountData?.uninvestedCash ?? 0)}
              </Value>
              <Label size="small">Available cash</Label>
            </ValueContainer>
          </ContentContainer>
          <StyledButtonsWrapper>
            <OpenAction
              accountId={accountId}
              accountType={accountType}
              transferActive={transferActive}
              regularInvestmentActive={regularInvestmentActive}
              onTransferAccount={onTransferAccount}
              onRegularInvestment={onRegularInvestment}
              onRebalancing={onRebalancing}
            />
          </StyledButtonsWrapper>
        </>
      ) : (
        <>
          {eligibility === Eligibility.CanOpen && (
            <>
              <ContentContainer vAlign="center" imgUrl={CardBackground}>
                {accountType === WrapperType.Gia && <UnOpenedGiaContent />}
                {accountType === WrapperType.Isa && <UnOpenedIsaContent />}
                {accountType === WrapperType.Sipp && <UnOpenedSippContent />}
              </ContentContainer>
              <StyledButtonsWrapper>
                <UnOpenAction
                  accountType={accountType}
                  canBeCreated={true}
                  transferActive={transferActive}
                  regularInvestmentActive={regularInvestmentActive}
                  onOpenAccount={onOpenAccount}
                  onTransferAccount={onTransferAccount}
                  onRegularInvestment={onRegularInvestment}
                />
              </StyledButtonsWrapper>
            </>
          )}
          {eligibility === Eligibility.Onboarding && (
            <>
              <ContentContainer vAlign="center" imgUrl={CardBackground}>
                {accountType === WrapperType.Gia && <UnOpenedGiaContent />}
                {accountType === WrapperType.Isa && <UnOpenedIsaContent />}
                {accountType === WrapperType.Sipp && <UnOpenedSippContent />}
              </ContentContainer>
              <StyledButtonsWrapper>
                <UnOpenAction
                  accountType={accountType}
                  isOnboarding={true}
                  transferActive={transferActive}
                  regularInvestmentActive={regularInvestmentActive}
                  onFinishSetup={onFinishSetup}
                  onTransferAccount={onTransferAccount}
                  onRegularInvestment={onRegularInvestment}
                />
              </StyledButtonsWrapper>
            </>
          )}
          {eligibility === Eligibility.Pending && (
            <>
              <ContentContainer vAlign="center" imgUrl={CardBackground}>
                <PendingAccountContent />
              </ContentContainer>
              <StyledButtonsWrapper>
                <SupportAction onSupport={onSupport} />
              </StyledButtonsWrapper>
            </>
          )}
          {eligibility === Eligibility.CannotOpen && (
            <>
              <ContentContainer vAlign="center" imgUrl={CardBackground}>
                {accountType === WrapperType.Gia && <UnOpenedGiaContent />}
                {accountType === WrapperType.Isa && <UnOpenedIsaContent />}
                {accountType === WrapperType.Sipp && !isSippAgeRestricted && (
                  <CannotOpenSippOther />
                )}
                {accountType === WrapperType.Sipp && isSippAgeRestricted && (
                  <CannotOpenSippAge />
                )}
              </ContentContainer>
              <StyledButtonsWrapper>
                <UnOpenAction
                  accountType={accountType}
                  transferActive={transferActive}
                  regularInvestmentActive={regularInvestmentActive}
                  canBeCreated={false}
                  onTransferAccount={onTransferAccount}
                  onRegularInvestment={onRegularInvestment}
                  onSupport={onCannotOpen}
                />
              </StyledButtonsWrapper>
            </>
          )}
        </>
      )}
    </>
  );
}

interface AccountCardProps {
  accountType: WrapperType;
  pathName?: string;
  className?: string;
  dataTestid?: string;
  onClick?: () => void;
}

export function AccountCard({
  accountType,
  className,
  pathName,
  onClick,
  dataTestid,
}: AccountCardProps) {
  const history = useHistory();
  const userProfileQuery = useUserProfileQuery();
  const { openAccount } = useOpenAccount();

  const { showNewMessages, trackEvent } = useIntercom();
  const { showModal } = useModal({ disableAutoDestroy: true });

  const [suppressTimeWeightedGrowthPercentagesToggle] = useToggle(
    'global-suppress-time-weighted-growth-percentages'
  );

  // Use the 9th of November (lol Javascript with your 0-indexed months you clown you fool)
  //
  const showTimeWeightingWhyChangedPromptIfAccountsOlderThan = new Date(
    2024,
    10,
    9
  );

  const showTimeWeightingWhyChangedPrompt =
    userProfileQuery?.data?.clientSummary?.accounts?.some(
      (x) =>
        new Date(x.createdTimestampUtc) <
        showTimeWeightingWhyChangedPromptIfAccountsOlderThan
    ) ?? false;

  const currentAccount = userProfileQuery?.data?.clientSummary?.accounts.find(
    (acc) => acc.wrapperType === accountType
  );

  const hasAccount = !!userProfileQuery?.data?.clientSummary?.accounts?.length;
  const isAccountClosed = useIsAccountClosed(accountType);
  const cardBackground = isAccountClosed ? ClosedCardBackground : '';

  const accountEligibilitiesQuery = useAccountEligibilitiesQuery(undefined, {
    enabled: !userProfileQuery.isFetching && !currentAccount,
  });

  const currentAccountEligibility = useMemo(
    () =>
      accountEligibilitiesQuery.data?.accountEligibilities?.find(
        (acc) => acc!.wrapperType === accountType
      )?.eligibility,
    [accountEligibilitiesQuery.data?.accountEligibilities, accountType]
  );

  const showRegularInvestmentButton = useMemo(() => {
    return currentAccount?.valuationSummary ||
      currentAccountEligibility === Eligibility.CanOpen
      ? true
      : false;
  }, [currentAccount?.valuationSummary, currentAccountEligibility]);

  const handleOpenAccount = async () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'dashboard',
      item_id: `open account button clicked - ${accountType}`,
    });
    // Check if user has an account open and if so, open intercom other wise resume/open kyc
    if (hasAccount && accountType !== WrapperType.Sipp) {
      showModal(SecondAccountDialog, {
        accountType,
      });
    } else {
      // If opening a GIA set the initial account type. (For ISAs we set this after the ISA Declaration).
      if (accountType === WrapperType.Gia) {
        await openAccount(AccountType.Gia, 'dashboard card');
      }
      history.push(
        `/open-account/${getPathSegmentForWrapperType(accountType)}`
      );
    }
  };

  const handleFinishSetup = () => {
    trackGa({
      event: GaEventNames.onboardingResume,
      content_type: 'dashboard',
      item_id: `finish setup button clicked - ${accountType}`,
    });
    history.push(`/open-account/${getPathSegmentForWrapperType(accountType)}`);
  };

  const handleTransferAccount = () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'dashboard',
      item_id: `transfer account button clicked - ${accountType}`,
    });
  };

  const handleRegularInvestment = () => {
    trackEvent(IntercomEventNames.regularInvestmentClicked);
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'talk to support',
      item_id: `${accountType} card - regular deposit button clicked`,
    });
    showNewMessages("I'd like to set up a regular deposit");
  };

  const handleSupport = () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'talk to support',
      item_id: `${accountType} card  - pending account button clicked`,
    });
    showNewMessages('Can I get an update on my pending account please?');
  };

  const handleCannotOpenSupport = () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'talk to support',
      item_id: `${accountType} card  - cannot open account support`,
    });
    showNewMessages(
      `I need help understanding why I cannot open a ${getNameForWrapperType(
        accountType
      )} account`
    );
  };

  const handleClosingSupport = () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'talk to support',
      item_id: `${accountType} card - closing account`,
    });
    showNewMessages();
  };

  const handleWithdraw = () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'dashboard',
      item_id: `withdraw button clicked - ${accountType}`,
    });
    showModal(WithdrawDialog, {
      accountId: currentAccount?.id!,
    });
  };

  const handleReOpenAccount = () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'talk to support',
      item_id: `${accountType} card - re-open account clicked`,
    });
    showNewMessages(`I would like to re-open my ${accountType}.`);
  };

  const handleRebalancingAccount = () => {
    const url = generateDynamicPortfolioConstructionPath({
      wrapperType: getPathSegmentForWrapperType(accountType),
    });
    trackGa({
      event: GaEventNames.click,
      outbound: 'false',
      link_url: url,
      link_text: `Rebalancing ${accountType} account`,
    });
    history.push(url);
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (pathName) {
      let target = event.target as HTMLElement;

      while (
        target !== null &&
        target.nodeName !== 'BUTTON' &&
        target.nodeName !== 'A' &&
        target !== event.currentTarget
      ) {
        target = target.parentElement!;
      }

      if (target === event.currentTarget) {
        trackGa({
          event: GaEventNames.selectContent,
          content_type: 'dashboard',
          item_id: `${accountType} - carousel card clicked`,
        });
        history.push(pathName);
        onClick?.();
      }
    }
  };

  let cardContent: React.ReactNode = null;

  switch (currentAccount?.status) {
    case AccountStatus.Closed:
      cardContent = (
        <PureAccountClosedCard
          accountType={accountType}
          accountData={currentAccount?.valuationSummary}
          onWithdraw={handleWithdraw}
          onReOpen={handleReOpenAccount}
        />
      );
      break;
    case AccountStatus.Closing:
      cardContent = (
        <PureClosingAccountCard
          accountType={accountType}
          accountData={currentAccount?.valuationSummary}
          onWithdraw={handleWithdraw}
          onSupport={handleClosingSupport}
        />
      );
      break;
    default:
      cardContent = (
        <PureAccountCard
          accountId={currentAccount?.id}
          accountType={accountType}
          eligibility={currentAccountEligibility}
          accountData={currentAccount?.valuationSummary}
          suppressTimeWeightedGrowthPercentages={
            suppressTimeWeightedGrowthPercentagesToggle?.enabled ?? false
          }
          showTimeWeightingWhyChangedPrompt={showTimeWeightingWhyChangedPrompt}
          regularInvestmentActive={showRegularInvestmentButton}
          onOpenAccount={handleOpenAccount}
          onFinishSetup={handleFinishSetup}
          onTransferAccount={handleTransferAccount}
          onRegularInvestment={handleRegularInvestment}
          onSupport={handleSupport}
          onCannotOpen={handleCannotOpenSupport}
          onRebalancing={handleRebalancingAccount}
        />
      );
  }

  return (
    <Container
      padding="medium"
      $elevation={1}
      className={clsx(className, isAccountClosed && 'closed-account')}
      onClick={handleClick}
      background={cardBackground}
      data-testid={dataTestid}
    >
      {cardContent}
    </Container>
  );
}
