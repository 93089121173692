import { OrderNote } from 'components/OrderNote';
import { InfoPopover } from 'components/Popover';
import { QueryState } from 'components/QueryState';
import {
  ColumnDefinition,
  ResponsiveTable,
} from 'components/Tables/ResponsiveTable/ResponsiveTable';
import { GaEventNames } from 'constants/gaConstants';
import * as format from 'formatting';
import {
  OrderDetailsQuery,
  OrderDirection,
  OrderStatus,
  useOrdersByPositionQuery,
} from 'generated/graphql';
import { trackGa } from 'helpers/track';
import React, { useCallback, useMemo } from 'react';
import { HiDownload } from 'react-icons/hi';
import * as tooltips from 'strings/tooltips';
import { Download, Pill } from './HoldingOrdersTable.style';

type Note = Exclude<
  OrderDetailsQuery['clientSummary'],
  null | undefined
>['orders'][number];

interface PureHoldingOrdersTableProps {
  data: Array<Note>;
}

// ts-unused-exports:disable-next-line
export function PureHoldingOrdersTable({ data }: PureHoldingOrdersTableProps) {
  const handleClick = (title: string) => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'dashboard - holding orders table',
      item_id: `download button clicked - ${title}`,
    });
  };

  const columns = useMemo((): ColumnDefinition<Note>[] => {
    return [
      {
        name: '',
        key: 'contractNoteUrl',
        width: '24px',
        renderValue: (value: string, data: Note) =>
          data.status === OrderStatus.Completed ? (
            <Download
              {...{
                component: 'a',
                target: '_blank',
                href: value,
              }}
              size="small"
              onClick={() => handleClick(data.instrument?.name || 'unknown')}
            >
              <HiDownload />
            </Download>
          ) : (
            <InfoPopover
              color="primary"
              content={tooltips.OrderPending}
              size="small"
              disablePadding={false}
              disableMargin
            />
          ),
      },
      {
        name: 'Order details',
        key: 'instrument.name',
        align: 'left',
      },
      {
        name: 'Order type',
        key: 'direction',
        align: 'center',
        width: '120px',
        renderValue: (direction: OrderDirection) => (
          <Pill direction={direction}>{direction}</Pill>
        ),
      },
      {
        name: 'Amount',
        key: 'amount',
        align: 'right',
        width: '140px',
        renderValue: (amount: string) =>
          amount ? format.currencyFull(amount) : '',
      },
      {
        name: 'Date',
        key: 'settlementDate',
        align: 'right',
        width: '140px',
        renderValue: (date: string) => (date ? format.date(date) : ''),
      },
    ];
  }, []);

  const mobileComponent = useCallback(
    (row: Note) => (
      <OrderNote
        downloadURL={row?.contractNoteUrl ?? undefined}
        status={row.status}
        value={row?.amount ?? undefined}
        instrumentName={row.instrument?.name || ''}
        direction={row.direction}
        date={row.settlementDate}
      />
    ),
    []
  );

  return (
    <ResponsiveTable
      columns={columns}
      data={data}
      noDataMessage="You have no orders."
      renderMobile={mobileComponent}
    />
  );
}

interface HoldingOrdersTableProps {
  accountId: string;
  isin: string;
}

export function HoldingOrdersTable({
  accountId,
  isin,
}: HoldingOrdersTableProps) {
  const ordersQuery = useOrdersByPositionQuery({ accountId, isin });

  return (
    <QueryState {...ordersQuery}>
      {() => (
        <PureHoldingOrdersTable
          data={ordersQuery?.data?.ordersByPosition ?? []}
        />
      )}
    </QueryState>
  );
}
