import React from 'react';
import { Circle, Container, ContainerAbsolute, Spinner } from './Loading.style';

type LoadingProps = {
  color?: string;
  inline?: boolean;
  absolute?: boolean;
  dataTestid?: string;
};

export function Loading({ color, inline, absolute, dataTestid }: LoadingProps) {
  let Wrapper: any = Container;
  if (inline) {
    Wrapper = React.Fragment;
  }
  if (absolute) {
    Wrapper = ContainerAbsolute;
  }
  return (
    <Wrapper>
      <Spinner
        role="progressbar"
        aria-label="Loading"
        aria-live="polite"
        data-testid={dataTestid}
      >
        <Circle color={color} />
        <Circle color={color} />
        <Circle color={color} />
      </Spinner>
    </Wrapper>
  );
}
