import { Dialog, DialogProps } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import { GaEventNames } from 'constants/gaConstants';
import { IntercomContext } from 'context/Intercom';
import { trackGa } from 'helpers/track';
import { useContext } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import {
  ActionsContainer,
  CgiBackground,
  CgiContent,
  CgiInner,
  CloseButton,
  ContentContainer,
  ContentWrapper,
  InnerDialog,
  SubSection,
  SubTitle,
  TitleContainer,
} from './Style/LandingMenuDialog.style';

interface LandingMenuDialogProps extends DialogProps {
  title: string;
  url: string;
  body: JSX.Element;
  onClose?: () => void;
}

export function LandingMenuDialog({
  onClose,
  title,
  body,
  url,
  ...props
}: LandingMenuDialogProps) {
  const trackViewFund = () => {
    trackGa({
      event: GaEventNames.selectContent,
      item_id: 'landing page hexagon',
      content_type: `view fund button clicked - ${title}`,
    });
  };
  const IntercomCtx = useContext(IntercomContext);

  return (
    <Dialog
      {...props}
      onClose={onClose}
      aria-labelledby={title}
      fullWidth
      disableEnforceFocus={IntercomCtx?.open}
      maxWidth={'sm'}
    >
      <InnerDialog>
        <CloseButton>
          <IconButton aria-label="close" onClick={() => onClose?.()}>
            <AiOutlineClose />
          </IconButton>
        </CloseButton>
        <TitleContainer id="alert-dialog-slide-title">{title}</TitleContainer>
        <ContentContainer>{body}</ContentContainer>
        <ActionsContainer>
          <CustomButtonV2
            {...{
              component: Link,
              to: url,
            }}
            onClick={trackViewFund}
          >
            View funds
          </CustomButtonV2>
        </ActionsContainer>
      </InnerDialog>
    </Dialog>
  );
}

interface CgiDataProps {
  subtitle: string;
  content: JSX.Element;
}
interface CgiDialogProps extends DialogProps {
  title: string;
  background: string | null;
  data: Array<CgiDataProps>;
  onClose?: () => void;
}

export function LandingCgiDialog({
  title,
  background,
  data,
  onClose,
  ...props
}: CgiDialogProps) {
  return (
    <Dialog
      {...props}
      onClose={onClose}
      aria-labelledby={title}
      fullWidth
      maxWidth={'lg'}
    >
      <CgiInner>
        <CgiBackground>
          <img src={background || ''} alt={title} />
        </CgiBackground>
        <CgiContent>
          <CloseButton>
            <IconButton aria-label="close" onClick={() => onClose?.()}>
              <AiOutlineClose />
            </IconButton>
          </CloseButton>
          <ContentWrapper>
            <TitleContainer id="alert-dialog-slide-title" pdBottom={0}>
              {title}
            </TitleContainer>
            {data.map((section) => {
              return (
                <SubSection key={section.subtitle}>
                  <SubTitle>{section.subtitle}</SubTitle>
                  {section.content}
                </SubSection>
              );
            })}
          </ContentWrapper>
        </CgiContent>
      </CgiInner>
    </Dialog>
  );
}
