import { Typography } from '@material-ui/core';
import { colors } from 'constants/colors';
import styled from 'styled-components';

export const Container = styled.div`
  p {
    margin: 10px 0;
  }
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${colors.lightGrey};
  }
  ::-webkit-scrollbar-track {
    background: ${colors.vapor};
  }
`;

export const DialogText = styled(Typography)`
  font-weight: 300;
  color: ${colors.richBlack};
  font-size: 1rem;
`;

export const ListTitle = styled(Typography)`
  font-weight: 500;
  color: ${colors.richBlack};
  padding-top: 10px;
`;

export const StyledList = styled.ul`
  padding-left: 25px;
  p {
    margin: 5px 0;
  }
`;
