import { yupResolver } from '@hookform/resolvers/yup';
import { TextField } from 'components/Form/TextField';
import { TitleField } from 'components/feature/openSipp/steps/MoreAboutYouStep/TitleField';
import { GaEventNames, OnboardingStepNames } from 'constants/gaConstants';
import { AccountType, Title } from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import {
  GoBackButton,
  StepActions,
  StepButton,
  StepContainer,
  StepContent,
  StepFormContainer,
  StepIntroduction,
  StepIntroductionTypography,
  StepIntroductionWidth,
  StepTitle,
} from '../../../../design-system/StepComponents/StepComponents';

const nameStepSchema = Yup.object().shape({
  firstName: Yup.string().label('First name').required().min(2),
  lastName: Yup.string().label('Last name').required().min(2),
  title: Yup.mixed<Title>().when('$selectedAccountType', {
    is: AccountType.Sipp.toString(),
    then: Yup.mixed<Title>()
      .not([Title.Unknown])
      .required('Please select a title.'),
  }),
});

export interface NameStepFormValues
  extends Yup.Asserts<typeof nameStepSchema> {}

interface NameStepProps {
  onProceed: (values: NameStepFormValues) => void;
  onGoBack: () => void;
  values?: NameStepFormValues;
  selectedAccountType: AccountType;
}

export function NameStep({
  onProceed,
  onGoBack,
  values,
  selectedAccountType,
}: NameStepProps) {
  const validationSchema = nameStepSchema;

  const methods = useForm<NameStepFormValues>({
    defaultValues: values,
    resolver: yupResolver(validationSchema),
    context: { selectedAccountType },
  });

  const onSubmit = async (data: NameStepFormValues) => {
    trackGa({
      event: GaEventNames.onboarding,
      onboardingStep: OnboardingStepNames.name,
    });
    onProceed(data);
  };
  const handleSubmit = methods.handleSubmit(onSubmit);

  const handleBack = () => {
    onGoBack();
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormProvider {...methods}>
        <StepContainer>
          <StepContent>
            <StepTitle>What’s your name?</StepTitle>
            <StepIntroduction mb={2} $width={StepIntroductionWidth.normal}>
              <StepIntroductionTypography>
                We need this to verify your identity and keep your account
                secure. Please enter your legal name, not a nickname or
                abbreviation.
              </StepIntroductionTypography>
            </StepIntroduction>

            <StepFormContainer>
              {selectedAccountType === AccountType.Sipp && (
                <TitleField name="title" label="Title" autoFocus />
              )}
              <TextField name="firstName" label="First name" fullWidth />
              <TextField name="lastName" label="Last name" fullWidth />
            </StepFormContainer>
          </StepContent>
          <StepActions>
            <StepButton
              type="submit"
              className="magenta"
              disabled={methods.formState.isSubmitting}
            >
              Continue
            </StepButton>
            <GoBackButton
              onClick={handleBack}
              disabled={methods.formState.isSubmitting}
            />
          </StepActions>
        </StepContainer>
      </FormProvider>
    </form>
  );
}
