import { createMuiTheme, Theme } from '@material-ui/core';
import { TypographyStyleOptions } from '@material-ui/core/styles/createTypography';
import { colors } from 'constants/colors';
import { transparentize } from 'polished';

const headingStyle: TypographyStyleOptions = {
  fontFamily: 'GT Super, serif',
  fontWeight: 300,
  color: colors.magenta,
};

export const muiTheme = createMuiTheme({
  typography: {
    fontFamily: "'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif",
    bodyFontFamily: "'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif",
    headingFontFamily: "'GT Super', serif",
    searchIconFontFamily:
      "'searchIconRegular', 'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif",
    bodyMono: "'IBM Plex Mono','Helvetica Neue', Arial, sans-serif",
    body1: {
      color: colors.richBlack,
    },
    body2: {
      color: colors.white,
      fontSize: '1rem',
    },
    button: {
      // textTransform: 'none',
      // fontWeight: 'bold',
    },
    h1: headingStyle,
    h2: headingStyle,
    h3: headingStyle,
    h4: headingStyle,
    h5: headingStyle,
    h6: headingStyle,
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: transparentize(0.2, colors.richBlue),
      },
    },
    MuiContainer: {
      root: {
        flexGrow: 1,
        padding: 56,
        paddingTop: 0,
        paddingLeft: undefined,
        paddingRight: undefined,
        display: 'flex',
        flexDirection: 'column',
      },
    },
    MuiFormLabel: {
      root: {
        color: colors.richBlue,
        '&.Mui-focused': {
          color: colors.richBlue,
        },
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottomColor: colors.richBlue,
        },
        '&:after': {
          borderBottomColor: colors.richBlue,
        },
      },
    },
    MuiButton: {
      root: {
        // padding: '0.571em 1.6em',
        '&:hover': {
          //backgroundColor: undefined,
        },
      },
      label: {
        '&>svg': {
          marginLeft: '1em',
        },
      },
      contained: {
        // '&:hover': {
        //   backgroundColor: undefined,
        // },
      },
      containedPrimary: {
        color: colors.white,
        backgroundColor: colors.black,
        '&:hover': {
          backgroundColor: undefined,
        },
        '&:disabled': {
          color: colors['grey-700'],
          backgroundColor: colors['grey-900'],
        },
      },
      // containedSizeSmall: {
      //   padding: '6px 12px',
      // },
      outlinedSecondary: {
        color: colors.black,
        backgroundColor: colors.white,
        border: `1px solid ${colors.black}`,
        '&:hover': {
          border: undefined,
          backgroundColor: undefined,
        },
        '&:disabled': {
          color: colors['grey-700'],
          borderColor: colors['grey-900'],
        },
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        color: colors.magenta,
        '&$checked': {
          color: colors.magenta,
        },
      },
    },
    MuiDialog: {
      root: {
        height: '100vh',
      },
    },
    MuiRadio: {
      colorSecondary: {
        color: colors.magenta,
        '&$checked': {
          color: colors.magenta,
        },
      },
    },
  },
  darkUniverse: false,
  header: {
    height: '80px',
  },
  zIndex: {
    videoask: 1600,
    topBar: 9,
    popup: 99,
    other: 8,
    banner: 1200,
    tooltip: 1400,
    graphTooltip: 8,
  },
});

muiTheme.typography.h1 = {
  fontWeight: 400,
  fontSize: '2.625rem',
  [muiTheme.breakpoints.up('md')]: {
    fontSize: '3rem',
  },
};

muiTheme.typography.subtitle1 = {
  fontWeight: 400,
  fontSize: '1.75rem',
  color: colors.magenta,
  [muiTheme.breakpoints.up('md')]: {
    fontSize: '2rem',
  },
};

muiTheme.typography.h2 = {
  fontWeight: 400,
  color: colors.magenta,
  fontSize: '1.5rem',
  [muiTheme.breakpoints.up('md')]: {
    fontSize: '1.75rem',
  },
};

muiTheme.typography.subtitle2 = {
  position: 'relative',
  fontWeight: 400,
  color: colors.magenta,
  fontSize: '1.5rem',
  marginBottom: muiTheme.spacing(3.75) + 'px',
  [muiTheme.breakpoints.up('md')]: {
    fontSize: '1.75rem',
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '50px',
    height: '2px',
    backgroundColor: colors.magenta,
  },
};

muiTheme.typography.h3 = {
  fontWeight: 400,
  color: colors.richBlue,
  fontSize: '1.25rem',
  [muiTheme.breakpoints.up('md')]: {
    fontSize: '1.375rem',
  },
};

muiTheme.typography.h4 = {
  fontWeight: 400,
  color: colors.richBlue,
  textDecoration: 'underline',
  fontSize: '1rem',
  [muiTheme.breakpoints.up('md')]: {
    fontSize: '1.275rem',
  },
};

// ts-unused-exports:disable-next-line
export const darkUniverseTheme = (outerTheme: Theme): Theme => ({
  ...outerTheme,
  darkUniverse: true,
  typography: {
    ...outerTheme.typography,
    body1: {
      ...outerTheme.typography.body1,
      color: colors.richBlack,
    },
    h3: {
      ...outerTheme.typography.h3,
      color: colors.richBlack,
    },
    h4: {
      ...outerTheme.typography.h4,
      color: colors.richBlack,
    },
    subtitle2: {
      ...outerTheme.typography.subtitle2,
      color: colors.richBlack,
      '&::after': {
        ...(outerTheme.typography.subtitle2['&::after'] as any),
        backgroundColor: colors.richBlack,
      },
    },
  },
});
