import {
  GoBackButton,
  GoBackLink,
  StepActions,
  StepButton,
  StepContainer,
  StepIntroduction,
  StepIntroductionTypography,
  StepIntroductionWidth,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import { WrapperType } from 'generated/graphql';
import { getPathSegmentForWrapperType } from 'helpers/accountHelpers';
import { generateDashboardAccountPath } from 'paths';
import { useHistory, useLocation } from 'react-router-dom';

interface IntroductionStepsProps {
  onProceed: () => void;
  wrapperType: WrapperType;
}

export function IntroductionStep({
  onProceed,
  wrapperType,
}: IntroductionStepsProps) {
  const history = useHistory();
  const location = useLocation<{ from?: string }>();

  const locationFrom = location.state?.from;

  return (
    <StepContainer>
      <StepTitle>Regular deposit & invest plan</StepTitle>
      <StepIntroduction $width={StepIntroductionWidth.extraWide}>
        <StepIntroductionTypography>
          Make it easier to build your portfolio by setting up a regular
          deposit.
        </StepIntroductionTypography>
        <StepIntroductionTypography>
          You can also set up a regular investment instruction for your deposit,
          or leave it as cash in your account to invest when you want.
        </StepIntroductionTypography>
      </StepIntroduction>

      <StepActions>
        <StepButton onClick={onProceed} className="magenta">
          Continue
        </StepButton>
        {locationFrom &&
        ['auto-save-invest', 'footer'].includes(locationFrom) ? (
          <GoBackButton onClick={() => history.goBack()} />
        ) : (
          <GoBackLink
            to={generateDashboardAccountPath({
              wrapperType: getPathSegmentForWrapperType(wrapperType),
            })}
          />
        )}
      </StepActions>
    </StepContainer>
  );
}
