import { ButtonWrapper } from 'components/design-system/Button/CustomButtonV2';
import { QueryState } from 'components/QueryState';
import { GaEventNames } from 'constants/gaConstants';
import {
  AccountType,
  useAccountEligibilitiesQuery,
  useUserProfileQuery,
} from 'generated/graphql';
import { trackGa } from 'helpers/track';
import {
  useHasOnboardingAccount,
  useHasOpenableAccount,
  useHasPendingAccount,
} from 'hooks/useAccountEligibility';
import { useOpenAccount } from 'hooks/useOpenAccount';
import { fundListPath, generateOpenAccountTypePath } from 'paths';
import { useHistory, useParams } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import {
  StepActions,
  StepButton,
  StepContainer,
  StepContent,
  StepIntroduction,
  StepIntroductionTypography,
  StepIntroductionWidth,
  StepTitle,
} from '../../../../design-system/StepComponents/StepComponents';
import { PickAccountType } from '../_shared/PickAccountType';
import { Actions } from './ChooseAccountType.styles';

type OnProceed = (accType: AccountType) => void;

function HasPendingAccount() {
  const history = useHistory();
  const { showNewMessages } = useIntercom();

  const handleContinue = () => {
    history.push(fundListPath);
  };

  const handleContactSupport = () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'talk to support',
      item_id: 'choose account type - has pending account',
    });
    showNewMessages('I’m having opening my account');
  };

  return (
    <StepContainer>
      <StepContent>
        <StepTitle>Nearly there!</StepTitle>
        <StepIntroduction mb={2} $width={StepIntroductionWidth.normal}>
          <StepIntroductionTypography>
            We're still verifying your identity and setting up your account.
            Until then you can keep browsing our fund universe, but you can't
            start making investments yet. When we're done your new account will
            appear on your Dashboard.
          </StepIntroductionTypography>
        </StepIntroduction>
      </StepContent>
      <StepActions>
        <ButtonWrapper>
          <StepButton
            type="submit"
            className="richBlue"
            onClick={handleContinue}
          >
            View all funds
          </StepButton>
          <StepButton
            type="submit"
            className="richBlue"
            onClick={handleContactSupport}
          >
            Contact support
          </StepButton>
        </ButtonWrapper>
      </StepActions>
    </StepContainer>
  );
}

interface HasOnboardingAccountProps {
  onProceed: OnProceed;
}
function HasOnboardingAccount({ onProceed }: HasOnboardingAccountProps) {
  const userProfileQuery = useUserProfileQuery();

  const handleContinueSettingUp = () => {
    // currently this is reachable only after clicking buy button
    trackGa({
      event: GaEventNames.onboardingResume,
      item_id: 'buy button',
    });
    if (userProfileQuery.data?.userProfile?.initialAccountType) {
      onProceed(userProfileQuery.data?.userProfile?.initialAccountType);
    }
  };

  return (
    <StepContainer>
      <StepContent>
        <StepTitle>Hold your horses!</StepTitle>

        <StepIntroduction mb={2} $width={StepIntroductionWidth.normal}>
          <StepIntroductionTypography>
            You haven’t finished setting up an investment account yet.
          </StepIntroductionTypography>
        </StepIntroduction>
      </StepContent>
      <StepActions>
        <StepButton
          type="submit"
          className="richBlue"
          onClick={handleContinueSettingUp}
        >
          Continue setting up your{' '}
          {userProfileQuery.data?.userProfile?.initialAccountType}
        </StepButton>
      </StepActions>
    </StepContainer>
  );
}

interface AnyCanOpenProps {
  onProceed: OnProceed;
  onTransfer: () => void;
}
function AnyCanOpen({ onProceed, onTransfer }: AnyCanOpenProps) {
  const history = useHistory();
  const { openAccount } = useOpenAccount({ onProceed });
  const handleOpenAccount = async (accType: AccountType) => {
    history.push(generateOpenAccountTypePath({ type: accType }));
    openAccount(accType, '');
  };

  return (
    <PickAccountType onProceed={handleOpenAccount} onTransfer={onTransfer} />
  );
}

interface ContactSupportProps {
  trackingSource?: string;
}
function ContactSupport({ trackingSource }: ContactSupportProps) {
  const { showNewMessages } = useIntercom();

  const handleContactSupport = () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'talk to support',
      item_id: 'choose account type - help opening account',
    });
    showNewMessages('I’m having trouble opening an account');
  };

  return (
    <StepContainer>
      <StepContent>
        <StepTitle>Contact support</StepTitle>

        <StepIntroduction mb={2} $width={StepIntroductionWidth.normal}>
          <StepIntroductionTypography>
            We might need some more information to process your request - please
            reach out to our support team.
          </StepIntroductionTypography>
        </StepIntroduction>
        <Actions></Actions>
      </StepContent>
      <StepActions>
        <StepButton
          type="submit"
          className="richBlue"
          onClick={handleContactSupport}
        >
          Contact support
        </StepButton>
      </StepActions>
    </StepContainer>
  );
}

interface ChooseAccountTypeProps {
  onProceed: OnProceed;
  onTransfer: () => void;
}
export function ChooseAccountType({
  onProceed,
  onTransfer,
}: ChooseAccountTypeProps) {
  const accountEligibilitiesQuery = useAccountEligibilitiesQuery();
  const hasPendingAccount = useHasPendingAccount();
  const hasOnboardingAccount = useHasOnboardingAccount();
  const anyCanOpen = useHasOpenableAccount();

  const { type: paramType } = useParams<{
    type: string | undefined;
    resume: string | undefined;
  }>();

  return (
    <QueryState {...accountEligibilitiesQuery}>
      {() => {
        if (hasPendingAccount) {
          return <HasPendingAccount />;
        }

        if (hasOnboardingAccount && paramType !== 'switch') {
          return <HasOnboardingAccount onProceed={onProceed} />;
        }

        if (anyCanOpen) {
          return <AnyCanOpen onTransfer={onTransfer} onProceed={onProceed} />;
        }

        return <ContactSupport />;
      }}
    </QueryState>
  );
}
