import { HedgingStatus } from 'generated/graphql';

export interface InstrumentLike {
  isin: string;
  assetId?: string | number | null;
  name?: string | null;
  asset?: {
    name: string;
    assetClass?: {
      name: string;
    } | null;
  } | null;
  incomeAccumulationFlag?: string | null;
  ongoingChargePercent?: number | null;
  hedging?: HedgingStatus | null;
  shareClass?: string | null;
  status?: string;
  instrumentType?: string | null;
  kiidDocumentUrl?: string | null;
  isDarkUniverse?: boolean | null;
  isTargetDateFund?: boolean | null;
  isCustomUniverse?: boolean | null;
}

interface InstrumentNameFactory {
  (
    assetName?: string,
    flag?: string | null,
    hedging?: HedgingStatus | null,
    shareClass?: string | null,
    isin?: string
  ): string;
}

function getInstrumentNameImpl(
  instrument: InstrumentLike,
  siblings: InstrumentLike[],
  nameFactory: InstrumentNameFactory
) {
  let ambiguousInstruments = siblings.filter(
    (i) =>
      i.isin !== instrument.isin && i.asset?.name === instrument.asset?.name
  );

  if (instrument.isTargetDateFund) {
    return nameFactory(instrument.asset?.name);
  }

  if (!ambiguousInstruments.length) {
    return nameFactory(instrument.asset?.name);
  }

  ambiguousInstruments = ambiguousInstruments.filter(
    (i) => i.incomeAccumulationFlag === instrument.incomeAccumulationFlag
  );

  if (!ambiguousInstruments.length) {
    return nameFactory(
      instrument.asset?.name,
      instrument.incomeAccumulationFlag
    );
  }

  ambiguousInstruments = ambiguousInstruments.filter(
    (i) => i.hedging === instrument.hedging
  );

  if (!ambiguousInstruments.length) {
    return nameFactory(
      instrument.asset?.name,
      instrument.incomeAccumulationFlag,
      instrument.hedging
    );
  }

  ambiguousInstruments = ambiguousInstruments.filter(
    (i) => i.shareClass === instrument.shareClass
  );

  if (!ambiguousInstruments.length) {
    return nameFactory(
      instrument.asset?.name,
      instrument.incomeAccumulationFlag,
      instrument.hedging,
      instrument.shareClass
    );
  }

  return nameFactory(
    instrument.asset?.name,
    instrument.incomeAccumulationFlag,
    instrument.hedging,
    instrument.shareClass,
    instrument.isin
  );
}

export function getInstrumentSelectorLabel(
  instrument: InstrumentLike,
  siblings: InstrumentLike[],
  short?: boolean
) {
  const nameFactory: InstrumentNameFactory = (
    _,
    incomeAccumulationFlag,
    hedging,
    shareClass,
    isin
  ) => {
    const discriminator: string[] = [];
    if (short) {
      let incAcc: string | null = null;
      if (incomeAccumulationFlag === 'Accumulation') {
        incAcc = 'Acc';
      } else if (incomeAccumulationFlag === 'Income') {
        incAcc = 'Inc';
      }
      if (!!incAcc) discriminator.push(incAcc);
    } else {
      if (!!incomeAccumulationFlag) discriminator.push(incomeAccumulationFlag);
    }
    if (!!hedging && hedging === HedgingStatus.Hedged)
      discriminator.push('- Hedged');
    if (!!shareClass) discriminator.push(`- ${shareClass}`);
    if (!!isin) discriminator.push(`(${isin})`);

    return discriminator.join(' ');
  };

  const res = getInstrumentNameImpl(instrument, siblings, nameFactory);

  return res;
}

export function getHoldingName(
  instrument: InstrumentLike,
  siblings: InstrumentLike[]
) {
  const prefix = instrument.status === 'Suspended' ? '[Suspended] ' : '';

  const nameFactory: InstrumentNameFactory = (
    assetName,
    incomeAccumulationFlag,
    hedging,
    shareClass,
    isin
  ) => {
    let incAcc: string | null = null;
    if (incomeAccumulationFlag === 'Accumulation') {
      incAcc = 'Acc';
    } else if (incomeAccumulationFlag === 'Income') {
      incAcc = 'Inc';
    }
    const discriminator: string[] = [];

    if (!!incAcc) discriminator.push(incAcc);
    if (!!hedging && hedging === HedgingStatus.Hedged)
      discriminator.push('- Hedged');
    if (!!shareClass) discriminator.push(`- ${shareClass}`);
    if (!!isin) discriminator.push(`${isin}`);

    return `${assetName}${
      discriminator.length ? ` (${discriminator.join(' ')})` : ''
    }`;
  };

  return `${prefix}${getInstrumentNameImpl(instrument, siblings, nameFactory)}`;
}

type AbbrLookupType = { [key: string]: string };

const abbrLookup: AbbrLookupType = {
  Accumulation: 'Acc',
  Income: 'Inc',
};

export function getOrderInstrumentShareClass(
  instrument: InstrumentLike,
  siblings: InstrumentLike[],
  short?: boolean
) {
  if (
    instrument.instrumentType === 'ETF' ||
    instrument.instrumentType === 'Investment Trust'
  ) {
    return 'N/A';
  }

  const nameFactory: InstrumentNameFactory = (
    assetName,
    incomeAccumulationFlag,
    hedging,
    shareClass,
    isin
  ) => {
    const discriminator: string[] = [];

    if (!!hedging && hedging === HedgingStatus.Hedged)
      discriminator.push(' - Hedged');
    if (!!shareClass) discriminator.push(`- ${shareClass}`);
    if (!!isin) discriminator.push(`(${isin})`);

    return discriminator.join(' ');
  };

  const incAcc =
    short &&
    instrument.incomeAccumulationFlag &&
    abbrLookup[instrument.incomeAccumulationFlag]
      ? abbrLookup[instrument.incomeAccumulationFlag]
      : instrument.incomeAccumulationFlag;

  if (!incAcc) {
    return null;
  }

  return `${incAcc}${getInstrumentNameImpl(instrument, siblings, nameFactory)}`;
}
