import { colors } from 'constants/colors';
import { transparentize } from 'polished';
import styled from 'styled-components';
import { ChipStyle } from './Chip';

interface ChipProps {
  $color?: string;
  $style?: keyof typeof ChipStyle;
  $hasAction?: boolean;
  $disabled?: boolean;
  $active?: boolean;
}

export const ChipContainer = styled.div<ChipProps>`
  display: inline-block;
  padding: 0.375rem 1rem;
  border-radius: 1.5rem;
  background-color: ${({ $color, $style }) =>
    $style === 'contained'
      ? transparentize(0.9, $color || colors.purple)
      : 'transparent'};
  border: ${({ $color, $style, $active }) =>
    $style === 'contained' && !$active
      ? 'transparent solid 1px'
      : ($color || colors.purple) + ' solid 1px'};
  color: ${({ $color }) => $color || colors.purple};
  ${({ $hasAction, $color, $disabled }) =>
    $hasAction &&
    !$disabled &&
    `
      cursor: pointer;
      &:hover {
        border: ${($color || colors.purple) + ' solid 1px'}}
      }
    `}
  ${({ $disabled }) =>
    $disabled &&
    `
      opacity: 0.5;
      border: none;
    `}
`;
