import { Popper, Typography } from '@material-ui/core';
import { colors } from 'constants/colors';
import styled, { css } from 'styled-components';

export const Arrow = styled.div`
  position: absolute;
  &:after,
  &:before {
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    transition: margin-left 0.5s ease, margin-right 0.5s ease,
      border-width 0.5s ease;
  }
`;

export const StyledPopper = styled(Popper)`
  z-index: ${(p) => p.theme.zIndex.graphTooltip};
  filter: drop-shadow(0 0 5px rgba(255, 255, 255, 0.5));

  .MuiPaper-root {
    margin-left: 10px;
    margin-right: 10px;
    padding: ${(p) => p.theme.spacing(2.5, 1.25)};
    max-width: ${(p) => p.theme.breakpoints.values.sm}px;
    background-color: var(
      --tooltip-background-color,
      ${(p) => (p.theme.darkUniverse ? colors.richBlack : colors.purple)}
    );
    border-width: 2px;
    border-style: solid;
    border-color: var(
      --tooltip-border-color,
      var(
        --tooltip-background-color,
        ${(p) => (p.theme.darkUniverse ? colors.richBlack : colors.purple)}
      )
    );
    overflow: visible;
    color: var(--tooltip-text-color, ${colors.white});
    transition: margin-left 0.5s ease, margin-right 0.5s ease,
      border-width 0.5s ease;

    .MuiTypography-root {
      color: var(--tooltip-text-color, ${colors.white});
      transition: margin-left 0.5s ease, margin-right 0.5s ease,
        border-width 0.5s ease;
    }
  }

  &[x-placement*='top'] {
    .MuiPaper-root {
      margin-left: 0;
      margin-right: 0;
      margin-top: 10px;
    }
    ${Arrow} {
      top: -10px;

      &:after {
        right: -10px;
        border-bottom-color: var(
          --tooltip-background-color,
          ${(p) => (p.theme.darkUniverse ? colors.richBlack : colors.purple)}
        );
        border-width: 11px;
      }

      &:before {
        right: -10px;
        border-bottom-color: var(
          --tooltip-border-color,
          var(
            --tooltip-background-color,
            ${(p) => (p.theme.darkUniverse ? colors.richBlack : colors.purple)}
          )
        );
        border-width: 11px;
      }
    }
  }

  &[x-placement*='right'] {
    ${Arrow} {
      left: 10px;

      &:after {
        right: 100%;
        border-right-color: var(
          --tooltip-background-color,
          ${(p) => (p.theme.darkUniverse ? colors.richBlack : colors.purple)}
        );
        border-width: 8px;
        margin-top: -8px;
      }

      &:before {
        right: 100%;
        border-right-color: var(
          --tooltip-border-color,
          var(
            --tooltip-background-color,
            ${(p) => (p.theme.darkUniverse ? colors.richBlack : colors.purple)}
          )
        );
        border-width: 11px;
        margin-top: -11px;
      }
    }
  }

  &[x-placement*='left'] {
    ${Arrow} {
      right: 12px;

      &:after {
        left: 100%;
        border-left-color: var(
          --tooltip-background-color,
          ${(p) => (p.theme.darkUniverse ? colors.richBlack : colors.purple)}
        );
        border-width: 8px;
        margin-top: -8px;
      }

      &:before {
        left: 100%;
        border-left-color: var(
          --tooltip-border-color,
          var(
            --tooltip-background-color,
            ${(p) => (p.theme.darkUniverse ? colors.richBlack : colors.purple)}
          )
        );
        border-width: 11px;
        margin-top: -11px;
      }
    }
  }
`;

export type horizontalAlignmentType =
  | 'flex-start'
  | 'center'
  | 'flex-end'
  | 'space-between';

interface horizontalAlignmentProps {
  horizontalAlignment?: horizontalAlignmentType;
}

export const LegendContainer = styled.div<horizontalAlignmentProps>`
  margin-top: ${(p) => p.theme.spacing(1.25)}px;
  padding-left: ${(p) => p.theme.spacing(4)}px;

  ${({ horizontalAlignment }) =>
    horizontalAlignment &&
    css`
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 0 1rem;
    `};

  .MuiTypography-root {
    font-size: 0.625rem;
  }
`;

export const LegendEntry = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${(p) => p.theme.spacing(1)}px;

  > div {
    height: 0.625rem;
    width: 0.625rem;
    margin-right: ${(p) => p.theme.spacing(1)}px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const TooltipContent = styled.div`
  .MuiTypography-root {
    font-weight: 500;
    font-size: 0.875rem;

    &:not(:first-child) {
      margin-top: ${(p) => p.theme.spacing(1)}px;
    }
  }

  dl {
    font-size: 0.875rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: ${(p) => p.theme.spacing(2.5)}px;
    margin-top: ${(p) => p.theme.spacing(1)}px;
    margin-bottom: 0;
  }

  dt {
    font-weight: 500;
  }

  dd {
    text-align: right;
    font-weight: 300;
    margin-left: 0;
  }
`;

export const Disclaimer = styled(Typography)`
  margin-top: ${(p) => p.theme.spacing(2.5)}px;
  text-align: left;
  font-size: 1rem;
  font-style: italic;
  line-height: 1.5;
`;
