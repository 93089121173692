import { useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { FontSize, Pill } from 'components/design-system/Pill/Pill';
import { GaEventNames } from 'constants/gaConstants';
import { Tag } from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { SearchAssetsQueryAsset } from 'types/graphqlTypes';
import { shouldNeverDisplayTag } from '../helpers/tagPolicies';
import { Pin } from './FundGridCard.style';

interface TagProps {
  title: string;
  code: string;
  content: JSX.Element | string | null;
  categoryCode?: string;
  isImportant: boolean;
}

interface TagToRender {
  name: string;
  isActive: boolean;
  display: boolean;
  categoryCode: string;
  code: string;
  isImportant: boolean;
}

interface FundTagsPillsProps {
  filteredTags: TagToRender[];
  canHover: boolean;
  removeTag?: (tag: { code: string }) => void;
  addTag?: (tag: { code: string }, important?: boolean) => void;
  fontSize?: FontSize;
}
const FundTagsPills = ({
  filteredTags,
  canHover,
  removeTag,
  addTag,
  fontSize,
}: FundTagsPillsProps) => {
  return (
    <>
      {filteredTags.map((tag, i: number) => {
        const icon = tag.isActive ? 'X' : '+';
        return (
          tag && (
            <Pill
              $fontSize={
                fontSize
                  ? fontSize
                  : canHover
                  ? FontSize.small
                  : FontSize.normal
              }
              $color={tag.isActive ? 'purple' : 'vapor'}
              key={`${tag.code}-${i}`}
              onClick={() => {
                if (tag.isActive && removeTag) {
                  removeTag?.(tag);
                  trackGa({
                    event: GaEventNames.selectContent,
                    content_type: 'fund card - pill - remove',
                    item_id: tag.code,
                    filter_group: tag.categoryCode,
                  });
                } else {
                  addTag?.(tag);
                  trackGa({
                    event: GaEventNames.selectContent,
                    content_type: 'fund card - pill - add',
                    item_id: tag.code,
                    filter_group: tag.categoryCode,
                  });
                }
              }}
              icon={addTag && removeTag ? icon : undefined}
            >
              {tag.isImportant && <Pin />}
              {tag.name}
            </Pill>
          )
        );
      })}
    </>
  );
};

interface FundGridCardTagsProps {
  asset: SearchAssetsQueryAsset;
  searchTags: TagProps[];
  missingTags: TagProps[];
  canHoverTags: boolean;
  removeTag?: (tag: { code: string }) => void;
  addTag?: (tag: { code: string }, important?: boolean) => void;
  fontSize?: FontSize;
}

export function FundGridCardTags({
  asset,
  searchTags,
  missingTags,
  removeTag,
  canHoverTags,
  addTag,
  fontSize,
}: FundGridCardTagsProps) {
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

  const canHover = useMediaQuery('(hover: hover)');

  const tags = asset?.tags?.nodes || null;

  const filteredTags: TagToRender[] = tags
    ? tags
        .map((tag: Tag) => {
          const searchTag = (searchTags || []).find(
            (searchTag) => searchTag.code === tag?.code
          );
          const isActive = !!searchTag;
          const isImportant = !!searchTag?.isImportant;
          return {
            name: tag?.name,
            isActive,
            display:
              !shouldNeverDisplayTag(tag.code) && (isActive || !!tag.display),
            categoryCode: tag.categoryCode,
            code: tag.code,
            isImportant,
          };
        })
        .filter(({ display }) => display)
        .sort((a, b) => {
          if (
            a.isImportant !== b.isImportant &&
            (a.isImportant === true || b.isImportant === true)
          ) {
            return a.isImportant ? -1 : 1;
          }

          if (a.isActive === b.isActive) {
            return (a?.name?.toUpperCase() || '') <
              (b?.name?.toUpperCase() || '')
              ? -1
              : 1;
          }
          return a.isActive ? -1 : 1;
        })
    : [];

  const matchTagsCount = filteredTags.reduce(
    (acc, tag) => (tag.isActive ? acc + 1 : acc),
    0
  );

  return (
    <>
      <FundTagsPills
        filteredTags={filteredTags.slice(0, matchTagsCount)}
        canHover={canHoverTags && canHover}
        removeTag={removeTag}
        addTag={addTag}
        fontSize={fontSize}
      />

      {missingTags.length > 0 && (
        <>
          {missingTags.map((tag) => (
            <Pill
              key={tag.code}
              $fontSize={fontSize}
              $color={'white'}
              $strikeThrough
              icon={isSmUp && canHoverTags ? 'pin' : undefined}
              $title={
                isSmUp && canHoverTags
                  ? 'Always include tag in search results'
                  : undefined
              }
              onClick={
                isSmUp
                  ? () => {
                      addTag?.(tag, true);
                    }
                  : undefined
              }
            >
              {tag.title}
            </Pill>
          ))}{' '}
        </>
      )}

      <FundTagsPills
        filteredTags={filteredTags.slice(matchTagsCount)}
        canHover={canHoverTags && canHover}
        removeTag={removeTag}
        addTag={addTag}
        fontSize={fontSize}
      />
    </>
  );
}
