import { GaEventNames } from 'constants/gaConstants';
import { trackGa } from 'helpers/track';
import React from 'react';
import { StyledTab, StyledTabs } from './TimeHorizonTabs.style';

interface TabsProps {
  label: string;
  value: string;
}

interface TimeHorizonTabsProps {
  trackingContext?: string;
  activeValue: string;
  onChange: (event: React.ChangeEvent<{}>, newValue: any) => void;
  tabs: TabsProps[];
}

export function TimeHorizonTabs({
  trackingContext,
  activeValue,
  onChange,
  tabs,
}: TimeHorizonTabsProps) {
  const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    trackingContext &&
      trackGa({
        event: GaEventNames.selectContent,
        content_type: 'graph time horizon',
        item_id: trackingContext,
        value: newValue,
      });
    onChange(event, newValue);
  };

  return (
    <StyledTabs
      value={activeValue}
      onChange={handleChange}
      variant="scrollable"
    >
      {tabs &&
        tabs.map((tab) => (
          <StyledTab key={tab.label} label={tab.label} value={tab.value} />
        ))}
    </StyledTabs>
  );
}
