import { Box, Typography, useMediaQuery } from '@material-ui/core';
import { QueryState } from 'components/QueryState';
import { colors } from 'constants/colors';
import { date, percent } from 'formatting';
import {
  FundPerformanceComparisonPeriod,
  FundPerformanceSeriesKind,
  useFundPerformanceQuery,
} from 'generated/graphql';
import _ from 'lodash';
import numeral from 'numeral';
import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { BaseGraph } from './BaseGraph/BaseGraph';
import { TooltipContent } from './BaseGraph/BaseGraph.styles';
import { TooltipHandle } from './BaseGraph/Tooltip';

interface PerformanceGraphProps {
  isin: string;
  period: FundPerformanceComparisonPeriod;
}

export function PerformanceGraph({ isin, period }: PerformanceGraphProps) {
  const theme = useTheme();
  const atLeastSm = useMediaQuery(theme.breakpoints.up('sm'));
  const atLeastLaptop = useMediaQuery(theme.breakpoints.up('md'));

  const { darkUniverse } = theme;

  const performanceQuery = useFundPerformanceQuery(
    {
      isin,
      period,
    },
    {
      enabled: !!isin,
    }
  );

  const series = useMemo(
    () => _.keyBy(performanceQuery.data?.fundPerformanceByIsin?.series, 'kind'),
    [performanceQuery.data?.fundPerformanceByIsin?.series]
  );

  const areaData = series[FundPerformanceSeriesKind.Fund];
  const lines = [series[FundPerformanceSeriesKind.PeerGroup]];

  const labels = useMemo(
    () =>
      performanceQuery.data?.fundPerformanceByIsin?.labels?.map((label) => {
        const dateParts = label.split('/').map((p) => parseInt(p));
        return new Date(dateParts[2], dateParts[1] - 1, dateParts[0]).getTime();
      }) ?? [],
    [performanceQuery.data?.fundPerformanceByIsin?.labels]
  );

  const renderTooltip = (tooltip: TooltipHandle, index: number) => {
    if (!darkUniverse) {
      tooltip.setStyles({
        backgroundColor:
          areaData.data[index]! < 0 ? colors.seaBlue : colors.purple,
      });
    }
    return (
      <TooltipContent>
        <Typography>{date(new Date(labels[index]))}</Typography>
        <dl>
          <dt>Fund:</dt>
          <dd>
            {areaData.data[index] !== null
              ? percent(areaData.data[index]! / 100)
              : '-'}
          </dd>
          <dt>Sector:</dt>
          <dd>
            {lines[0].data[index] !== null
              ? percent(lines[0].data[index]! / 100)
              : '-'}
          </dd>
        </dl>
      </TooltipContent>
    );
  };

  const tickFormat = (value: number) => numeral(value / 100).format('0,0.%');

  const getHeight = () => {
    if (atLeastLaptop) {
      return 240;
    }

    if (atLeastSm) {
      return 330;
    }

    return 200;
  };

  return (
    <Box position="relative" minHeight={getHeight()}>
      <QueryState
        {...performanceQuery}
        isError={
          (performanceQuery.isError ||
            !Object.keys(series).length ||
            !labels.length) as any
        }
        loadingAbsolute
      >
        {() => (
          <BaseGraph
            labels={labels}
            area={areaData}
            lines={lines}
            tickFormat={tickFormat}
            renderTooltipContent={renderTooltip}
            graphSettings={{
              height: getHeight(),
            }}
            graphLocation="Fund details"
            graphType="Fund performance"
          />
        )}
      </QueryState>
    </Box>
  );
}
