import { ErrorMessage } from '@hookform/error-message';
import { TextField } from '@material-ui/core';
import { Controller, UseFormMethods } from 'react-hook-form';
import styled from 'styled-components';

interface MaskedFieldProps {
  name: string;
  id?: string;
  label: string;
  defaultValue?: string;
  placeholder?: string;
  control?: UseFormMethods['control'];
  disabled?: boolean;
  autoFocus?: boolean;
  inputComponent?: any;
}

const StyledTextField = styled(TextField)`
  .MuiInputLabel-root {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;

    &.MuiInputLabel-shrink {
      max-width: 125%;
    }
  }
`;

export function MaskedField({
  name,
  id,
  label,
  defaultValue = '',
  placeholder,
  control,
  disabled = false,
  autoFocus = false,
  inputComponent,
}: MaskedFieldProps) {
  return (
    <Controller
      name={name}
      defaultValue={{ isMasked: !!defaultValue, value: defaultValue }}
      control={control}
      render={({ ref, value, name, onChange, onBlur }, { invalid }) => (
        <StyledTextField
          inputRef={ref}
          name={name}
          id={id}
          value={value.value}
          onChange={(e) =>
            onChange({
              isMasked:
                e.target.value.length && e.target.value === defaultValue,
              value: e.target.value,
            })
          }
          onBlur={onBlur}
          InputLabelProps={{
            error: false,
          }}
          label={label}
          placeholder={placeholder}
          fullWidth
          error={!!invalid}
          helperText={<ErrorMessage name={name + '.value'} />}
          disabled={disabled}
          autoFocus={autoFocus}
          InputProps={
            inputComponent
              ? {
                  inputComponent: inputComponent as any,
                }
              : undefined
          }
        />
      )}
    />
  );
}
