import { Text, TextSmall } from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import { motion } from 'framer-motion';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { OverAllocatedMsg } from './ModeRecurringFooter.styles';

export const FooterIcon = styled(motion.button).attrs({
  initial: { transform: 'translateY(100%)', opacity: 0.5 },
  animate: { transform: 'translateY(0%)', opacity: 1 },
  exit: { transform: 'translateY(100%)', opacity: 0.5 },
})`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  bottom: 1.25rem;
  right: 5.5rem;
  height: 3.125rem;
  border-radius: 1.5rem;
  align-self: flex-end;
  color: white;
  text-align: left;
  background-color: ${colors['purple-500']};
  padding: 1rem 1.5rem 1rem 1rem;
  z-index: ${({ theme }) => theme.zIndex.other};
  border: none;
  cursor: pointer;
`;

export const FooterSection = styled(motion.div).attrs({
  initial: { transform: 'translateY(100%)' },
  animate: { transform: 'translateY(0%)' },
  exit: { transform: 'translateY(100%)' },
  transition: {
    type: 'linear',
  },
})`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  gap: 1rem;
  background-color: ${colors['purple-50']};
  border-top: solid 1px ${colors['purple-100']};
  padding: 1rem 1.5rem;
  z-index: ${({ theme }) => theme.zIndex.other};

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 1rem 1.875rem;
  }
`;

interface FooterLayoutProps {
  $hasControls?: boolean;
}
export const FooterLayout = styled.div<FooterLayoutProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1.5rem;
  width: 100%;

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: grid;
    grid-template-columns: ${({ $hasControls }) =>
      $hasControls ? 'auto 1fr' : '1fr'};
    gap: 1.5rem;
    max-width: 1280px;
  }
`;

export const ControlsWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  gap: 0.5rem;

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 1rem;
    align-items: center;
  }
`;

export const AccountControlWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

interface CashLinkProps {
  $needsAttention?: boolean;
}

export const CashLink = styled(Link)<CashLinkProps>`
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 300;
  margin-left: 0.25rem;

  ${Text} &, ${Text} &:visited, ${Text} &:hover, ${Text} &:focus {
    text-decoration: underline;
    color: ${({ $needsAttention }) =>
      $needsAttention ? colors['danger'] : 'inherit'};
  }
`;

interface FooterKeyValueTextProps {
  $needsAttention: boolean;
}

const FooterKeyValueText = styled(TextSmall)<FooterKeyValueTextProps>`
  color: ${({ $needsAttention }) =>
    $needsAttention ? colors['danger'] : 'inherit'};
  margin: 0;
`;

interface KeyValueProps {
  label: ReactNode;
  value: ReactNode;
  $needsAttention?: boolean;
}

export const KeyValue = ({ label, value, $needsAttention }: KeyValueProps) => {
  return (
    <FooterKeyValueText $noMargin $needsAttention={$needsAttention}>
      <strong>{value}</strong>
      <br />
      {label}
    </FooterKeyValueText>
  );
};

export const SummaryNumbers = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  p + p,
  ${OverAllocatedMsg}+ p {
    border-top: solid 1px ${colors['purple-100']};
    padding-top: 0.25rem;
    margin-top: 0.25rem;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: row;
    margin: 0;
    text-align: left;
    p + p,
    ${OverAllocatedMsg}+ p {
      border-top: none;
      border-left: solid 1px ${colors['purple-100']};
      padding-top: 0;
      padding-left: 1.5rem;
      margin-top: 0;
      margin-left: 1.5rem;
    }
  }
`;
