import React, { ForwardRefRenderFunction, InputHTMLAttributes } from 'react';
import {
  AmountInputContainer,
  PercentageInputInput,
  PercentageInputLabel,
} from './AmountInput.styles';

interface AmountInputProps extends InputHTMLAttributes<HTMLInputElement> {
  ref?: React.ForwardedRef<HTMLInputElement>;
  id: string;
}

const Input: ForwardRefRenderFunction<HTMLInputElement, AmountInputProps> = (
  { ...props },
  ref
) => {
  return (
    <AmountInputContainer>
      <PercentageInputInput
        type="text"
        inputMode="numeric"
        pattern="[\d\.]*"
        ref={ref}
        {...props}
        id={props.id}
      />
      <PercentageInputLabel htmlFor={props.id}>%</PercentageInputLabel>
    </AmountInputContainer>
  );
};

export const PercentageInput = React.forwardRef(Input);
