import { DepositDialog } from 'components/Dialogs/Deposit/DepositDialog';
import { QueryState } from 'components/QueryState';
import {
  ButtonWrapper,
  CustomButtonV2,
  LinkCustomButton,
} from 'components/design-system/Button/CustomButtonV2';
import { UnauthBasketPlacementDialog } from 'components/feature/PortfolioBuilder/UnauthBasketPlacement/UnauthBasketPlacement';
import type { LsBuyOrders } from 'components/feature/PortfolioBuilder/hooks/useFundsBasket';
import { useWhichBasketType } from 'components/feature/PortfolioBuilder/hooks/useWhichBasketType';
import { useAuth } from 'context/AuthContext';
import { currencyFull } from 'formatting';
import { WrapperType, useUserProfileQuery } from 'generated/graphql';
import {
  getPathSegmentForWrapperType,
  getShortNameForWrapperType,
} from 'helpers/accountHelpers';
import { useDialog } from 'hooks/useDialog';
import {
  generateCheckoutPath,
  generateDynamicPortfolioConstructionBasketPath,
} from 'paths';
import { useEffect, useMemo, useState } from 'react';
import { FaShoppingBasket } from 'react-icons/fa';
import { useHistory, useLocation } from 'react-router-dom';
import type { UserProfileQueryClientSummaryAccount } from 'types/graphqlTypes';
import { useLocalStorage } from 'usehooks-ts';
import { useFundsBasket } from '../PortfolioBuilder/hooks/useFundsBasket';
import { CheckoutDialog } from './CheckoutDialog';
import { CashLink, KeyValue, SummaryNumbers } from './ModeFooter.styles';
import {
  AllocateButton,
  FooterLayout,
  FooterMobileSummaryLayout,
} from './ModeOrderFooter.styles';

interface AllocateLocalBasketButtonProps {
  onOrdersAllocated: () => void;
  buttonCopy?: string;
}

function AllocateLocalBasketButton({
  onOrdersAllocated,
  buttonCopy = 'Allocate',
}: AllocateLocalBasketButtonProps) {
  const [miniBasketOpen, setMiniBasketOpen] = useState(true);
  const handleOnClick = () => {
    setMiniBasketOpen(!miniBasketOpen);
  };

  return (
    <>
      <AllocateButton onClick={handleOnClick}>{buttonCopy}</AllocateButton>
      <UnauthBasketPlacementDialog
        open={miniBasketOpen}
        actions="close"
        onClose={() => {
          setMiniBasketOpen(false);
        }}
        onItemsAllocated={() => {
          setMiniBasketOpen(false);
          onOrdersAllocated();
        }}
      />
    </>
  );
}

function useHasUnknownBasketToAllocate(): [
  boolean | undefined,
  React.Dispatch<React.SetStateAction<boolean | undefined>>
] {
  const basketType = useWhichBasketType();
  const [basketLs, setBasketLs] = useLocalStorage<LsBuyOrders>(
    'nonAccountBuyOrders',
    {}
  );

  const buyOrders = basketLs?.buyOrders;
  const hasUnknownBasket = buyOrders && buyOrders.length > 0;
  const isUnknownBasketEmpty = buyOrders && buyOrders.length === 0;

  const [hasUnknownBasketToAllocate, setHasUnknownBasketToAllocate] = useState<
    boolean | undefined
  >(undefined);

  useEffect(() => {
    if (
      hasUnknownBasketToAllocate === undefined &&
      hasUnknownBasket &&
      !isUnknownBasketEmpty &&
      basketType === 'AccountBasket'
    ) {
      setHasUnknownBasketToAllocate(true);
    }

    if (isUnknownBasketEmpty) {
      setBasketLs({});
    }
  }, [
    basketType,
    hasUnknownBasket,
    hasUnknownBasketToAllocate,
    isUnknownBasketEmpty,
    setBasketLs,
  ]);

  return [hasUnknownBasketToAllocate, setHasUnknownBasketToAllocate];
}

interface ModeOrderMobileSummaryFooterProps {
  account: UserProfileQueryClientSummaryAccount;
}

export function ModeOrderMobileSummaryFooter({
  account,
}: ModeOrderMobileSummaryFooterProps) {
  const { basketSummary, portfolioRebalancingsQuery } = useFundsBasket({
    selectedAccountId: account?.id ?? null,
  });

  return (
    <FooterMobileSummaryLayout>
      <FaShoppingBasket size="1.5rem" />
      <div>
        <div>
          {account && getShortNameForWrapperType(account.wrapperType)} order{' '}
        </div>
        {portfolioRebalancingsQuery.isLoading ? (
          <div>Loading...</div>
        ) : (
          <div>
            {currencyFull(basketSummary.amount)} ({basketSummary.orderCount})
          </div>
        )}
      </div>
    </FooterMobileSummaryLayout>
  );
}

interface ModeOrderFooterProps {
  account: UserProfileQueryClientSummaryAccount;
  onViewBasket?: () => void;
}

export function ModeOrderFooter({
  account,
  onViewBasket,
}: ModeOrderFooterProps) {
  const { signedIn } = useAuth();
  const userProfileQuery = useUserProfileQuery(undefined, {
    enabled: signedIn,
  });
  const history = useHistory();
  const { pathname, search } = useLocation();

  const [
    hasUnknownBasketToAllocate,
    setHasUnknownBasketToAllocate,
  ] = useHasUnknownBasketToAllocate();

  const { basketSummary, getRebalancingId, canCheckout } = useFundsBasket({
    selectedAccountId: account?.id ?? null,
  });

  const returnPath = pathname + search;

  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const addCash = queryParams.has('add-cash');

  const paymentId = queryParams.has('paymentId')
    ? (queryParams.get('paymentId') as string)
    : undefined;

  const addCashPath = useMemo(() => {
    const addCashQueryParams = new URLSearchParams(search);
    addCashQueryParams.append('add-cash', '');
    return pathname + '?' + addCashQueryParams.toString();
  }, [pathname, search]);

  const availableCash = account?.valuationSummary?.uninvestedCash!;

  const remainingAmount =
    basketSummary.orderCount <= 0
      ? availableCash
      : Math.round((availableCash - basketSummary.amount) * 100) / 100;

  const selectedRebalancingId = getRebalancingId();

  const checkoutDialog = useDialog();

  return (
    <QueryState {...userProfileQuery}>
      {() => {
        return (
          <>
            {addCash && account.id && (
              <DepositDialog
                accountId={account.id}
                paymentId={paymentId}
                isPolling
                returnPath={process.env.REACT_APP_BASE_URL + returnPath}
                onClose={() => {
                  queryParams.delete('add-cash');
                  queryParams.delete('paymentId');
                  history.push(pathname + '?' + queryParams.toString());
                }}
                open
              />
            )}
            {checkoutDialog.open && (
              <CheckoutDialog
                open
                onClose={() => {
                  checkoutDialog.closeDialog();
                }}
              />
            )}

            <FooterLayout>
              <SummaryNumbers>
                {signedIn && remainingAmount > 0 && (
                  <KeyValue
                    label="Remaining cash balance"
                    value={
                      <>
                        {currencyFull(remainingAmount)}{' '}
                        <CashLink to={addCashPath}>Add cash</CashLink>
                      </>
                    }
                  />
                )}

                {signedIn && remainingAmount <= 0 && (
                  <KeyValue
                    $needsAttention
                    label="Order shortfall"
                    value={
                      <>
                        {currencyFull(remainingAmount * -1)}{' '}
                        <CashLink to={addCashPath} $needsAttention>
                          Add cash
                        </CashLink>
                      </>
                    }
                  />
                )}

                {basketSummary.amount >= 0 ? (
                  <KeyValue
                    label="Basket balance"
                    value={<>{currencyFull(basketSummary.amount)}</>}
                  />
                ) : (
                  <KeyValue
                    label="Estimated cash raised (net)"
                    value={<>+{currencyFull(Math.abs(basketSummary.amount))}</>}
                  />
                )}

                <KeyValue label="Items" value={basketSummary.orderCount} />

                {hasUnknownBasketToAllocate && (
                  <KeyValue
                    label={
                      <AllocateLocalBasketButton
                        onOrdersAllocated={() => {
                          setHasUnknownBasketToAllocate(false);
                        }}
                      />
                    }
                    value="Unallocated to account"
                  />
                )}
              </SummaryNumbers>

              <ButtonWrapper $direction="row" $noMargin>
                <LinkCustomButton
                  type="button"
                  $color="secondary"
                  to={generateDynamicPortfolioConstructionBasketPath({
                    wrapperType: getPathSegmentForWrapperType(
                      account?.wrapperType ?? WrapperType.Unknown
                    ),
                  })}
                  onClick={() => onViewBasket?.()}
                >
                  View Basket
                </LinkCustomButton>
                {selectedRebalancingId ? (
                  <LinkCustomButton
                    type="button"
                    $color="primary"
                    variant="contained"
                    disabled={!canCheckout()}
                    to={generateCheckoutPath({
                      wrapperType: getPathSegmentForWrapperType(
                        account.wrapperType
                      ),
                      selectedRebalancingId: selectedRebalancingId,
                    })}
                  >
                    Checkout
                  </LinkCustomButton>
                ) : (
                  <CustomButtonV2
                    type="button"
                    $color="primary"
                    variant="contained"
                    disabled={!canCheckout()}
                    onClick={() => {
                      checkoutDialog.openDialog();
                    }}
                  >
                    Checkout
                  </CustomButtonV2>
                )}
              </ButtonWrapper>
            </FooterLayout>
          </>
        );
      }}
    </QueryState>
  );
}
