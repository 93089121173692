import { yupResolver } from '@hookform/resolvers/yup';
import { FormHelperText } from '@material-ui/core';
import { Loading } from 'components/Loading';
import { QueryState } from 'components/QueryState';
import { Alert, Severity } from 'components/design-system/Alert/Alert';
import { H4 } from 'components/design-system/Heading/Heading';
import { InfoPopoverV2 } from 'components/design-system/InfoPopoverV2/InfoPopoverV2';
import { AmountInput } from 'components/design-system/Inputs/AmountInput/AmountInput';
import { PercentageInput } from 'components/design-system/Inputs/AmountInput/PercentageInput';
import { ExclusivePill } from 'components/design-system/Pill/Pill';
import {
  GoBackButton,
  StepActions,
  StepButton,
  StepContainer,
  StepContent,
} from 'components/design-system/StepComponents/StepComponents';
import { TextSmall } from 'components/design-system/Text/Text';
import { InstrumentSelectorSelect } from 'components/feature/FundDetails/InstrumentSelector/InstrumentSelector';
import {
  ExclusivePillWrapper,
  FundDoLink,
  KeyValue,
  Pill,
  PillContainer,
} from 'components/feature/PortfolioBuilder/AddToBasket/AddToBasketDialog.style';
import { ImportantBuyInformation } from 'components/feature/PortfolioBuilder/ImportantInformation/ImportantBuyInformation';
import { ShareClassInfoPopover } from 'components/feature/PortfolioBuilder/_shared/ShareClassInfoPopover';
import { useAutoSaveInvestState } from 'components/feature/autoSaveInvest/AutoSaveInvestContext';
import { useMode } from 'components/feature/mode/useMode';
import { colors } from 'constants/colors';
import { currencyFull, percent3dp } from 'formatting';
import {
  WrapperType,
  useAccountsQuery,
  useBuyOrderDetailsByAssetQuery,
  useInstrumentsByIsinsQuery,
} from 'generated/graphql';
import { getShortNameForWrapperType } from 'helpers/accountHelpers';
import { isNumberInputValid } from 'helpers/inputHelpers';
import numeral from 'numeral';
import 'numeral/locales/en-gb';
import { generateFundDetailsPath } from 'paths';
import { Controller, useForm } from 'react-hook-form';
import { HiExternalLink } from 'react-icons/hi';
import { AssetQueryAsset, SearchAssetsQueryAsset } from 'types/graphqlTypes';
import * as Yup from 'yup';
import { MinTradeUnitStatus } from '../../_shared/MinTradeUnitStatus';
import {
  OverAllocatedMsg,
  PercentageAmountOr,
  PercentageAmountWrapper,
  PercentageWrapper,
  SelectFundsFormContainer,
  SelectInstrumentWrapper,
  ShareClassWrapper,
} from '../AddToFundToRecurringOrder.styles';

const selectFundsFormSchema = Yup.object().shape({
  isin: Yup.string().label('Instrument').required(),
  amount: Yup.number()
    .transform((value, originalValue) =>
      originalValue === '' ? undefined : value
    )
    .label('Amount')
    .min(0, 'The amount must be more than 0')
    .required(),
  percentage: Yup.number()
    .transform((value, originalValue) =>
      originalValue === '' ? undefined : value
    )
    .label('Percentage')
    .min(0, 'The percentage must be more than 0.001')
    .max(100, 'The percentage must be less than 100')
    .required(),
});

interface EnterAmountStepFormValues {
  assetId: string;
  isin: string;
  amount: string;
  percentage: string;
}

const parseFloatStringOrNumber = (value: string | number) => {
  return typeof value === 'string' ? parseFloat(value) : value;
};

interface OrderShortfallInfoContentProps {
  remainingToAllocatePercentage: number;
  totalAllocationProportion: number;
  orders: { isin: string; proportion: number }[];
}

const OrderShortfallInfoContent = ({
  remainingToAllocatePercentage,
  totalAllocationProportion,
  orders,
}: OrderShortfallInfoContentProps) => {
  const isins = orders.map(({ isin }) => isin);
  const instrumentsByIsinQuery = useInstrumentsByIsinsQuery(
    { isins: isins },
    {
      enabled: isins && isins?.length > 0,
    }
  );
  const instruments = instrumentsByIsinQuery.data?.instrumentsByIsins?.nodes;

  return (
    <div>
      {remainingToAllocatePercentage < 0 ? (
        <>
          <TextSmall>
            This is your recurring order shortfall amount. It adds the total of
            all the funds in your draft regular order.
          </TextSmall>
        </>
      ) : (
        <TextSmall>
          This is the amount you have available to invest in funds. It takes
          into account both your account cash balance and the net value of buy
          and sell orders in your basket.
        </TextSmall>
      )}
      <QueryState {...instrumentsByIsinQuery}>
        {() => {
          if (!instruments) {
            return null;
          }
          return (
            <>
              {orders
                .filter((order) => order.proportion > 0)
                .map((order) => {
                  const instrument = instruments.find(
                    (instrument) => instrument.isin === order.isin
                  );
                  return (
                    <KeyValue
                      label={instrument?.name}
                      value={percent3dp(order.proportion, undefined, false)}
                    />
                  );
                })}
              <KeyValue
                label="Total"
                value={percent3dp(totalAllocationProportion, undefined, false)}
              />
            </>
          );
        }}
      </QueryState>
      <br />
      {remainingToAllocatePercentage < 0 && (
        <>
          <TextSmall>
            You can carry on adding more funds to the order - but you'll need to
            make sure the total allocation percentage is 100% or less before you
            can confirm the changes.
          </TextSmall>
        </>
      )}
    </div>
  );
};

function AddToRecurringOrderAmountStepInner({
  wrapperType,
  selectedAsset,
  selectedIsin,
  selectedAccountId,
  handleClose,
  onGoBack,
}: AddToRecurringOrderAmountStepProps) {
  const [, setMode] = useMode();
  const {
    state,
    setState,
    totalDepositAmount,
    updateAutoSaveInvestDraftState,
  } = useAutoSaveInvestState(selectedAccountId);

  const accountsQuery = useAccountsQuery(undefined, {
    enabled: !!selectedAccountId,
  });
  const currentAccount = accountsQuery.data?.accounts?.find(
    (acc) => acc.id === selectedAccountId
  );

  const assetIsins = selectedAsset?.instruments?.nodes.map((i) => i.isin) || [];

  const assetOrder = state?.orders?.find(({ isin }) =>
    assetIsins.includes(isin)
  );

  const methods = useForm({
    resolver: yupResolver(selectFundsFormSchema),
    mode: 'onBlur',
    context: {
      assetInstrumentCount: selectedAsset?.instruments.nodes.length ?? 0,
    },
    defaultValues: {
      isin: selectedIsin,
      amount: assetOrder
        ? `${numeral(totalDepositAmount * (assetOrder?.proportion || 0)).format(
            '0,0.00'
          )}`
        : '',
      percentage: assetOrder
        ? `${numeral(assetOrder.proportion * 100).format('0,0.[000]')}`
        : '',
    },
  });

  const { register, handleSubmit, setValue, control, errors, watch } = methods;

  const watchAmount = parseFloatStringOrNumber(watch('amount')) || 0;
  const watchPercentage = parseFloat(watch('percentage')) || 0;
  const watchIsin = watch('isin') || 0;

  const totalAllocationProportion =
    (state?.orders?.reduce((acc, order) => {
      if (order.isin === watchIsin) {
        return acc;
      }
      return acc + order.proportion;
    }, 0) ?? 0) +
    watchPercentage / 100;
  const totalAllocationAmount = totalDepositAmount * totalAllocationProportion;

  const selectedInstrument = selectedAsset?.instruments.nodes.find(
    ({ isin }) => isin === watchIsin
  );

  const buyOrderDetailsByAssetQuery = useBuyOrderDetailsByAssetQuery(
    {
      assetId: `${selectedAsset?.id || ''}`,
      accountId: selectedAccountId ? selectedAccountId : null,
    },
    { enabled: !!selectedAsset }
  );
  const buyOrderDetails =
    buyOrderDetailsByAssetQuery?.data?.buyOrderDetailsByAsset;
  const charges = buyOrderDetails?.charges;

  const remainingToAllocatePercentage = 1 - totalAllocationProportion;

  const hasExisting = !!state?.orders?.some(
    (order) => order.isin === watchIsin
  );

  const onSubmit = async (data: EnterAmountStepFormValues) => {
    setMode({
      mode: 'autoSaveInvest',
      wrapperType: wrapperType!,
    });

    const orders = [
      ...(state?.orders?.filter((order) => order.isin !== data.isin) || []),
      {
        isin: data.isin,
        proportion: parseFloat(data.percentage) / 100,
        isNew: !hasExisting,
        isEdited: hasExisting,
        isDeleted: false,
      },
    ];

    await setState({
      deposits: state?.deposits || [],
      orders: orders,
    });

    handleClose();
  };

  const amountFigures = (
    <>
      {wrapperType !== WrapperType.Sipp && totalDepositAmount > 0 ? (
        <>
          {currencyFull(Math.abs(totalDepositAmount - totalAllocationAmount))} (
          {percent3dp(
            Math.abs(remainingToAllocatePercentage),
            undefined,
            false
          )}
          )
        </>
      ) : (
        <>
          {percent3dp(
            Math.abs(remainingToAllocatePercentage),
            undefined,
            false
          )}
        </>
      )}
      <InfoPopoverV2
        size="small"
        $width="normal"
        content={
          <OrderShortfallInfoContent
            remainingToAllocatePercentage={remainingToAllocatePercentage}
            totalAllocationProportion={totalAllocationProportion}
            orders={[
              ...(state?.orders?.filter(
                (order) => order.isin !== selectedIsin
              ) || []),
              {
                isin: selectedIsin,
                proportion: watchPercentage / 100,
              },
            ]}
          />
        }
      />
    </>
  );
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {(wrapperType === WrapperType.Sipp || totalDepositAmount === 0) && (
        <input id="amount" name="amount" type="hidden" ref={register()} />
      )}
      <StepContainer>
        <StepContent>
          <PillContainer>
            <Pill $filled $color="buy">
              Recurring order
            </Pill>
            {wrapperType && wrapperType !== WrapperType.Unknown && (
              <Pill>{getShortNameForWrapperType(wrapperType)}</Pill>
            )}
          </PillContainer>
          <SelectFundsFormContainer>
            <div>
              <H4 $noMargin>{selectedInstrument?.name}</H4>
              {selectedAsset && (
                <FundDoLink
                  to={{
                    pathname: generateFundDetailsPath({
                      id: selectedAsset?.id!,
                      slug: selectedAsset?.slug!,
                    }),
                    state: {
                      shouldGoBack: true,
                    },
                  }}
                  target="_blank"
                >
                  What does this fund do? <HiExternalLink />
                </FundDoLink>
              )}
            </div>
            <div
              style={{
                padding: '1.5rem 0',
                border: `1px solid ${colors.midGrey}`,
                borderWidth: '1px 0',
                margin: '1rem 0',
              }}
            >
              <SelectInstrumentWrapper
                style={{
                  display:
                    !selectedInstrument?.isTargetDateFund &&
                    selectedAsset?.instruments?.nodes &&
                    selectedAsset?.instruments?.nodes.length > 1
                      ? 'inline'
                      : 'none',
                }}
              >
                <Controller
                  control={control}
                  error={errors.isin}
                  name="isin"
                  render={({ onChange, value }) => (
                    <>
                      <ShareClassWrapper>
                        <InstrumentSelectorSelect
                          account={currentAccount}
                          instrument={selectedInstrument!}
                          instruments={selectedAsset?.instruments?.nodes || []}
                          onChange={(isin) => {
                            onChange(isin, { shouldValidate: true });
                          }}
                          value={value}
                          $color="secondary"
                        />
                        <ShareClassInfoPopover size="xs" />
                      </ShareClassWrapper>
                      {selectedInstrument?.isCustomUniverse && (
                        <ExclusivePillWrapper>
                          <ExclusivePill $canHover={false}>
                            Employer Exclusive
                          </ExclusivePill>
                        </ExclusivePillWrapper>
                      )}
                    </>
                  )}
                />
                {errors.isin && (
                  <FormHelperText error>{errors.isin.message}</FormHelperText>
                )}
              </SelectInstrumentWrapper>
              <PercentageAmountWrapper>
                {wrapperType !== WrapperType.Sipp && totalDepositAmount > 0 && (
                  <>
                    <PercentageWrapper>
                      <AmountInput
                        id="amount"
                        name="amount"
                        ref={register()}
                        defaultValue={0}
                        inputMode="decimal"
                        onKeyDown={(ev) => {
                          if (!isNumberInputValid(ev)) {
                            ev.preventDefault();
                          }
                        }}
                        onKeyUp={(ev) => {
                          if (totalDepositAmount === 0) {
                            setValue(`percentage`, 0);
                            return;
                          }

                          const newValue = parseFloat(ev.currentTarget.value);
                          setValue(
                            `percentage`,
                            Number.isNaN(newValue) || newValue < 0
                              ? ''
                              : Math.round(
                                  (newValue / totalDepositAmount) * 100000
                                ) / 1000
                          );
                        }}
                      />
                    </PercentageWrapper>
                    <PercentageAmountOr>/</PercentageAmountOr>
                  </>
                )}
                <PercentageWrapper>
                  <PercentageInput
                    id="percentage"
                    name="percentage"
                    ref={register()}
                    defaultValue={0}
                    inputMode="decimal"
                    placeholder="0"
                    onKeyDown={(ev) => {
                      if (!isNumberInputValid(ev)) {
                        ev.preventDefault();
                      }
                    }}
                    onKeyUp={(ev) => {
                      const newValue = parseFloat(ev.currentTarget.value);
                      setValue(
                        'amount',
                        Number.isNaN(newValue) || newValue < 0
                          ? ''
                          : (
                              Math.round(totalDepositAmount * newValue) / 100
                            ).toFixed(2)
                      );
                    }}
                  />
                </PercentageWrapper>
              </PercentageAmountWrapper>

              {(errors.amount?.message || errors.percentage?.message) && (
                <FormHelperText error>
                  {errors.amount?.message || errors.percentage?.message}
                </FormHelperText>
              )}

              {totalAllocationProportion > 1 ? (
                <OverAllocatedMsg>
                  <KeyValue label="Shortfall" value={amountFigures} />
                </OverAllocatedMsg>
              ) : (
                <KeyValue label="Remaining to allocate" value={amountFigures} />
              )}
            </div>

            {selectedInstrument &&
              selectedInstrument.askPrice &&
              wrapperType && (
                <MinTradeUnitStatus
                  amount={watchAmount}
                  askPrice={selectedInstrument.askPrice}
                  minimumTradeUnit={selectedInstrument.minimumTradeUnit}
                />
              )}

            {selectedInstrument && (
              <QueryState {...buyOrderDetailsByAssetQuery}>
                {() => (
                  <ImportantBuyInformation
                    selectedInstrument={selectedInstrument}
                    amountEntered={watchAmount}
                    charges={charges}
                    existingPosition={undefined}
                  />
                )}
              </QueryState>
            )}
          </SelectFundsFormContainer>
        </StepContent>

        {updateAutoSaveInvestDraftState.isError && (
          <Alert severity={Severity.error} padding="small">
            Something went wrong, please wait and then try again.
          </Alert>
        )}

        <StepActions>
          <StepButton
            className="magenta"
            type="submit"
            disabled={updateAutoSaveInvestDraftState.isLoading}
          >
            Add selected fund
          </StepButton>
          {onGoBack && (
            <GoBackButton
              onClick={onGoBack}
              disabled={updateAutoSaveInvestDraftState.isLoading}
            />
          )}
        </StepActions>
      </StepContainer>
    </form>
  );
}

interface AddToRecurringOrderAmountStepProps {
  selectedAsset: SearchAssetsQueryAsset | AssetQueryAsset;
  selectedIsin: string;
  handleClose: () => void;
  wrapperType: WrapperType | null;
  selectedAccountId: string;
  onGoBack?: () => void;
}

export function AddToRecurringOrderAmountStep({
  wrapperType,
  selectedAsset,
  selectedIsin,
  selectedAccountId,
  handleClose,
  onGoBack,
}: AddToRecurringOrderAmountStepProps) {
  const {
    recurringTransactionsQuery,
    userProfileQuery,
    state,
  } = useAutoSaveInvestState(selectedAccountId);

  return (
    <QueryState {...userProfileQuery}>
      {() => {
        return (
          <QueryState {...recurringTransactionsQuery}>
            {() => {
              if (state === undefined) {
                return <Loading />;
              }
              return (
                <AddToRecurringOrderAmountStepInner
                  wrapperType={wrapperType}
                  selectedAsset={selectedAsset}
                  selectedIsin={selectedIsin}
                  selectedAccountId={selectedAccountId}
                  handleClose={handleClose}
                  onGoBack={onGoBack}
                />
              );
            }}
          </QueryState>
        );
      }}
    </QueryState>
  );
}
