import { DialogActions, DialogContent, Typography } from '@material-ui/core';
import {
  ButtonWrapper,
  CustomButtonV2,
} from 'components/design-system/Button/CustomButtonV2';
import React from 'react';
import { StyledTitle } from '../styles/Deposit.styles';

interface TemporaryUnavailableProps {
  onPayManually: () => void;
  onTryLater: () => void;
}

export function TemporaryUnavailable({
  onPayManually,
  onTryLater,
}: TemporaryUnavailableProps) {
  return (
    <>
      <StyledTitle disableTypography>
        <Typography variant="subtitle1" component="h1">
          Payment by OpenBanking is temporarily unavailable
        </Typography>
      </StyledTitle>
      <DialogContent>
        <Typography>
          We're having temporary issues taking payments via OpenBanking just
          now, but you can still pay by manual bank transfer.
        </Typography>
      </DialogContent>
      <DialogActions>
        <ButtonWrapper $align="center">
          <CustomButtonV2
            onClick={() => onPayManually()}
            $color="secondary"
            fullWidth
          >
            Manual transfer
          </CustomButtonV2>
          <CustomButtonV2
            onClick={() => onTryLater()}
            $color="secondary"
            fullWidth
          >
            Try again later
          </CustomButtonV2>
        </ButtonWrapper>
      </DialogActions>
    </>
  );
}
