import { TextField } from '@material-ui/core';
import { colors } from 'constants/colors';
import styled from 'styled-components';

export const StyledTextField = styled(TextField)`
  &.MuiTextField-root {
    label {
      font-size: 0.75rem;
      font-weight: 500;

      &.Mui-disabled {
        color: ${colors.richBlack};
      }
    }
    .MuiInput-root {
      margin-top: 0.75rem;
      input,
      textarea {
        font-size: 0.875rem;
        font-weight: 300;
      }

      &.Mui-disabled {
        color: ${colors.richBlack};
        &:before {
          border-bottom: 1px solid ${colors.richBlack};
        }
      }
    }
  }
`;
