import { colors } from 'constants/colors';
import { transparentize } from 'polished';
import { HiChevronDown } from 'react-icons/hi';
import styled from 'styled-components';

export const TableBreakdownWrapper = styled.div`
  width: 100%;
`;

interface TableRowLayoutProps {
  $hasDescription?: boolean;
  $altBackground?: boolean;
}
export const TableRowLayout = styled.div<TableRowLayoutProps>`
  display: grid;
  grid-template-columns: ${({ $hasDescription }) =>
    $hasDescription ? '2fr 1fr 2rem' : '2fr 1fr'};
  justify-content: space-between;
  align-items: center;
  min-height: 60px;
  gap: 2rem;
  padding: 0 0.5rem;
  cursor: ${({ $hasDescription }) => ($hasDescription ? 'pointer' : 'auto')};
  background: ${({ $altBackground }) =>
    $altBackground && transparentize(0.5, colors['grey-100'])};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    gap: 4rem;
    padding: 0 1rem;
  }
`;

interface RowDividerProps {
  $isBold?: boolean;
}
export const RowDivider = styled.hr<RowDividerProps>`
  width: auto;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ $isBold }) =>
    $isBold ? colors.darkGrey : colors.midGrey};
  margin: 0;
`;

interface RowDescriptionContainerProps {
  $isExpanded: boolean;
}
export const RowDescriptionContainer = styled.div<RowDescriptionContainerProps>`
  overflow: hidden;
  max-height: ${({ $isExpanded }) => ($isExpanded ? '120rem' : '0')};
  transition-property: max-height;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
`;

export const RowDescriptionLayout = styled.div`
  padding: 0.5rem 0.5rem 1.5rem;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: 0.5rem 1rem 1.5rem;
  }
`;

interface LogoNameContainerProps {
  $hasLogo?: boolean;
}
export const LogoNameContainer = styled.div<LogoNameContainerProps>`
  display: grid;
  grid-template-columns: ${({ $hasLogo }) => ($hasLogo ? '40px auto' : 'auto')};
  grid-template-areas: ${({ $hasLogo }) =>
    $hasLogo ? "'logo name'" : "'name'"};
  gap: 1rem;
  align-items: center;

  img {
    grid-area: logo;
  }
  p {
    grid-area: name;
  }
`;

export interface StyledChevronIconProps {
  $isActive: boolean;
}
export const StyledChevronIcon = styled.button<StyledChevronIconProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  width: 2rem;
  border: 0;
  padding: 0 0.25rem;
  border-radius: 100%;
  justify-self: flex-end;
  background-color: transparent;
  color: ${colors.richBlack};
  cursor: pointer;
  &:hover,
  &:focus {
    background-color: ${colors.grey};
    color: ${colors.richBlack};
    outline: none;
  }
`;

interface ChevronIconButtonProps {
  $isActive: boolean;
}
export const ChevronIconButton = styled(HiChevronDown)<ChevronIconButtonProps>`
  transform: ${({ $isActive }) =>
    $isActive ? 'rotate(-180deg)' : 'rotate(0)'};
  transition: transform 0.35s;
`;

export const DrillDownLine = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 2fr 1fr 2rem;
  justify-content: space-between;
  align-items: center;
  gap: 4rem;
  padding: 0.5rem 0;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(100% - 6rem);
    height: 1px;
    background-color: ${colors['grey-200']};
  }

  &:last-child {
    &:after {
      display: none;
    }
  }
`;

export const ShowAllButton = styled.button`
  background: none;
  padding: 0%;
  color: ${colors.magenta};
  border: none;
  cursor: pointer;
`;
