import {
  ResponsiveDialog,
  ResponsiveDialogProps,
} from 'components/Dialogs/ResponsiveDialog';
import React from 'react';
import { QRCode } from './QRCode';
import { CodeContainer, DialogContentContainer } from './ReferralsCard.styles';

interface QRCodeDialogProps extends ResponsiveDialogProps {
  url: string;
}

export function QRCodeDialog({ url, ...props }: QRCodeDialogProps) {
  return (
    <ResponsiveDialog {...props} fullScreen>
      <DialogContentContainer>
        <CodeContainer>
          <QRCode value={url!} size={260} />
        </CodeContainer>
      </DialogContentContainer>
    </ResponsiveDialog>
  );
}
