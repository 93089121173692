import { yupResolver } from '@hookform/resolvers/yup';
import { QueryState } from 'components/QueryState';
import {
  NullableRadioFormState,
  YesNoRadios,
} from 'components/design-system/FormElements/YesNoRadios';
import { ServerError } from 'components/design-system/ServerError/ServerError';
import {
  StepActions,
  StepContainer,
  StepContent,
  StepContentWidth,
  StepIntroductionTypography,
  StepText,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import { FontWeight, TextNormal } from 'components/design-system/Text/Text';
import { GaEventNames, OnboardingStepNames } from 'constants/gaConstants';
import { currencyFull } from 'formatting';
import {
  IllustrationQuery,
  useIllustrationQuery,
  useUpdateIllustrationPaymentsMutation,
} from 'generated/graphql';
import { trackGa } from 'helpers/track';
import {
  FieldError,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useQueryClient } from 'react-query';
import * as Yup from 'yup';
import { FormActions } from '../_shared/FormActions';
import { MoreInfoWrapper } from '../_shared/steps.styles';
import {
  DetailsFieldLabelWrapper,
  RadiosWrapper,
  RootErrorMessage,
  StyledTextField,
  TaxReclaimLabelContent,
} from './FundingChoicesStep.styles';

function useInvalidateMutatedQueries() {
  const queryClient = useQueryClient();

  return async () => {
    await queryClient.invalidateQueries(useIllustrationQuery.getKey());
  };
}

function getBooleanFormValueString(
  field: number | undefined
): NullableRadioFormState {
  if (field === undefined) {
    return undefined;
  }
  return field > 0 ? 'true' : 'false';
}

const booleanSchema = Yup.boolean()
  .typeError('Please answer yes or no')
  .required();

const amountSchema = Yup.number()
  .typeError('Please enter a valid amount between £0 and £10,000,000')
  .min(0, 'The amount must be between £0 and £10,000,000')
  .max(10000000, 'The amount must be less than or equal to £10,000,000')
  .nullable()
  .transform((_, value) => (value === '' ? null : Number(value)));

const amountSchemaRequired = Yup.number()
  .typeError('Please enter a valid amount between £0 and £10,000,000')
  .min(0, 'The amount must be between £0 and £10,000,000')
  .max(10000000, 'The amount must be less than or equal to £10,000,000')
  .required()
  .transform((_, value) => (value === '' ? null : Number(value)));

const monthlyAmountSchema = Yup.number()
  .typeError('Please enter a valid amount between £0 and £5,000')
  .min(0, 'The amount must be between £0 and £5,000')
  .max(5000, 'The amount must be less than or equal to £5,000')
  .nullable()
  .transform((_, value) => (value === '' ? null : Number(value)));

const monthlyAmountSchemaRequired = Yup.number()
  .typeError('Please enter a valid amount between £0 and £5,000')
  .min(0, 'The amount must be between £0 and £5,000')
  .max(5000, 'The amount must be less than or equal to £5,000')
  .required()
  .transform((_, value) => (value === '' ? null : Number(value)));

const fundingChoicesFormSchema = Yup.object({
  byLumpSum: booleanSchema,
  initialPayment: amountSchema.when(['byLumpSum'], {
    is: (byLumpSum: boolean) => byLumpSum === true,
    then: amountSchemaRequired,
  }),
  byMonthlyEmployeeContribution: booleanSchema,
  monthlyEmployeeContribution: monthlyAmountSchema.when(
    ['byMonthlyEmployeeContribution'],
    {
      is: (byMonthlyEmployeeContribution: boolean) =>
        byMonthlyEmployeeContribution === true,
      then: monthlyAmountSchemaRequired,
    }
  ),
  byTransferFromAnotherProvider: booleanSchema,
  transferFromAnotherProvider: amountSchema.when(
    ['byTransferFromAnotherProvider'],
    {
      is: (byTransferFromAnotherProvider: boolean) =>
        byTransferFromAnotherProvider === true,
      then: amountSchemaRequired,
    }
  ),
}).test({
  name: 'root',
  message: 'You must specify at least one funding method above.',
  test: (value, { options }) => {
    const skipAnyRequired = options.context?.skipAnyRequired;
    if (skipAnyRequired) {
      return true;
    }

    return !!(
      value?.initialPayment ||
      value?.transferFromAnotherProvider ||
      value?.monthlyEmployeeContribution
    );
  },
});

type FundingChoicesStepFormValues = Yup.InferType<
  typeof fundingChoicesFormSchema
>;

interface FundingChoicesStepFormProps extends FundingChoicesStepProps {
  data: IllustrationQuery['illustration'];
}

function FundingChoicesStepForm({
  source,
  onProceed,
  onGoBack,
  data,
  hasEmployer,
}: FundingChoicesStepFormProps) {
  const {
    mutateAsync: updateIllustration,
    isError,
    reset,
  } = useUpdateIllustrationPaymentsMutation();

  const invalidateQueries = useInvalidateMutatedQueries();

  const hasPayrollContributions =
    (data?.monthlyEmployeeViaPayrollContribution?.amount ?? 0) > 0 ||
    (data?.monthlyEmployerContribution?.amount ?? 0) > 0;

  const defaultValues = {
    byLumpSum: getBooleanFormValueString(data?.initialPayment?.amount),
    initialPayment: data?.initialPayment?.amount ?? null,
    byTransferFromAnotherProvider: getBooleanFormValueString(
      data?.transferFromAnotherProvider?.amount
    ),
    transferFromAnotherProvider:
      data?.transferFromAnotherProvider?.amount ?? null,
    byMonthlyEmployeeContribution: getBooleanFormValueString(
      data?.monthlyEmployeeContribution?.amount
    ),
    monthlyEmployeeContribution:
      data?.monthlyEmployeeContribution?.amount ?? null,
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(fundingChoicesFormSchema),
    context: {
      skipAnyRequired: hasPayrollContributions,
    },
  });

  const {
    watch,
    handleSubmit,
    formState: { isDirty, isSubmitting, errors },
    register,
  } = methods;

  const rootError: FieldError = (errors as any).root;

  const onSubmit: SubmitHandler<FundingChoicesStepFormValues> = async (
    data
  ) => {
    reset();
    try {
      if (isDirty) {
        const toPayload = (value: number | null | undefined) =>
          value ? { amount: value } : { amount: 0 };

        await updateIllustration({
          input: {
            initialPayment: toPayload(data.initialPayment),
            directMonthlyContribution: toPayload(
              data.monthlyEmployeeContribution
            ),
            transferFromOtherProviders: toPayload(
              data.transferFromAnotherProvider
            ),
          },
        });
        await invalidateQueries();
      }

      if (source === 'openAccountStep') {
        trackGa({
          event: GaEventNames.onboarding,
          onboardingStep: OnboardingStepNames.fundingChoices,
        });
      }
      onProceed();
    } catch {
      // error handled by state
    }
  };

  const byMonthlyEmployeeContribution = watch('byMonthlyEmployeeContribution');
  const byLumpSum = watch('byLumpSum');
  const byTransferFromAnotherProvider = watch('byTransferFromAnotherProvider');

  const monthlyEmployeeContributionAmount = watch(
    'monthlyEmployeeContribution'
  );
  const initialPaymentAmount = watch('initialPayment');

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormProvider {...methods}>
          <StepContainer>
            <StepTitle>How will you be funding your pension?</StepTitle>
            <StepContent width={StepContentWidth.extraWide}>
              <StepText>
                <StepIntroductionTypography>
                  To better illustrate how your pension value might change over
                  time so that you can determine if this is the right choice for
                  you, we need to learn a bit more about how you want to fund
                  your TILLIT Pension.
                </StepIntroductionTypography>
                <StepIntroductionTypography>
                  Fill in the details as best you can.
                </StepIntroductionTypography>
              </StepText>
            </StepContent>
            <ServerError isVisible={isError} />

            <StepContent width={StepContentWidth.extraWide}>
              <RadiosWrapper>
                <YesNoRadios
                  name="byMonthlyEmployeeContribution"
                  register={register}
                  error={errors.byMonthlyEmployeeContribution}
                  defaultValue={defaultValues.byMonthlyEmployeeContribution}
                  label={
                    <TextNormal $noMargin>
                      By topping up from your bank account monthly?
                    </TextNormal>
                  }
                />
                {byMonthlyEmployeeContribution === 'true' && (
                  <MoreInfoWrapper>
                    <DetailsFieldLabelWrapper>
                      <StyledTextField
                        name="monthlyEmployeeContribution"
                        id="employeeContribution"
                        data-testid="employeeContribution-input"
                        label=""
                        register={register}
                        hideLabel={true}
                        startAdornment="£"
                        $noMargin
                      />
                      <TextNormal $noMargin $fontWeight={FontWeight.medium}>
                        per month
                        {(monthlyEmployeeContributionAmount ?? 0) > 0 && (
                          <TaxReclaimLabelContent>{` (+${currencyFull(
                            0.25 * monthlyEmployeeContributionAmount!
                          )} in basic rate tax relief)`}</TaxReclaimLabelContent>
                        )}
                      </TextNormal>
                    </DetailsFieldLabelWrapper>
                  </MoreInfoWrapper>
                )}
                <YesNoRadios
                  name="byLumpSum"
                  register={register}
                  error={errors.byLumpSum}
                  defaultValue={defaultValues.byLumpSum}
                  label={
                    <TextNormal $noMargin>
                      By adding a cash lump sum?
                    </TextNormal>
                  }
                />
                {byLumpSum === 'true' && (
                  <MoreInfoWrapper>
                    <DetailsFieldLabelWrapper>
                      <StyledTextField
                        name="initialPayment"
                        id="lumpSum"
                        register={register}
                        label=""
                        hideLabel={true}
                        startAdornment="£"
                        $noMargin
                      />
                      <TextNormal $noMargin>
                        {(initialPaymentAmount ?? 0) > 0
                          ? ` (+${currencyFull(
                              0.25 * initialPaymentAmount!
                            )} in basic rate tax relief)`
                          : ''}
                      </TextNormal>
                    </DetailsFieldLabelWrapper>
                  </MoreInfoWrapper>
                )}
                <YesNoRadios
                  name="byTransferFromAnotherProvider"
                  register={register}
                  error={errors.byTransferFromAnotherProvider}
                  defaultValue={defaultValues.byTransferFromAnotherProvider}
                  label={
                    <TextNormal $noMargin>
                      By transferring or consolidating other pensions into this
                      one?
                    </TextNormal>
                  }
                />
                {byTransferFromAnotherProvider === 'true' && (
                  <MoreInfoWrapper>
                    <label htmlFor="transferField">
                      Approximate value to transfer from other pension pots
                    </label>
                    <DetailsFieldLabelWrapper>
                      <StyledTextField
                        name="transferFromAnotherProvider"
                        id="transferField"
                        label=""
                        hideLabel={true}
                        startAdornment="£"
                      />
                    </DetailsFieldLabelWrapper>
                    <TextNormal $noMargin>
                      Not all pension schemes can be transferred in (for example
                      NHS or Civil Service pensions), and if you want to
                      transfer from a Defined Benefit (or 'Final Salary')
                      pension scheme we may need you to seek financial advice
                      first.
                    </TextNormal>
                  </MoreInfoWrapper>
                )}
              </RadiosWrapper>
            </StepContent>
            <StepActions>
              {rootError && (
                <RootErrorMessage error={true}>
                  {rootError.message}
                </RootErrorMessage>
              )}
              <FormActions onGoBack={onGoBack} disabled={isSubmitting} />
            </StepActions>
          </StepContainer>
        </FormProvider>
      </form>
    </>
  );
}

interface FundingChoicesStepProps {
  source?: 'openAccountStep';
  onProceed: () => void;
  onGoBack: () => void;
  hasEmployer: boolean;
}

export function FundingChoicesStep(props: FundingChoicesStepProps) {
  const illustrationQuery = useIllustrationQuery();

  return (
    <QueryState {...illustrationQuery}>
      {(queryResult) => (
        <FundingChoicesStepForm
          {...props}
          data={queryResult.data?.illustration}
        />
      )}
    </QueryState>
  );
}
