import { Checkbox } from '@material-ui/core';
import { StyledLink } from 'components/design-system/Link';
import { IsaDeclarationCopy } from 'components/IsaDeclaration/IsaDeclarationCopy';
import { GaEventNames, OnboardingStepNames } from 'constants/gaConstants';

import {
  AccountType,
  AffirmationKind,
  useMakeAffirmationMutation,
  useUserProfileQuery,
} from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { useIsaRuleset } from 'hooks/useIsaRuleset';
import { useOpenAccount } from 'hooks/useOpenAccount';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import {
  SkipButton,
  StepActions,
  StepButton,
  StepContainer,
  StepContent,
  StepContentWidth,
  StepIntroduction,
  StepIntroductionTypography,
  StepIntroductionWidth,
} from '../../../../design-system/StepComponents/StepComponents';
import {
  AffirmationsContainer,
  CopyContainer,
  CustomFormControlLabel,
  DownloadContainer,
  DownloadIcon,
  DownloadLink,
  GiaLink,
  IsaStepTitle,
} from './IsaDeclarationStep.styles';

interface IsaDeclarationStepProps {
  onProceed: () => void;
  onGoBack?: () => void;
  onSwitchToGia?: () => void;
  shouldSetInitialAccount?: boolean;
}

export function IsaDeclarationStep({
  onProceed,
  onGoBack,
  onSwitchToGia,
  shouldSetInitialAccount = false,
  ...props
}: IsaDeclarationStepProps) {
  const { openAccount } = useOpenAccount({ onProceed });

  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useMakeAffirmationMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(useUserProfileQuery.getKey());
    },
  });

  const handleContinue = async (madeAffirmations: AffirmationKind[]) => {
    await mutateAsync({
      input: {
        affirmations: madeAffirmations,
      },
    });

    trackGa({
      event: GaEventNames.onboarding,
      onboardingStep: OnboardingStepNames.isaDeclaration,
    });

    if (shouldSetInitialAccount) {
      // Set initial account
      openAccount(AccountType.Isa, ''); // @todo - set source
    } else {
      onProceed();
    }
  };

  return (
    <IsaDeclarationStepContent
      {...props}
      onContinue={handleContinue}
      onSwitchToGia={onSwitchToGia}
      isLoading={isLoading}
      onCancel={onGoBack}
    />
  );
}

interface IsaDeclarationStepContentProps {
  onSwitchToGia?: () => void;
  onCancel?: () => void;
  onContinue: (madeAffirmations: AffirmationKind[]) => void;
  showIntro?: boolean;
  isLoading?: boolean;
}

export function IsaDeclarationStepContent({
  showIntro,
  onSwitchToGia,
  onContinue,
  onCancel,
  isLoading,
}: IsaDeclarationStepContentProps) {
  const [kfdDeclaration, setKfdDeclaration] = useState(false);
  const { affirmationsRequired, kfd, declarationPdf } = useIsaRuleset();

  const handleContinue = () => {
    onContinue(getMadeAffirmations());
  };

  const getMadeAffirmations = () => {
    const madeAffirmations = [];
    if (kfdDeclaration) {
      madeAffirmations.push(AffirmationKind.ReadAndUnderstoodIsaKfd);
    }
    return madeAffirmations;
  };

  const hasAllAffirmations = () => {
    const madeAffirmations = getMadeAffirmations();
    return affirmationsRequired.every((aff) => madeAffirmations.includes(aff));
  };

  return (
    <StepContainer>
      <StepContent width={StepContentWidth.extraWide}>
        <IsaStepTitle>ISA declaration</IsaStepTitle>
        {showIntro && (
          <StepIntroduction mb={2} $width={StepIntroductionWidth.xxWide}>
            <StepIntroductionTypography>
              Because of the tax perks that come with an ISA, HMRC require you
              to make a declaration when applying to open a new ISA account.
              This is to make sure that you are eligible to fund a new ISA
              during the current financial year.
            </StepIntroductionTypography>

            <StepIntroductionTypography>
              Already opened a Stocks & Shares ISA this year? You can transfer
              it to TILLIT in just a few steps and our Transfer Concierge will
              keep you in the loop every step of the way.{' '}
            </StepIntroductionTypography>
          </StepIntroduction>
        )}
      </StepContent>
      <StepContent width={StepContentWidth.wide}>
        <CopyContainer>
          <IsaDeclarationCopy />
        </CopyContainer>
        <AffirmationsContainer>
          <CustomFormControlLabel
            control={
              <Checkbox
                checked={kfdDeclaration}
                onChange={(e) => setKfdDeclaration(e.target.checked)}
              />
            }
            label={
              <>
                I have read and understood the key features of this investment
                product.{' '}
                <StyledLink href={kfd} target="_blank">
                  Read the Key Features Document (KFD)
                </StyledLink>
                .
              </>
            }
          />
        </AffirmationsContainer>
        <div>
          <DownloadContainer>
            <DownloadIcon />
            <DownloadLink
              href={declarationPdf}
              target="_blank"
              underline="none"
            >
              Download PDF copy of my ISA declaration
            </DownloadLink>
          </DownloadContainer>
        </div>
      </StepContent>
      <StepActions>
        <StepButton
          className="magenta"
          onClick={handleContinue}
          disabled={!hasAllAffirmations() || isLoading}
        >
          Continue
        </StepButton>
      </StepActions>
      {onSwitchToGia && (
        <GiaLink
          style={{
            whiteSpace: 'break-spaces',
          }}
          onClick={onSwitchToGia}
          underline="always"
        >
          Want to open a GIA instead?
        </GiaLink>
      )}
      {onCancel && (
        <SkipButton variant="outlined" className="richBlue" onClick={onCancel}>
          Cancel
        </SkipButton>
      )}
    </StepContainer>
  );
}
