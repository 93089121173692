import { useAuth } from 'context/AuthContext';
import { WrapperType } from 'generated/graphql';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLocalStorage } from 'usehooks-ts';

export type modeType = 'buy' | 'autoSaveInvest';

type ModeAccount = {
  wrapperType: WrapperType | undefined;
  mode: modeType;
};

type ModeResume = {
  mode: 'resume';
};

export type Mode = ModeAccount | ModeResume;

const voidFn = () => {};

type UseModeType = [Mode | null, (newMode: Mode | null) => void];

const modeContext = createContext<UseModeType | undefined>(undefined);

function useModeContext(): [Mode | null, (newMode: Mode | null) => void] {
  const [localStorageMode, setLocalStorageMode] = useLocalStorage<Mode | null>(
    'tillit.mode',
    null
  );

  const [mode, setStateMode] = useState<Mode | null>(localStorageMode);

  const setMode = useCallback(
    (newMode: Mode | null) => {
      setStateMode(newMode);
      setLocalStorageMode(newMode);
    },
    [setStateMode, setLocalStorageMode]
  );

  return [mode, setMode];
}

interface ModeContextProviderProviderProps {
  children: React.ReactNode;
}

export function ModeContextProvider({
  children,
}: ModeContextProviderProviderProps) {
  const value = useModeContext();
  return <modeContext.Provider value={value}>{children}</modeContext.Provider>;
}

export function useMode(): UseModeType {
  const { signedIn } = useAuth();
  const modeContextValue = useContext(modeContext);
  const mode = modeContextValue?.[0];

  // Check the mode is valid, and if it isn't clear the mode...
  useEffect(() => {
    if (modeContextValue === undefined) {
      return;
    }

    const [mode, setMode] = modeContextValue;

    // Clear the mode if the mode is only for signed in users and the user is not signed in...
    if (
      (!signedIn && mode?.mode === 'resume') ||
      (!signedIn && mode?.mode === 'autoSaveInvest') ||
      (!signedIn && mode?.mode === 'buy' && mode?.wrapperType !== undefined)
    ) {
      setMode(null);
    }
  }, [signedIn, modeContextValue]);

  if (modeContextValue === undefined) {
    return [null, voidFn];
  }

  // Check the mode is valid, and if it isn't don't return it...
  if (
    !signedIn &&
    (mode?.mode === 'resume' || mode?.mode === 'autoSaveInvest')
  ) {
    return [null, modeContextValue[1]];
  }

  return modeContextValue;
}
