import { CustomButton } from 'components/Button/CustomButton';
import { AccountType } from 'generated/graphql';
import { getNameForAccountType } from 'helpers/accountHelpers';
import { useUpdateInitialAccountTypeMutation } from 'hooks/useUpdateInitialAccountTypeMutation';
import {
  StepActions,
  StepContainer,
  StepContent,
  StepIntroduction,
  StepIntroductionTypography,
  StepIntroductionWidth,
  StepTitle,
} from '../../../../design-system/StepComponents/StepComponents';
import { ServerError } from '../_shared/ServerError';

interface SwitchAccountTypeStepProps {
  currentAccountType: AccountType;
  newAccountType: AccountType;
  onProceed: (accountType: AccountType) => void;
}

export function SwitchAccountTypeStep({
  currentAccountType,
  newAccountType,
  onProceed,
}: SwitchAccountTypeStepProps) {
  const {
    mutateAsync: updateInitialAccountTypeAsync,
    isError,
    isLoading,
  } = useUpdateInitialAccountTypeMutation();

  const handleSwitch = async () => {
    await updateInitialAccountTypeAsync({
      input: {
        initialAccount: { value: newAccountType },
      },
    });
    onProceed(newAccountType);
  };

  const handleFinish = () => {
    onProceed(currentAccountType);
  };

  return (
    <StepContainer>
      <StepContent>
        <StepTitle>Before that</StepTitle>
        <StepIntroduction mb={2} $width={StepIntroductionWidth.normal}>
          <StepIntroductionTypography>
            You've already started opening a {currentAccountType} with us. You
            can either finish setting that account up first, or switch to open
            an {newAccountType} first instead.
          </StepIntroductionTypography>
          <StepIntroductionTypography>
            Don't worry - you can still open another account once your first one
            is set up.
          </StepIntroductionTypography>
        </StepIntroduction>
        {isError && <ServerError isVisible />}
        <StepActions $isHorizontal>
          <CustomButton
            className="richBlue"
            onClick={handleSwitch}
            disabled={isLoading}
          >
            Open an {getNameForAccountType(newAccountType)}
          </CustomButton>
          <CustomButton
            className="magenta"
            onClick={handleFinish}
            disabled={isLoading}
          >
            Finish setup
          </CustomButton>
        </StepActions>
      </StepContent>
    </StepContainer>
  );
}
