import { useAuth } from 'context/AuthContext';
import { Eligibility, useAccountEligibilitiesQuery } from 'generated/graphql';
import { useMemo } from 'react';

function useHasAccountWithEligibility(eligibility: Eligibility) {
  const { signedIn } = useAuth();
  const accountEligibilitiesQuery = useAccountEligibilitiesQuery(undefined, {
    enabled: signedIn,
  });
  return useMemo(
    () =>
      accountEligibilitiesQuery.data?.accountEligibilities?.some(
        (account) => account?.eligibility === eligibility
      ),
    [accountEligibilitiesQuery.data?.accountEligibilities, eligibility]
  );
}

export function useHasPendingAccount() {
  return useHasAccountWithEligibility(Eligibility.Pending);
}

export function useHasOnboardingAccount() {
  return useHasAccountWithEligibility(Eligibility.Onboarding);
}

export function useHasOpenableAccount() {
  return useHasAccountWithEligibility(Eligibility.CanOpen);
}
