import { InfoPopoverV2 } from 'components/design-system/InfoPopoverV2/InfoPopoverV2';
import {
  FontWeight,
  MonoSpan,
  TextLarge,
} from 'components/design-system/Text/Text';
import { currencyFull } from 'formatting';
import { CashDepositTransferDetails } from 'generated/graphql';
import * as tooltips from 'strings/tooltips';
import {
  BankLogo,
  BankLogoRow,
  ImportantCopy,
  ObscuredDetails,
  TransferDetails,
  TransferFrom,
  TransferOrderDetailRow,
  TransferOrderDetailRowCopyToClipboard,
  TransferOrderDetailRowLabel,
  TransferOrderDetailRowValue,
  TransferOrderDetailsWrapper,
} from './PaymentSummary.styles';

interface PaymentSummaryProps {
  amount: number;
  transferDetails: CashDepositTransferDetails;
  copyEnabled?: boolean;
  showSourceWarning?: boolean;
}

export function PaymentSummary({
  amount,
  transferDetails,
  copyEnabled = true,
  showSourceWarning = true,
}: PaymentSummaryProps) {
  const {
    payeeName,
    payeeBankName,
    payeeAccountNumber,
    payeeSortCode,
    paymentReference,
    sourceBankLogoUrl,
    sourceAccountNumberMasked,
    sourceSortCodeMasked,
  } = transferDetails;
  return (
    <>
      {showSourceWarning && (
        <ImportantCopy>
          <span>
            <strong>Important:</strong> The deposit must be made from your
            nominated bank account:
          </span>
        </ImportantCopy>
      )}

      <TransferDetails>
        <TransferFrom>
          <TextLarge $fontWeight={FontWeight.medium} $noMargin>
            From account
          </TextLarge>
          <ObscuredDetails>{sourceAccountNumberMasked}</ObscuredDetails>
        </TransferFrom>

        <TransferFrom>
          <TextLarge $fontWeight={FontWeight.medium} $noMargin>
            From sort code
          </TextLarge>
          <ObscuredDetails>{sourceSortCodeMasked}</ObscuredDetails>
        </TransferFrom>

        <TransferOrderDetailsWrapper>
          <TransferOrderDetailRow>
            <TransferOrderDetailRowLabel>Amount</TransferOrderDetailRowLabel>
            <TransferOrderDetailRowCopyToClipboard
              text={amount.toString()}
              size="medium"
            />
            <TransferOrderDetailRowValue>
              <MonoSpan>{currencyFull(amount)}</MonoSpan>
            </TransferOrderDetailRowValue>
          </TransferOrderDetailRow>

          <TransferOrderDetailRow>
            <TransferOrderDetailRowLabel>
              Payee name{' '}
              <InfoPopoverV2
                size="small"
                placement="top"
                content={tooltips.PayeeName}
              />
            </TransferOrderDetailRowLabel>
            <TransferOrderDetailRowCopyToClipboard
              text={payeeName}
              size="medium"
            />
            <TransferOrderDetailRowValue>
              {payeeName}
            </TransferOrderDetailRowValue>
          </TransferOrderDetailRow>

          <TransferOrderDetailRow>
            <TransferOrderDetailRowLabel>Bank</TransferOrderDetailRowLabel>
            <TransferOrderDetailRowCopyToClipboard
              text={payeeBankName}
              size="medium"
            />
            <TransferOrderDetailRowValue>
              {payeeBankName}
            </TransferOrderDetailRowValue>
          </TransferOrderDetailRow>

          <TransferOrderDetailRow>
            <TransferOrderDetailRowLabel>
              Account number
            </TransferOrderDetailRowLabel>
            <TransferOrderDetailRowCopyToClipboard
              text={payeeAccountNumber}
              size="medium"
            />
            <TransferOrderDetailRowValue>
              <MonoSpan>{payeeAccountNumber}</MonoSpan>
            </TransferOrderDetailRowValue>
          </TransferOrderDetailRow>

          <TransferOrderDetailRow>
            <TransferOrderDetailRowLabel>Sort code</TransferOrderDetailRowLabel>
            <TransferOrderDetailRowCopyToClipboard
              text={payeeSortCode}
              size="medium"
            />
            <TransferOrderDetailRowValue>
              <MonoSpan>{payeeSortCode}</MonoSpan>
            </TransferOrderDetailRowValue>
          </TransferOrderDetailRow>

          <TransferOrderDetailRow>
            <TransferOrderDetailRowLabel>
              Payment reference
            </TransferOrderDetailRowLabel>
            <TransferOrderDetailRowCopyToClipboard
              text={paymentReference}
              size="medium"
            />
            <TransferOrderDetailRowValue>
              <MonoSpan>{paymentReference}</MonoSpan>
            </TransferOrderDetailRowValue>
          </TransferOrderDetailRow>

          {sourceBankLogoUrl && (
            <BankLogoRow>
              <BankLogo src={sourceBankLogoUrl} alt="Bank logo" />
            </BankLogoRow>
          )}
        </TransferOrderDetailsWrapper>
      </TransferDetails>
    </>
  );
}
