import { IconButton, useMediaQuery, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { GlossaryDialog } from 'components/Glossary';
import { Glossary } from 'components/Glossary/GlossaryDialog';
import { Badge } from 'components/design-system/Header/_shared/_shared.styles';
import { Popover } from 'components/design-system/InfoPopoverV2/InfoPopoverV2';
import { CustomSwipeableDrawer } from 'components/design-system/SwipeableDrawer/SwipeableDrawer';
import { TextAlign, TextSmall } from 'components/design-system/Text/Text';
import { GaEventNames } from 'constants/gaConstants';
import { useFiltersQuery } from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { useAssetsFilter } from 'hooks/useAssetsFilter';
import { useDialog } from 'hooks/useDialog';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { BiFilter } from 'react-icons/bi';
import { HiOutlineBookOpen } from 'react-icons/hi';
import { Filters } from '../Filters/Filters';

import {
  SearchBarContainer,
  SearchBarContent,
  SearchBarPlaceMarker,
  StyledIconButton,
} from '../NewFundsPageLayout.styles';
import { SearchBar } from '../Filters/SearchBar';

interface FundsGridListSearchProps {
  onFiltersClick: (showFilters: boolean) => void;
  showFilters: boolean;
}

export function FundsGridListSearch({
  onFiltersClick,
  showFilters,
}: FundsGridListSearchProps) {
  const filtersQuery = useFiltersQuery();
  const { variables, update } = useAssetsFilter();

  const filtersCount = variables.tags?.length;
  const [searchBarIsStuck, setSearchBarIsStuck] = useState<boolean>(false);
  const searchBarRef = useRef(null);
  const {
    open: glossaryOpen,
    openDialog: openGlossary,
    closeDialog: closeGlossary,
  } = useDialog();

  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const canHover = useMediaQuery('(hover: hover)');

  const trackFilters = (label: string, selectedOptions: string[] = []) => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'fund filter',
      item_id: selectedOptions.join(', '),
      filter_group: label,
    });
  };

  const handleChange = (
    name: string,
    value: string[],
    label: string,
    selectedOptions: string[]
  ) => {
    const categoryTags =
      filtersQuery.data?.tagCategories?.nodes
        .find((cat) => cat.code === name)
        ?.tags?.nodes?.map((t) => t.code) ?? [];
    variables.tags = _(variables.tags)
      .differenceBy(categoryTags, (tag) => tag.replace('+', ''))
      .union(value)
      .value();
    trackFilters(label, selectedOptions);
    update(variables);
  };

  const handleGlossaryDialog = () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'cta button',
      item_id: 'glossary opened',
    });

    openGlossary();
  };

  useEffect(() => {
    const target = searchBarRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setSearchBarIsStuck(false); // not sticky
          } else {
            setSearchBarIsStuck(true); // is sticky
          }
        });
      },
      {
        root: null, // the viewport
        rootMargin: '0px 0px -80px 0px',
        threshold: 0.9,
      }
    );

    if (target) {
      observer.observe(target);
    }

    return () => {
      if (target) {
        observer.unobserve(target);
      }
    };
  }, []);

  return (
    <>
      <SearchBarPlaceMarker ref={searchBarRef}></SearchBarPlaceMarker>
      <SearchBarContainer className={clsx(searchBarIsStuck && 'is-stuck')}>
        <SearchBarContent>
          <SearchBar isStuck={searchBarIsStuck} />
          {!canHover ? (
            <>
              <StyledIconButton
                color={showFilters ? 'primary' : 'default'}
                aria-label="More filters"
                onClick={() => {
                  onFiltersClick(!showFilters);
                }}
              >
                {!!filtersCount && <Badge>{filtersCount}</Badge>}
                <BiFilter />
              </StyledIconButton>
              <IconButton
                color="default"
                aria-label="Glossary"
                onClick={() => {
                  handleGlossaryDialog();
                }}
              >
                <HiOutlineBookOpen />
              </IconButton>
            </>
          ) : (
            <>
              <Popover
                popupId="filter-icon"
                openOn="hover"
                placement="bottom"
                $padding="narrow"
                content={
                  <TextSmall $textAlign={TextAlign.center} $noMargin>
                    Filters
                  </TextSmall>
                }
              >
                {(popoverProps) => (
                  <StyledIconButton
                    color={showFilters ? 'primary' : 'default'}
                    aria-label="More filters"
                    onClick={() => {
                      onFiltersClick(!showFilters);
                    }}
                    {...popoverProps}
                  >
                    {!!filtersCount && <Badge>{filtersCount}</Badge>}
                    <BiFilter />
                  </StyledIconButton>
                )}
              </Popover>
              <Popover
                popupId="glossary-icon"
                openOn="hover"
                placement="bottom"
                $padding="narrow"
                content={
                  <TextSmall $textAlign={TextAlign.center} $noMargin>
                    Glossary
                  </TextSmall>
                }
              >
                {(popoverProps) => (
                  <IconButton
                    color="default"
                    aria-label="Glossary"
                    onClick={() => {
                      handleGlossaryDialog();
                    }}
                    {...popoverProps}
                  >
                    <HiOutlineBookOpen />
                  </IconButton>
                )}
              </Popover>
            </>
          )}
        </SearchBarContent>
      </SearchBarContainer>

      {!isMdUp && showFilters && (
        <CustomSwipeableDrawer
          onClose={() => {
            onFiltersClick(false);
          }}
          title="Filters"
        >
          <Filters variant="slider" />
        </CustomSwipeableDrawer>
      )}

      {!isMdUp && glossaryOpen && (
        <CustomSwipeableDrawer
          onClose={() => {
            closeGlossary();
          }}
          title="Glossary"
        >
          <Glossary open onChangeFilter={handleChange} />
        </CustomSwipeableDrawer>
      )}
      {isMdUp && glossaryOpen && (
        <GlossaryDialog
          onClose={closeGlossary}
          open={glossaryOpen}
          onChangeFilter={handleChange}
        />
      )}
    </>
  );
}
