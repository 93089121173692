import { Box } from '@material-ui/core';
import { CustomButton, LinkCustomButton } from 'components/Button/CustomButton';
import { colors } from 'constants/colors';
import { Variants, motion } from 'framer-motion';
import { FaArrowLeft } from 'react-icons/fa';
import { Link, LinkProps } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { H1, H2 } from '../Heading/Heading';
import { TextNormal } from '../Text/Text';

const baseStepTitle = css`
  font-family: 'GT Super', serif;
  font-size: 2.375rem;
  text-align: center;
  font-weight: 300;
  margin-bottom: 1rem;
  line-height: 2.75rem;
  color: ${colors.darkBlue} !important;
`;

export const StepTitle = styled(H1)`
  ${baseStepTitle}
`;

export const StepTitleSmall = styled(H2)`
  ${baseStepTitle}
`;

export enum StepIntroductionWidth {
  narrow = 'narrow',
  normal = 'normal',
  extraWide = 'extraWide',
  xxWide = 'xxWide',
}

interface StepIntroductionProps {
  $width?: StepIntroductionWidth;
}

const stepIntroductionWidthLookup = {
  [StepIntroductionWidth.narrow]: '22rem',
  [StepIntroductionWidth.normal]: '31.25rem',
  [StepIntroductionWidth.extraWide]: '36rem',
  [StepIntroductionWidth.xxWide]: '45rem',
};

export const StepIntroduction = styled(Box)<StepIntroductionProps>`
  text-align: center;
  font-weight: 300;
  align-self: center;

  max-width: ${({ $width }) =>
    $width
      ? stepIntroductionWidthLookup[$width]
      : stepIntroductionWidthLookup[StepIntroductionWidth.narrow]};
`;

export const StepText = styled(Box)`
  text-align: center;
  font-weight: 300;
`;

interface StepIntroductionTypographyProps {
  textAlign?: 'left' | 'center';
  $italic?: boolean;
}

export const StepIntroductionTypography = styled(
  TextNormal
)<StepIntroductionTypographyProps>`
  text-align: ${({ textAlign }) => (textAlign ? textAlign : 'center')};
  font-weight: 300;
  margin-bottom: 1rem;
  ${({ $italic }) => ($italic ? 'font-style: italic;' : '')}
  strong {
    font-weight: 500;
  }
`;

export const StepIntroductionLi = styled.li`
  text-align: left;
  font-weight: 300;
  margin-bottom: 0.5rem;
`;

type StepButtonProps = {
  $minWidth?: 'narrow' | 'normal';
};

export const StepButton = styled(CustomButton)<StepButtonProps>`
  width: 100%;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: auto;
    min-width: ${({ $minWidth }) =>
      $minWidth === 'narrow' ? '15rem' : '18rem'};
  }
`;

export const LinkStepButton = styled(LinkCustomButton)<StepButtonProps>`
  width: 100%;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: auto;
    min-width: ${({ $minWidth }) =>
      $minWidth === 'narrow' ? '15rem' : '18rem'};
  }
`;

export const SkipButton = styled(CustomButton)`
  border: none;
  min-width: 18rem;
  text-transform: none;
  font-weight: 400;
  font-size: 1rem;
  &:disabled {
    border: none;
  }
`;

interface GoBackButtonRawProps {
  $noMinWidth?: boolean;
}
const GoBackButtonRaw = styled(CustomButton)<GoBackButtonRawProps>`
  border: none;
  ${({ $noMinWidth }) => !$noMinWidth && 'min-width: 18rem;'}
  text-transform: none;
  font-weight: 400;
  font-size: 1rem;
  &:disabled {
    border: none;
  }
`;

const StyledFaArrowLeft = styled(FaArrowLeft)`
  margin-left: 0 !important; // The important is need to override - '.MuiButton-label>svg {margin-left: 1em;}'
  font-size: 0.75rem;
  margin-right: 0.25rem;
`;

type GoBackButtonParams = Parameters<typeof GoBackButtonRaw>;

export const GoBackButton = (props: GoBackButtonParams[0]) => {
  return (
    <GoBackButtonRaw variant="outlined" className="richBlue" {...props}>
      <StyledFaArrowLeft /> Go back
    </GoBackButtonRaw>
  );
};

type GoBackLinkProps = GoBackButtonParams[0] & LinkProps;
export const GoBackLink = (props: GoBackLinkProps) => {
  return (
    <GoBackButtonRaw
      component={Link}
      variant="outlined"
      className="richBlue"
      {...props}
    >
      <StyledFaArrowLeft /> Go back
    </GoBackButtonRaw>
  );
};

export const StepFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  ${(p) => p.theme.breakpoints.up('sm')} {
    max-width: 100%;
    width: 30rem;
  }
`;

interface StepCheckboxesContainerProps {
  alignItems?: 'flex-start' | 'center';
}

export const StepCheckboxesContainer = styled.div<StepCheckboxesContainerProps>`
  display: flex;
  flex-direction: column;
  max-width: 40rem;
  width: 100%;
  align-items: ${({ alignItems }) => (alignItems ? alignItems : 'flex-start')};
`;

const pageVariants: Variants = {
  initial: {
    opacity: 0,
    x: '100%',
  },
  in: {
    opacity: 1,
    x: 0,
    transition: {
      stiffness: 0,
      duration: 0.3,
      delay: 0,
      ease: 'easeInOut',
    },
  },
  out: {
    opacity: 0,
    x: '-100%',
    transition: {
      stiffness: 0,
      duration: 0.3,
      delay: 0,
      ease: 'easeInOut',
    },
  },
};

export const StepContainer = styled(motion.div).attrs({
  initial: 'initial',
  animate: 'in',
  exit: 'out',
  variants: pageVariants,
})`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  align-items: stretch;
  justify-content: center;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    align-items: center;
  }
`;

export enum StepContentWidth {
  normal = 'normal',
  wide = 'wide',
  extraWide = 'extraWide',
  xxWide = 'xxWide',
}

interface StepContentProps {
  width?: StepContentWidth;
  autoWidth?: boolean;
}

const widthLookup = {
  [StepContentWidth.normal]: '30rem',
  [StepContentWidth.wide]: '40rem',
  [StepContentWidth.extraWide]: '45rem',
  [StepContentWidth.xxWide]: '50rem',
};

export const StepContent = styled.div<StepContentProps>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${({ autoWidth }) => (autoWidth ? 'auto' : '100%')};
  max-width: ${({ width }) =>
    width ? widthLookup[width] : widthLookup[StepContentWidth.wide]};
`;

interface StepActionsProps {
  $isHorizontal?: boolean;
}

export const StepActions = styled.div<StepActionsProps>`
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${(p) => (p.$isHorizontal ? 'row' : 'column')};
  align-items: stretch;
  gap: ${(p) => (p.$isHorizontal ? '1rem' : '0.25rem')};
  justify-content: center;
  ${(p) => p.theme.breakpoints.up('sm')} {
    align-items: center;
  }
`;

export const StepActionsWithCar = styled.div<StepActionsProps>`
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
  ${(p) => p.theme.breakpoints.up('sm')} {
    align-items: center;
  }
`;

export const StepActionsCar = styled(TextNormal)`
  text-align: center;
  font-weight: 300;
  max-width: 25rem;
`;

export const StepWrapper = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
  padding: 1rem;
  overflow: hidden;
  width: 100%;

  ${(p) => p.theme.breakpoints.up('sm')} {
    padding: 0 3rem;
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    padding: 0 5rem;
  }
`;

export const StepFormWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  justify-content: center;
`;
