import { ErrorDisplay } from 'components/ErrorDisplay';
import { Loading } from 'components/Loading';
import React from 'react';
import { UseQueryResult } from 'react-query';

type QueryStateProps<ResultType> = UseQueryResult<ResultType> & {
  loadingAbsolute?: boolean;
  suppressLoadingState?: boolean;
  loadingComponent?: React.ReactNode | JSX.Element | string;
  errorComponent?: React.ReactNode | JSX.Element | string;
  children: (props: UseQueryResult<ResultType>) => React.ReactNode;
  dataTestid?: string;
};

export function QueryState<ResultType>(props: QueryStateProps<ResultType>) {
  if (props.isLoading && !props.suppressLoadingState) {
    return props.loadingComponent ? (
      <>{props.loadingComponent}</>
    ) : (
      <Loading dataTestid={props.dataTestid} absolute={props.loadingAbsolute} />
    );
  }
  if (props.isError) {
    return props.errorComponent ? (
      <>{props.errorComponent}</>
    ) : (
      <ErrorDisplay />
    );
  }
  return <>{props.children(props as QueryStateProps<ResultType>)}</>;
}

interface CombinedQueryStateProps {
  queries: UseQueryResult[];
  children: React.ReactNode;
}

/**
 * This component serves as a workaround for nested QueryState elements
 * when we need to wait for multiple queries. It does have an issue with the
 * loading animation if the outer query completes before the inner one.
 */
export function CombinedQueryState({
  queries,
  children,
}: CombinedQueryStateProps) {
  return queries.reduce(
    (AccumulatedQueryStates, currentQuery) => (
      <QueryState {...currentQuery}>{() => AccumulatedQueryStates}</QueryState>
    ),
    <>{children}</>
  );
}
