import { useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import { HeaderLogo } from 'components/TillitLogo/Logo';
import { GaEventNames } from 'constants/gaConstants';
import { useAuth } from 'context/AuthContext';
import {
  useReferralCodeQuery,
  useUserProfileOnboardingQuery,
} from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { useGetEmployerCode } from 'hooks/useGetEmployerCode';
import { useScrollDirection } from 'hooks/useScrollDirection';
import {
  hexagonPagePath,
  openAccountPath,
  openAccountResumeAccountPath,
  openAccountResumeTypePath,
  openAccountTypePath,
  openPensionPath,
} from 'paths';
import { useRouteMatch } from 'react-router-dom';
import {
  CoBrandedContainer,
  HeaderContainer,
  HeaderInner,
  LogoContainer,
} from './LogoHeader.styles';

export function LogoHeader() {
  const { signedIn } = useAuth();
  const { referralCode } = useGetEmployerCode();

  const scrollDirectionClass = useScrollDirection();
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const isOnboarding = useRouteMatch([
    openAccountPath,
    openAccountResumeTypePath,
    openAccountTypePath,
    openAccountResumeAccountPath,
  ]);

  const isOnboardingPension = useRouteMatch([openPensionPath]);

  const UserProfileOnboarding = useUserProfileOnboardingQuery(undefined, {
    enabled: !!isOnboardingPension?.isExact && signedIn,
  });

  const referralCodeQuery = useReferralCodeQuery(
    { code: referralCode! },
    {
      enabled: !!referralCode,
    }
  );
  const employments = referralCodeQuery.data?.referralCode
    ? [
        {
          startDate: '',
          endDate: null,
          employer: referralCodeQuery?.data?.referralCode?.employer!,
        },
      ]
    : UserProfileOnboarding.data?.userProfile?.employments || [];

  return (
    <>
      <HeaderContainer className={clsx('app--header', scrollDirectionClass)}>
        <HeaderInner>
          <LogoContainer
            to={hexagonPagePath}
            onClick={() => {
              if (isOnboarding) {
                trackGa({
                  event: GaEventNames.onboardingAbort,
                });
              }
            }}
          >
            <HeaderLogo
              size={1.2}
              pad={false}
              compact={isExtraSmall}
              tooltip="Back to home"
            />
          </LogoContainer>

          {employments.length > 0 &&
            employments[0].employer &&
            employments[0].employer.logoUrl && (
              <CoBrandedContainer>
                <img
                  src={employments[0].employer.logoUrl}
                  alt={
                    employments[0].employer.displayName ||
                    employments[0].employer.companyName
                  }
                />
              </CoBrandedContainer>
            )}
        </HeaderInner>
      </HeaderContainer>
    </>
  );
}
