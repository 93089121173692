import { Container } from 'components/Container/Container.style';
import { ReadOnlyInput } from 'components/Input';
import { QueryState } from 'components/QueryState';
import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import { GaEventNames } from 'constants/gaConstants';
import {
  TokenisedBankAccountType,
  useUserProfileQuery,
} from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { useIntercom } from 'react-use-intercom';
import { Base } from './Base';
import { SingleAction } from './MyDetails.style';

interface PureBankDetailsProps {
  bankDetails: TokenisedBankAccountType | undefined;
  onClick: () => void;
}

// ts-unused-exports:disable-next-line
export function PureBankDetails({
  bankDetails,
  onClick,
}: PureBankDetailsProps) {
  return (
    <Base title="Bank details">
      <Container padding="none">
        <ReadOnlyInput
          id="acNumber-read-only-input"
          label="Account number"
          defaultValue={
            bankDetails
              ? bankDetails.accountNumberToken?.maskedValue
              : 'Not provided'
          }
        />

        <ReadOnlyInput
          id="sortCode-read-only-input"
          label="Sort code"
          defaultValue={
            bankDetails
              ? bankDetails.sortCodeToken?.maskedValue
              : 'Not provided'
          }
        />

        <SingleAction>
          <CustomButtonV2 $color="secondary" onClick={() => onClick()}>
            Change bank account
          </CustomButtonV2>
        </SingleAction>
      </Container>
    </Base>
  );
}

export function BankDetails() {
  const userProfileQuery = useUserProfileQuery();
  const userProfile = userProfileQuery.data?.userProfile;
  const { showNewMessages } = useIntercom();

  const handleClick = () => {
    showNewMessages(`I would like to change my bank details.`);

    trackGa({
      event: GaEventNames.selectContent,
      item_id: 'dashboard - my details - bank',
      content_type: `change bank details clicked clicked`,
    });
  };

  return (
    <QueryState {...userProfileQuery}>
      {() => (
        <PureBankDetails
          bankDetails={
            userProfile?.bankAccountTokenised as TokenisedBankAccountType
          }
          onClick={handleClick}
        />
      )}
    </QueryState>
  );
}
