import { QueryState } from 'components/QueryState';
import { StyledLink } from 'components/design-system/Link';
import {
  StepIntroduction,
  StepIntroductionTypography,
  StepIntroductionWidth,
} from 'components/design-system/StepComponents/StepComponents';
import { NationalIdentifierStep } from 'components/feature/openAccount/steps/NationalIdentifierStep';
import { usePortfolioRebalancingQuery } from 'generated/graphql';
import { mifidToInvest } from 'paths';

interface MifidStepProps {
  quickOrderId: string;
  onProceed: () => void;
  onGoBack: () => void;
}

export function MifidStep({
  quickOrderId,
  onProceed,
  onGoBack,
}: MifidStepProps) {
  const portfolioRebalancingQuery = usePortfolioRebalancingQuery({
    id: quickOrderId,
  });

  const introduction = (
    <StepIntroduction $width={StepIntroductionWidth.normal} mb={2}>
      <StepIntroductionTypography>
        To trade{' '}
        {
          portfolioRebalancingQuery.data?.portfolioRebalancing?.buyOrders[0]
            .instrument.name
        }
        , our regulator requires that we ask for your national identifier.{' '}
        <StyledLink href={mifidToInvest} target="_blank">
          Learn more
        </StyledLink>
        .
      </StepIntroductionTypography>
    </StepIntroduction>
  );

  return (
    <QueryState {...portfolioRebalancingQuery}>
      {() => (
        <NationalIdentifierStep
          source="quickOrderStep"
          onProceed={onProceed}
          onGoBack={onGoBack}
          introduction={introduction}
        />
      )}
    </QueryState>
  );
}
