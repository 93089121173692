import { Typography } from '@material-ui/core';
import { CustomButton } from 'components/Button/CustomButton';
import {
  StepActions,
  StepContainer,
  StepContent,
} from 'components/StepContainer';
import React from 'react';

interface ConfirmationProps {
  onContinue: () => void;
}

export function Confirmation({ onContinue }: ConfirmationProps) {
  return (
    <StepContainer>
      <StepContent>
        <Typography>Password changed successfully</Typography>
      </StepContent>
      <StepActions>
        <CustomButton className="richBlue" onClick={() => onContinue()}>
          Continue
        </CustomButton>
      </StepActions>
    </StepContainer>
  );
}
