import {
  StepActions,
  StepButton,
  StepContainer,
  StepContent,
  StepIntroductionTypography,
  StepText,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import { GaEventNames } from 'constants/gaConstants';
import { WrapperType } from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { useIntercom } from 'react-use-intercom';
import { wrapperNameFriendly } from '../_shared/WrapperNameUtils';

interface ErrorStepProps {
  wrapperType: WrapperType;
}

export function ErrorStep({ wrapperType }: ErrorStepProps) {
  const { showNewMessages } = useIntercom();

  const handleSupport = () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'talk to support ',
      item_id: `transfer dialog - error - transferring my ${wrapperType}`,
    });
    showNewMessages(
      `I am having trouble transferring a ${wrapperType} to TILLIT.`
    );
  };

  return (
    <StepContainer>
      <StepContent>
        <StepTitle>We need more information.</StepTitle>
        <StepText>
          <StepIntroductionTypography>
            Before we can transfer your {wrapperNameFriendly(wrapperType)} we
            need more information. Please contact support.
          </StepIntroductionTypography>
        </StepText>

        <StepActions>
          <StepButton className="richBlue" fullWidth onClick={handleSupport}>
            Talk to support
          </StepButton>
        </StepActions>
      </StepContent>
    </StepContainer>
  );
}
