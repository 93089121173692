import { Typography } from '@material-ui/core';
import { InfoPopover } from 'components/Popover';
import { colors } from 'constants/colors';
import _ from 'lodash';
import React from 'react';
import { useTheme } from 'styled-components';
import {
  AreaSeries,
  darkUniverseLineColors,
  lineColors,
  LineSeries,
} from './BaseGraph';
import {
  horizontalAlignmentType,
  LegendContainer,
  LegendEntry,
} from './BaseGraph.styles';

interface LegendProps {
  series: Array<AreaSeries | LineSeries>;
  style?: React.CSSProperties;
  legendAlignment?: horizontalAlignmentType;
  primaryEntry?: AreaSeries | LineSeries;
}

export function Legend({
  series,
  style,
  primaryEntry,
  legendAlignment,
}: LegendProps) {
  const { darkUniverse } = useTheme();
  const ordered = _.sortBy(series, (s) => (s === primaryEntry ? -1 : 0));

  return (
    <LegendContainer horizontalAlignment={legendAlignment} style={style}>
      {ordered.map((s, i) => (
        <LegendEntry key={s.name}>
          <div>
            {s.legendEntry ? (
              s.legendEntry
            ) : i === 0 && !(s as LineSeries).stroke ? (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  backgroundImage: darkUniverse
                    ? 'linear-gradient(to bottom, #555, #aaa 45%, #333)'
                    : `linear-gradient(180deg, ${colors.purple} 35%, ${colors.seaBlue} 100%)`,
                }}
              />
            ) : (
              <div
                style={{
                  borderTop: `3px solid ${
                    (s as LineSeries).stroke ??
                    (darkUniverse
                      ? darkUniverseLineColors[i - 1]
                      : lineColors[i - 1])
                  }`,
                  width: '100%',
                }}
              />
            )}
          </div>

          <Typography>{s.name}</Typography>

          {s.description && (
            <InfoPopover color="primary" content={s.description} size="small" />
          )}
        </LegendEntry>
      ))}
    </LegendContainer>
  );
}
