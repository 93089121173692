import { ErrorMessage } from '@hookform/error-message';
import { FormHelperText, InputLabel } from '@material-ui/core';
import { MobileNumberInput, MobileNumberInputProps } from './MobileNumberInput';

interface MobileNumberFieldProps extends MobileNumberInputProps {
  label: string;
}

export function MobileNumberField({
  name,
  label,
  ...other
}: MobileNumberFieldProps) {
  return (
    <div>
      <InputLabel htmlFor={name} shrink>
        {label}
      </InputLabel>
      <MobileNumberInput
        name={name}
        {...other}
        id={name}
        aria-describedby={name + '-helper-text'}
      />
      <ErrorMessage
        name={name}
        render={({ message }) => {
          return (
            <FormHelperText id={name + '-helper-text'} error={true}>
              {message}
            </FormHelperText>
          );
        }}
      />
    </div>
  );
}
