import { useMediaQuery, useTheme } from '@material-ui/core';
import { SmallCopy } from 'components/Copy/SmallCopy';
import { TabTable } from 'components/TabTable/TabTable';
import {
  FontSize,
  FontWeight,
  Text,
  TextAlign,
  TextNormal,
  TextSmall,
  TextXS,
} from 'components/design-system/Text/Text';
import * as format from 'formatting';
import { WrapperType } from 'generated/graphql';
import React, { useEffect, useMemo, useState } from 'react';
import {
  CurrentBreakdownType,
  GetAssetGroupString,
  IncludeAssetType,
  getAssetClassOfAsset,
  getBondStyleOfAsset,
  getEquityStyleOfAsset,
  getRegionOfAsset,
  useGetCurrentBreakdown,
} from '../../hooks/useGetBreakdowns';
import {
  BreakdownGroupingSelect,
  BreakdownTabHeading,
  CardTableContainer,
  HoldingsTabInfo,
  HoldingsTabInner,
} from '../_shared/DrillDownTab.styles';
import { Table, Td } from '../_shared/Table.styles';
import { CurrentStatBoxes } from './_shared/CurrentStatBoxes';

type grouping = 'region' | 'assetClass' | 'equityStyle' | 'bondStyle';

interface GroupingOption {
  label: string;
  value: grouping;
}

interface BreakdownTabProps {
  selectedAccountType: WrapperType;
  includeAsset: IncludeAssetType;
  activeBreakdown: CurrentBreakdownType;
  groupings: GroupingOption[];
}

interface BreakdownNameLookUp {
  [key: string]: string;
}
const breakdownNameLookUp: BreakdownNameLookUp = {
  region: 'by region',
  assetClass: 'by asset class',
  equityStyle: 'by equity style',
  bondStyle: 'by bond style',
};

const breakdownTableNameLookUp: BreakdownNameLookUp = {
  region: 'Region',
  assetClass: 'Asset class',
  equityStyle: 'Equity style',
  bondStyle: 'Bond style',
};

const assetClassDisplayLookup: Record<string, string> = {
  Equities: 'equity',
  Bonds: 'bond',
  Property: 'property',
  'Alternative assets': 'property',
  'Multi-asset': 'equity',
};

const regionDisplayLookup: Record<string, string> = {
  Global: 'global',
  UK: 'UK',
  US: 'US',
  Europe: 'European',
  Japan: 'Japan',
  Asia: 'Asia',
  'Asia ex-Japan': 'Asia ex-Japan',
  'Emerging markets': 'emerging market',
  'Frontier markets': 'frontier market',
};

interface BreakdownGroupingLookUp {
  [key: string]: GetAssetGroupString;
}
const lookUp: BreakdownGroupingLookUp = {
  region: getRegionOfAsset,
  assetClass: getAssetClassOfAsset,
  equityStyle: getEquityStyleOfAsset,
  bondStyle: getBondStyleOfAsset,
};

interface CurrentBreakdownTableProps {
  breakdowns: CurrentBreakdownType[];
  name: string;
  footNote: React.ReactNode | JSX.Element | string;
}

const CurrentBreakdownTable = ({
  breakdowns,
  name,
  footNote,
}: CurrentBreakdownTableProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const holdingsLabel = 'Holding (%)';
  const valueLabel = 'Value (£)';

  const mobData = useMemo(() => {
    const getHoldingsData = () => {
      return breakdowns.map((breakdown) => {
        return {
          name: breakdown.name,
          value: format.percent(breakdown.percentage / 100),
        };
      });
    };

    const getValueData = () => {
      return breakdowns.map((breakdown) => {
        return {
          name: breakdown.name,
          value: format.currencyFull(breakdown.amount),
        };
      });
    };
    return [
      {
        label: holdingsLabel,
        data: getHoldingsData(),
        extraInfo: footNote,
      },
      {
        label: valueLabel,
        data: getValueData(),
      },
    ];
  }, [breakdowns, footNote]);

  if (isMobile) {
    return (
      <>
        <TabTable data={mobData} />
      </>
    );
  } else {
    return (
      <CardTableContainer>
        <Table>
          <thead>
            <tr>
              <Td>
                <TextSmall $noMargin $fontWeight={FontWeight.medium}>
                  {name}
                </TextSmall>
              </Td>
              <Td>
                <Text
                  $fontSize={FontSize.small}
                  $fontWeight={FontWeight.medium}
                  $textAlign={TextAlign.right}
                  $noMargin
                >
                  Holding (%)
                </Text>
              </Td>
              <Td>
                <Text
                  $fontSize={FontSize.small}
                  $fontWeight={FontWeight.medium}
                  $textAlign={TextAlign.right}
                  $noMargin
                >
                  Value (£)
                </Text>
              </Td>
            </tr>
          </thead>
          {breakdowns.map(({ name, amount, percentage }) => (
            <tr>
              <Td>
                <TextXS $noMargin>{name}</TextXS>
              </Td>
              <Td>
                <Text
                  $fontSize={FontSize.xs}
                  $fontWeight={FontWeight.light}
                  $textAlign={TextAlign.right}
                  $noMargin
                >
                  {format.percent(percentage / 100)}
                </Text>
              </Td>
              <Td>
                <Text
                  $fontSize={FontSize.xs}
                  $fontWeight={FontWeight.light}
                  $textAlign={TextAlign.right}
                  $noMargin
                >
                  {format.currencyFull(amount)}
                </Text>
              </Td>
            </tr>
          ))}
        </Table>
      </CardTableContainer>
    );
  }
};

export const CurrentBreakdown = ({
  selectedAccountType,
  includeAsset,
  groupings,
  activeBreakdown,
}: BreakdownTabProps) => {
  const [activeGrouping, setActiveGrouping] = useState<string>(
    groupings[0].value
  );

  useEffect(() => {
    setActiveGrouping(groupings[0].value);
  }, [groupings, setActiveGrouping]);

  const { breakdowns } = useGetCurrentBreakdown(
    selectedAccountType,
    lookUp[activeGrouping],
    includeAsset
  );

  const breakDownName =
    regionDisplayLookup[activeBreakdown.name] ??
    assetClassDisplayLookup[activeBreakdown.name] ??
    activeBreakdown.name.toLocaleLowerCase();

  const footnoteContent = `Please note that the 'Holding (%)' is calculated as a proportion of your ${breakDownName} allocation, not your total portfolio.`;

  return (
    <div>
      <BreakdownTabHeading>
        <TextNormal $noMargin>
          {activeBreakdown.name} {breakdownNameLookUp[activeGrouping]}
        </TextNormal>
        {groupings.length > 1 && (
          <div>
            {groupings.map((grouping) => (
              <BreakdownGroupingSelect
                onClick={() => {
                  setActiveGrouping(grouping.value);
                }}
                $active={activeGrouping === grouping.value}
              >
                {grouping.label}
              </BreakdownGroupingSelect>
            ))}
          </div>
        )}
      </BreakdownTabHeading>

      <HoldingsTabInner>
        <CurrentBreakdownTable
          name={breakdownTableNameLookUp[activeGrouping]}
          breakdowns={breakdowns}
          footNote={<SmallCopy $fontStyle="italic" copy={footnoteContent} />}
        />
        <HoldingsTabInfo>
          <CurrentStatBoxes activeBreakdown={activeBreakdown} />
        </HoldingsTabInfo>
      </HoldingsTabInner>
    </div>
  );
};
