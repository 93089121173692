import TextField from '@material-ui/core/TextField';
import React from 'react';
import { HiChevronDown } from 'react-icons/hi';
import { StyledComboBox, StyledPopper } from './ComboBoxInput.styles';

interface ComboBoxInputProps<T> {
  label: string;
  noOptionText?: string | JSX.Element;
  options: Array<T>;
  optionLabel: (option: T) => string;
  onChange: (event: React.SyntheticEvent, value: T) => void;
  value: string;
}

export function ComboBoxInput<T>({
  label,
  noOptionText,
  options,
  optionLabel,
  onChange,
  value,
}: ComboBoxInputProps<T>) {
  const CustomPopper = (props: any) => (
    <StyledPopper {...props} placement="bottom" transition />
  );

  const handleChange = (event: React.SyntheticEvent, value: T) => {
    onChange(event, value);
  };

  return (
    <>
      <StyledComboBox
        id="combo-box-demo"
        options={options}
        getOptionLabel={optionLabel}
        noOptionsText={noOptionText}
        fullWidth={true}
        popupIcon={<HiChevronDown />}
        renderInput={(params) => <TextField {...params} label={label} />}
        PopperComponent={CustomPopper}
        onChange={handleChange}
        value={value}
      />
    </>
  );
}
