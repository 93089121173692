import React from 'react';
import * as format from 'formatting';
import { Box, CircularProgressProps } from '@material-ui/core';
import {
  GraphContainer,
  CircularGraph,
  CircularGraphBottom,
  GraphValue,
} from './CircularProgress.style';

interface CircularProgressExtendedProps extends CircularProgressProps {
  max: number;
}

export function CircularProgress({
  value = 0,
  max,
  ...other
}: CircularProgressExtendedProps) {
  return (
    <Box
      position="relative"
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{ margin: '0' }}
    >
      <GraphContainer>
        <CircularGraph
          variant="determinate"
          size={50}
          thickness={6}
          value={Math.min((value / max) * 100, 100)}
          {...other}
        />
        <CircularGraphBottom
          variant="determinate"
          size={50}
          thickness={6}
          value={100}
          {...other}
        />
        <GraphValue>{format.currencyFull(max, false)}</GraphValue>
      </GraphContainer>
    </Box>
  );
}
