import { Container } from '@material-ui/core';
import { Text } from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import styled from 'styled-components';

export const PortfolioBuilderContainer = styled(Container)`
  padding: 0 1.5rem;
  overflow: hidden;
`;

export const DecisionMakingContainer = styled(Container)`
  ${(p) => p.theme.breakpoints.down('md')} {
    padding: 1rem 1rem 0 1rem;
  }
  ${(p) => p.theme.breakpoints.up('md')} {
    padding-top: 3.5rem;
    padding-bottom: 0;
  }
`;

export const ErrorMessage = styled(Text)`
  color: ${colors.danger};
`;

export const PreambleWrapper = styled.div`
  padding-bottom: 1rem;
`;

export const ClosedAccountMessageWrapper = styled.div`
  padding: 3rem;
`;
