import { useMediaQuery, useTheme } from '@material-ui/core';
import { SmallCopy } from 'components/Copy/SmallCopy';
import { TabTable } from 'components/TabTable/TabTable';
import {
  FontSize,
  FontWeight,
  Text,
  TextAlign,
  TextNormal,
  TextSmall,
} from 'components/design-system/Text/Text';
import * as format from 'formatting';
import { WrapperType } from 'generated/graphql';
import React, { useMemo } from 'react';
import {
  CurrentBreakdownType,
  IncludeAssetType,
  getAssetNameOfAsset,
  useGetCurrentBreakdown,
} from '../../hooks/useGetBreakdowns';
import {
  CardTableContainer,
  HoldingsTabInfo,
  HoldingsTabInner,
} from '../_shared/DrillDownTab.styles';
import { NameTdInner, Table, Td, Th, Tr } from '../_shared/Table.styles';
import { CurrentStatBoxes } from './_shared/CurrentStatBoxes';

const assetClassDisplayLookup: Record<string, string> = {
  Equities: 'equity',
  Bonds: 'bond',
  Property: 'property',
  'Alternative assets': 'property',
  'Multi-asset': 'equity',
};

const regionDisplayLookup: Record<string, string> = {
  Global: 'global',
  UK: 'UK',
  US: 'US',
  Europe: 'European',
  Japan: 'Japan',
  Asia: 'Asia',
  'Asia ex-Japan': 'Asia ex-Japan',
  'Emerging markets': 'emerging market',
  'Frontier markets': 'frontier market',
};

interface OrdersTableProps {
  breakdowns: CurrentBreakdownType[];
  footNote: React.ReactNode | JSX.Element | string;
}

type DataType = {
  name: string;
  value: string;
};

type TabTableData = {
  label: string;
  data: DataType[];
};

function OrdersTable({ breakdowns, footNote }: OrdersTableProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const holdingsLabel = 'Holding (%)';
  const valueLabel = 'Value (£)';
  const returnLabel = 'Return (%) since purchase';

  const mobData: TabTableData[] = useMemo(() => {
    const getHoldingsData = () => {
      return breakdowns.map((breakdown) => {
        return {
          name: breakdown.name,
          value: format.percent(breakdown.percentage / 100),
        };
      });
    };

    const getValueData = () => {
      return breakdowns.map((breakdown) => {
        return {
          name: breakdown.name,
          value: format.currencyFull(breakdown.amount),
        };
      });
    };

    const getReturnData = () => {
      return breakdowns.map((breakdown) => {
        return {
          name: breakdown.name,
          value:
            breakdown.growthProportion !== null
              ? format.percent(breakdown.growthProportion, true)
              : 'N/A',
        };
      });
    };

    return [
      {
        label: holdingsLabel,
        data: getHoldingsData(),
        extraInfo: footNote,
      },
      {
        label: valueLabel,
        data: getValueData(),
      },
      {
        label: returnLabel,
        data: getReturnData(),
      },
    ];
  }, [breakdowns, footNote]);

  if (isMobile) {
    return (
      <>
        <TabTable data={mobData} />
      </>
    );
  } else {
    return (
      <>
        <CardTableContainer>
          <Table>
            <thead>
              <tr>
                <Th>
                  <TextSmall $noMargin $fontWeight={FontWeight.medium}>
                    Name
                  </TextSmall>
                </Th>
                <Th>
                  <Text
                    $fontSize={FontSize.small}
                    $fontWeight={FontWeight.medium}
                    $textAlign={TextAlign.right}
                    $noMargin
                  >
                    Holding (%)
                  </Text>
                </Th>
                <Th>
                  <Text
                    $fontSize={FontSize.small}
                    $fontWeight={FontWeight.medium}
                    $textAlign={TextAlign.right}
                    $noMargin
                  >
                    Value (£)
                  </Text>
                </Th>
                <Th>
                  <Text
                    $fontSize={FontSize.small}
                    $fontWeight={FontWeight.medium}
                    $textAlign={TextAlign.right}
                    $noMargin
                  >
                    Return (%) since purchase
                  </Text>
                </Th>
              </tr>
            </thead>
            <tbody>
              {breakdowns.map((breakdown) => {
                return (
                  <Tr key={breakdown.name}>
                    <Td>
                      <NameTdInner>
                        <Text
                          $fontSize={FontSize.xs}
                          $fontWeight={FontWeight.light}
                          $textAlign={TextAlign.right}
                          $noMargin
                        >
                          {breakdown.name}
                        </Text>
                      </NameTdInner>
                    </Td>
                    <Td>
                      <Text
                        $fontSize={FontSize.xs}
                        $fontWeight={FontWeight.light}
                        $textAlign={TextAlign.right}
                        $noMargin
                      >
                        {format.percent(breakdown.percentage / 100)}
                      </Text>
                    </Td>
                    <Td>
                      <Text
                        $fontSize={FontSize.xs}
                        $fontWeight={FontWeight.light}
                        $textAlign={TextAlign.right}
                        $noMargin
                      >
                        {format.currencyFull(breakdown.amount)}
                      </Text>
                    </Td>
                    <Td>
                      <Text
                        $fontSize={FontSize.xs}
                        $fontWeight={FontWeight.light}
                        $textAlign={TextAlign.right}
                        $noMargin
                      >
                        {breakdown.growthProportion !== null && (
                          <>
                            {format.percent(breakdown.growthProportion, true)}
                          </>
                        )}
                      </Text>
                    </Td>
                  </Tr>
                );
              })}
            </tbody>
          </Table>
        </CardTableContainer>
        {footNote}
      </>
    );
  }
}

interface HoldingsTabProps {
  selectedAccountType: WrapperType;
  includeAsset: IncludeAssetType;
  activeBreakdown: CurrentBreakdownType;
}

export function CurrentHoldingsTab({
  selectedAccountType,
  includeAsset,
  activeBreakdown,
}: HoldingsTabProps) {
  const { breakdowns } = useGetCurrentBreakdown(
    selectedAccountType,
    getAssetNameOfAsset,
    includeAsset
  );

  const breakDownName =
    assetClassDisplayLookup[activeBreakdown.name] ??
    regionDisplayLookup[activeBreakdown.name] ??
    activeBreakdown.name.toLocaleLowerCase();

  const footnoteContent = `Please note that the 'Holding (%)' is calculated as a proportion of your ${breakDownName} allocation, not your total portfolio.`;

  return (
    <div>
      <TextNormal>{activeBreakdown.name} holdings</TextNormal>
      <HoldingsTabInner>
        <OrdersTable
          breakdowns={breakdowns}
          footNote={<SmallCopy $fontStyle="italic" copy={footnoteContent} />}
        />

        <HoldingsTabInfo>
          <CurrentStatBoxes activeBreakdown={activeBreakdown} />
        </HoldingsTabInfo>
      </HoldingsTabInner>
    </div>
  );
}
