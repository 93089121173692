import { IconButton, IconButtonProps, Popper } from '@material-ui/core';
import { styledLinkStyleOnly } from 'components/design-system/Link/Link.styles';
import { colors } from 'constants/colors';
import styled from 'styled-components';

interface StyledIconButtonProps extends IconButtonProps {
  $disablePadding?: boolean;
  $disableMargin?: boolean;
}

export const StyledIconButton = styled(IconButton)<StyledIconButtonProps>`
  ${(p) => !p.$disableMargin && `margin-left: ${p.theme.spacing(1)}px;`}
  ${(p) => p.$disablePadding && 'padding: 0;'}

  &.MuiIconButton-colorPrimary {
    color: ${colors.magenta};
  }
`;

export const Arrow = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
`;

export const StyledSpan = styled.span`
  ${styledLinkStyleOnly};
`;

export type styledPopperPaddingOptions = 'narrow' | 'normal';

type styledPopperWidthOptions = 'narrow' | 'normal' | 'wide';
const styledPopperWidthLookup = {
  narrow: '250px',
  normal: '300px',
  wide: '340px',
};
const styledPopperWidthLookupSmUp = {
  narrow: '250px',
  normal: '375px',
  wide: '650px',
};

interface StyledPopperProps {
  $isDark?: boolean;
  $width: styledPopperWidthOptions;
  $padding?: styledPopperPaddingOptions;
}

export const StyledPopper = styled(Popper)<StyledPopperProps>`
  z-index: ${(p) => p.theme.zIndex.tooltip};

  filter: drop-shadow(0px 2px 40px rgba(0, 0, 0, 0.25));

  .MuiPaper-root {
    padding: ${({ $padding }) =>
      $padding === 'narrow' ? '0.5rem 0.5rem' : '1.25rem'};
    border-radius: 8px;
    background-color: ${({ $isDark }) =>
      $isDark ? colors.richBlack : colors.white};
    overflow: visible;
    color: ${({ $isDark }) => ($isDark ? colors.white : colors.richBlack)};
    max-width: ${({ $width }) => styledPopperWidthLookup[$width]};
    ${({ theme }) => theme.breakpoints.up('sm')} {
      max-width: ${({ $width }) => styledPopperWidthLookupSmUp[$width]};
    }
  }

  &[x-placement*='top'] {
    .MuiPaper-root {
      margin-bottom: 15.5px;
    }

    ${Arrow} {
      bottom: 0;
      border-top: 15px solid;
      border-top-color: ${({ $isDark }) =>
        $isDark ? colors.richBlack : colors.white};
    }
  }

  &[x-placement*='top-start'] {
    .MuiPaper-root {
      margin-bottom: 15px;
    }

    ${Arrow} {
      bottom: 0;
      left: 0;
      ${({ theme }) => theme.breakpoints.up('sm')} {
        left: 16px !important;
      }
      border-top: 15px solid;
      border-top-color: ${({ $isDark }) =>
        $isDark ? colors.richBlack : colors.white};
    }
  }

  &[x-placement*='top'] {
    ${({ theme }) => theme.breakpoints.up('sm')} {
      left: 0 !important;
    }
    .MuiPaper-root {
      margin-bottom: 15px;
    }

    ${Arrow} {
      bottom: 0;
      left: calc(50% + 7.5px);
      border-top: 15px solid;
      border-top-color: ${({ $isDark }) =>
        $isDark ? colors.richBlack : colors.white};
    }
  }

  &[x-placement*='bottom-start'] {
    .MuiPaper-root {
      margin-top: 15.5px;
    }

    ${Arrow} {
      top: 0;
      left: 0;
      ${({ theme }) => theme.breakpoints.up('sm')} {
        left: 16px !important;
      }
      border-bottom: 14.9px solid;
      border-bottom-color: ${({ $isDark }) =>
        $isDark ? colors.richBlack : colors.white};
    }
  }

  &[x-placement*='bottom'] {
    .MuiPaper-root {
      margin-top: 14px;
    }

    ${Arrow} {
      top: 0;
      left: calc(50% + 7.5px);
      border-bottom: 14.9px solid;
      border-bottom-color: ${({ $isDark }) =>
        $isDark ? colors.richBlack : colors.white};
    }
  }
`;
