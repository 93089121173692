import { Dialog, DialogProps, IconButton } from '@material-ui/core';
import { colors } from 'constants/colors';
import React from 'react';
import styled, { css } from 'styled-components';

export const StyledDialog = styled(
  React.forwardRef(({ className, ...props }: DialogProps, forwardedRef) => (
    <Dialog {...props} ref={forwardedRef} classes={{ paper: className }} />
  ))
)`
  padding: ${(p) => p.theme.spacing(10, 3, 7)};

  /** Extra padding to overcome bug in IOS 18.2 - browser height with expanding address bar can be wrong  */
  @supports (-webkit-touch-callout: none) {
    padding: ${(p) => p.theme.spacing(10, 3, 12)};
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: ${(p) => p.theme.spacing(10, 7, 7)};
  }
  overflow-x: hidden;

  // TODO: remove after form refactoring
  & > form,
  & > * > form {
    margin: 0;
  }

  .MuiDialogTitle-root {
    .MuiTypography-root {
      font-size: 2rem;
      font-weight: normal;
      line-height: normal;
      color: ${colors.magenta};
    }
  }

  .MuiTypography-h2 {
    font-size: 1.5rem;
    color: ${colors.richBlue};
    font-weight: 200;
    margin-bottom: ${(p) => p.theme.spacing(1.5)}px;
  }

  .MuiDialogContent-root {
    margin-top: ${(p) => p.theme.spacing(4)}px;
    padding: 0;

    & > .MuiTypography-body1:not(:last-child) {
      margin-bottom: ${(p) => p.theme.spacing(3)}px;
    }

    .MuiAlert-root {
      margin: ${(p) => p.theme.spacing(3, 0)};
    }
  }

  .MuiDialogActions-root {
    margin-top: ${(p) => p.theme.spacing(7)}px;
    padding: 0;
    flex-direction: column-reverse;

    &.MuiDialogActions-spacing > * {
      margin-left: 0;
      margin-top: ${(p) => p.theme.spacing(2)}px;
    }

    & > * {
      flex: 1 1 auto;
    }

    ${(p) => p.theme.breakpoints.up('sm')} {
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: wrap;

      &.MuiDialogActions-spacing > * {
        margin-top: 0;
      }

      &.MuiDialogActions-spacing > :nth-child(even) {
        margin-left: ${(p) => p.theme.spacing(2)}px;
      }

      & > * {
        flex: 1 1 calc(50% - ${(p) => p.theme.spacing(2)}px);
      }
    }
  }

  .MuiLink-root {
    color: ${colors.magenta};
  }
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  color: ${colors.darkGrey};
  ${({ theme }) => css`
    top: ${theme.spacing(4) - 12}px;
    right: ${theme.spacing(4) - 12}px;
    ${theme.breakpoints.up('sm')} {
      top: ${theme.spacing(7) - 12}px;
      right: ${theme.spacing(7) - 12}px;
    }
  `}
`;
