import { Snackbar, useMediaQuery } from '@material-ui/core';
import { FontSize, FontWeight, Text } from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import { GrClose } from 'react-icons/gr';
import styled, { useTheme } from 'styled-components';

export enum ToastType {
  Success = 'Success',
  Information = 'Information',
}

type ToastContentStyleProps = {
  $type: ToastType;
};

const toastTypeColourLookup = {
  [ToastType.Success]: colors.success,
  [ToastType.Information]: colors.richBlue,
};

const ToastContent = styled.div<ToastContentStyleProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  background: ${(p) => toastTypeColourLookup[p.$type] ?? colors.success};
  border-radius: 5px;
  position: fixed;
  top: calc(20px + ${({ theme }) => theme.header.height});
  padding: 0.5rem 1rem;
  filter: drop-shadow(0px 2px 20px rgba(0, 0, 0, 0.3));
  width: calc(100vw - 2rem);

  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: max-content;
    justify-content: flex-start;
  }

  p {
    color: ${colors.white};
  }
`;

const CloseToast = styled(GrClose)`
  cursor: pointer;
  path {
    stroke: ${colors.white};
    stroke-width: 1px;
  }
`;

interface ToastProps {
  open: boolean;
  autoHideDuration?: number;
  onClose: () => void;
  message: string;
  type: ToastType;
}
export const Toast = ({
  open,
  onClose,
  message,
  autoHideDuration = 3000,
  type,
}: ToastProps) => {
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={() => onClose()}
      anchorOrigin={{
        vertical: 'top',
        horizontal: isSmUp ? 'right' : 'center',
      }}
    >
      <ToastContent $type={type}>
        <Text
          $fontSize={FontSize.normal}
          $fontWeight={FontWeight.medium}
          $noMargin
        >
          {message}
        </Text>
        <CloseToast size={'1.3rem'} onClick={() => onClose()} />
      </ToastContent>
    </Snackbar>
  );
};
