import {
  ResponsiveDialog,
  ResponsiveDialogProps,
} from 'components/Dialogs/ResponsiveDialog';
import { QueryState } from 'components/QueryState';
import { VerifyInstrumentSelectionStepBuy } from 'components/feature/PortfolioBuilder/AddToBasket/steps/VerifyInstrumentSelection';
import { VerifyVintageSelectionStepBuy } from 'components/feature/PortfolioBuilder/AddToBasket/steps/VerifyVintageSelection';
import { useMode } from 'components/feature/mode/useMode';
import { useAuth } from 'context/AuthContext';
import {
  WrapperType,
  useAccountsQuery,
  useAssetQuery,
  useUserProfileQuery,
} from 'generated/graphql';
import { getWrapperTypeForPathSegment } from 'helpers/accountHelpers';
import { useGetDefaultSelectedInstrumentIsin } from 'helpers/assetHelpers';
import { matchesHeldAsset } from 'helpers/matchesHeldAsset';
import { useToggle } from 'hooks/useFeatureToggle';
import { useMatchesAllocatedVintage } from 'hooks/useMatchesAllocatedVintage';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import type {
  AccountsQueryAccount,
  AccountsQueryAccountPosition,
  AssetQueryAsset,
} from 'types/graphqlTypes';
import { useAutoSaveInvestState } from '../../AutoSaveInvestContext';
import { AddToRecurringOrderAmountStep } from './steps/AddToRecurringOrderAmountStep';

interface AddToFundToRecurringOrderFormProps {
  asset: AssetQueryAsset;
  selectedAccountType?: WrapperType;
  selectedAccountId?: string;
  selectedIsin: string;
  activeAccount: AccountsQueryAccount;
  handleInstrumentChange?: (isin: string) => void;
  onGoBack?: () => void;
  closeDialog: () => void;
}

type steps =
  | 'verifyVintageSelection'
  | 'verifyPositionSelection'
  | 'selectAmount';

export function AddToFundToRecurringOrderForm({
  asset,
  selectedAccountType,
  selectedAccountId,
  selectedIsin,
  activeAccount,
  handleInstrumentChange,
  onGoBack,
  closeDialog,
}: AddToFundToRecurringOrderFormProps) {
  const [verifyVintageStepToggle] = useToggle('global-verify-vintage-step');
  const [verifyInstrumentStepToggle] = useToggle(
    'global-verify-instrument-step'
  );
  const [activeStep, setActiveStep] = useState<steps | null>(null);
  const {
    matches,
    targetDateInstrument,
    selectedInstrument,
  } = useMatchesAllocatedVintage({
    isin: selectedIsin,
    asset,
  });

  const positions = activeAccount.positions as AccountsQueryAccountPosition[];

  const { userHoldsPosition, heldPositionsByAsset } = matchesHeldAsset({
    isin: selectedIsin,
    asset,
    positions: positions,
  });

  useEffect(() => {
    if (activeStep !== null || !asset) {
      return;
    }

    if (
      asset.isTargetDateFund &&
      selectedAccountType &&
      selectedAccountId &&
      !matches &&
      verifyVintageStepToggle?.enabled
    ) {
      setActiveStep('verifyVintageSelection');
      return;
    }

    if (
      selectedAccountType &&
      selectedAccountId &&
      !userHoldsPosition &&
      !asset.isTargetDateFund &&
      heldPositionsByAsset.length > 0 &&
      verifyInstrumentStepToggle
    ) {
      setActiveStep('verifyPositionSelection');
      return;
    }

    if (selectedAccountType && selectedAccountId) {
      setActiveStep('selectAmount');
      return;
    }
  }, [
    activeStep,
    asset,
    heldPositionsByAsset.length,
    matches,
    selectedAccountId,
    selectedAccountType,
    userHoldsPosition,
    verifyInstrumentStepToggle,
    verifyVintageStepToggle?.enabled,
  ]);

  return (
    <>
      {activeStep === 'verifyVintageSelection' &&
        selectedAccountType &&
        selectedAccountId &&
        verifyVintageStepToggle?.enabled && (
          <VerifyVintageSelectionStepBuy
            selectedIsin={selectedIsin}
            targetDateInstrument={targetDateInstrument}
            selectedInstrument={selectedInstrument}
            handleInstrumentChange={handleInstrumentChange}
            flow="Recurring order"
            onProceed={() => {
              setActiveStep('selectAmount');
            }}
            onGoBack={onGoBack}
            asset={asset}
          />
        )}
      {activeStep === 'verifyPositionSelection' &&
        selectedAccountType &&
        selectedAccountId &&
        verifyInstrumentStepToggle?.enabled && (
          <VerifyInstrumentSelectionStepBuy
            asset={asset}
            selectedAccountType={selectedAccountType}
            selectedIsin={selectedIsin}
            heldPositions={heldPositionsByAsset}
            handleInstrumentChange={handleInstrumentChange}
            flow="Recurring order"
            onProceed={() => {
              setActiveStep('selectAmount');
            }}
            onGoBack={onGoBack}
          />
        )}
      {activeStep === 'selectAmount' &&
        selectedAccountType &&
        selectedAccountId && (
          <AddToRecurringOrderAmountStep
            wrapperType={selectedAccountType}
            selectedAsset={asset}
            selectedIsin={selectedIsin}
            selectedAccountId={selectedAccountId}
            handleClose={closeDialog}
            onGoBack={() => {
              if (
                selectedAccountType &&
                selectedAccountId &&
                !asset.isTargetDateFund &&
                !userHoldsPosition &&
                heldPositionsByAsset.length > 0 &&
                verifyInstrumentStepToggle
              ) {
                setActiveStep('verifyPositionSelection');
                return;
              }

              if (
                asset.isTargetDateFund &&
                selectedAccountType &&
                selectedAccountId &&
                !matches &&
                verifyVintageStepToggle?.enabled
              ) {
                setActiveStep('verifyVintageSelection');
                return;
              }

              onGoBack?.();
            }}
          />
        )}
    </>
  );
}

interface EnterAmountStepAssetIdDialogProps extends ResponsiveDialogProps {
  selectedAssetId: number;
  selectedIsin?: string;
  open: boolean;
  handleInstrumentChange?: (isin: string) => void;
  onGoBack?: () => void;
  closeDialog: () => void;
}

export function AddFundToRecurringOrderDialog({
  selectedAssetId,
  selectedIsin,
  open,
  handleInstrumentChange,
  onGoBack,
  closeDialog,
}: EnterAmountStepAssetIdDialogProps) {
  const urlParams = new URLSearchParams(window.location.search);
  const search = urlParams.get('search') || '';
  const [mode] = useMode();

  const { wrapperType: paramsWrapperType } = useParams<{
    wrapperType: string;
  }>();

  const wrapperType = paramsWrapperType
    ? getWrapperTypeForPathSegment(paramsWrapperType)
    : mode?.mode === 'resume'
    ? undefined
    : mode?.wrapperType;

  const assetQuery = useAssetQuery({
    id: selectedAssetId,
  });
  const { signedIn } = useAuth();

  const userProfileQuery = useUserProfileQuery(undefined, {
    enabled: signedIn,
  });
  const accountsQuery = useAccountsQuery(undefined, {
    enabled: signedIn,
  });

  const accounts = accountsQuery.data?.accounts ?? [];
  const selectedAccount = accounts.find(
    (account) => account.wrapperType === wrapperType
  );

  const { state } = useAutoSaveInvestState(selectedAccount?.id);

  const defaultSelectedIsin = useGetDefaultSelectedInstrumentIsin(
    assetQuery.data?.asset,
    mode,
    userProfileQuery.data?.userProfile ?? undefined,
    accountsQuery.data?.accounts ?? [],
    search,
    undefined,
    state?.orders ?? undefined,
    selectedIsin
  );

  return (
    <ResponsiveDialog open={open} onClose={closeDialog}>
      {open && (
        <QueryState {...assetQuery}>
          {() =>
            assetQuery.data?.asset &&
            selectedAccount &&
            defaultSelectedIsin && (
              <AddToFundToRecurringOrderForm
                activeAccount={selectedAccount}
                selectedAccountType={wrapperType}
                selectedAccountId={selectedAccount?.id}
                asset={assetQuery.data?.asset}
                selectedIsin={defaultSelectedIsin}
                handleInstrumentChange={handleInstrumentChange}
                onGoBack={onGoBack}
                closeDialog={() => {
                  closeDialog?.();
                }}
              />
            )
          }
        </QueryState>
      )}
    </ResponsiveDialog>
  );
}
