import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import FundRemovalPolicy from 'assets/Fund_Removal_Policy.pdf';
import FundSelectionManifesto from 'assets/Fund_Selection_Manifesto.pdf';
import FundSelectionPolicy from 'assets/Fund_Selection_Policy.pdf';
import clsx from 'clsx';
import { PageContainer } from 'components/PageContainer';
import {
  StepActions,
  StepContainer,
  StepContent,
} from 'components/StepContainer';
import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import { H3 } from 'components/design-system/Heading/Heading';
import { StyledLink } from 'components/design-system/Link';
import { FontSize, FontWeight, Text } from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import { AnimatePresence } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { HiChevronDown } from 'react-icons/hi';
import { useReportAffirmations } from '../hooks/useReportAffirmations';
import {
  AccordionWrapper,
  AffirmationsWrapper,
  AlertContainer,
  Hr,
  RiskText,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledButton,
} from './IcReportForm.styles';

enum FormSteps {
  KeyInfo = 'KeyInfo',
  Confirm = 'Confirm',
  AllDone = 'AllDone',
}

interface IcReportFormProps {
  reportUrl: string;
  onClose?: () => void;
}

export function IcReportForm({ onClose, reportUrl }: IcReportFormProps) {
  const { affirmations, setAffirmations } = useReportAffirmations();
  const [activeStep, setActiveStep] = useState<FormSteps>(FormSteps.KeyInfo);

  function handleOnProceed(step: FormSteps) {
    if (step === FormSteps.AllDone) {
      const affirmationsStored = affirmations?.some(
        (entry) => entry === reportUrl
      );

      if (!affirmationsStored) {
        const newAffirmations = [...affirmations, reportUrl];
        setAffirmations(newAffirmations);
      }

      window.open(reportUrl, '_blank');
      onClose?.();
    } else {
      setActiveStep(step);
    }
  }

  return (
    <PageContainer>
      <AnimatePresence>
        {activeStep === FormSteps.KeyInfo && (
          <KeyInfoStep onProceed={() => handleOnProceed(FormSteps.Confirm)} />
        )}
        {activeStep === FormSteps.Confirm && (
          <ConfirmStep onProceed={() => handleOnProceed(FormSteps.AllDone)} />
        )}
      </AnimatePresence>
    </PageContainer>
  );
}

interface StepProps {
  onProceed: () => void;
}
function KeyInfoStep({ onProceed }: StepProps) {
  const [accordion1Viewed, setAccordion1Viewed] = useState(false);
  const [accordion2Viewed, setAccordion2Viewed] = useState(false);
  const [accordion3Viewed, setAccordion3Viewed] = useState(false);
  const [accordion4Viewed, setAccordion4Viewed] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [expandedAccordion, setExpandedAccordion] = useState<string | false>(
    false
  );

  const buttonDisabled =
    !accordion1Viewed ||
    !accordion2Viewed ||
    !accordion3Viewed ||
    !accordion4Viewed;

  function handleClick() {
    const allAccordionsViewed =
      accordion1Viewed &&
      accordion2Viewed &&
      accordion3Viewed &&
      accordion4Viewed;

    if (allAccordionsViewed) {
      onProceed();
    } else {
      setDisplayError(true);
    }
  }

  function handleSetExpanded(accordion: string) {
    if (accordion === expandedAccordion) {
      setExpandedAccordion(false);
    } else {
      setExpandedAccordion(accordion);
    }
  }

  useEffect(() => {
    !buttonDisabled && setDisplayError(false);
  }, [buttonDisabled]);

  return (
    <StepContainer>
      <StepContent>
        <H3>Key information</H3>
        <Text $fontWeight={FontWeight.light} style={{ color: colors.darkBlue }}>
          <b>Click on each tab</b> to learn more before you proceed to the
          Quarterly Investment Committee report and Discussion Summary.
        </Text>
        <AccordionWrapper>
          <StyledAccordion
            square
            elevation={0}
            expanded={expandedAccordion === 'accordion1'}
            onChange={() => {
              !accordion1Viewed && setAccordion1Viewed(true);
              handleSetExpanded('accordion1');
            }}
          >
            <StyledAccordionSummary
              IconButtonProps={{
                disableRipple: true,
              }}
              expandIcon={
                <HiChevronDown size={20} style={{ color: colors.darkBlue }} />
              }
            >
              <Text
                $noMargin
                $fontWeight={FontWeight.medium}
                style={{ color: colors.darkBlue }}
              >
                Why are we sharing this information?
              </Text>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <>
                <Hr />
                <Text $fontWeight={FontWeight.light}>
                  At TILLIT, we believe in transparency, accountability and good
                  governance. This is why we share our Investment Committee
                  reports, prepared by our Fund Selection team ahead of our
                  quarterly Investment Committee meetings. We also share
                  Discussion Summaries of the Investment Committee meetings for
                  additional transparency.
                </Text>
              </>
            </StyledAccordionDetails>
          </StyledAccordion>
          <StyledAccordion
            square
            elevation={0}
            expanded={expandedAccordion === 'accordion2'}
            onChange={() => {
              !accordion2Viewed && setAccordion2Viewed(true);
              handleSetExpanded('accordion2');
            }}
          >
            <StyledAccordionSummary
              IconButtonProps={{
                disableRipple: true,
              }}
              expandIcon={
                <HiChevronDown size={20} style={{ color: colors.darkBlue }} />
              }
            >
              <Text
                $noMargin
                $fontWeight={FontWeight.medium}
                style={{ color: colors.darkBlue }}
              >
                How do these reports help me?
              </Text>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <>
                <Hr />
                <Text $fontWeight={FontWeight.light}>
                  By sharing these reports, you get unrestricted access to how
                  we make decisions related to selecting, rejecting, or removing
                  funds from the TILLIT Universe. The reports also give you an
                  insight into our views on markets, the performance of the
                  funds in the TILLIT Universe, how they stack up against peers,
                  as well as visibility into the discussions we have with fund
                  managers.
                </Text>
              </>
            </StyledAccordionDetails>
          </StyledAccordion>
          <StyledAccordion
            square
            elevation={0}
            expanded={expandedAccordion === 'accordion3'}
            onChange={() => {
              !accordion3Viewed && setAccordion3Viewed(true);
              handleSetExpanded('accordion3');
            }}
          >
            <StyledAccordionSummary
              IconButtonProps={{
                disableRipple: true,
              }}
              expandIcon={
                <HiChevronDown size={20} style={{ color: colors.darkBlue }} />
              }
            >
              <Text
                $noMargin
                $fontWeight={FontWeight.medium}
                style={{ color: colors.darkBlue }}
              >
                Is the IC report the same as the TILLIT Fund Selection Policy?
              </Text>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <>
                <Hr />
                <Text $fontWeight={FontWeight.light}>
                  No, our Quarterly Investment Committee reports demonstrate how
                  we implement and adhere to our{' '}
                  <StyledLink href={FundSelectionManifesto} target="_blank">
                    Fund Selection Manifesto
                  </StyledLink>
                  ,{' '}
                  <StyledLink href={FundSelectionPolicy} target="_blank">
                    Fund Selection Policy
                  </StyledLink>{' '}
                  and Fund Selection{' '}
                  <StyledLink href={FundRemovalPolicy} target="_blank">
                    Removal Policy
                  </StyledLink>
                  .
                </Text>
              </>
            </StyledAccordionDetails>
          </StyledAccordion>
          <StyledAccordion
            square
            elevation={0}
            expanded={expandedAccordion === 'accordion4'}
            onChange={() => {
              !accordion4Viewed && setAccordion4Viewed(true);
              handleSetExpanded('accordion4');
            }}
          >
            <StyledAccordionSummary
              IconButtonProps={{
                disableRipple: true,
              }}
              expandIcon={
                <HiChevronDown size={20} style={{ color: colors.darkBlue }} />
              }
            >
              <Text
                $noMargin
                $fontWeight={FontWeight.medium}
                style={{ color: colors.darkBlue }}
              >
                Is the content of the Quarterly Investment Committee reports and
                Discussion Summaries financial advice?
              </Text>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <>
                <Hr />
                <Text $fontWeight={FontWeight.light}>
                  No. The information and recommendations presented in the
                  Quarterly Investment Committee reports are intended for the
                  purpose of discussion and decision-making by the Investment
                  Committee. The content of the Quarterly Investment Committee
                  reports and the Discussion Summaries offers information about
                  our Fund Selection Process and is{' '}
                  <strong>
                    not a personal recommendation for investment, or financial
                    advice
                  </strong>
                </Text>
              </>
            </StyledAccordionDetails>
          </StyledAccordion>
        </AccordionWrapper>
      </StepContent>
      <StepActions>
        <StyledButton
          className={clsx(buttonDisabled && 'disabled')}
          $color="primary"
          onClick={() => handleClick()}
        >
          Continue
        </StyledButton>
      </StepActions>
      {displayError && (
        <AlertContainer>
          <Alert severity="error" variant="outlined">
            <Text
              $noMargin
              $fontWeight={FontWeight.normal}
              $fontSize={FontSize.small}
            >
              You need to click on each tab before you can continue
            </Text>
          </Alert>
        </AlertContainer>
      )}
    </StepContainer>
  );
}

function ConfirmStep({ onProceed }: StepProps) {
  const [affirmation1, setAffirmation1] = useState(false);
  const [affirmation2, setAffirmation2] = useState(false);

  return (
    <StepContainer>
      <StepContent>
        <H3>Access the report</H3>
        <Text $fontSize={FontSize.normal}>
          To proceed to the Quarterly Investment Committee report and the
          Discussion Summary, I confirm that:
        </Text>
        <AffirmationsWrapper>
          <FormControlLabel
            control={
              <Checkbox
                checked={affirmation1}
                onChange={(e) => setAffirmation1(e.target.checked)}
              />
            }
            label={
              <Text $fontSize={FontSize.normal} $noMargin>
                I have read and understood the Key information.
              </Text>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={affirmation2}
                onChange={(e) => setAffirmation2(e.target.checked)}
              />
            }
            label={
              <Text $fontSize={FontSize.normal} $noMargin>
                I understand that the information presented in the Quarterly
                Investment Committee reports and Discussion Summaries is{' '}
                <strong>
                  not a personal recommendation for investment, or financial
                  advice.{' '}
                </strong>
              </Text>
            }
          />
        </AffirmationsWrapper>
      </StepContent>
      <StepActions>
        <CustomButtonV2
          $color="primary"
          onClick={() => onProceed()}
          disabled={!affirmation1 || !affirmation2}
        >
          Read report
        </CustomButtonV2>
      </StepActions>
      <RiskText $fontSize={FontSize.small} $noMargin>
        Capital is at risk. Past performance is not a reliable indicator of
        future results. The value of investments can go up and down, so you
        could get back less than you put in. If you’re not sure which
        investments are right for you, please seek advice from a qualified
        financial adviser.
      </RiskText>
    </StepContainer>
  );
}
