import { colors } from 'constants/colors';
import styled from 'styled-components';

export const SimpleHeroContainer = styled.div`
  padding: 10rem 1.25rem 2rem;
  position: relative;
  background-color: ${colors.white};
  color: ${colors.richBlack} ${(p) => p.theme.breakpoints.up('md')} {
    padding: ${(p) => p.theme.spacing(5)}px;
  }
`;

export const ChildrenContainer = styled.div`
  text-align: center;
  max-width: 680px;
  margin: 0 auto;
  padding: 0;

  ${(p) => p.theme.breakpoints.up('sm')} {
    padding: ${(p) => p.theme.spacing(0, 0, 3.75, 0)};
  }
`;
