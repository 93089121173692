import { AnyAsset } from 'types/graphqlTypes';
import { FundActions } from './FundActions';

interface HeaderActionsOldProps {
  asset: AnyAsset;
  isin: string;
  userHolds: boolean;
  onBuy: () => void;
  onSell: () => void;
}

interface HeaderActionsProps extends HeaderActionsOldProps {
  handleInstrumentChange: (isin: string) => void;
}

export function HeaderActions({
  asset,
  isin,
  userHolds,
  handleInstrumentChange,
}: HeaderActionsProps) {
  return (
    <FundActions
      asset={asset}
      isin={isin}
      userHolds={userHolds}
      handleInstrumentChange={handleInstrumentChange}
    />
  );
}
