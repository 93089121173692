import { currencyFull } from 'formatting';
import React from 'react';
import { Container, Main, Title, TotalAmount } from './CashHoldingCard.styles';
import { Actions, Value } from './HoldingCard.styles';

interface ResidualAssetsCardProps {
  amount: number;
}

export function ResidualAssetsCard({ amount }: ResidualAssetsCardProps) {
  return (
    <Container>
      <Main>
        <Title>
          Residual assets
          {/* Commented out until we are waiting for signed-off copy */}
          {/* <InfoPopover content="We are selling down investments that were transferred from another provider but that aren't supported on TILLIT. The proceeds will be added to your cash balance when the trades are completed." /> */}
        </Title>
      </Main>

      <TotalAmount>
        <Value>{currencyFull(amount)}</Value>
      </TotalAmount>

      <Actions />
    </Container>
  );
}
