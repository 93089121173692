import { CombinedQueryState } from 'components/QueryState/QueryState';
import { GaEventNames } from 'constants/gaConstants';
import {
  PaymentMethodKind,
  PaymentStatus,
  useAccountQuery,
  useAccountsQuery,
  useCashPaymentByIdQuery,
  useCreateDepositMutation,
  useDepositDetailsByAccountQuery,
} from 'generated/graphql';
import { trackGa, trackGaProperties } from 'helpers/track';
import { useToggle } from 'hooks/useFeatureToggle';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { DepositFormVariant } from './DepositFormVariant';
import { DepositWizard } from './DepositWizard';
import { DepositsDisabled } from './DepositsDisabled';
import { DepositVariantContext } from './_shared/DepositVariantContext';
import { fundListPath } from 'paths';

interface DepositFormProps {
  accountId: string;
  variant?: DepositFormVariant;
  isPolling?: boolean;
  urlPaymentId?: string | null;
  proceedOnComplete?: boolean;
  minimumAmount?: number | null;
  defaultValue?: number | null;
  displayBuffer?: boolean;
  isCheckout?: boolean;
  onClose?: (amount?: number) => void;
  onSkipStep?: () => void;
  returnPath: string;
}

export function DepositForm({
  accountId,
  variant = DepositFormVariant.ExistingAccount,
  isPolling = false,
  urlPaymentId = null,
  proceedOnComplete = false,
  minimumAmount,
  defaultValue,
  displayBuffer = false,
  isCheckout = false,
  returnPath,
  onClose,
  onSkipStep,
}: DepositFormProps) {
  const [paymentId, setPaymentId] = useState<string | null>(urlPaymentId);
  const [redirectUri, setRedirectUri] = useState<string | null>(null);
  const history = useHistory();
  const dialogRef = useRef<HTMLDivElement>(null);
  const timeout = useRef<number | null>(null);
  const [hasTimedOut, setHasTimedOut] = useState(false);
  const minAmount =
    minimumAmount && minimumAmount > 10000 ? 10000 : minimumAmount;

  const queryClient = useQueryClient();

  /**
   * Only track GA events for onboarding if the user is creating a new account
   */
  const onboardingTrackGa = useCallback(
    (properties: trackGaProperties) => {
      if (variant === DepositFormVariant.NewAccount) {
        trackGa(properties);
      }
    },
    [variant]
  );

  const handleClose = (amount?: number) => {
    setHasTimedOut(false);
    setPaymentId(null);
    setRedirectUri(null);
    onClose?.(amount);
  };

  const accountQuery = useAccountQuery(
    { id: accountId },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: (q) => q.queryKey[0] === useAccountsQuery.getKey()[0],
        });
      },
    }
  );

  const availableBalance =
    accountQuery.data?.account?.valuationSummary?.uninvestedCash;
  const wrapperType = accountQuery.data?.account?.wrapperType;

  const depositContextQuery = useDepositDetailsByAccountQuery({
    id: accountId!,
  });
  const depositDetails = depositContextQuery.data?.depositDetailsByAccount;

  const canDeposit = depositDetails?.canDeposit;
  const paymentMethods = depositDetails?.availablePaymentMethods;
  const statusReason = depositDetails?.statusReason;
  const validRange = depositDetails?.validRange;
  const transferDetails = depositDetails?.transferDetails;

  const { mutateAsync: createDeposit } = useCreateDepositMutation();

  const cashPaymentQuery = useCashPaymentByIdQuery(
    { id: paymentId! },
    {
      enabled: !!paymentId && !hasTimedOut,
      onSuccess: (data) => {
        // Set the timeout for polling
        if (
          cashPaymentQuery.data?.cashPaymentById?.status ===
            PaymentStatus.Transferring &&
          timeout.current === null
        ) {
          const time = isPolling ? 60000 : 10000;
          timeout.current = setTimeout(() => {
            setHasTimedOut(true);
          }, time);
        }

        // Clear the timeout if the payment is completed
        if (
          [
            PaymentStatus.Completed,
            PaymentStatus.Cancelled,
            PaymentStatus.Failed,
          ].includes(data?.cashPaymentById?.status ?? PaymentStatus.Unknown)
        ) {
          queryClient.invalidateQueries({
            predicate: (q) =>
              q.queryKey[0] === useAccountsQuery.getKey()[0] ||
              q.queryKey[0] === useAccountQuery.getKey({ id: accountId! })[0],
          });

          if (timeout.current) {
            clearTimeout(timeout.current);
            timeout.current = null;
          }
        }
      },
      refetchInterval: (data) => {
        return [
          PaymentStatus.Completed,
          PaymentStatus.Cancelled,
          PaymentStatus.Failed,
        ].includes(data?.cashPaymentById?.status ?? PaymentStatus.Unknown)
          ? false
          : 2000;
      },
    }
  );

  const { showNewMessages } = useIntercom();

  const [redirectFlowFeature, togglesQuery] = useToggle(
    'global-truelayer-redirect-flow'
  );

  useEffect(() => {
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
    };
  });

  const handleCreateDeposit = useCallback(
    async (
      amount: number,
      paymentMethodKind: PaymentMethodKind,
      popupWindow?: Window,
      autoInvest?: boolean
    ) => {
      const response = await createDeposit({
        input: {
          amount,
          accountId,
          paymentMethodKind,
          returnUri: redirectFlowFeature?.enabled ? returnPath : null,
          autoInvest: autoInvest ?? false,
        },
      });

      if (response.createDeposit?.redirectUrl) {
        if (redirectFlowFeature?.enabled) {
          window.location.href = response.createDeposit.redirectUrl;
        } else {
          setRedirectUri(response.createDeposit.redirectUrl);
          setPaymentId(response.createDeposit!.id);
        }
        window.location.href = response.createDeposit.redirectUrl;
      }

      trackGa({
        event: GaEventNames.purchase,
        orderType: 'cash deposit',
        ecommerce: {
          transaction_id: response.createDeposit!.id,
          affiliation: 'Tillit',
          value: amount,
          tax: 0,
          shipping: 0,
          currency: 'GBP',
          items: [
            {
              item_id: 'SKU_12345',
              item_name: 'Cash deposit',
              affiliation: 'Tillit',
              currency: 'USD',
              item_variant: wrapperType,
              price: amount,
              quantity: 1,
            },
          ],
        },
      });
    },
    [
      accountId,
      createDeposit,
      redirectFlowFeature?.enabled,
      wrapperType,
      returnPath,
    ]
  );

  const handleDepositAction = (deferred: boolean) => {
    trackGa({
      event: GaEventNames.viewItem,
      orderType: `cash deposit ${deferred ? '- deferred' : '- made'}`,
      ecommerce: {
        items: [
          {
            item_id: 'cash',
            item_name: 'Cash deposit',
            affiliation: 'Tillit',
            currency: 'GBP',
            item_variant: wrapperType,
          },
        ],
      },
    });
  };

  const handlePopupClosed = () => {};

  const handleContactSupport = () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'talk to support',
      item_id: 'deposit wizard - true layer',
    });
    showNewMessages();
  };

  const handleBrowseFunds = () => {
    onboardingTrackGa({
      event: GaEventNames.onboardingEnd,
    });
    handleClose();
    history.push(fundListPath);
  };

  const handleDashboard = () => {
    onboardingTrackGa({
      event: GaEventNames.onboardingEnd,
    });
    history.push('/dashboard');
    handleClose();
  };

  const handleScrollToTop = useCallback(() => {
    dialogRef.current
      ?.querySelector('.MuiDialog-scrollBody')
      ?.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <DepositVariantContext.Provider value={{ variant, wrapperType }}>
      <CombinedQueryState
        queries={[accountQuery, depositContextQuery, togglesQuery]}
      >
        {canDeposit ? (
          <DepositWizard
            wrapperType={wrapperType!}
            cashBalance={availableBalance ?? 0}
            accountId={accountId!}
            minAmount={minAmount || validRange?.minimumPaymentAmount || 0}
            maxAmount={validRange?.maximumPaymentAmount ?? 0}
            transferDetails={transferDetails!}
            onCreateDeposit={handleCreateDeposit}
            onDepositAction={handleDepositAction}
            onClose={handleClose}
            paymentMethods={paymentMethods ?? []}
            onPopupClosed={handlePopupClosed}
            cashPayment={cashPaymentQuery.data?.cashPaymentById}
            onContactSupport={handleContactSupport}
            onBrowseFunds={handleBrowseFunds}
            onDashboard={handleDashboard}
            redirectUri={redirectUri}
            onScrollToTop={handleScrollToTop}
            timedOut={hasTimedOut}
            variant={variant}
            isPolling={isPolling}
            urlPaymentId={urlPaymentId}
            onSkipStep={onSkipStep}
            defaultValue={defaultValue}
            displayBuffer={displayBuffer}
            proceedOnComplete={proceedOnComplete}
            isCheckout={isCheckout}
          />
        ) : (
          <DepositsDisabled reason={statusReason!} onClose={handleClose} />
        )}
      </CombinedQueryState>
    </DepositVariantContext.Provider>
  );
}
