import { QueryState } from 'components/QueryState';
import { Seo } from 'components/Seo/Seo';
import { TargetDateBanner } from 'components/feature/FundDetails/TargetDateBanner';
import { TargetDateForm } from 'components/feature/FundDetails/TargetDateForm';
import { TargetDateFormValues } from 'components/feature/FundDetails/TargetDateForm/TargetDateForm';
import { getTargetDateInstrument } from 'components/feature/FundDetails/helpers/getTargetDateInstrument';
import { useTargetDateInfo } from 'components/feature/FundDetails/hooks/useTargetDateInfo';
import { useRecentlyViewedAssets } from 'components/feature/FundsList/hooks/useRecentlyViewedAssets';
import { useFundsBasket } from 'components/feature/PortfolioBuilder/hooks/useFundsBasket';
import { useMode } from 'components/feature/mode/useMode';
import { GaEventNames } from 'constants/gaConstants';
import { useAuth } from 'context/AuthContext';
import {
  AccountStatus,
  Asset,
  useAccountsQuery,
  useAssetQuery,
} from 'generated/graphql';
import { useGetDefaultSelectedInstrumentIsin } from 'helpers/assetHelpers';
import { trackGa } from 'helpers/track';
import { useFreeview } from 'hooks/useFreeview';
import { useUpdateRootCSSVars } from 'hooks/useUpdateRootCSSVars';
import { useUserHolds } from 'hooks/useUserHolds';
import { isEmpty } from 'lodash';
import once from 'lodash/once';
import { isTypeSource } from 'pages/FundDetails/fundDetailsTypes';
import { NotFound } from 'pages/NotFound/NotFound';
import {
  fundDetailsBreakdownPath,
  fundDetailsInformationPath,
  fundDetailsMeetManagersPath,
  fundDetailsPath,
  fundDetailsPerformancePath,
  generateFundDetailsPath,
  generateFundDetailsSunPath,
  generateFundDetailsWithReturnPath,
} from 'paths';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Route, useHistory, useParams } from 'react-router-dom';
import { PartialDeep } from 'type-fest';
import {
  AccountsQueryAccount,
  AssetQueryAsset,
  AssetQueryAssetInstrument,
} from 'types/graphqlTypes';
import { FundNav } from './FundNav';
import { Header } from './Header';
import { ActionsSlice } from './Slices/ActionsSlice';
import { DarkHeader } from './Slices/DarkHeader/DarkHeader';
import { DarkUniverseExplainerSlice } from './Slices/DarkUniverseExplainerSlice';
import { DisclosureSlice } from './Slices/DisclosureSlice';
import { EmployerExclusiveExplainerSlice } from './Slices/EmployerExclusiveExplainerSlice';
import { MeetManagerSlice } from './Slices/MeetManagerSlice';
import { PerformanceSlice } from './Slices/PerformanceSlice';
import RecentlyViewedFundsSlice from './Slices/RecentlyViewedFundsSlice';
import { SustainabilitySlice } from './Slices/SustainabilitySlice/SustainabilitySlice';
import { TeamSlice } from './Slices/TeamSlice';
import { TillitViewSlice } from './Slices/TillitViewSlice';
import {
  ContentContainer,
  PageContainer,
  PageContainerInner,
  TargetDateFormInner,
  TargetDateFormWrapper,
} from './Styles/FundDetails.style';
import { BreakdownTab } from './Tabs/BreakdownTab';
import { InformationTab } from './Tabs/InformationTab';
import { NoDataTab } from './Tabs/NoDataTab';

function useHasAccountWithActiveStatus() {
  const { signedIn } = useAuth();
  const accountsQuery = useAccountsQuery(undefined, { enabled: signedIn });

  const hasActiveAccount =
    signedIn &&
    accountsQuery.data?.accounts?.some(
      (account) => account.status === AccountStatus.Active
    );
  return {
    accountsQuery,
    hasActiveAccount,
  };
}

interface FundDetailsInnerProps {
  assetQuery: ReturnType<typeof useAssetQuery>;
  id: string;
  slug: string;
  route: string;
  asset: AssetQueryAsset;
  accounts: AccountsQueryAccount[];
  selectedAccount?: AccountsQueryAccount;
  search: string;
}

function FundDetailsInner({
  assetQuery,
  accounts,
  selectedAccount,
  id,
  slug,
  route,
  asset,
  search,
}: FundDetailsInnerProps) {
  const { basketBuyOrders } = useFundsBasket({
    selectedAccountId: selectedAccount?.id ?? null,
  });
  const [mode] = useMode();
  const defaultSelectedIsin = useGetDefaultSelectedInstrumentIsin(
    asset,
    mode,
    undefined,
    accounts,
    search,
    basketBuyOrders
  );

  const [selectedIsin, setSelectedIsin] = useState(defaultSelectedIsin!);
  const { signedIn } = useAuth();
  const { hasActiveAccount } = useHasAccountWithActiveStatus();
  const { canFreeView } = useFreeview();

  const fundId = asset.id || null;
  const fundName = asset.name || '';
  const assetClassName = asset.assetClass?.name || '';
  const isActivelyManaged = asset.isActivelyManaged;

  const isTargetDateFund = asset.isTargetDateFund || false;
  const { targetDateInfo } = useTargetDateInfo();
  const shouldShowTargetDateForm =
    isTargetDateFund &&
    (isEmpty(targetDateInfo) ||
      !targetDateInfo?.yearOfBirth ||
      !targetDateInfo?.intendedRetirementAge);

  const canSeeTillitView =
    hasActiveAccount || canFreeView(id) || isTargetDateFund;

  const assetIsins = useMemo(
    () => asset?.instruments?.nodes?.map((i) => i.isin) ?? [],
    [asset?.instruments?.nodes]
  );

  const userHolds = useUserHolds(assetIsins);

  const isSustainable = useMemo(() => {
    return asset?.tags?.nodes.some(
      (t) => t.categoryCode === 'SUST' && t.code !== 'NOFOSSIL'
    );
  }, [asset?.tags?.nodes]);

  const handleInstrumentChange = useCallback(
    (isin: string) => {
      trackGa({
        event: GaEventNames.selectContent,
        content_type: 'share class',
        item_id: `${isin} - ${fundId} - ${fundName}`,
      });

      setSelectedIsin(isin);
    },
    [fundId, fundName]
  );

  const handleTargetDateForm = (data: TargetDateFormValues) => {
    const retirementYear = data.yearOfBirth + data.intendedRetirementAge;
    const selectedInstrument =
      asset && getTargetDateInstrument(asset, retirementYear);

    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'share class - Target date fund form',
      item_id: `${selectedInstrument?.instrument?.isin!} - ${
        selectedInstrument?.instrument?.asset?.name
      }`,
    });

    setSelectedIsin(selectedInstrument?.instrument?.isin!);
  };

  const instruments = asset.instruments && asset.instruments.nodes.length > 0;
  const currentInstrument = asset.instruments?.nodes?.find(
    (i) => i.isin === selectedIsin
  );

  const isTillitManaged = (instrument?: AssetQueryAssetInstrument) =>
    instrument?.holdings?.nodes?.some((h) => h.source === 'tillit') ?? false;

  const instrumentHasValuationData =
    currentInstrument?.valuationSummary?.lastUpdatedUtc ?? false;

  const instrumentHasBreakdownData =
    instrumentHasValuationData || isTillitManaged(currentInstrument);

  const informationDocumentShort =
    currentInstrument?.instrumentType === 'Fund' ? 'KIID' : 'KID';
  const informationDocumentLong =
    currentInstrument?.instrumentType === 'Fund'
      ? 'Key Investor Information Document'
      : 'Key Information Document';

  const rootStyling = [
    {
      property: '--main-logo-color',
      value: 'black',
    },
    {
      property: '--main-header-color',
      value: 'black',
    },
  ];
  useUpdateRootCSSVars(rootStyling);

  useEffect(() => {
    if (assetQuery.isFetched) {
      const locationHash = window.location.hash.slice(1);
      if (locationHash) {
        setTimeout(() => {
          const el = document.getElementById(locationHash);
          const elPosition = el?.offsetTop;
          if (elPosition) {
            window.scrollTo({
              top: elPosition - 100,
              behavior: 'smooth',
            });
          }
        }, 1500);
      }
    }
  }, [assetQuery.isFetched]);

  const canonicalHref = asset.slug
    ? route
      ? generateFundDetailsSunPath({
          id,
          slug: asset.slug,
          route,
        })
      : generateFundDetailsPath({
          id,
          slug: asset.slug,
        })
    : undefined;

  return (
    <>
      <>
        <Seo
          title={asset.name}
          description={asset.description!}
          canonicalHref={canonicalHref}
        />
        {shouldShowTargetDateForm && (
          <TargetDateFormWrapper>
            <TargetDateFormInner>
              <TargetDateForm onProceed={handleTargetDateForm} $canGoBack />
            </TargetDateFormInner>
          </TargetDateFormWrapper>
        )}
        {asset?.isDarkUniverse! ? (
          <DarkHeader
            title={asset.name}
            isin={selectedIsin}
            assetId={asset.id!.toString()}
            userHolds={userHolds}
            signedIn={signedIn}
            asset={asset}
            handleInstrumentChange={handleInstrumentChange}
          />
        ) : (
          <Header
            title={asset.name}
            description={asset.description!}
            asset={asset}
            assetClass={assetClassName}
            userHolds={userHolds}
            assetId={asset.id!.toString()}
            mainContent={asset?.main?.nodes}
            hasFullVideo={!!asset?.meetManagerVideo}
            hideActions={shouldShowTargetDateForm}
            isin={selectedIsin}
            handleInstrumentChange={handleInstrumentChange}
          />
        )}
        {!shouldShowTargetDateForm && (
          <>
            <FundNav
              slug={slug}
              id={id}
              isActivelyManaged={isActivelyManaged || false}
              isDarkUniverse={asset?.isDarkUniverse!}
            />

            {isTargetDateFund && asset && (
              <TargetDateBanner
                assetData={asset}
                onProceed={handleTargetDateForm}
              />
            )}

            <ContentContainer maxWidth={false}>
              <PageContainerInner
                maxWidth="lg"
                className={isSustainable ? 'sustainable' : ''}
              >
                <Route path={fundDetailsPath} exact>
                  {asset?.isCustomUniverse && (
                    <EmployerExclusiveExplainerSlice />
                  )}
                  {asset?.isDarkUniverse && !asset?.isCustomUniverse && (
                    <DarkUniverseExplainerSlice assetData={asset} />
                  )}
                  {!asset?.isDarkUniverse && !asset?.isCustomUniverse && (
                    <>
                      <TillitViewSlice
                        asset={asset}
                        canSeeTillitView={isTargetDateFund || canSeeTillitView}
                      />
                      {isSustainable && (
                        <SustainabilitySlice
                          asset={asset}
                          canSeeTillitView={
                            isTargetDateFund || canSeeTillitView
                          }
                        />
                      )}
                    </>
                  )}
                </Route>

                {!asset?.isDarkUniverse! && (
                  <Route path={fundDetailsMeetManagersPath}>
                    <TeamSlice asset={asset} />
                    {isActivelyManaged && (
                      <MeetManagerSlice
                        asset={asset}
                        hasActiveAccount={!!hasActiveAccount}
                        signedIn={signedIn}
                      />
                    )}
                  </Route>
                )}

                <Route path={fundDetailsPerformancePath}>
                  {instrumentHasValuationData && currentInstrument ? (
                    <PerformanceSlice
                      asset={asset}
                      selectedIsin={selectedIsin}
                      handleInstrumentChange={handleInstrumentChange}
                    />
                  ) : (
                    <NoDataTab title="Performance" />
                  )}
                </Route>

                <Route path={fundDetailsBreakdownPath}>
                  {instrumentHasBreakdownData && currentInstrument ? (
                    <>
                      <BreakdownTab
                        asset={asset}
                        assetClassName={assetClassName}
                        selectedIsin={selectedIsin}
                        handleInstrumentChange={handleInstrumentChange}
                      />
                    </>
                  ) : (
                    <NoDataTab title="Breakdown" />
                  )}
                </Route>

                <Route path={fundDetailsInformationPath}>
                  {instruments && currentInstrument ? (
                    <InformationTab
                      asset={asset}
                      selectedIsin={selectedIsin}
                      informationDocumentLong={informationDocumentLong}
                      informationDocumentShort={informationDocumentShort}
                      handleInstrumentChange={handleInstrumentChange}
                    />
                  ) : (
                    <NoDataTab title="Key information" />
                  )}
                </Route>

                {instruments && (
                  <ActionsSlice
                    asset={asset}
                    selectedIsin={selectedIsin}
                    handleInstrumentChange={handleInstrumentChange}
                    userHolds={userHolds}
                  />
                )}
              </PageContainerInner>
            </ContentContainer>

            <RecentlyViewedFundsSlice asset={asset} />

            {instruments && (
              <DisclosureSlice asset={asset} selectedIsin={selectedIsin} />
            )}
          </>
        )}
      </>
    </>
  );
}

export function FundDetails() {
  const [mode] = useMode();

  const { signedIn } = useAuth();
  const urlParams = new URLSearchParams(window.location.search);
  const tags = urlParams.get('tags') || '';
  const search = urlParams.get('search') || '';
  const source = urlParams.get('source') || '';

  const { id, slug, route } = useParams<{
    id: string;
    slug: string;
    route: string;
  }>();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  const history = useHistory();

  // cspell:ignore fundDetailsview
  const trackView = useMemo(
    () =>
      once((asset: PartialDeep<Asset>) => {
        const assetTags = asset.tags
          ?.nodes!.filter((tag) => tag!.display)
          .map((tag) => tag!.name);

        trackGa({
          event: GaEventNames.viewItem,
          ecommerce: {
            items: [
              {
                item_id: asset.id,
                item_name: asset.name,
                item_type: asset.assetClass?.name,
                affiliation: 'Tillit',
                item_brand: asset.assetManager?.name,
                item_category: assetTags![0],
                item_category2: assetTags![1],
                item_category3: assetTags![2],
                item_category4: assetTags![3],
                item_category5: assetTags![4],
                // item_list_name: 'Tillit Funds', // Name of the list where the results are displayed
                // index: 0, // Position in the list starting at zero. Must be an integer
              },
            ],
          },
        });
      }),
    []
  );

  const { updateRecentlyViewed } = useRecentlyViewedAssets();

  const accountsQuery = useAccountsQuery(undefined, {
    enabled: signedIn,
  });

  const selectedAccount =
    mode?.mode === 'buy' || mode?.mode === 'autoSaveInvest'
      ? accountsQuery.data?.accounts?.find(
          ({ wrapperType }) => wrapperType === mode.wrapperType
        )
      : undefined;

  const assetQuery = useAssetQuery(
    { id: parseInt(id!) },
    {
      onSuccess: (data) => {
        if (!data.asset?.isDarkUniverse) {
          updateRecentlyViewed(id);
        }

        trackView(data.asset!);

        const fundSlug = data.asset?.slug || '';
        // if the slug in the url does not match the one returned in the query redirect to the correct one
        if (slug !== fundSlug) {
          const decoded = decodeURIComponent(slug);
          const [decodedSlug, decodedHash] = decoded.split('?');

          if (
            decodedSlug !== fundSlug ||
            decodedHash !== window.location.hash.slice(1)
          ) {
            const updatedPath = generateFundDetailsWithReturnPath(
              {
                id,
                slug: fundSlug,
              },
              {
                source: isTypeSource(source) ? source : undefined,
                searchTerm: search,
                searchTags: tags,
              }
            );
            history.replace(updatedPath);
          }
        }
      },
    }
  );

  return (
    <PageContainer>
      <QueryState {...accountsQuery} dataTestid="accountsQuery">
        {() => (
          <QueryState {...assetQuery} dataTestid="assetQuery">
            {({ data }) =>
              !data?.asset ? (
                <NotFound />
              ) : (
                <FundDetailsInner
                  key={id}
                  assetQuery={assetQuery}
                  asset={assetQuery.data!.asset!}
                  accounts={accountsQuery.data?.accounts ?? []}
                  id={id}
                  slug={slug}
                  route={route}
                  search={search}
                  selectedAccount={selectedAccount}
                />
              )
            }
          </QueryState>
        )}
      </QueryState>
    </PageContainer>
  );
}
