import imgBkCurated from 'assets/backgrounds/Tillit_Curated_largeSquare.jpg';
import imgBkEmpower from 'assets/backgrounds/Tillit_Empower_largeSquare.jpg';
import imgBkOpen from 'assets/backgrounds/Tillit_Open_largeSquare.jpg';
import imgBkSimple from 'assets/backgrounds/Tillit_Simple_largeSquare.jpg';
import imgBkTransparent from 'assets/backgrounds/Tillit_Transparent_largeSquare.jpg';
import imgBkTrust from 'assets/backgrounds/Tillit_Trust_largeSquare.jpg';
import { LinkCustomButton } from 'components/design-system/Button/CustomButtonV2';
import { H1, H5 } from 'components/design-system/Heading/Heading';
import { StyledLink } from 'components/design-system/Link';
import { TextNormal } from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import { GaEventNames } from 'constants/gaConstants';
import { trackGa } from 'helpers/track';
import {
  fundListPath,
  getSustainableInvestingTypeFormLinkPath,
  insightArticleMultiAssetFundsPath,
  insightIncomeInvestingPath,
  insightSurgingInflationExploringPath,
} from 'paths';
import { ReactNode, useState } from 'react';
import styled from 'styled-components';

interface GoalDefinition {
  tags: string;
  label: string;
  description: ReactNode;
}

const goalList: GoalDefinition[] = [
  {
    tags: 'INVFORDECADES',
    label: 'Investing for decades',
    description: (
      <div>
        <TextNormal>
          Investing is about growing your money over the long-term. There are
          some investment opportunities that are{' '}
          <strong>
            particularly well-suited to a long time horizon due to how, where or
            in what they invest
          </strong>
          .
        </TextNormal>
        <TextNormal>
          Over the long-term these funds have the{' '}
          <strong>
            potential to deliver high returns, but in the short-term they can
            experience a lot of volatility
          </strong>
          . Therefore, this collection of funds is for{' '}
          <strong>investors with particularly long time horizons</strong>.
        </TextNormal>
        <TextNormal>
          These funds invest in things that can take{' '}
          <strong>decades to deliver on their full potential</strong>,
          including:
        </TextNormal>
        <ul>
          <TextNormal as="li" $noMargin>
            <strong>Private or early-stage companies</strong>
          </TextNormal>
          <TextNormal as="li" $noMargin>
            <strong>Niche or developing countries;</strong>
          </TextNormal>
          <TextNormal as="li" $noMargin>
            <strong>Rare or unusual investment styles;</strong>
          </TextNormal>
          <TextNormal as="li" $noMargin>
            <strong>High-risk or innovative industries.</strong>
          </TextNormal>
        </ul>
        <TextNormal>
          If you are a long-term investor, take a look at our{' '}
          <strong>
            selection of funds that aim to deliver growth for decades to come
          </strong>
          .
        </TextNormal>
      </div>
    ),
  },
  {
    tags: 'SHIELDPROTECT',
    label: 'Shield and protect',
    description: (
      <div>
        <TextNormal>
          Sometimes, growing your money isn’t the primary, or at least the only,
          investment goal. For some (or in some circumstances), it’s{' '}
          <strong>
            more important to preserve the value of your money and shield it
            from things like inflation, etc
          </strong>
          .
        </TextNormal>
        <TextNormal>
          There are different ways to try to achieve this goal, including:
        </TextNormal>
        <ul>
          <TextNormal as="li" $noMargin>
            Multi-asset funds which combine different asset classes to deliver a
            more <strong>balanced outcome</strong>;
          </TextNormal>
          <TextNormal as="li" $noMargin>
            Bond funds that particularly focus on{' '}
            <strong>downside protection</strong>;
          </TextNormal>
          <TextNormal as="li" $noMargin>
            Alternative assets such as gold or infrastructure which aims to{' '}
            <strong>keep up with inflation over time</strong>;
          </TextNormal>
          <TextNormal as="li">
            Or some equity funds that specifically invest in more predictable,{' '}
            <strong>steady-eddy companies</strong>.
          </TextNormal>
        </ul>
        <TextNormal>
          You can read more about how to protect your money against inflation{' '}
          <StyledLink
            href={insightSurgingInflationExploringPath}
            target="_blank"
          >
            here
          </StyledLink>
          , or take a look at{' '}
          <strong>
            our selection of funds that can help you shield and protect your
            money.
          </strong>
        </TextNormal>
      </div>
    ),
  },
  {
    tags: 'RETURNSLEDBYVAL',
    label: 'Returns led by values',
    description: (
      <div>
        <TextNormal>
          Investing doesn’t have to be about only maximising financial returns,
          it can also be{' '}
          <strong>
            a tool to drive change in society in line with your values.
          </strong>{' '}
          Or perhaps you’re keen to invest in{' '}
          <strong>funds run by women.</strong> Regardless of what matters to
          you,{' '}
          <strong>
            if you want to invest in line with your values, this investment goal
            is for you.
          </strong>
        </TextNormal>
        <TextNormal>
          Sustainable investing is an evolving style of investing with a wide
          variety of approaches (and even more labels), including:
        </TextNormal>
        <ul>
          <TextNormal as="li" $noMargin>
            <strong>Excluding certain industries</strong> or products;
          </TextNormal>
          <TextNormal as="li" $noMargin>
            <strong>Investing in ‘best-practice’</strong> companies or assets in
            traditional sectors;
          </TextNormal>
          <TextNormal as="li" $noMargin>
            Investing in{' '}
            <strong>
              products, services or ways of operating that have a positive
              impact on society
            </strong>
            ;
          </TextNormal>
          <TextNormal as="li">
            Or even{' '}
            <strong>
              sacrificing financial returns in place of having a meaningful
              positive impact on the world.
            </strong>
          </TextNormal>
        </ul>
        <TextNormal>
          To learn more about sustainable investing, get our dedicated guide{' '}
          <StyledLink
            href={getSustainableInvestingTypeFormLinkPath}
            target="_blank"
          >
            here
          </StyledLink>{' '}
          or take a look at{' '}
          <strong>
            our selection of funds that aim to generate returns led by values.
          </strong>
        </TextNormal>
      </div>
    ),
  },

  {
    tags: 'READYMADE',
    label: 'Ready-made',
    description: (
      <div>
        <TextNormal>
          Do you want to pick your own funds, but{' '}
          <strong>
            don’t want to make your own decisions on asset allocation, or
            perhaps you just want broad access to ‘the stock market’, then a
            ready-made solution could be for you.
          </strong>
        </TextNormal>
        <TextNormal>
          Whether you are new to investing or have been investing for decades,
          ‘ready-made’ solutions can be great{' '}
          <strong>
            building blocks in any portfolio, and sometimes make up the entire
            portfolio
          </strong>
          . These solutions include:
        </TextNormal>
        <ul>
          <TextNormal as="li" $noMargin>
            <strong>Multi-asset funds:</strong> these funds are a bit like the{' '}
            <strong>investment equivalent of the Eatwell Guide</strong>. They
            invest in multiple asset classes, creating balanced solutions, each
            with a slightly different focus to cater for different investors.
          </TextNormal>
          <TextNormal as="li">
            <strong>Passive market trackers:</strong> these funds track large,
            mainstream stock markets such as the{' '}
            <strong>FTSE in the UK and S&P 500 in the US</strong>.
          </TextNormal>
        </ul>
        <TextNormal>
          You can read more about multi-asset funds{' '}
          <StyledLink href={insightArticleMultiAssetFundsPath} target="_blank">
            here
          </StyledLink>{' '}
          or take a look at{' '}
          <strong>
            our selection of different ways to invest ‘off-the-shelf’.
          </strong>
        </TextNormal>
      </div>
    ),
  },
  {
    tags: 'INCBOOSTERS',
    label: 'Income boosters',
    description: (
      <div>
        <TextNormal>
          <strong>
            If you want a regular income from your investments, then this goal
            is for you.
          </strong>{' '}
          Income investing allows you to get{' '}
          <strong>
            some of your investment returns paid out regularly as you go
          </strong>
          , rather than reinvest them. This can be particularly helpful if you
          want to <strong>supplement your main source of income</strong>.
        </TextNormal>
        <TextNormal>
          Most people think of bonds when they think of income investing, but
          you can actually achieve this investment goal in a number of other
          ways, including:
        </TextNormal>
        <ul>
          <TextNormal as="li" $noMargin>
            <strong>Bond</strong> funds typically generate income from{' '}
            <strong>interest payments</strong>;
          </TextNormal>
          <TextNormal as="li" $noMargin>
            <strong>Equity</strong> funds generate income from investing in
            companies that pay regular <strong>dividends</strong>;
          </TextNormal>
          <TextNormal as="li" $noMargin>
            <strong>Property</strong> funds, typically generate income from the{' '}
            <strong>rental payments</strong> of the underlying property
            investments;
          </TextNormal>
          <TextNormal as="li" $noMargin>
            <strong>Alternative asset</strong> funds generate income from a
            range of different underlying investments.
          </TextNormal>
        </ul>
        <TextNormal>
          You can read more about income investing{' '}
          <StyledLink href={insightIncomeInvestingPath} target="_blank">
            here
          </StyledLink>{' '}
          or take a look at{' '}
          <strong>
            our selection of funds that can help boost your income.
          </strong>
        </TextNormal>
      </div>
    ),
  },
  {
    tags: 'DIFFERENT',
    label: 'Something different',
    description: (
      <div>
        <TextNormal>
          <strong>
            Are you looking for something a bit unusual, niche or off the beaten
            track?
          </strong>{' '}
          One of the benefits of picking your own funds is that you can{' '}
          <strong>
            invest in things that reflect your personal interests, preferences,
            or even hopes for the future
          </strong>
          . So if you are after something a bit different to add flavour to your
          portfolio, this is the right place.
        </TextNormal>

        <TextNormal>
          In this collection of funds, you will typically find things that carry
          a bit more (or at least different){' '}
          <strong>risk compared to more mainstream funds</strong>. Therefore, it
          is prudent to consider these funds in the context of a broader
          portfolio. Examples include:
        </TextNormal>
        <ul>
          <TextNormal as="li" $noMargin>
            Very <strong>concentrated</strong> funds;
          </TextNormal>
          <TextNormal as="li" $noMargin>
            <strong>Niche industry or country</strong> focus;
          </TextNormal>
          <TextNormal as="li" $noMargin>
            <strong>Specialist</strong> investment styles;
          </TextNormal>
          <TextNormal as="li">
            Or, a <strong>unique approach</strong>/firm structure.
          </TextNormal>
        </ul>
        <TextNormal>
          If this piques your curiosity, take a look at{' '}
          <strong>our selection of funds that stand out from the crowd.</strong>
        </TextNormal>
      </div>
    ),
  },
];

const FlipCardLayout = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
  justify-content: center;
  margin-top: 1.5rem;
`;

interface FlipCardProps {
  $flip: boolean;
}

const backgroundImages = [
  imgBkOpen,
  imgBkSimple,
  imgBkTransparent,
  imgBkTrust,
  imgBkCurated,
  imgBkEmpower,
];

interface FlipCardFrontProps {
  $index: number;
}

const FlipCardFront = styled.div<FlipCardFrontProps>`
  box-sizing: border-box;
  position: absolute;
  height: 100%;
  width: 100%;
  background: ${colors['grey-100']};
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  text-align: center;
  transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out;
  overflow: hidden;

  h1 {
    z-index: 1;
    color: #fff;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    ${({ $index }) =>
      `background-image: url(${
        backgroundImages[$index % backgroundImages.length]
      });`}
    background-size: cover;
    background-position: center;
    filter: brightness(0.65);
    transition: transform 0.3s ease-in-out, filter 0.3s ease-in-out;
  }
`;

const FlipCardBack = styled.div<FlipCardFrontProps>`
  box-sizing: border-box;
  position: absolute;
  height: 100%;
  width: 100%;
  backface-visibility: hidden;
  background: ${colors.white};
  ${({ $index }) =>
    `background-image: url(${
      backgroundImages[$index % backgroundImages.length]
    });`}
  background-size: 150% 150%;
  background-position: center;
  padding: 1rem;
  transform: rotateY(180deg);
  overflow: hidden;
`;

const FlipCardContentWrapper = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  gap: 0.5rem;
  justify-items: start;
  overflow: hidden;
  background: ${colors.white};
  padding: 1rem;
`;

const FlipCardGoalDescription = styled.div`
  position: relative;
  overflow: hidden;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 30px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.5) 30%,
      rgba(255, 255, 255, 1) 100%
    );
    pointer-events: none;
    z-index: 1;
    width: calc(100% - 10px);
    ${({ theme }) => theme.breakpoints.up('md')} {
      width: calc(100% - 15px);
    }
  }
  > div {
    position: relative;
    height: 100%;
    overflow-y: auto;
    z-index: 1;
    padding-right: 5px;
    ${({ theme }) => theme.breakpoints.up('md')} {
      padding-right: 0;
    }
  }
`;

const FlipCard = styled.div`
  height: 420px;
  width: 100%;
  max-width: 420px;

  position: relative;
  perspective: 2000px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    perspective: 1000px;
  }
`;

const FlipCardContent = styled.div<FlipCardProps>`
  transition: transform 0.6s;
  transform-style: preserve-3d;
  height: 100%;
  ${({ $flip }) => ($flip ? 'transform: rotateY(180deg);' : '')}
  cursor: pointer;

  ${({ $flip }) =>
    $flip
      ? `
        h1 {
          visibility: hidden;
        }
      `
      : ''}

  ${({ theme }) => theme.breakpoints.up('md')} {
    &:hover ${FlipCardFront}::before {
      transform: scale(1.5);
      filter: brightness(0.8);
    }
    h1 {
      transition: transform 0.3s ease-in-out, 0.3s color ease-in-out;
    }
    &:hover h1 {
      transform: scale(1.1);
    }
    ${({ $flip }) =>
      $flip
        ? `
        h1 {
          transform: scale(1.1);
          visibility: hidden;
        }
        ${FlipCardFront}::before {
          transform: scale(1.5);
          filter: brightness(0.75);
        }
      `
        : ''}
  }
`;

const Goal = ({ goal, index }: { goal: GoalDefinition; index: number }) => {
  const [flip, setFlip] = useState(false);

  const path = `${fundListPath}?tags=${goal.tags}`;
  return (
    <FlipCard
      onClick={(ev) => {
        const target = ev.target as HTMLElement;
        if (target.nodeName !== 'A') {
          ev.stopPropagation();
          setFlip(!flip);
        }
      }}
    >
      <FlipCardContent $flip={flip}>
        <FlipCardFront $index={index}>
          <H1>{goal.label}</H1>
        </FlipCardFront>
        <FlipCardBack $index={index}>
          <FlipCardContentWrapper>
            <H5>{goal.label}</H5>
            <FlipCardGoalDescription>
              {goal.description}
            </FlipCardGoalDescription>
            <LinkCustomButton
              to={path}
              $size="small"
              style={{ marginLeft: 'auto' }}
              onClick={() => {
                trackGa({
                  event: GaEventNames.selectContent,
                  content_type: 'landing - goals',
                  item_id: `view funds button clicked - ${goal.label}`,
                });
              }}
            >
              View funds
            </LinkCustomButton>
          </FlipCardContentWrapper>
        </FlipCardBack>
      </FlipCardContent>
    </FlipCard>
  );
};

export const GoalLandingMenu = () => {
  return (
    <FlipCardLayout>
      {goalList.map((goal, index) => (
        <Goal goal={goal} index={index} key={goal.tags} />
      ))}
    </FlipCardLayout>
  );
};
