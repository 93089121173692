import styled from 'styled-components';

interface WrapperProps {
  isTallForm: boolean | undefined;
}

export const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  position: relative;
  padding: ${(p) => (p.isTallForm ? '6rem 0 4rem 0' : '6rem 0')};
`;
