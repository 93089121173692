import { Typography } from '@material-ui/core';
import { colors } from 'constants/colors';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import styled from 'styled-components';
import {
  StepActions,
  StepFormContainer,
} from '../../../../../design-system/StepComponents/StepComponents';

export const SearchResultsStepFormContainer = styled(StepFormContainer)`
  gap: 0;
`;

export const SearchResultsStepAction = styled(StepActions)`
  margin-top: 0;
`;

interface SearchResultsContainerProps {
  $active: boolean;
}

export const SearchResultsContainer = styled.div<SearchResultsContainerProps>`
  overflow: hidden;
  ${({ $active }) => ($active ? 'height: 17.5rem;' : 'height: 0px;')}
  transition: height 0.35s;
  margin-right: -2rem;
`;

export const SearchResults = styled.div`
  display: flex;
  flex-direction: column;
  height: 15rem;
  overflow-y: scroll;
  padding-right: 1.5rem;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${colors.lightGrey};
    border-radius: 0.25rem;
  }
  ::-webkit-scrollbar-track {
    background: ${colors.vapor};
    border-radius: 0.25rem;
  }
`;

export const SearchResultsTitle = styled(Typography)`
  font-weight: 500;
  margin: 0.5rem 0;
`;

export const SearchResult = styled.button`
  text-align: left;
  border: none;
  background: white;
  padding: 1rem;
  cursor: pointer;
  border-bottom: solid 1px ${colors.lightGrey};
  &:first-child {
    border-top: solid 1px ${colors.lightGrey};
  }
  &:hover {
    background-color: ${colors.vapor};
  }
`;

const SearchContainerResultEl = styled(SearchResult)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type SearchContainerResultProps = Parameters<typeof SearchContainerResultEl>;
export const SearchContainerResult = ({
  children,
  ...props
}: SearchContainerResultProps[0]) => {
  return (
    <SearchContainerResultEl {...props}>
      {children}
      <HiChevronRight />
    </SearchContainerResultEl>
  );
};

const BackToSearchResultsEl = styled(SearchResult)`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

export const BackToSearchResults = ({
  children,
  ...props
}: SearchContainerResultProps[0]) => {
  return (
    <BackToSearchResultsEl {...props}>
      <HiChevronLeft />
      {children}
    </BackToSearchResultsEl>
  );
};

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 0.5rem;
  width: 100%;
`;
