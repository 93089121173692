import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import {
  GoBackButton,
  StepActions,
  StepButton,
  StepContainer,
  StepFormContainer,
  StepIntroductionTypography,
  StepText,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import { TextNormal } from 'components/design-system/Text/Text';
import { QueryState } from 'components/QueryState';
import { useUserProfileQuery, WrapperType } from 'generated/graphql';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  AffirmationContainer,
  ChangeDetailsButton,
  ConfirmDetailsStepContent,
  ErrorMessage,
  StyledReadOnlyInput,
} from './ConfirmDetails.styles';
import { useIntercom } from 'react-use-intercom';
import { getNameForWrapperType } from 'helpers/accountHelpers';
import { CedingProvider } from '../../types';

const ConfirmDetailsSchema = yup.object().shape({
  confirmDetailsDisclaimer: yup
    .boolean()
    .oneOf([true], 'Please check the confirmation checkbox')
    .required('Please check the confirmation checkbox'),
});

interface ConfirmDetailsFormValues {
  confirmDetailsDisclaimer: boolean;
}

interface ConfirmDetailsStepProps {
  wrapperType: WrapperType;
  cedingProvider: CedingProvider;
  onProceed: () => void;
  onGoBack?: () => void;
}

export function ConfirmDetailsStep({
  wrapperType,
  cedingProvider,
  onProceed,
  onGoBack,
}: ConfirmDetailsStepProps) {
  const { showNewMessages } = useIntercom();
  const userProfileQuery = useUserProfileQuery();

  const methods = useForm<ConfirmDetailsFormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(ConfirmDetailsSchema),
    context: { wrapperType },
    defaultValues: {
      confirmDetailsDisclaimer: false,
    },
  });

  const { register, handleSubmit, errors } = methods;
  const onSubmit = async (data: ConfirmDetailsFormValues) => {
    if (data.confirmDetailsDisclaimer) {
      onProceed();
    }
  };

  const handleChangeName = () => {
    showNewMessages(
      `I would like to update my name before proceeding with my ${getNameForWrapperType(
        wrapperType
      )} transfer from ${cedingProvider.name}.`
    );
  };

  const handleChangeAddress = () => {
    showNewMessages(
      `I would like to update my address before proceeding with my ${getNameForWrapperType(
        wrapperType
      )} transfer from ${cedingProvider.name}.`
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <StepContainer>
        <ConfirmDetailsStepContent>
          <StepTitle>Confirm your details</StepTitle>
          <StepText>
            <StepIntroductionTypography>
              To help your transfer go smoothly, please review your personal
              details below. If anything is missing (such as your middle name)
              please click on the links to get in touch with us. It is important
              that the details we have on file for you match those at your
              current provider to avoid your transfer being rejected.
            </StepIntroductionTypography>
          </StepText>
        </ConfirmDetailsStepContent>
        <StepFormContainer>
          <QueryState {...userProfileQuery}>
            {({ data }) => {
              const userData = data?.userProfile;
              const currentAddress = userData?.addressHistory?.[0];

              return (
                <>
                  <StyledReadOnlyInput
                    label="Full name"
                    value={
                      userData?.middle
                        ? `${userData?.first} ${userData?.middle} ${userData?.last}`
                        : `${userData?.first} ${userData?.last}`
                    }
                  />
                  <ChangeDetailsButton onClick={handleChangeName} type="button">
                    This isn't my full name
                  </ChangeDetailsButton>
                  <StyledReadOnlyInput
                    label="National Insurance Number"
                    value={`${
                      userData?.nationalInsuranceNumberTokenised?.maskedValue ||
                      'Not provided'
                    }`}
                  />
                  <StyledReadOnlyInput
                    label="Current address"
                    value={`${currentAddress?.buildingName || ''} ${
                      currentAddress?.line1OrStreet
                    }, ${currentAddress?.townOrCity} ${
                      currentAddress?.postCode
                    }`}
                  />
                  <ChangeDetailsButton
                    onClick={handleChangeAddress}
                    type="button"
                  >
                    I have moved
                  </ChangeDetailsButton>

                  <AffirmationContainer>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="confirmDetailsDisclaimer"
                          name="confirmDetailsDisclaimer"
                          inputRef={register}
                        />
                      }
                      label={
                        <TextNormal $noMargin>
                          I confirm that the information above is correct and
                          match those at my current provider.
                        </TextNormal>
                      }
                    />
                    {errors.confirmDetailsDisclaimer && (
                      <ErrorMessage>
                        {errors.confirmDetailsDisclaimer.message}
                      </ErrorMessage>
                    )}
                  </AffirmationContainer>
                </>
              );
            }}
          </QueryState>
        </StepFormContainer>
        <StepActions>
          <StepButton className="magenta" type="submit">
            Continue
          </StepButton>
          <GoBackButton onClick={onGoBack} />
        </StepActions>
      </StepContainer>
    </form>
  );
}
