import {
  TableBody,
  TableCell,
  TableCellProps,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { InfoPopover } from 'components/Popover';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { MobileListContainer, StyledTable } from './ResponsiveTable.style';

export interface ColumnDefinition<T> {
  name: string;
  description?: string;
  key: string;
  align?: TableCellProps['align'];
  headAlign?: TableCellProps['align'];
  width?: TableCellProps['width'];
  renderValue?: (value: string | number, data: T) => React.ReactNode;
}

interface ResponsiveTableProps<T> {
  columns: Array<ColumnDefinition<T>>;
  data: Array<T>;
  noDataMessage: string;
  dark?: boolean;
  renderMobile: (item: T) => React.ReactElement;
}

export function ResponsiveTable<T>({
  columns,
  data,
  noDataMessage = 'No entries',
  dark = false,
  renderMobile,
}: ResponsiveTableProps<T>) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const rows: Array<T & { uniqueId: string }> = useMemo(
    () => data.map((d) => ({ ...d, uniqueId: _.uniqueId() })),
    [data]
  );

  if (!isMounted) {
    return null;
  }

  return isDesktop ? (
    <StyledTable $dark={dark}>
      <TableHead>
        <TableRow>
          {columns.map((col) => (
            <TableCell
              key={col.name}
              align={col.headAlign ?? col.align ?? 'right'}
              width={col.width ?? col.width ?? 'auto'}
            >
              {col.name}
              {col.description && (
                <>
                  {' '}
                  <InfoPopover
                    color="primary"
                    size="small"
                    content={col.description}
                  />
                </>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows &&
          rows.map((row) => (
            <TableRow key={row.uniqueId}>
              {columns.map((col) => (
                <TableCell key={col.name} align={col.align ?? 'right'}>
                  {col.renderValue?.(_.get(row, col.key), row) ??
                    _.get(row, col.key)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        {!rows.length && (
          <TableRow>
            <TableCell colSpan={columns.length} align="center">
              {noDataMessage}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </StyledTable>
  ) : (
    <MobileListContainer $dark={dark}>
      {rows &&
        rows.map((row) =>
          React.cloneElement(renderMobile(row), { key: row.uniqueId })
        )}
      {!rows.length && <Typography color="inherit">{noDataMessage}</Typography>}
    </MobileListContainer>
  );
}
