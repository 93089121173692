import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import { Text } from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import styled from 'styled-components';

export const Hr = styled.hr`
  background-color: ${colors.midGrey};
  height: 1px;
  border: 0;
  width: 100%;
  margin: 0 0 1rem 0;
`;

export const AffirmationsWrapper = styled.div`
  display: grid;
  gap: 0.75rem;
  padding: 0 0 2rem 0;
`;

export const StyledAccordion = styled(Accordion)`
  &:before {
    display: none;
  }
`;

export const AccordionWrapper = styled.div`
  display: grid;
  gap: 0.75rem;
  margin-bottom: 2rem;

  > .MuiAccordion-root {
    border: 1px solid ${colors.midGrey};
    border-radius: 10px;
    padding: 0 1rem;
  }
  .Mui-expanded {
    margin: 0;
  }
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  padding: 0;
  justify-content: space-between !important;
  .Mui-expanded {
    margin-right: -12px;
  }
  .MuiAccordionSummary-content {
    margin: 0;
    padding: 0.75rem 0;
  }
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 0;
  flex-direction: column;

  ul {
    padding-left: 20px;
  }
`;

export const StyledButton = styled(CustomButtonV2)`
  &.disabled {
    opacity: 0.6;
  }
`;

export const AlertContainer = styled.div`
  margin: 1rem 1rem 0;
  display: flex;
  justify-content: center;
  > div {
    background: rgba(229, 73, 56, 0.04);
    display: inline-flex;
  }
`;

export const RiskText = styled(Text)`
  margin-top: 2rem;
`;
