import { OtherDocuments } from 'components/OtherDocument';
import { QueryState } from 'components/QueryState';
import {
  ColumnDefinition,
  ResponsiveTable,
} from 'components/Tables/ResponsiveTable/ResponsiveTable';
import { GaEventNames } from 'constants/gaConstants';
import * as format from 'formatting';
import { Document, useClientDocumentsQuery } from 'generated/graphql';
import { trackGa } from 'helpers/track';
import React, { useCallback, useMemo } from 'react';
import { HiDownload } from 'react-icons/hi';
import { Download } from './OtherDocumentsTable.style';

interface PureOtherDocumentsTableProps {
  data: Array<Document>;
}

// ts-unused-exports:disable-next-line
export function PureOtherDocumentsTable({
  data,
}: PureOtherDocumentsTableProps) {
  const handleDownloadClick = (title: string) => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'dashboard - other documents table',
      item_id: `download button clicked - ${title}`,
    });
  };

  const columns = useMemo((): ColumnDefinition<Document>[] => {
    return [
      {
        name: '',
        key: 'url',
        width: '24px',
        renderValue: (value: string, document: Document) => (
          <Download
            {...{
              component: 'a',
              target: '_blank',
              href: value,
            }}
            onClick={() => handleDownloadClick(document.title)}
            size="small"
          >
            <HiDownload />
          </Download>
        ),
      },
      {
        name: 'Document type',
        key: 'title',
        align: 'left',
      },
      {
        name: 'Date',
        key: 'date',
        renderValue: (date: string) => (date ? format.date(date) : ''),
      },
    ];
  }, []);

  const mobileComponent = useCallback(
    (row: Document) => (
      <OtherDocuments url={row.url} title={row.title} date={row.date} />
    ),
    []
  );

  return (
    <ResponsiveTable
      columns={columns}
      data={data}
      noDataMessage="You have no documents."
      renderMobile={mobileComponent}
    />
  );
}

export function OtherDocumentsTable() {
  const documentsQuery = useClientDocumentsQuery();

  return (
    <QueryState {...documentsQuery}>
      {() => (
        <PureOtherDocumentsTable
          data={documentsQuery?.data?.documents?.documents!}
        />
      )}
    </QueryState>
  );
}
