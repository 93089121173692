import { tillitFetch } from 'api/tillitFetch';
import { ReactComponent as DrivingLicenceIcon } from 'assets/icons/onboarding/license.svg';
import { ReactComponent as PassportIcon } from 'assets/icons/onboarding/passport.svg';
import clsx from 'clsx';
import { IdSampleDialog } from 'components/Dialogs/IdSampleDialog';
import { Form } from 'components/Form/Form';
import { FormState } from 'components/Form/FormState';
import { UploadField } from 'components/Form/UploadFieldV2';

import { useMediaQuery } from '@material-ui/core';
import { InfoPopoverV2 } from 'components/design-system/InfoPopoverV2/InfoPopoverV2';
import { FontWeight, Text } from 'components/design-system/Text/Text';
import { StepProps } from 'components/feature/openAccount/steps/_shared/StepProps';
import { GaEventNames, OnboardingStepNames } from 'constants/gaConstants';
import { UserProfileQuery, useUserProfileQuery } from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { useModal } from 'mui-modal-provider';
import React, { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useTheme } from 'styled-components';
import { useEffectOnce } from 'usehooks-ts';
import {
  GoBackButton,
  StepActions,
  StepButton,
  StepContainer,
  StepContent,
  StepIntroduction,
  StepIntroductionTypography,
  StepIntroductionWidth,
  StepTitle,
} from '../../../../design-system/StepComponents/StepComponents';
import { ServerError } from '../_shared/ServerError';
import { DocumentPreviouslyUploaded } from './DocumentPreviouslyUploaded';
import {
  DocumentUploadFormValues,
  allowedFileTypesStr,
  documentUploadSchema,
} from './DocumentUploadConsts';
import {
  DocumentType,
  DocumentTypeContainer,
  NeedHelpWrapper,
  PopoverContent,
  StyledButton,
  SvgWrapper,
  UploadsContainer,
} from './DocumentUploadStep.styles';

enum IdType {
  passport = 'passport',
  drivingLicence = 'driversLicence',
  nationalIdCard = 'nationalIdCard',
}

enum UploadStep {
  upload = 'upload',
  previouslyUploaded = 'previouslyUploaded',
}

enum UploadFieldSide {
  frontSide = 'frontSide',
  backSide = 'backSide',
}
type UploadFieldDetails = {
  fileName: string;
  isImage: boolean;
  base64: string | undefined;
};

export function DocumentUploadStep({ onProceed, onGoBack }: StepProps) {
  const [documentType, setDocumentType] = useState<string | null>(null);
  const [hasFieldData, setHasFieldData] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [activeStep, setActiveStep] = useState<UploadStep | null>(null);
  const [
    frontSideFieldData,
    setFrontSideFieldData,
  ] = useState<UploadFieldDetails>();
  const [
    backSideFieldData,
    setBackSideFieldData,
  ] = useState<UploadFieldDetails>();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  const queryClient = useQueryClient();
  const userProfile = queryClient.getQueryData<UserProfileQuery>(
    useUserProfileQuery.getKey()
  )?.userProfile!;
  const documentsUploaded = !!userProfile?.identityDocuments?.length;
  const documentsUploadedType = userProfile?.identityDocuments?.[0]?.type;

  useEffectOnce(() => {
    if (userProfile?.identityDocuments?.length && documentsUploadedType) {
      setActiveStep(UploadStep.previouslyUploaded);
    } else {
      setActiveStep(UploadStep.upload);
    }
  });

  useEffect(() => {
    if (documentType === IdType.nationalIdCard) {
      setHasFieldData(!!frontSideFieldData && !!backSideFieldData);
    } else {
      setHasFieldData(!!frontSideFieldData);
    }
  }, [frontSideFieldData, backSideFieldData, documentType]);

  const validationSchema = documentUploadSchema;

  const onSubmit = async (data: DocumentUploadFormValues, isDirty: boolean) => {
    if (isDirty) {
      const formData = new FormData();
      formData.append('sides', 'front');
      formData.append('files', data.frontSide![0]);

      if (data.backSide) {
        formData.append('sides', 'back');
        formData.append('files', data.backSide![0]);
      }

      const response = await tillitFetch(
        `/profile/identitydocuments/${documentType}`,
        {
          method: 'PUT',
          body: formData,
        }
      );

      if (response!.status >= 300) {
        setUploadError(true);
        return;
      }
    }

    trackGa({
      event: GaEventNames.onboarding,
      onboardingStep: OnboardingStepNames.idSubmitted,
    });

    onProceed();
  };

  const handleUploadFieldUpdate = useCallback(
    (
      name: UploadFieldSide,
      fileName: string,
      isImage: boolean,
      base64: string | undefined,
      shouldClear?: boolean
    ) => {
      if (shouldClear) {
        clearFieldData(name);
      } else {
        if (name === UploadFieldSide.frontSide) {
          setFrontSideFieldData({ fileName, isImage, base64 });
        } else {
          setBackSideFieldData({ fileName, isImage, base64 });
        }
      }
    },
    []
  );

  const { showModal } = useModal();

  const handleExamplesLink = useCallback(
    (e: React.MouseEvent) => {
      trackGa({
        event: GaEventNames.selectContent,
        item_id: 'Onboarding documents upload',
        reason: 'Help uploading a document',
      });
      e.preventDefault();
      showModal(IdSampleDialog);
    },
    [showModal]
  );

  const popoverContent = (
    <>
      <PopoverContent>
        <Text $fontWeight={FontWeight.normal} $noMargin>
          When uploading your ID, make sure that the image meets the following
          conditions:
        </Text>
        <ul>
          <li>
            <Text $fontWeight={FontWeight.normal} $noMargin>
              The image is clear and in focus;
            </Text>
          </li>
          <li>
            <Text $fontWeight={FontWeight.normal} $noMargin>
              The image is well lit without glare;
            </Text>
          </li>
          <li>
            <Text $fontWeight={FontWeight.normal} $noMargin>
              If you are taking a photo, make sure your ID is square in the
              frame (not at an angle);
            </Text>
          </li>
          <li>
            <Text $fontWeight={FontWeight.normal} $noMargin>
              There are no fingers or items covering any part of the ID;
            </Text>
          </li>
          <li>
            <Text $fontWeight={FontWeight.normal} $noMargin>
              The file size is smaller than 4MB
            </Text>
          </li>
        </ul>
        <Text $fontWeight={FontWeight.normal} $noMargin>
          File formats accepted: png, jpeg, tiff, pdf.
        </Text>
      </PopoverContent>
      <StyledButton onClick={(e) => handleExamplesLink(e)}>
        Examples
      </StyledButton>
    </>
  );

  const clearFieldData = (side?: UploadFieldSide) => {
    if (side === undefined || side === UploadFieldSide.frontSide) {
      setFrontSideFieldData(undefined);
    }
    if (side === undefined || side === UploadFieldSide.backSide) {
      setBackSideFieldData(undefined);
    }
  };

  const handleUploadNew = () => {
    setDocumentType(null);
    setActiveStep(UploadStep.upload);
    clearFieldData();
  };

  const handleNationalIdInstead = () => {
    setDocumentType(null);
    setDocumentType(IdType.nationalIdCard);
    setActiveStep(UploadStep.upload);
    clearFieldData();
  };

  return (
    <StepContainer>
      <Form<DocumentUploadFormValues>
        onSubmit={onSubmit}
        schema={validationSchema}
        validationContext={{
          docsUploaded: documentsUploaded,
        }}
      >
        {activeStep === UploadStep.upload && (
          <>
            <StepContent>
              <StepTitle>
                {isDesktop ? 'Upload your ID' : 'Take a photo of your ID'}
              </StepTitle>
              <StepIntroduction mb={2} $width={StepIntroductionWidth.normal}>
                <StepIntroductionTypography>
                  In order to verify your identity, we need a photo or scan of
                  your ID.
                </StepIntroductionTypography>
              </StepIntroduction>

              <ServerError isVisible={uploadError} />

              {documentType !== IdType.nationalIdCard && (
                <DocumentTypeContainer>
                  <DocumentType
                    onClick={() => setDocumentType(IdType.passport)}
                    className={clsx(
                      documentType &&
                        documentType !== IdType.passport &&
                        'inactive'
                    )}
                    htmlFor="document-front-upload-field"
                  >
                    <SvgWrapper>
                      <PassportIcon />
                    </SvgWrapper>
                    <Text $fontWeight={FontWeight.normal} $noMargin>
                      Passport
                    </Text>
                  </DocumentType>
                  <DocumentType
                    onClick={() => setDocumentType(IdType.drivingLicence)}
                    className={clsx(
                      documentType &&
                        documentType !== IdType.drivingLicence &&
                        'inactive'
                    )}
                    htmlFor="document-front-upload-field"
                  >
                    <SvgWrapper>
                      <DrivingLicenceIcon />
                    </SvgWrapper>
                    <Text $fontWeight={FontWeight.normal} $noMargin>
                      Driving licence
                    </Text>
                  </DocumentType>
                </DocumentTypeContainer>
              )}
              {documentType && (
                <UploadsContainer>
                  <UploadField
                    name="frontSide"
                    label={
                      documentType === IdType.nationalIdCard
                        ? 'Front of ID'
                        : 'Upload'
                    }
                    inputProps={{ accept: allowedFileTypesStr }}
                    labelIsAbove={documentType === IdType.nationalIdCard}
                    updateParent={handleUploadFieldUpdate}
                    id="document-front-upload-field"
                  />
                  {documentType === IdType.nationalIdCard && (
                    <UploadField
                      name="backSide"
                      label="Back of ID"
                      inputProps={{ accept: allowedFileTypesStr }}
                      labelIsAbove={documentType === IdType.nationalIdCard}
                      updateParent={handleUploadFieldUpdate}
                    />
                  )}
                </UploadsContainer>
              )}
              {documentType !== IdType.nationalIdCard && (
                <Text $noMargin>
                  Use{' '}
                  <StyledButton type="button" onClick={handleNationalIdInstead}>
                    national photo identity
                  </StyledButton>{' '}
                  card instead
                </Text>
              )}
            </StepContent>

            <StepActions>
              <FormState>
                {({ isSubmitting }) => (
                  <>
                    <StepButton
                      type="submit"
                      className="magenta"
                      disabled={isSubmitting || !hasFieldData}
                    >
                      Continue
                    </StepButton>
                    <GoBackButton
                      onClick={() =>
                        documentType === IdType.nationalIdCard
                          ? setDocumentType(null)
                          : onGoBack()
                      }
                      disabled={isSubmitting}
                    />
                  </>
                )}
              </FormState>
            </StepActions>
            <NeedHelpWrapper>
              <InfoPopoverV2
                content={popoverContent}
                label="Need help with your ID?"
                $width="wide"
                placement="top"
              />
            </NeedHelpWrapper>
          </>
        )}
        {activeStep === UploadStep.previouslyUploaded && (
          <DocumentPreviouslyUploaded
            documentsUploadedType={documentsUploadedType}
            handleUploadNew={() => handleUploadNew()}
            onProceed={() => onProceed()}
            onGoBack={() => onGoBack()}
          />
        )}
      </Form>
    </StepContainer>
  );
}
