import { UsePaginationProps } from '@material-ui/lab';
import { StyledPagination } from './Pagination.styles';

export interface PaginationProps {
  page: number;
  count: number;
  onChange: UsePaginationProps['onChange'];
}

export function Pagination({ page, count, onChange }: PaginationProps) {
  return (
    <StyledPagination
      page={page}
      count={count}
      hidePrevButton={page === 1}
      hideNextButton={page === count}
      onChange={onChange}
    />
  );
}
