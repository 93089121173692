import { Typography } from '@material-ui/core';
import { colors } from 'constants/colors';
import { mix } from 'polished';
import styled from 'styled-components';

const borderStyle = '1px solid ' + mix(0.5, colors.white, colors.richBlue);

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: hidden;
  min-width: 100%;
  min-height: 64px;
  align-items: stretch;
  border-radius: 5px;
`;

export const Main = styled.div`
  flex: 1 1 auto;
  padding: ${(p) => p.theme.spacing(1.25)}px;
  position: relative;
  min-height: 100%;
  background-color: ${colors.white};
  border-left: ${borderStyle};
  border-top: ${borderStyle};
  border-bottom: ${borderStyle};
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  display: flex;
  align-items: center;

  ${(p) => p.theme.breakpoints.up('md')} {
    border-radius: 5px;
    border: ${borderStyle};
    padding: ${(p) => p.theme.spacing(2.25, 2.5)};
  }
`;

export const Title = styled(Typography)`
  font-family: 'GT Super', serif;
  font-size: 0.75rem;
  font-weight: 500;
  color: ${colors.richBlack};
  text-align: left;

  ${(p) => p.theme.breakpoints.up('sm')} {
    font-size: 1.125rem;
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    border-radius: 1.5rem;
  }
`;

const Supplement = styled.div`
  flex: 0 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${(p) => p.theme.spacing(1.25)}px;

  ${(p) => p.theme.breakpoints.up('md')} {
    border-radius: 5px;
    margin-left: ${(p) => p.theme.spacing(1.25)}px;
    min-width: 180px;
    border: ${borderStyle};
  }
`;

export const PendingOrders = styled(Supplement)`
  display: none;
  border-top: ${borderStyle};
  border-bottom: ${borderStyle};

  ${(p) => p.theme.breakpoints.up('md')} {
    display: flex;
  }
`;

export const TotalAmount = styled(Supplement)`
  border-right: ${borderStyle};
  border-top: ${borderStyle};
  border-bottom: ${borderStyle};
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
`;
