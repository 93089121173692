import { colors } from 'constants/colors';
import { IconButton } from '@material-ui/core';
import styled from 'styled-components';
import { OrderDirection } from 'generated/graphql';

interface directionProp {
  direction: OrderDirection;
}

export const Download = styled(IconButton)`
  color: ${colors.magenta};
`;

export const Pill = styled.div<directionProp>`
  display: inline-block;
  min-width: 72px;
  text-align: center;
  background-color: ${(p) =>
    p.direction === OrderDirection.Buy ? colors.magenta : colors.richBlue};
  padding: ${(p) => p.theme.spacing(0.5, 2)};
  border-radius: ${(p) => p.theme.spacing(4)}px;
  color: ${colors.white};
`;
