import { getBuyEstimate } from 'components/Dialogs/Trading/helpers';
import { useToast } from 'context/ToastContext';
import {
  PortfolioRebalancingStatus,
  useCreatePortfolioRebalancingMutation,
  useInstrumentsByIsinsBreakdownQuery,
  useInstrumentsByIsinsQuery,
  usePortfolioRebalancingQuery,
  usePortfolioRebalancingsQuery,
  useUpdatePortfolioRebalancingMutation,
} from 'generated/graphql';
import { useBuyOrderDetailsByAssetQueries } from 'hooks/useBuyOrderDetailsByAssetQueries';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useLocalStorage } from 'usehooks-ts';
import { v4 as uuid } from 'uuid';

export interface BasketBuyOrder {
  type: 'buyOrder';
  id: number;
  slug?: string | null;
  isin: string;
  instrumentName: string;
  instrumentType: string;
  isDarkUniverse: boolean;
  name: string;
  description?: string | null;
  fundName: string;
  amount: number;
  assetClass?: string;
  units: number;
  askPrice?: number | null;
  minAmount: number;
  minimumTradeUnit: number;
  quoteUnit?: number | null;
}

export interface BasketSellOrder {
  type: 'sellOrder';
  id: number;
  slug?: string | null;
  isin: string;
  instrumentName: string;
  instrumentType: string;
  isDarkUniverse: boolean;
  name: string;
  description?: string | null;
  fundName: string;
  amount: number;
  assetClass?: string;
  units: number;
  quotedBid: number;
  quotedBidDateUtc: string;
}

interface AccountBasket {
  rebalancingId?: string;
  basketBuyOrders: BasketBuyOrder[];
  basketSellOrders: BasketSellOrder[];
}

export interface Basket {
  [accountId: string]: AccountBasket | undefined;
}

interface LsBuyOrder {
  id: number;
  isin: string;
  amount: number;
}

export interface LsBuyOrders {
  buyOrders?: LsBuyOrder[];
}

/**
 * useFundsBasketProvider
 *
 * A react hook that stores a list of BasketFund items in local storage with add, remove, has, updateItem and clear methods
 *
 * @todo - validate the items in the basket are still valid on page load - use useQueries?
 */
const useFundsBasketProvider = (): FundsBasketProps => {
  const [basket, setBasket] = useState<Basket>({});

  const [
    nonAccountBuyOrdersLs,
    setNonAccountBuyOrdersLs,
  ] = useLocalStorage<LsBuyOrders>('nonAccountBuyOrders', {});

  // Populate local state from the non account basket
  const unknownBuyOrderInstruments = useInstrumentsByIsinsQuery(
    {
      isins: nonAccountBuyOrdersLs.buyOrders?.map(({ isin }) => isin),
    },
    {
      enabled: !!nonAccountBuyOrdersLs.buyOrders,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retryOnMount: false,
    }
  );

  const buyOrderDetails = useBuyOrderDetailsByAssetQueries(
    (nonAccountBuyOrdersLs.buyOrders || [])!.map(({ id }) => ({
      assetId: `${id}`,
      accountId: null,
    })),
    {
      enabled: !!nonAccountBuyOrdersLs.buyOrders,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retryOnMount: false,
    }
  );

  const allLoaded =
    unknownBuyOrderInstruments.isSuccess &&
    buyOrderDetails.every((query) => query.isSuccess);

  useEffect(() => {
    if (basket.unknown !== undefined) {
      return;
    }
    if (allLoaded && nonAccountBuyOrdersLs.buyOrders) {
      const basketBuyOrders: BasketBuyOrder[] = nonAccountBuyOrdersLs.buyOrders.map(
        (buyOrder) => {
          const instrument = unknownBuyOrderInstruments.data.instrumentsByIsins?.nodes.find(
            ({ isin }) => isin === buyOrder.isin
          )!;

          const buyOrderDetailQuery = buyOrderDetails.find((query) => {
            return (
              query.isSuccess &&
              query.data?.context.assetId === `${buyOrder.id}`
            );
          })!;
          const buyOrderDetail = buyOrderDetailQuery.data
            ?.buyOrderDetailsByAsset!;

          return {
            type: 'buyOrder',
            id: buyOrder.id,
            isin: buyOrder.isin,
            slug: instrument.asset?.slug || '',
            instrumentName: instrument.name,
            instrumentType: instrument.instrumentType,
            isDarkUniverse: instrument.isDarkUniverse || false,
            name: instrument.asset?.name || '',
            description: instrument.asset?.description || '',
            fundName: instrument.asset?.name || '',
            amount: buyOrder.amount,
            units: getBuyEstimate(
              instrument,
              buyOrder.amount,
              buyOrderDetail.charges!
            ),
            askPrice: instrument.askPrice,
            minimumTradeUnit: instrument.minimumTradeUnit,
            quoteUnit: instrument.quoteUnit,
            assetClass: instrument.asset?.assetClass?.name,
            minAmount: buyOrderDetail?.validRange?.valueRange.minimumValue!,
          };
        }
      );

      setBasket({
        ...basket,
        unknown: {
          basketBuyOrders,
          basketSellOrders: [],
        },
      });
    } else {
      return;
    }
  }, [
    allLoaded,
    basket,
    buyOrderDetails,
    nonAccountBuyOrdersLs.buyOrders,
    unknownBuyOrderInstruments.data?.instrumentsByIsins?.nodes,
  ]);

  useEffect(() => {
    if (basket.unknown) {
      setNonAccountBuyOrdersLs({
        buyOrders: basket.unknown?.basketBuyOrders.map((buyOrder) => ({
          id: buyOrder.id,
          isin: buyOrder.isin,
          amount: buyOrder.amount,
        })),
      });
    }
  }, [setNonAccountBuyOrdersLs, basket.unknown]);

  const addBuyOrder = (accountId: string, buyOrder: BasketBuyOrder) => {
    const accountBasket = basket[accountId] || {
      basketBuyOrders: [],
      basketSellOrders: [],
    };
    const updatedAccountBasket = {
      ...accountBasket,
      basketSellOrders: accountBasket.basketSellOrders.filter(
        (sellOrder) => sellOrder.isin !== buyOrder.isin
      ),
      basketBuyOrders: [buyOrder, ...accountBasket.basketBuyOrders],
    };

    setBasket({
      ...basket,
      [accountId]: updatedAccountBasket,
    });

    return updatedAccountBasket;
  };

  const addSellOrder = (accountId: string, sellOrder: BasketSellOrder) => {
    const accountBasket = basket[accountId] || {
      basketBuyOrders: [],
      basketSellOrders: [],
    };

    const updatedAccountBasket = {
      ...accountBasket,
      basketBuyOrders: accountBasket.basketBuyOrders.filter(
        (buyOrder) => buyOrder.isin !== sellOrder.isin
      ),
      basketSellOrders: [sellOrder, ...accountBasket.basketSellOrders],
    };
    setBasket({
      ...basket,
      [accountId]: updatedAccountBasket,
    });
    return updatedAccountBasket;
  };

  const updateSellOrder = (
    accountId: string,
    updatedSellOrder: BasketSellOrder
  ) => {
    const accountBasket = basket[accountId] || {
      basketBuyOrders: [],
      basketSellOrders: [],
    };
    const updatedAccountBasket = {
      ...accountBasket,
      basketSellOrders: accountBasket.basketSellOrders.map((sellOrder) => {
        if (updatedSellOrder.isin === sellOrder.isin) {
          return updatedSellOrder;
        }
        return sellOrder;
      }),
    };
    setBasket({
      ...basket,
      [accountId]: updatedAccountBasket,
    });
    return updatedAccountBasket;
  };

  const remove = (accountId: string, isin: string) => {
    const accountBasket = basket[accountId] || {
      basketBuyOrders: [],
      basketSellOrders: [],
    };
    const updatedAccountBasket = {
      ...accountBasket,
      basketBuyOrders: accountBasket.basketBuyOrders.filter(
        (buyOrder) => buyOrder.isin !== isin
      ),
      basketSellOrders: accountBasket.basketSellOrders.filter(
        (buyOrder) => buyOrder.isin !== isin
      ),
    };
    setBasket({
      ...basket,
      [accountId]: updatedAccountBasket,
    });
    return updatedAccountBasket;
  };

  const has = (accountId: string, isin: string) => {
    const accountBasket = basket[accountId] || {
      basketBuyOrders: [],
      basketSellOrders: [],
    };
    return (
      accountBasket.basketSellOrders.some(
        (sellOrder) => sellOrder.isin === isin
      ) ||
      accountBasket.basketBuyOrders.some((buyOrder) => buyOrder.isin === isin)
    );
  };

  const getSellOrder = (accountId: string, isin: string) => {
    const accountBasket = basket[accountId] || {
      basketBuyOrders: [],
      basketSellOrders: [],
    };
    return accountBasket.basketSellOrders.find(
      (sellOrder) => sellOrder.isin === isin
    );
  };

  const getBuyOrder = (accountId: string, id: number) => {
    const accountBasket = basket[accountId] || {
      basketBuyOrders: [],
      basketSellOrders: [],
    };
    return accountBasket.basketBuyOrders.find((fund) => fund.id === id);
  };

  const get = (accountId: string, isin: string) => {
    const accountBasket = basket[accountId] || {
      basketBuyOrders: [],
      basketSellOrders: [],
    };
    const order = accountBasket.basketBuyOrders.find(
      (buyOrder) => buyOrder.isin === isin
    );
    if (order) {
      return order;
    }
    return accountBasket.basketSellOrders.find(
      (sellOrder) => sellOrder.isin === isin
    );
  };

  const hasOrderByFundId = (accountId: string, fundId: number) => {
    const accountBasket = basket[accountId] || {
      basketBuyOrders: [],
      basketSellOrders: [],
    };
    return (
      accountBasket.basketSellOrders.some(
        (sellOrder) => sellOrder.id === fundId
      ) ||
      accountBasket.basketBuyOrders.some((buyOrder) => buyOrder.id === fundId)
    );
  };

  const getOrderByFundId = (accountId: string, fundId: number) => {
    const accountBasket = basket[accountId] || {
      basketBuyOrders: [],
      basketSellOrders: [],
    };
    const order = accountBasket.basketBuyOrders.find(
      (buyOrder) => buyOrder.id === fundId
    );
    if (order) {
      return order;
    }
    return accountBasket.basketSellOrders.find(
      (sellOrder) => sellOrder.id === fundId
    );
  };

  const updateBuyOrder = (
    accountId: string,
    updatedBuyOrder: BasketBuyOrder
  ) => {
    const accountBasket = basket[accountId] || {
      basketBuyOrders: [],
      basketSellOrders: [],
    };
    const updatedAccountBasket = {
      ...accountBasket,
      basketBuyOrders: accountBasket.basketBuyOrders.map((buyOrder) => {
        if (updatedBuyOrder.id === buyOrder.id) {
          return updatedBuyOrder;
        }
        return buyOrder;
      }),
    };
    setBasket({
      ...basket,
      [accountId]: updatedAccountBasket,
    });
    return updatedAccountBasket;
  };

  const addRebalancingId = (
    accountId: string,
    rebalancingId: string,
    accountBasket: AccountBasket | undefined
  ) => {
    const existingAccountBasket = accountBasket ||
      basket[accountId] || {
        rebalancingId,
        basketBuyOrders: [],
        basketSellOrders: [],
      };
    const updatedAccountBasket = {
      ...existingAccountBasket,
      rebalancingId,
    };
    setBasket({
      ...basket,
      [accountId]: updatedAccountBasket,
    });
  };

  const getRebalancingId = (accountId: string) => {
    const accountBasket = basket[accountId];
    return accountBasket?.rebalancingId;
  };

  const clear = (accountId: string) => {
    setBasket({
      ...basket,
      [accountId]: undefined,
    });
  };

  const getBasketSummary = (accountId: string): BasketSummary => {
    const accountBasket = basket[accountId] || {
      basketBuyOrders: [],
      basketSellOrders: [],
    };
    const orders = [
      ...accountBasket.basketBuyOrders,
      ...accountBasket.basketSellOrders,
    ];
    const amount = orders.reduce((amountAcc, order) => {
      const orderAmount =
        order.type === 'buyOrder' ? order.amount : -order.amount;
      return amountAcc + orderAmount;
    }, 0);
    return {
      amount,
      orderCount:
        accountBasket.basketBuyOrders.length +
        accountBasket.basketSellOrders.length,
      buyOrderCount: accountBasket.basketBuyOrders.length,
      sellOrderCount: accountBasket.basketSellOrders.length,
    };
  };

  const isBasketEmpty = (accountId: string) => {
    const accountBasket = basket[accountId] || {
      basketBuyOrders: [],
      basketSellOrders: [],
    };

    return (
      accountBasket.basketBuyOrders.length === 0 &&
      accountBasket.basketSellOrders.length === 0
    );
  };

  const getBasket = (accountId: string) => {
    return (
      basket[accountId] || {
        basketBuyOrders: [],
        basketSellOrders: [],
      }
    );
  };

  const initBasket = (
    accountId: string,
    rebalancingId: string,
    basketItemsAdds: BasketBuyOrder[],
    basketItemsSells: BasketSellOrder[]
  ) => {
    setBasket({
      ...basket,
      [accountId]: {
        rebalancingId,
        basketBuyOrders: basketItemsAdds,
        basketSellOrders: basketItemsSells,
      },
    });
  };

  return {
    basket,
    getBasket,
    getBasketSummary,
    isBasketEmpty,

    getRebalancingId,
    addRebalancingId,

    getBuyOrder,
    addBuyOrder,
    updateBuyOrder,

    getSellOrder,
    updateSellOrder,
    addSellOrder,

    remove,
    has,
    get,

    hasOrderByFundId,
    getOrderByFundId,

    clear,
    initBasket,
  };
};

export interface BasketSummary {
  amount: number;
  orderCount: number;
  buyOrderCount: number;
  sellOrderCount: number;
}

interface FundsBasketProps {
  basket: Basket;
  getBasket: (accountId: string) => AccountBasket;
  getBasketSummary: (accountId: string) => BasketSummary;
  isBasketEmpty: (accountId: string) => boolean;

  getRebalancingId: (accountId: string) => string | undefined;
  addRebalancingId: (
    accountId: string,
    rebalancingId: string,
    accountBasket: AccountBasket | undefined
  ) => void;

  // Buy orders:
  getBuyOrder: (accountId: string, id: number) => BasketBuyOrder | undefined;
  addBuyOrder: (accountId: string, fund: BasketBuyOrder) => AccountBasket;
  updateBuyOrder: (
    accountId: string,
    buyOrder: BasketBuyOrder
  ) => AccountBasket;

  // Sell orders:
  addSellOrder: (
    accountId: string,
    sellOrder: BasketSellOrder
  ) => AccountBasket;
  getSellOrder: (
    accountId: string,
    isin: string
  ) => BasketSellOrder | undefined;
  updateSellOrder: (
    accountId: string,
    sellOrder: BasketSellOrder
  ) => AccountBasket;

  // Common:
  remove: (accountId: string, isin: string) => AccountBasket;

  has: (accountId: string, isin: string) => boolean;
  get: (
    accountId: string,
    isin: string
  ) => BasketBuyOrder | BasketSellOrder | undefined;

  hasOrderByFundId: (accountId: string, id: number) => boolean;
  getOrderByFundId: (
    accountId: string,
    id: number
  ) => BasketBuyOrder | BasketSellOrder | undefined;

  clear: (accountId: string) => void;
  initBasket: (
    accountId: string,
    rebalancingId: string,
    basketItemsAdds: BasketBuyOrder[],
    basketItemsSells: BasketSellOrder[]
  ) => void;
}

const FundsBasket = createContext<FundsBasketProps | null>(null);

interface FundsBasketProviderProps {
  children: React.ReactNode;
}
export const FundsBasketProvider = ({ children }: FundsBasketProviderProps) => {
  const fundBasket = useFundsBasketProvider();
  return (
    <FundsBasket.Provider value={fundBasket}>{children}</FundsBasket.Provider>
  );
};

interface UseFundsBasketProps {
  selectedAccountId?: string | null;
  includeBreakdown?: boolean;
}

/**
 *
 * @param options
 * @param options.selectedAccountId: string
 * @returns
 */
export const useFundsBasket = ({
  selectedAccountId,
  includeBreakdown = false,
}: UseFundsBasketProps) => {
  const { openToast } = useToast();
  const [idempotencyToken] = useState(uuid());

  const basketId = selectedAccountId ? selectedAccountId : 'unknown';

  const fundBasket = useContext(FundsBasket);
  if (!fundBasket) {
    throw new Error('fundBasket has to be used within <FundsBasket.Provider>');
  }

  const queryClient = useQueryClient();

  const portfolioRebalancingsQuery = usePortfolioRebalancingsQuery(
    { filter: { active: true, accountId: selectedAccountId } },
    {
      enabled: !!selectedAccountId && selectedAccountId !== 'unknown',
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retryOnMount: false,
      onSuccess: ({ portfolioRebalancings }) => {
        const draftPortfolioRebalancing = portfolioRebalancings?.find(
          ({ status }) => status === PortfolioRebalancingStatus.Draft
        );
        if (draftPortfolioRebalancing) {
          const buyOrders = draftPortfolioRebalancing.buyOrders
            .filter((buyOrder) => {
              const asset = buyOrder.instrument.asset;
              return !!asset && buyOrder.instrument.status === 'Active';
            })
            .map((buyOrders) => {
              const asset = buyOrders.instrument.asset;
              if (!asset) {
                return undefined;
              }
              const buyOrder: BasketBuyOrder = {
                type: 'buyOrder',
                isin: buyOrders.isin,
                id: asset.id,
                name: asset.name,
                slug: asset.slug,
                instrumentName: buyOrders.instrument.name,
                instrumentType: buyOrders.instrument.instrumentType,
                isDarkUniverse: buyOrders.instrument.isDarkUniverse || false,
                description: asset.description,
                fundName: asset.name,
                amount: buyOrders.amount,
                units: getBuyEstimate(
                  buyOrders.instrument,
                  buyOrders.amount,
                  buyOrders.context?.charges!
                ),
                askPrice: buyOrders.instrument.askPrice,
                minimumTradeUnit: buyOrders.instrument.minimumTradeUnit,
                quoteUnit: buyOrders.instrument.quoteUnit,
                assetClass: asset.assetClass?.name,
                minAmount: buyOrders.context?.validRange?.valueRange
                  .minimumValue!,
              };
              return buyOrder;
            }) as BasketBuyOrder[];

          const sellOrders = draftPortfolioRebalancing.sellOrders
            .map((sellOrder) => {
              const asset = sellOrder.instrument.asset;
              if (!asset || !sellOrder.instrument.bidPrice) {
                return undefined;
              }
              const basketSellOrder: BasketSellOrder = {
                type: 'sellOrder',
                isin: sellOrder.isin,
                amount: sellOrder.enteredAmount,
                id: asset.id,
                slug: asset.slug,
                name: asset.name,
                instrumentName: sellOrder.instrument.name,
                instrumentType: sellOrder.instrument.instrumentType,
                isDarkUniverse: sellOrder.instrument.isDarkUniverse || false,
                description: asset.description,
                fundName: asset.name,
                assetClass: asset.assetClass?.name,
                units: sellOrder.quantity,
                quotedBid: sellOrder.instrument.bidPrice,
                quotedBidDateUtc: new Date(
                  sellOrder.instrument.priceDate
                ).toISOString(),
              };
              return basketSellOrder;
            })
            .filter(
              (sellOrder) => sellOrder !== undefined
            ) as BasketSellOrder[];

          // populate basket with the buy orders from the server
          fundBasket?.initBasket(
            basketId,
            draftPortfolioRebalancing.id,
            buyOrders,
            sellOrders
          );

          const buyOrdersInactiveBuyOrders = draftPortfolioRebalancing.buyOrders.filter(
            (buyOrder) => {
              const asset = buyOrder.instrument.asset;
              return !!asset && buyOrder.instrument.status !== 'Active';
            }
          );
          if (buyOrdersInactiveBuyOrders.length > 0) {
            openToast(
              'Bad ISIN detected. Fund has been removed from your basket.'
            );
            buyOrdersInactiveBuyOrders.forEach((buyOrder) => {
              fundBasket.remove(basketId, buyOrder.isin);
            });
          }
        }
      },
    }
  );

  const selectedAccountBasket = fundBasket.basket[basketId];

  const instrumentsByIsinQuery = useInstrumentsByIsinsBreakdownQuery(
    {
      isins: selectedAccountBasket?.basketBuyOrders.map(
        (buyOrder) => buyOrder.isin
      ),
    },
    {
      enabled: includeBreakdown,
    }
  );

  const createPortfolioRebalancingMutationQuery = useCreatePortfolioRebalancingMutation();
  const {
    mutateAsync: createPortfolioRebalancingMutation,
  } = createPortfolioRebalancingMutationQuery;

  const updatePortfolioRebalancingMutationQuery = useUpdatePortfolioRebalancingMutation();
  const {
    mutateAsync: updatePortfolioRebalancingMutation,
  } = updatePortfolioRebalancingMutationQuery;

  const syncFromServer = (portfolioRebalancingId: string) => {
    queryClient.invalidateQueries(
      usePortfolioRebalancingsQuery.getKey(),
      {
        refetchActive: true,
      },
      {
        cancelRefetch: true,
      }
    );
    queryClient.invalidateQueries(
      usePortfolioRebalancingQuery.getKey({ id: portfolioRebalancingId }),
      {
        refetchActive: true,
      },
      {
        cancelRefetch: true,
      }
    );
  };

  /**
   * syncToServer
   */
  const syncBasketToServer = async (accountBasket: AccountBasket) => {
    if (accountBasket.rebalancingId) {
      const updatePortfolioRebalancingResult = await updatePortfolioRebalancingMutation(
        {
          input: {
            portfolioRebalancingId: accountBasket.rebalancingId,
            buyOrders: accountBasket?.basketBuyOrders.map((fund) => ({
              amount: fund.amount,
              isin: fund.isin,
            })),
            sellOrders: accountBasket?.basketSellOrders.map((sellOrder) => {
              return {
                enteredAmount: sellOrder.amount,
                isin: sellOrder.isin,
                quantity: sellOrder.units,
                quotedBid: sellOrder.quotedBid,
                quotedBidDateUtc: new Date(
                  sellOrder.quotedBidDateUtc
                ).toISOString(),
              };
            }),
          },
        }
      );

      syncFromServer(accountBasket.rebalancingId);

      return updatePortfolioRebalancingResult;
    } else {
      const createPortfolioRebalancingResult = await createPortfolioRebalancingMutation(
        {
          input: {
            accountId: selectedAccountId!,
            buyOrders: accountBasket?.basketBuyOrders.map((fund) => ({
              amount: fund.amount,
              isin: fund.isin,
            })),
            sellOrders: accountBasket?.basketSellOrders.map((sellOrder) => {
              return {
                enteredAmount: sellOrder.amount,
                isin: sellOrder.isin,
                quantity: sellOrder.units,
                quotedBid: sellOrder.quotedBid,
                quotedBidDateUtc: new Date(
                  sellOrder.quotedBidDateUtc
                ).toISOString(),
              };
            }),
            idempotencyToken,
          },
        }
      );
      fundBasket.addRebalancingId(
        selectedAccountId!,
        createPortfolioRebalancingResult.createPortfolioRebalancing.id,
        accountBasket
      );

      syncFromServer(
        createPortfolioRebalancingResult.createPortfolioRebalancing.id
      );

      return createPortfolioRebalancingResult;
    }
  };

  const syncToServer = () => {
    if (!portfolioRebalancingsQuery.isFetched) {
      throw new Error('portfolioRebalancingsQuery must be fetched first');
    }
    if (!selectedAccountId) {
      throw new Error('selectedAccountId is required');
    }
    const accountBasket = fundBasket.basket[selectedAccountId];
    if (accountBasket) {
      return syncBasketToServer(accountBasket);
    }
  };

  return {
    // #region API

    // loading query states:

    instrumentsByIsinQuery,
    portfolioRebalancingsQuery,

    /**
     * Repopulate the basket from the server
     */
    sync: () => {
      portfolioRebalancingsQuery.refetch();
    },

    /**
     * Push the basket to the server
     */
    syncToServer,
    syncToServerQueryState: fundBasket.basket[basketId]?.rebalancingId
      ? updatePortfolioRebalancingMutationQuery
      : createPortfolioRebalancingMutationQuery,

    syncFromServer,

    // #endregion

    getRebalancingId: () => fundBasket.getRebalancingId(basketId),

    // #region Summary

    basketSummary: fundBasket.getBasketSummary(basketId),

    isBasketEmpty: () => fundBasket.isBasketEmpty(basketId),

    // #endregion

    // #region Buy orders:
    basketBuyOrders: fundBasket.basket[basketId]?.basketBuyOrders || [],

    /**
     * Get a buy orders from the basket
     *
     * @param id
     * @returns
     */
    getBuyOrder: (id: number) => fundBasket.getBuyOrder(basketId, id),

    /**
     * Add a buy order to the basket
     *
     * @param fund
     * @returns
     */
    addBuyOrder: (fund: BasketBuyOrder) => {
      const accountBasket = fundBasket.addBuyOrder(basketId, fund);
      if (basketId !== 'unknown') {
        return syncBasketToServer(accountBasket);
      }
    },

    /**
     * Update a buy order in the basket
     *
     * @param buyOrder
     * @returns
     */
    updateBuyOrder: (buyOrder: BasketBuyOrder) => {
      const accountBasket = fundBasket.updateBuyOrder(basketId, buyOrder);
      if (basketId !== 'unknown') {
        return syncBasketToServer(accountBasket);
      }
    },

    // #endregion

    // #region Sell orders:

    /**
     * All sell orders from the basket
     */
    basketSellOrders: fundBasket.basket[basketId]?.basketSellOrders || [],

    /**
     * Get a sell order from the basket
     *
     * @param id
     * @returns
     */
    getSellOrder: (isin: string) => fundBasket.getSellOrder(basketId, isin),

    /**
     * Add a sell order to the basket
     *
     * @param sellOrder
     * @returns
     */
    addSellOrder: (sellOrder: BasketSellOrder) => {
      const accountBasket = fundBasket.addSellOrder(basketId, sellOrder);
      if (basketId !== 'unknown') {
        return syncBasketToServer(accountBasket);
      }
    },

    /**
     * Update a sell order in the basket
     *
     * @param sellOrder
     * @returns
     */
    updateSellOrder: (sellOrder: BasketSellOrder) => {
      const accountBasket = fundBasket.updateSellOrder(basketId, sellOrder);
      if (basketId !== 'unknown') {
        return syncBasketToServer(accountBasket);
      }
    },

    // #endregion

    // #region Common:

    /**
     * Remove a buy OR sell order from the basket
     *
     * @param isin
     * @returns
     */
    remove: (isin: string) => {
      const accountBasket = fundBasket.remove(basketId, isin);
      if (basketId !== 'unknown') {
        return { accountBasket, res: syncBasketToServer(accountBasket) };
      }
      return { accountBasket };
    },

    /**
     * Check if a buy OR sell order is in the basket
     *
     * @param isin
     * @returns
     */
    has: (isin: string) => fundBasket.has(basketId, isin),

    /**
     * Get a buy OR sell order from the basket
     *
     * @param id
     * @returns
     */
    get: (isin: string) => fundBasket.get(basketId, isin),

    /**
     * hasOrderByFundId
     *
     * @param fundId
     * @returns
     */
    hasOrderByFundId: (fundId: number) =>
      fundBasket.hasOrderByFundId(basketId, fundId),

    /**
     * getOrderByFundId
     *
     * @param fundId
     * @returns
     */
    getOrderByFundId: (fundId: number) =>
      fundBasket.getOrderByFundId(basketId, fundId),

    /**
     * Empty the basket
     */
    clear: () => fundBasket.clear(basketId),

    // #endregion

    /**
     * Can checkout
     */
    canCheckout: () => {
      const selectedBasket = fundBasket.basket[basketId];
      return (
        selectedBasket &&
        (selectedBasket.basketBuyOrders?.length > 0 ||
          selectedBasket.basketSellOrders?.length > 0)
      );
    },
  };
};
