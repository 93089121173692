import { InstrumentLike } from 'helpers/instrumentNaming';

export enum InstrumentSelectorAlignment {
  center = 'center',
  left = 'flex-start',
}

export interface InstrumentSelectorProps {
  instruments: InstrumentLike[];
  $align?: InstrumentSelectorAlignment;
  value?: string;
  onChange: (isin: string) => void;
}
