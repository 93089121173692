import { forwardRef } from 'react';
import { IMaskInput } from 'react-imask';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  inputRef: () => void;
  name: string;
}

/**
 * generateFormattedInput
 *
 * Returns a react input component that can be used as the InputProps -> inputComponent on MUI text
 * field components.
 */
export const generateFormattedInput = ({
  mask,
  definitions,
}: {
  mask: string;
  definitions?: { [key: string]: RegExp | string };
}) =>
  forwardRef<HTMLInputElement, CustomProps>(function TextMaskCustom(
    props,
    ref
  ) {
    const { onChange, inputRef, ...other } = props;

    return (
      <IMaskInput
        {...other}
        mask={mask}
        inputRef={inputRef ?? ref}
        onAccept={(value: any) =>
          onChange?.({ target: { name: props.name, value } })
        }
        definitions={definitions}
        overwrite
      />
    );
  });
