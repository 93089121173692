import {
  ResponsiveDialog,
  ResponsiveDialogProps,
} from 'components/Dialogs/ResponsiveDialog';
import { ExistingPosition } from 'components/feature/PortfolioBuilder/ImportantInformation/_shared/ImportantInformation.types';
import { WrapperType } from 'generated/graphql';
import { useState } from 'react';
import { AnyAsset } from 'types/graphqlTypes';
import { AddSellsToBasketStep } from './steps/AddSellOrderToBasketStep';

interface AddSellOrderToBasketDialogProps extends ResponsiveDialogProps {
  openDialog: () => void;
  closeDialog: () => void;
  open: boolean;
  selectedAccountId?: string;
  selectedAccountType: WrapperType;
  selectedPosition?: ExistingPosition;
  asset: AnyAsset;
}

export function AddSellOrderToBasketDialog({
  closeDialog,
  open,
  selectedAccountId,
  selectedAccountType,
  asset,
  selectedPosition,
}: AddSellOrderToBasketDialogProps) {
  return (
    <ResponsiveDialog open={open} onClose={closeDialog}>
      {open && (
        <AddSellsToBasketStep
          flow="addToBasketOnlyFlow"
          onProceed={closeDialog}
          selectedPosition={selectedPosition}
          asset={asset}
          selectedAccountId={selectedAccountId}
          selectedAccountType={selectedAccountType}
        />
      )}
    </ResponsiveDialog>
  );
}

export const useDialog = () => {
  const [open, setOpen] = useState(false);

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  return { open, openDialog, closeDialog };
};
