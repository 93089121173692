import {
  DialogContent,
  Grow,
  Paper,
  Popper,
  Typography,
} from '@material-ui/core';
import { StyledTitle } from 'components/Dialogs/Deposit/styles/Deposit.styles';
import {
  ResponsiveDialog,
  ResponsiveDialogProps,
} from 'components/Dialogs/ResponsiveDialog';
import { colors } from 'constants/colors';
import { GaEventNames } from 'constants/gaConstants';
import { trackGa } from 'helpers/track';
import {
  bindPopper,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import { useCallback } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { HiOutlineClipboardCopy, HiOutlineMail } from 'react-icons/hi';
import {
  RiMessage2Line,
  RiTelegramLine,
  RiTwitterLine,
  RiWhatsappLine,
} from 'react-icons/ri';
import styled from 'styled-components';
import { QRCode } from './QRCode';
import {
  ButtonsContainer,
  CodeContainer,
  OptionsContainer,
  QRCodeWrapper,
  TileButton,
} from './ReferralsCard.styles';

interface ShareDialogProps extends ResponsiveDialogProps {
  url: string;
  shareMessage: string;
}

export function ShareDialog({ url, shareMessage, ...props }: ShareDialogProps) {
  return (
    <ResponsiveDialog {...props}>
      <StyledTitle disableTypography>
        <Typography variant="subtitle1" component="h1">
          Share your referral link
        </Typography>
      </StyledTitle>
      <DialogContent>
        <Typography style={{ fontWeight: 300 }}>
          Click on one of the buttons below to share the referral code on your
          preferred platform.
        </Typography>
        <OptionsContainer>
          <QRCodeWrapper>
            <CodeContainer>
              <QRCode value={url} size={202} />
            </CodeContainer>
          </QRCodeWrapper>
          <ButtonsContainer>
            <CopyToClipboardButton
              onCopy={() => {
                trackGa({
                  event: GaEventNames.selectContent,
                  content_type: 'referral link',
                  item_id: 'copy link',
                });
              }}
              text={url}
            />
            <TileButton
              component="a"
              target="_blank"
              onClick={() => {
                trackGa({
                  event: GaEventNames.selectContent,
                  content_type: 'referral link',
                  item_id: 'mail',
                });
              }}
              href={`mailto:?body=${shareMessage} ${encodeURIComponent(url)}`}
            >
              <HiOutlineMail />
            </TileButton>
            <TileButton
              component="a"
              target="_blank"
              onClick={() => {
                trackGa({
                  event: GaEventNames.selectContent,
                  content_type: 'referral link',
                  item_id: 'sms',
                });
              }}
              href={`sms:?body=${shareMessage} ${encodeURIComponent(url)}`}
            >
              <RiMessage2Line />
            </TileButton>
            <TileButton
              component="a"
              target="_blank"
              onClick={() => {
                trackGa({
                  event: GaEventNames.selectContent,
                  content_type: 'referral link',
                  item_id: 'twitter',
                });
              }}
              href={`https://twitter.com/intent/tweet?text=${shareMessage}&url=${encodeURIComponent(
                url
              )}`}
            >
              <RiTwitterLine />
            </TileButton>
            <TileButton
              component="a"
              target="_blank"
              onClick={() => {
                trackGa({
                  event: GaEventNames.selectContent,
                  content_type: 'referral link',
                  item_id: 'whats app',
                });
              }}
              href={`https://wa.me/?text=${shareMessage} ${encodeURIComponent(
                url
              )}`}
            >
              <RiWhatsappLine />
            </TileButton>
            <TileButton
              component="a"
              target="_blank"
              onClick={() => {
                trackGa({
                  event: GaEventNames.selectContent,
                  content_type: 'referral link',
                  item_id: 'telegram',
                });
              }}
              href={`https://t.me/share/url?text=${shareMessage}&url=${encodeURIComponent(
                url
              )}`}
            >
              <RiTelegramLine />
            </TileButton>
          </ButtonsContainer>
        </OptionsContainer>
      </DialogContent>
    </ResponsiveDialog>
  );
}

const PopoverPaper = styled(Paper)`
  padding: ${(p) => p.theme.spacing(0.5, 1)};
  background-color: ${colors.richBlue};

  .MuiTypography-root {
    color: ${colors.white};
  }
`;

const popoverDuration = 1000;

interface CopyToClipboardButtonProps {
  text: string;
  onCopy: () => void;
}

function CopyToClipboardButton({ text, onCopy }: CopyToClipboardButtonProps) {
  const popupState = usePopupState({ variant: 'popper', popupId: null });
  const handleEntered = useCallback(
    () => setTimeout(popupState.close, popoverDuration),
    [popupState.close]
  );
  return (
    <>
      <CopyToClipboard text={text} onCopy={onCopy}>
        <TileButton {...bindTrigger(popupState)}>
          <HiOutlineClipboardCopy />
        </TileButton>
      </CopyToClipboard>
      <Popper
        {...bindPopper(popupState)}
        transition
        placement={'bottom'}
        disablePortal
        style={{
          zIndex: 9999,
        }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} onEntered={handleEntered} timeout={350}>
            <PopoverPaper>
              <Typography>Copied!</Typography>
            </PopoverPaper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
