import { useRef } from 'react';
import { ResponsiveDialog, ResponsiveDialogProps } from '../ResponsiveDialog';
import { DepositForm } from './DepositForm';

interface DepositDialogProps extends ResponsiveDialogProps {
  accountId: string;
  paymentId?: string | null;
  isPolling?: boolean;
  returnPath: string;
}

export function DepositDialog({
  accountId,
  onClose,
  open,
  paymentId,
  isPolling = false,
  returnPath,
  ...props
}: DepositDialogProps) {
  const dialogRef = useRef<HTMLDivElement>(null);

  return (
    <ResponsiveDialog {...props} open={open} onClose={onClose} ref={dialogRef}>
      {open && (
        <DepositForm
          accountId={accountId}
          onClose={onClose}
          urlPaymentId={paymentId}
          isPolling={isPolling}
          returnPath={returnPath}
        />
      )}
    </ResponsiveDialog>
  );
}
