import { useMediaQuery } from '@material-ui/core';
import ImgMyDetails from 'assets/backgrounds/Tillit_Empower_crop.jpg';
import ImgUrl from 'assets/backgrounds/back_seaBlue_type.jpg';
import { Card, CardsCarousel } from 'components/CardsCarousel/CardsCarousel';
import { Container } from 'components/Container/Container';
import { AccountCard } from 'components/Dashboard/Accounts/Account';
import { DashboardNav, NavItem } from 'components/Dashboard/Nav/DashboardNav';
import { ReferralsCard } from 'components/Dashboard/Referrals/ReferralsCard';
import { TransferCard } from 'components/Dashboard/Transfer/TransferCard';
import { Seo } from 'components/Seo/Seo';
import { HeaderShim } from 'components/design-system/Header';
import TransfersSection from 'components/feature/Transfers/Section/TransfersSection';
import {
  AccountStatus,
  WrapperType,
  useUserProfileQuery,
} from 'generated/graphql';
import { useUpdateRootCSSVars } from 'hooks/useUpdateRootCSSVars';
import sortBy from 'lodash/sortBy';
import {
  dashBoardInvestmentCommitteePath,
  dashboardAccountAddCashPath,
  dashboardGiaPath,
  dashboardIsaPath,
  dashboardMyDetailsPath,
  dashboardMyDocumentsPath,
  dashboardOrders,
  dashboardPensionPath,
  dashboardReferralsPath,
  dashboardTransfersPath,
} from 'paths';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import { useTheme } from 'styled-components';
import {
  BackgroundContainer,
  HeroContainer,
  HeroInner,
  PageContainer,
  UserName,
} from './Styles/MyDashboard.styles';
import { Account as AccountSection } from './SubPages/Account';
import { ICReports } from './SubPages/ICReports';
import { MyDetails } from './SubPages/MyDetails';
import { MyDocuments } from './SubPages/MyDocuments';
import { DashboardOrders } from './SubPages/Orders';

type AccountIdWrapperTypeLookUpType = Partial<{ [key in WrapperType]: string }>;

interface DashboardItem_Base {
  label: string;
  wrapperType?: WrapperType;
  path?: string;
}

interface DashboardItem_Card extends DashboardItem_Base {
  displayCard: true;
  component: React.FunctionComponent<any>;
  props: any;
}

interface DashboardItem_Nav extends DashboardItem_Base {
  displayNav: true;
  navType?: string;
  value: string;
}

interface DashboardItem_Both extends DashboardItem_Card, DashboardItem_Nav {}

type DashboardItem =
  | DashboardItem_Both
  | DashboardItem_Card
  | DashboardItem_Nav;

function isDashboardItemCard(
  dashboardItem: DashboardItem
): dashboardItem is DashboardItem_Card {
  return (dashboardItem as DashboardItem_Card).displayCard === true;
}

function isDashboardItemNav(
  dashboardItem: DashboardItem
): dashboardItem is DashboardItem_Nav {
  return (dashboardItem as DashboardItem_Nav).displayNav === true;
}

export function MyDashboard() {
  const rootStyling = [
    {
      property: '--main-logo-color',
      value: 'black',
    },
    {
      property: '--main-header-color',
      value: 'black',
    },
  ];

  useUpdateRootCSSVars(rootStyling);
  const theme = useTheme();
  const userProfileQuery = useUserProfileQuery();
  const first = userProfileQuery.data?.userProfile?.first || '';
  const { page: dashboardPage } = useParams<{ page: string | undefined }>();
  const isAccountAddCashPath = useRouteMatch(dashboardAccountAddCashPath);
  const location = useLocation();
  const history = useHistory();
  const navRef = useRef<HTMLDivElement>(null);
  const [activeCard, setActiveCard] = useState(0);
  const [icReportViewed, setIcReportViewed] = useState(false);
  const isBelowTablet = useMediaQuery(theme.breakpoints.down('sm'));

  const handleCardClick = () => {
    navRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const dashboardItems: DashboardItem[] = useMemo(() => {
    const sippCard: DashboardItem = {
      displayCard: true,
      displayNav: true,
      label: 'Pension',
      wrapperType: WrapperType.Sipp,
      path: dashboardPensionPath,
      component: AccountCard,
      props: {
        accountType: WrapperType.Sipp,
        pathName: dashboardPensionPath,
        onClick: handleCardClick,
      },
    };

    const dashboardItemsUnsorted: DashboardItem[] = [
      sippCard,
      {
        displayCard: true,
        displayNav: true,
        label: 'ISA',
        wrapperType: WrapperType.Isa,
        path: dashboardIsaPath,
        component: AccountCard,
        props: {
          accountType: WrapperType.Isa,
          pathName: dashboardIsaPath,
          onClick: handleCardClick,
        },
      },
      {
        displayCard: true,
        displayNav: true,
        label: 'GIA',
        wrapperType: WrapperType.Gia,
        path: dashboardGiaPath,
        component: AccountCard,
        props: {
          accountType: WrapperType.Gia,
          pathName: dashboardGiaPath,
          onClick: handleCardClick,
        },
      },
      {
        displayCard: true,
        label: 'REFERRALS',
        component: ReferralsCard,
        path: dashboardReferralsPath,
        props: {},
      },
      {
        displayNav: true,
        path: dashboardMyDocumentsPath,
        label: 'My documents',
        value: 'my-documents',
      },
      {
        displayNav: true,
        path: dashboardMyDetailsPath,
        label: 'My details',
        value: 'my-details',
      },
      {
        displayNav: true,
        path: dashboardOrders,
        label: 'Orders',
        value: 'orders',
      },
    ];

    if (!!userProfileQuery.data?.clientSummary?.accounts?.length) {
      dashboardItemsUnsorted.push({
        displayNav: true,
        path: dashBoardInvestmentCommitteePath,
        label: 'IC reports',
        value: 'ic-reports',
      });
    }

    dashboardItemsUnsorted.push({
      displayCard: true,
      displayNav: true,
      label: 'Transfers',
      path: dashboardTransfersPath,
      component: TransferCard,
      props: { onClick: handleCardClick },
      navType: 'cta',
    });

    return sortBy(dashboardItemsUnsorted, [
      // Prefer active accounts (0 sorts earlier than 1)...
      (dashboardItem) =>
        userProfileQuery.data?.clientSummary?.accounts?.find(
          (account) =>
            account.wrapperType === dashboardItem.wrapperType &&
            account.status === AccountStatus.Active
        )
          ? 0
          : 1,
    ]);
  }, [userProfileQuery.data?.clientSummary?.accounts]);

  const navigationItems: NavItem[] = dashboardItems
    .filter(isDashboardItemNav)
    .map((dashboardItem) => ({
      path: dashboardItem.path!,
      label: dashboardItem.label,
      navType: dashboardItem.navType,
    }));

  const accountCards: Card[] = useMemo(
    () =>
      dashboardItems.filter(isDashboardItemCard).map((dashboardItem) => ({
        key: dashboardItem.label,
        path: dashboardItem.path,
        props: dashboardItem.props,
        component: dashboardItem.component,
        label: dashboardItem.label,
      })),
    [dashboardItems]
  );

  useEffect(() => {
    if (
      location.pathname === dashBoardInvestmentCommitteePath &&
      !icReportViewed
    ) {
      navRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setIcReportViewed(true);
    }
  }, [icReportViewed, location.pathname, navRef]);

  useEffect(() => {
    const index = accountCards.findIndex((c) => c.path === location.pathname);
    if (index > -1) {
      setActiveCard(index);
    }
  }, [accountCards, location.pathname]);

  useEffect(() => {
    if (
      !isAccountAddCashPath?.isExact &&
      dashboardPage === undefined &&
      userProfileQuery.isSuccess
    ) {
      history.replace(navigationItems[0].path);
    }
  }, [
    dashboardPage,
    userProfileQuery.isSuccess,
    history,
    location.pathname,
    navigationItems,
    isAccountAddCashPath?.isExact,
  ]);

  const handleCarouselChange = useCallback(
    (index: number) => {
      const card = accountCards[index];

      if (card.path) {
        history.push(card.path);
      } else {
        setActiveCard(index);
      }
    },
    [accountCards, history]
  );

  const accountIdLookUp: AccountIdWrapperTypeLookUpType = userProfileQuery.data
    ?.clientSummary
    ? userProfileQuery.data.clientSummary.accounts.reduce((acc, account) => {
        return { ...acc, [account.wrapperType]: account.id };
      }, {})
    : {};

  return (
    <>
      <Seo
        title="My Dashboard"
        description="Invest in funds picked by experts"
      />
      <HeroContainer>
        <HeaderShim />
        <BackgroundContainer backgroundImg={ImgUrl} />
        <HeroInner>
          <UserName>
            {`${first}${first.endsWith('s') ? "'" : "'s"} Dashboard`}
          </UserName>
        </HeroInner>
        <CardsCarousel
          activeCard={activeCard}
          onChange={handleCarouselChange}
          cards={accountCards}
        />
      </HeroContainer>

      <PageContainer>
        <DashboardNav ref={navRef} items={navigationItems} />
        <Switch>
          <Route path={dashboardGiaPath}>
            <AccountSection
              accountType={WrapperType.Gia}
              accountId={accountIdLookUp[WrapperType.Gia]}
              isBelowTablet={isBelowTablet}
            />
          </Route>
          <Route path={dashboardIsaPath}>
            <AccountSection
              accountType={WrapperType.Isa}
              accountId={accountIdLookUp[WrapperType.Isa]}
              isBelowTablet={isBelowTablet}
            />
          </Route>
          <Route path={dashboardTransfersPath}>
            <Container
              square={false}
              $elevation={3}
              $elevationBreakpoint={'sm'}
              removeHorizontalPadding={isBelowTablet}
              data-testid="dashboard-section--transfers"
            >
              <TransfersSection />
            </Container>
          </Route>
          <Route path={dashboardPensionPath}>
            <AccountSection
              accountType={WrapperType.Sipp}
              accountId={accountIdLookUp[WrapperType.Sipp]}
              isBelowTablet={isBelowTablet}
            />
          </Route>
          <Route path={dashboardMyDocumentsPath}>
            <Container
              padding="large"
              square={false}
              $elevation={3}
              $elevationBreakpoint={'sm'}
              removeHorizontalPadding={isBelowTablet}
              data-testid="dashboard-section--my-documents"
            >
              <MyDocuments />
            </Container>
          </Route>
          <Route path={[dashboardMyDetailsPath, dashboardReferralsPath]}>
            <Container
              padding="large"
              square={false}
              $elevation={3}
              $elevationBreakpoint={'sm'}
              background={ImgMyDetails}
              data-testid="dashboard-section--my-details"
            >
              <MyDetails />
            </Container>
          </Route>
          <Route path={dashboardOrders}>
            <Container
              padding="large"
              square={false}
              $elevation={3}
              $elevationBreakpoint={'sm'}
              removeHorizontalPadding
              data-testid="dashboard-section--rebalancing-orders"
            >
              <DashboardOrders />
            </Container>
          </Route>
          {!!userProfileQuery.data?.clientSummary?.accounts.length && (
            <Route path={dashBoardInvestmentCommitteePath}>
              <Container
                padding="large"
                square={false}
                $elevation={3}
                $elevationBreakpoint={'sm'}
                data-testid="dashboard-section--investment-committee"
              >
                <ICReports />
              </Container>
            </Route>
          )}
        </Switch>
      </PageContainer>
    </>
  );
}
