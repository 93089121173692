import { ServerError } from 'components/design-system/ServerError/ServerError';
import { AccountType } from 'generated/graphql';
import { useUpdateInitialAccountTypeMutation } from 'hooks/useUpdateInitialAccountTypeMutation';
import {
  StepActions,
  StepButton,
  StepContainer,
  StepContent,
  StepIntroduction,
  StepIntroductionTypography,
  StepIntroductionWidth,
  StepTitle,
} from '../../../../design-system/StepComponents/StepComponents';

interface CannotOpenStepProps {
  onProceed: () => void;
}

export function CannotOpenStep({ onProceed }: CannotOpenStepProps) {
  const {
    mutateAsync: updateInitialAccountTypeAsync,
    isError,
    isLoading,
  } = useUpdateInitialAccountTypeMutation();

  const handleContinue = async () => {
    await updateInitialAccountTypeAsync({
      input: {
        initialAccount: { value: AccountType.NotSpecified },
      },
    });
    onProceed();
  };

  return (
    <StepContainer>
      <StepContent>
        <StepTitle>We can't offer you a TILLIT Pension yet</StepTitle>

        <StepIntroduction mb={2} $width={StepIntroductionWidth.normal}>
          <StepIntroductionTypography>
            Due to tax rules, you cannot open or contribute to a Self-Invested
            Personal Pension like the TILLIT Pension if you are 75 or older.
          </StepIntroductionTypography>
        </StepIntroduction>
      </StepContent>
      {isError && <ServerError isVisible />}
      <StepActions>
        <StepButton
          type="submit"
          className="magenta"
          onClick={handleContinue}
          disableElevation={isLoading}
        >
          Dashboard
        </StepButton>
      </StepActions>
    </StepContainer>
  );
}
