import { LinkCustomButton } from 'components/design-system/Button/CustomButtonV2';
import {
  H3,
  TextAlign as HeadingAlign,
} from 'components/design-system/Heading/Heading';
import { Text, TextAlign } from 'components/design-system/Text/Text';
import { GaEventNames } from 'constants/gaConstants';
import { WrapperType } from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { generateDynamicPortfolioConstructionPath } from 'paths';
import React from 'react';
import {
  StepActions,
  StepContainer,
  StepContent,
  StepContentWidth,
} from '../../../../design-system/StepComponents/StepComponents';
import { DPCheckoutSteps } from '../../Checkout';
import { DpFlowTypes } from '../../flowType';

interface ISAAllowanceLimitProps {
  selectedAccountType: WrapperType;
  activeFlow: DpFlowTypes;
}

export function ISAAllowanceLimit({
  selectedAccountType,
  activeFlow,
}: ISAAllowanceLimitProps) {
  return (
    <StepContainer>
      <StepContent width={StepContentWidth.wide}>
        <H3 $textAlign={HeadingAlign.center}>
          Order value is above your ISA allowance.
        </H3>
        <Text $textAlign={TextAlign.center}>
          Your current order would require you to subscribe too much cash to
          your Stocks and Shares ISA in this tax year.
        </Text>
      </StepContent>
      <StepActions>
        <LinkCustomButton
          $size="normal"
          $color="secondary"
          $isWide
          to={() =>
            generateDynamicPortfolioConstructionPath({
              wrapperType: selectedAccountType.toLowerCase(),
            })
          }
          onClick={() => {
            trackGa({
              event: GaEventNames.checkoutEdit,
              content_type: 'cta button',
              item_id: 'edit order - isa allowance',
              checkoutStep: DPCheckoutSteps.isaAllowanceLimit,
              checkoutFlow: activeFlow,
            });
          }}
        >
          Edit order
        </LinkCustomButton>
      </StepActions>
    </StepContainer>
  );
}
