import { useMediaQuery, useTheme } from '@material-ui/core';
import { KeyValueRow } from 'components/KeyValueRow/KeyValueRow';
import { Card } from 'components/design-system/Card/Card';
import {
  FontSize,
  FontWeight,
  Text,
  TextAlign,
  TextNormal,
  TextSmall,
  TextXS,
} from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import * as format from 'formatting';
import { WrapperType } from 'generated/graphql';
import { useEffect, useState } from 'react';
import {
  GetAssetGroupString,
  IncludeAssetType,
  getAssetClassOfAsset,
  getBondStyleOfAsset,
  getEquityStyleOfAsset,
  getRegionOfAsset,
} from '../../hooks/useGetBreakdowns';
import {
  BasketBreakdownType,
  useGetBasketBreakdown,
} from '../../hooks/useGetBreakdowns/useGetBasketBreakdown';
import { CurrentStatBoxes } from '../CurrentAllocation/_shared/CurrentStatBoxes';
import {
  BreakdownGroupingSelect,
  BreakdownTabHeading,
  CardTableContainer,
  HoldingsTabInfo,
  HoldingsTabInner,
} from '../_shared/DrillDownTab.styles';
import { Table, Td } from '../_shared/Table.styles';

type grouping = 'region' | 'assetClass' | 'equityStyle' | 'bondStyle';

export interface GroupingOption {
  label: string;
  value: grouping;
}

interface BreakdownTabProps {
  selectedAccountType: WrapperType;
  includeAsset: IncludeAssetType;
  groupings: GroupingOption[];
  activeBreakdown: BasketBreakdownType;
}

interface BreakdownNameLookUp {
  [key: string]: string;
}
const breakdownNameLookUp: BreakdownNameLookUp = {
  region: 'Region',
  assetClass: 'Asset class',
  equityStyle: 'Equity style',
  bondStyle: 'Bond style',
};

interface BreakdownGroupingLookUp {
  [key: string]: GetAssetGroupString;
}
const lookUp: BreakdownGroupingLookUp = {
  region: getRegionOfAsset,
  assetClass: getAssetClassOfAsset,
  equityStyle: getEquityStyleOfAsset,
  bondStyle: getBondStyleOfAsset,
};

interface BasketOnlyBreakdownTableProps {
  breakdowns: BasketBreakdownType[];
  name: string;
}

const BasketOnlyBreakdownTable = ({
  breakdowns,
  name,
}: BasketOnlyBreakdownTableProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (isMobile) {
    return (
      <>
        {breakdowns.map(({ name, amount, percentage }) => (
          <Card>
            <Text
              $fontSize={FontSize.normal}
              $fontWeight={FontWeight.medium}
              $textAlign={TextAlign.left}
            >
              {name}
            </Text>
            <KeyValueRow
              label="Holding (%)"
              value={format.percent(percentage / 100)}
              fontSize={FontSize.xs}
              noMargin
            />
            <KeyValueRow
              label="Value (£)"
              value={format.currencyFull(amount)}
              fontSize={FontSize.xs}
              noMargin
              divider
              color={colors.grey}
            />
          </Card>
        ))}
      </>
    );
  } else {
    return (
      <CardTableContainer>
        <Table>
          <thead>
            <tr>
              <Td>
                <TextSmall $noMargin $fontWeight={FontWeight.medium}>
                  {name}
                </TextSmall>
              </Td>
              <Td>
                <Text
                  $fontSize={FontSize.small}
                  $fontWeight={FontWeight.medium}
                  $textAlign={TextAlign.right}
                  $noMargin
                >
                  Holding (%)
                </Text>
              </Td>
              <Td>
                <Text
                  $fontSize={FontSize.small}
                  $fontWeight={FontWeight.medium}
                  $textAlign={TextAlign.right}
                  $noMargin
                >
                  Value (£)
                </Text>
              </Td>
            </tr>
          </thead>
          {breakdowns.map(({ name, amount, percentage }) => (
            <tr>
              <Td>
                <TextXS $noMargin>{name}</TextXS>
              </Td>
              <Td>
                <Text
                  $fontSize={FontSize.xs}
                  $fontWeight={FontWeight.light}
                  $textAlign={TextAlign.right}
                  $noMargin
                >
                  {format.percent(percentage / 100)}
                </Text>
              </Td>
              <Td>
                <Text
                  $fontSize={FontSize.xs}
                  $fontWeight={FontWeight.light}
                  $textAlign={TextAlign.right}
                  $noMargin
                >
                  {format.currencyFull(amount)}
                </Text>
              </Td>
            </tr>
          ))}
        </Table>
      </CardTableContainer>
    );
  }
};

export const BasketOnlyBreakdown = ({
  selectedAccountType,
  includeAsset,
  groupings,
  activeBreakdown,
}: BreakdownTabProps) => {
  const [activeGrouping, setActiveGrouping] = useState<string>(
    groupings[0].value
  );

  useEffect(() => {
    setActiveGrouping(groupings[0].value);
  }, [groupings, setActiveGrouping]);

  const { basketBreakdowns: breakdowns } = useGetBasketBreakdown(
    null,
    lookUp[activeGrouping],
    includeAsset
  );

  return (
    <div>
      <BreakdownTabHeading>
        <TextNormal $noMargin>
          {breakdownNameLookUp[activeGrouping]} breakdown
        </TextNormal>
        {groupings.length > 1 && (
          <div>
            {groupings.map((grouping) => (
              <BreakdownGroupingSelect
                onClick={() => {
                  setActiveGrouping(grouping.value);
                }}
                $active={activeGrouping === grouping.value}
              >
                {grouping.label}
              </BreakdownGroupingSelect>
            ))}
          </div>
        )}
      </BreakdownTabHeading>

      <HoldingsTabInner>
        <BasketOnlyBreakdownTable
          name={breakdownNameLookUp[activeGrouping]}
          breakdowns={breakdowns}
        />
        <HoldingsTabInfo>
          <CurrentStatBoxes activeBreakdown={activeBreakdown} />
        </HoldingsTabInfo>
      </HoldingsTabInner>
    </div>
  );
};
