import { yupResolver } from '@hookform/resolvers/yup';
import {
  GoBackButton,
  StepActions,
  StepButton,
  StepFormContainer,
  StepIntroduction,
  StepIntroductionTypography,
  StepIntroductionWidth,
} from 'components/design-system/StepComponents/StepComponents';
import { colors } from 'constants/colors';
import { subtractYears } from 'helpers/dateHelpers';
import { useBackLinkPath } from 'pages/FundDetails/BackToResults';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { useTargetDateInfo } from '../hooks/useTargetDateInfo';
import {
  FormTextField,
  StepTitle,
  TargetDateFormContainer,
} from './TargetDateForm.styles';

export type TargetDateFormValues = {
  yearOfBirth: number;
  intendedRetirementAge: number;
};

const BackLinkStyles = {
  textAlign: 'center',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'none',
  },
  '&:focus': {
    outlineColor: colors.richBlue,
  },
};

const date = new Date();
const maxTargetDate = subtractYears(date, 18);
const maxTargetDateYear = maxTargetDate.getFullYear();

const targetDateFormSchema = Yup.object().shape({
  yearOfBirth: Yup.number()
    .label('Year of birth')
    .typeError(
      'Please enter a valid year of birth between 1957 and ' + maxTargetDateYear
    )
    .integer()
    .required()
    .min(1957)
    .max(maxTargetDateYear),
  intendedRetirementAge: Yup.number()
    .label('Intended retirement age')
    .typeError('Please enter a valid retirement age between 55 and 80')
    .integer()
    .required()
    .min(55)
    .max(80),
});

interface TargetDateFormProps {
  title?: string;
  $canGoBack?: boolean;
  $theme?: 'dark' | 'light';
  $editMode?: boolean;
  onProceed: (data: TargetDateFormValues) => void;
  onGoBack?: () => void;
}

export function TargetDateForm({
  title = 'Before that...',
  $canGoBack = true,
  $theme = 'dark',
  $editMode = false,
  onProceed,
  onGoBack,
}: TargetDateFormProps) {
  const location = useLocation();
  const backLink = useBackLinkPath(location);
  const { targetDateInfo, setTargetDateInfo } = useTargetDateInfo();

  const methods = useForm<TargetDateFormValues>({
    resolver: yupResolver(targetDateFormSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      yearOfBirth: targetDateInfo?.yearOfBirth ?? null!,
      intendedRetirementAge: targetDateInfo?.intendedRetirementAge ?? null!,
    },
  });

  const handleSubmit = (formData: TargetDateFormValues) => {
    if (!formData.yearOfBirth || !formData.intendedRetirementAge) {
      return;
    }
    setTargetDateInfo(formData);
    onProceed(formData);
  };

  return targetDateInfo?.yearOfBirth &&
    targetDateInfo?.intendedRetirementAge &&
    !$editMode ? (
    <></>
  ) : (
    <FormProvider {...methods}>
      <TargetDateFormContainer $theme={$theme}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <StepTitle>{title}</StepTitle>

          <StepIntroduction $width={StepIntroductionWidth.extraWide}>
            <StepIntroductionTypography>
              This fund is meant for retirement saving, and the investments it
              makes depend on the year you might retire.
            </StepIntroductionTypography>
            <StepIntroductionTypography>
              To show you the correct information, please let us know the year
              of your birth and your planned retirement age.
            </StepIntroductionTypography>
          </StepIntroduction>

          <StepFormContainer>
            <FormTextField
              name="yearOfBirth"
              label="Year of birth"
              $theme={$theme}
            />

            <FormTextField
              name="intendedRetirementAge"
              label="Intended retirement age"
              $theme={$theme}
            />
          </StepFormContainer>

          <StepActions>
            <StepButton
              type="submit"
              className={$theme === 'light' ? 'white' : 'magenta'}
            >
              Continue
            </StepButton>
            {$canGoBack && (
              <GoBackButton
                as={Link}
                to={backLink ? `${backLink.path}${backLink.queryPath}` : ''}
                onClick={() => {
                  onGoBack?.();
                }}
                style={BackLinkStyles}
              />
            )}
          </StepActions>
        </form>
      </TargetDateFormContainer>
    </FormProvider>
  );
}
