import {
  Checkbox,
  ClickAwayListener,
  FormControlLabel,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import { Text } from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { useRef, useState } from 'react';
import { BsPinFill } from 'react-icons/bs';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';
import styled from 'styled-components';

const FilterHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  background-color: ${colors.vapor};
  gap: 0.25rem;
  justify-content: space-between;
  flex-direction: column;

  ${(p) => p.theme.breakpoints.up('md')} {
    flex-direction: row;
  }
`;

const FilterOption = styled(FormControlLabel)`
  padding: 0 0.25rem;
  margin: 0;

  .MuiFormControlLabel-label {
    font-size: 0.875rem;
    font-weight: 400;
  }

  .Mui-checked + .MuiFormControlLabel-label {
    color: ${colors.magenta};
  }

  :hover {
    background-color: ${colors.vapor};
    color: ${colors.white};
  }
`;

interface FilterButtonProps {
  $isActive: boolean;
  $variant: 'portfolio-builder' | 'list' | 'slider';
}

const FilterOptionsWrapper = styled.div`
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
`;

const FilterOptions = styled.div`
  padding: 0 0 1rem 0;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
`;

const FilterButton = styled.button<FilterButtonProps>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: none;
  font-size: 1rem;
  font-weight: 300;
  background-color: ${colors.white};
  border: none;
  border-bottom: 1px solid;
  padding: ${({ $variant }) =>
    $variant === 'list' ? ` 1rem 0.75rem 1rem 0` : ` 1rem 0.75rem`};
  cursor: pointer;
  ${({ $isActive }) => `
    border-color: ${$isActive ? colors.magenta : colors.lightGrey};
    color: ${$isActive ? colors.magenta : 'inherit'};
  `}
`;

interface FilterMenuProps {
  name: string;
  label: string;
  value: string[];
  options: {
    value: string;
    label: string;
    totalCount: number;
    disabled?: boolean;
  }[];
  multiple?: boolean;
  onChange: (
    name: string,
    value: string[],
    label: string,
    selectedOptions: string[]
  ) => void;
  variant: 'portfolio-builder' | 'list' | 'slider';
}

export function FilterMenu({
  name,
  label,
  value,
  options,
  onChange,
  variant,
}: FilterMenuProps) {
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const popperState = usePopupState({ variant: 'popper', popupId: null });

  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleClose = () => {
    popperState.close();
  };

  const clearAll = () => {
    onChange(name, [], label, []);
  };

  const selectAll = () => {
    onChange(
      name,
      options.map((o) => o.value),
      label,
      options.map((o) => o.label)
    );
  };

  const handleSelect = (selected: string) => {
    if (
      value?.some(
        (activeValue) =>
          activeValue === selected || activeValue === `+${selected}`
      )
    ) {
      const selectedOptionValues = value.filter((v) => v !== selected);
      onChange(
        name,
        selectedOptionValues,
        label,
        options
          .filter(({ value }) => selectedOptionValues.includes(value))
          .map(({ label }) => label)
      );
    } else {
      const selectedOptionValues = [...value, selected];
      onChange(
        name,
        selectedOptionValues,
        label,
        options
          .filter(({ value }) => selectedOptionValues.includes(value))
          .map(({ label }) => label)
      );
    }
  };

  const hasValue = !!value?.length;

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <FilterButton
          ref={anchorRef}
          $isActive={hasValue}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          $variant={variant}
        >
          {label}
          {isOpen ? <HiChevronUp /> : <HiChevronDown />}
        </FilterButton>

        <FilterOptionsWrapper
          style={{
            maxHeight: isOpen ? `${42 * (options.length + 1) + 16 + 32}px` : 0,
          }}
        >
          <FilterHeader>
            {isMdUp && variant === 'list' && (
              <Text $noMargin>{value?.length ?? 0} Selected</Text>
            )}
            {hasValue ? (
              <CustomButtonV2
                variant="outlined"
                onClick={clearAll}
                $size="extraSmall"
                $color="secondary"
                disabled={!isOpen}
              >
                Clear
              </CustomButtonV2>
            ) : (
              <CustomButtonV2
                variant="outlined"
                $size="extraSmall"
                $color="secondary"
                onClick={selectAll}
                disabled={!isOpen}
              >
                All
              </CustomButtonV2>
            )}
          </FilterHeader>
          <FilterOptions>
            {options.map((option, i) => {
              const valueMatch = value?.find(
                (v) => v.replace('+', '') === option.value
              );
              const isSelected = !!valueMatch;
              const isImportant = valueMatch?.startsWith('+');
              return (
                <FilterOption
                  key={option.value}
                  control={
                    <Checkbox
                      name="ageAssertion"
                      checked={isSelected}
                      onClick={() => handleSelect(option.value)}
                      autoFocus={i === 0}
                      disabled={!isOpen}
                    />
                  }
                  label={
                    <span
                      style={{
                        display: 'flex',
                        gap: '0.5rem',
                        alignItems: 'center',
                      }}
                    >
                      {option.label}
                      {isImportant && (
                        <BsPinFill title="Tag always included in search results" />
                      )}
                    </span>
                  }
                />
              );
            })}
          </FilterOptions>
        </FilterOptionsWrapper>
      </div>
    </ClickAwayListener>
  );
}
