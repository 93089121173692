import { IconButton } from '@material-ui/core';
import { QueryState } from 'components/QueryState';
import {
  ButtonWrapper,
  LinkCustomButton,
} from 'components/design-system/Button/CustomButtonV2';
import { InfoPopoverV2 } from 'components/design-system/InfoPopoverV2/InfoPopoverV2';
import { TextSmall } from 'components/design-system/Text/Text';
import { currencyFull, percent3dp } from 'formatting';
import { useUserProfileQuery } from 'generated/graphql';
import {
  getPathSegmentForWrapperType,
  getShortNameForWrapperType,
} from 'helpers/accountHelpers';
import max from 'lodash/max';
import {
  autoSaveInvestPath,
  autoSaveInvestPathAddFundsPath,
  autoSaveInvestPathMyFundsPath,
  generateAutoSaveInvestPathPath,
  generateAutoSaveInvestSubPath,
} from 'paths';
import { useState } from 'react';
import { FaChevronUp, FaShoppingBasket } from 'react-icons/fa';
import { Route, Switch } from 'react-router-dom';
import type { UserProfileQueryClientSummaryAccount } from 'types/graphqlTypes';
import { useAutoSaveInvestState } from '../autoSaveInvest/AutoSaveInvestContext';
import { MultipleDepositsStepDialog } from '../autoSaveInvest/regularInvest/ManageDepositsDialog';
import { CashLink, KeyValue, SummaryNumbers } from './ModeFooter.styles';
import {
  FooterMobileLayout,
  FooterMobileSummaryLayout,
} from './ModeOrderFooter.styles';
import {
  FooterLayout,
  ManageDepositButton,
  OverAllocatedMsg,
} from './ModeRecurringFooter.styles';

interface ModeRecurringMobileSummaryFooterProps {
  account: UserProfileQueryClientSummaryAccount;
  onOpen?: () => void;
}

export function ModeRecurringMobileFooter({
  account,
  onOpen,
}: ModeRecurringMobileSummaryFooterProps) {
  const { state, totalAllocationAmount } = useAutoSaveInvestState(account.id);

  return (
    <FooterMobileLayout>
      <IconButton onClick={onOpen}>
        <FaChevronUp size="1.5rem" />
      </IconButton>

      <div>
        <div>
          {getShortNameForWrapperType(account.wrapperType)} Regular Order
        </div>
        <div>
          {currencyFull(totalAllocationAmount)}
          {(state?.orders?.length ?? 0) > 0 && ` (${state?.orders?.length})`}
        </div>
      </div>
      <LinkCustomButton
        type="button"
        color="secondary"
        to={generateAutoSaveInvestSubPath({
          wrapperType: getPathSegmentForWrapperType(account.wrapperType),
          action: 'confirm',
        })}
      >
        Continue
      </LinkCustomButton>
    </FooterMobileLayout>
  );
}

export function ModeRecurringMobileSummaryFooter({
  account,
}: ModeRecurringMobileSummaryFooterProps) {
  const { state, totalAllocationAmount } = useAutoSaveInvestState(account.id);

  return (
    <FooterMobileSummaryLayout>
      <FaShoppingBasket size="1.5rem" />
      <div>
        <div>
          {getShortNameForWrapperType(account.wrapperType)} Regular order
        </div>
        <div>
          {currencyFull(totalAllocationAmount)}
          {(state?.orders?.length ?? 0) > 0 && ` (${state?.orders?.length})`}
        </div>
      </div>
    </FooterMobileSummaryLayout>
  );
}

interface ModeRecurringFooterProps {
  account: UserProfileQueryClientSummaryAccount;
  onViewOrder?: () => void;
}

export function ModeRecurringFooter({
  account,
  onViewOrder,
}: ModeRecurringFooterProps) {
  const userProfileQuery = useUserProfileQuery();

  const {
    state,
    latestEmployerContribution,
    totalDepositAmount,
    totalAllocationAmount,
    totalAllocationProportion,
  } = useAutoSaveInvestState(account.id);

  const deposits = state?.deposits ?? [];

  const totalAllocationPercentage = totalAllocationProportion * 100;
  const cashAmount = max([
    totalDepositAmount - totalAllocationAmount,
    0,
  ]) as number;

  const overAllocatedAmount = totalAllocationAmount - totalDepositAmount;
  const remainingToAllocatePercentage = 100 - totalAllocationPercentage;
  const remainingToAllocationAmount =
    totalDepositAmount - totalAllocationAmount;

  const [showMultiDeposits, setShowMultiDeposits] = useState<boolean>(false);

  return (
    <QueryState {...userProfileQuery}>
      {() => {
        return (
          <>
            {showMultiDeposits && (
              <MultipleDepositsStepDialog
                account={account}
                open
                closeDialog={() => {
                  setShowMultiDeposits(false);
                }}
                onProceed={() => {
                  setShowMultiDeposits(false);
                }}
              />
            )}

            <FooterLayout>
              <SummaryNumbers>
                {overAllocatedAmount > 0 && (
                  <OverAllocatedMsg>
                    <KeyValue
                      label="Shortfall"
                      value={currencyFull(overAllocatedAmount)}
                    />
                  </OverAllocatedMsg>
                )}
                <KeyValue
                  label={
                    latestEmployerContribution !== null ? (
                      <>
                        Est. monthly deposit
                        <InfoPopoverV2
                          color="default"
                          $width="wide"
                          placement={'top'}
                          size="small"
                          content={
                            <>
                              <TextSmall $noMargin>
                                This includes an estimate of how much your
                                employer pays in each month, based on the last
                                payment they made
                              </TextSmall>
                            </>
                          }
                        />
                      </>
                    ) : (
                      'Monthly deposit'
                    )
                  }
                  $needsAttention={totalDepositAmount === 0}
                  value={
                    deposits.length === 0 &&
                    latestEmployerContribution === null ? (
                      <>
                        {currencyFull(totalDepositAmount)}{' '}
                        <CashLink
                          to={{
                            pathname: generateAutoSaveInvestSubPath({
                              wrapperType: getPathSegmentForWrapperType(
                                account.wrapperType
                              ),
                              action: 'create-deposit',
                            }),
                            state: { from: 'footer' },
                          }}
                          $needsAttention={totalDepositAmount === 0}
                        >
                          Add deposit
                        </CashLink>
                      </>
                    ) : (
                      <>
                        {currencyFull(totalDepositAmount)}{' '}
                        <ManageDepositButton
                          $needsAttention={totalDepositAmount === 0}
                          onClick={() => {
                            setShowMultiDeposits(true);
                          }}
                        >
                          Manage
                        </ManageDepositButton>
                      </>
                    )
                  }
                />

                <KeyValue
                  label="Allocated to funds"
                  value={
                    <>
                      {currencyFull(totalAllocationAmount)} (
                      {percent3dp(
                        totalAllocationPercentage / 100,
                        false,
                        false
                      )}
                      )
                    </>
                  }
                />
                <KeyValue
                  label="Allocated to cash"
                  value={
                    <>
                      {cashAmount > 0
                        ? currencyFull(remainingToAllocationAmount)
                        : '0%'}{' '}
                      (
                      {cashAmount > 0
                        ? percent3dp(
                            remainingToAllocatePercentage / 100,
                            false,
                            false
                          )
                        : '£0'}
                      )
                    </>
                  }
                />
              </SummaryNumbers>

              <ButtonWrapper $direction="row" $noMargin>
                <Switch>
                  <Route path={[autoSaveInvestPath]} exact>
                    <div id="mode-footer--action"></div>
                  </Route>
                  <Route
                    path={[
                      autoSaveInvestPathAddFundsPath,
                      autoSaveInvestPathMyFundsPath,
                    ]}
                  >
                    <LinkCustomButton
                      type="button"
                      color="secondary"
                      to={generateAutoSaveInvestSubPath({
                        wrapperType: getPathSegmentForWrapperType(
                          account.wrapperType
                        ),
                        action: 'confirm',
                      })}
                    >
                      Continue
                    </LinkCustomButton>
                  </Route>
                  <Route>
                    <LinkCustomButton
                      type="button"
                      color="secondary"
                      to={generateAutoSaveInvestPathPath({
                        wrapperType: getPathSegmentForWrapperType(
                          account.wrapperType
                        ),
                      })}
                      onClick={() => onViewOrder?.()}
                    >
                      View regular order
                    </LinkCustomButton>
                  </Route>
                </Switch>
              </ButtonWrapper>
            </FooterLayout>
          </>
        );
      }}
    </QueryState>
  );
}
