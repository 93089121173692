import { DialogActions, DialogContent, Typography } from '@material-ui/core';
import {
  ButtonWrapper,
  CustomButtonV2,
} from 'components/design-system/Button/CustomButtonV2';
import { StepTitle } from 'components/design-system/StepComponents/StepComponents';
import { CashDepositTransferDetails } from 'generated/graphql';
import React from 'react';
import { PaymentSummary } from '../PaymentSummary';

interface SummaryStepProps {
  amount: number;
  transferDetails: CashDepositTransferDetails;
  onDeferred: () => void;
  onConfirmed: () => void;
}

export function SummaryStep({
  amount,
  transferDetails,
  onDeferred,
  onConfirmed,
}: SummaryStepProps) {
  return (
    <>
      <StepTitle>Make your transfer</StepTitle>
      <DialogContent>
        <Typography>
          Create your bank transfer using the details below. Please make sure
          that the amount and reference match.
        </Typography>

        <PaymentSummary amount={amount} transferDetails={transferDetails} />
      </DialogContent>
      <DialogActions>
        <ButtonWrapper $align="center">
          <CustomButtonV2
            $color="secondary"
            fullWidth
            onClick={() => onConfirmed()}
          >
            I’ve made my transfer
          </CustomButtonV2>
          <CustomButtonV2
            $color="secondary"
            fullWidth
            onClick={() => onDeferred()}
          >
            I’ll make it later
          </CustomButtonV2>
        </ButtonWrapper>
      </DialogActions>
    </>
  );
}
