import { GoalLandingMenu } from 'components/Landing/GoalLanding/GoalLandingMenu';
import { useState } from 'react';
import styled from 'styled-components';

import { H6, TextAlign } from 'components/design-system/Heading/Heading';
import { LandingMenu } from 'components/Landing/HexLanding/LandingMenu';
import { colors } from 'constants/colors';
import { GaEventNames } from 'constants/gaConstants';
import { AnimatePresence } from 'framer-motion';
import { trackGa } from 'helpers/track';
import { transparentize } from 'polished';
import { LandingMenuWrapper } from './SliderLanding.styles';

type LandingMenus = 'goal' | 'filters';

const LandingMenusNavContainer = styled.div`
  margin: 0.5rem 0;
  align-self: center;
  display: flex;
  flex-direction: column;
  width: auto;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1rem;

  > div {
    display: flex;
    gap: 2rem;
  }

  &-lock {
    display: none;
  }
`;

const StyledH6 = styled(H6)`
  color: ${colors.magenta};
`;

const LandingMenuNav = styled.button`
  cursor: pointer;
  border: none;
  width: auto;
  height: auto;
  border-radius: 0.25rem;
  padding: 0.25rem 0;
  background-color: white;
  font-size: 0.875rem;
  transition: color 0.4s ease-in-out;
  text-transform: uppercase;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    background-color: ${transparentize(0.75, colors.magenta)};
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    transform: rotateY(90deg);
    background-color: ${colors.magenta};
    transition: transform 0.4s ease-in-out;
  }

  &.active,
  &:focus {
    outline: none;
    position: relative;
    text-decoration: none;
    &:after {
      background-color: ${colors.richBlack};
      transform: rotateY(0deg);
    }
  }

  &:hover {
    position: relative;
    text-decoration: none;
    color: ${colors.magenta};
    &:after {
      background-color: ${colors.magenta};
      transform: rotateY(0deg);
    }
  }

  &:visited {
    color: inherit;
  }
`;

export function SliderLanding() {
  const [firstAnimationComplete, setFirstAnimationComplete] = useState(false);
  const [activeLandingMenu, setActiveLandingMenu] = useState<LandingMenus>(
    'goal'
  );

  return (
    <>
      <LandingMenusNavContainer>
        <StyledH6 $textAlign={TextAlign.center} $noMargin>
          Find funds by
        </StyledH6>
        <div>
          <LandingMenuNav
            className={activeLandingMenu === 'goal' ? 'active' : ''}
            onClick={() => {
              setActiveLandingMenu('goal');
              trackGa({
                event: GaEventNames.selectContent,
                content_type: 'landing slider',
                item_id: 'investment goal button clicked',
              });
            }}
          >
            Investment goal
          </LandingMenuNav>
          <LandingMenuNav
            className={activeLandingMenu === 'filters' ? 'active' : ''}
            onClick={() => {
              setActiveLandingMenu('filters');
              trackGa({
                event: GaEventNames.selectContent,
                content_type: 'landing slider',
                item_id: 'investment style button clicked',
              });
            }}
          >
            Investment style
          </LandingMenuNav>
        </div>
      </LandingMenusNavContainer>

      <div
        style={{
          position: 'relative',
          minHeight: '1100px',
          width: '1280px',
          maxWidth: '100%',
        }}
      >
        <AnimatePresence>
          {activeLandingMenu === 'goal' && (
            <LandingMenuWrapper
              key="goal"
              $animateFrom={firstAnimationComplete ? 'left' : 'right'}
              onAnimationComplete={() => {
                setFirstAnimationComplete(true);
              }}
            >
              <GoalLandingMenu />
            </LandingMenuWrapper>
          )}
          {activeLandingMenu === 'filters' && (
            <LandingMenuWrapper key="filters" $animateFrom="right">
              <div style={{ width: '100%' }}>
                <LandingMenu activeCat="" />
              </div>
            </LandingMenuWrapper>
          )}
        </AnimatePresence>
      </div>
    </>
  );
}
