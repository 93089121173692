import { Typography } from '@material-ui/core';
import { CustomButton } from 'components/Button/CustomButton';
import { GaEventNames } from 'constants/gaConstants';
import { WrapperType } from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { useIntercom } from 'react-use-intercom';
import { ActionContainer, DialogTitle } from './SecondAccount.styles';

interface ErrorStepProps {
  accountType: WrapperType;
  onClose: () => void;
}

export function ErrorStep({ accountType, onClose }: ErrorStepProps) {
  const title =
    accountType === WrapperType.Isa
      ? 'Stocks & Shares ISA'
      : 'General Investment Account';
  const { showNewMessages } = useIntercom();

  const handleSupport = () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'talk to support',
      item_id: `second account dialog - ${accountType} error step`,
    });
    showNewMessages(`I am having trouble opening a ${title}.`);
    onClose();
  };

  return (
    <>
      <DialogTitle variant="subtitle1" align="center">
        We need more information
      </DialogTitle>
      <Typography align="center">
        Before we can open your new {title} we need more information. Please
        contact support.
      </Typography>

      <ActionContainer>
        <CustomButton
          className="richBlue"
          fullWidth
          style={{
            whiteSpace: 'break-spaces',
          }}
          onClick={handleSupport}
        >
          Talk to support
        </CustomButton>
      </ActionContainer>
    </>
  );
}
