import { StartTransferForm } from 'components/feature/Transfers/startTransferForm';
import { AccountType } from 'generated/graphql';
import { deriveWrapperTypeFromAccountType } from 'helpers/AccountTypeWrapperTypeHelpers';

interface TransferStepProps {
  accountType: AccountType;
  onProceed: () => void;
}

export function TransferStep({ accountType, onProceed }: TransferStepProps) {
  return (
    <StartTransferForm
      wrapperType={deriveWrapperTypeFromAccountType(accountType)}
      onProceed={() => {
        onProceed();
      }}
      source="openAccountStep"
    />
  );
}
