import { IconButton, Link, Typography } from '@material-ui/core';
import { colors } from 'constants/colors';
import { FiRefreshCcw } from 'react-icons/fi';
import { animated } from 'react-spring';
import styled, { css } from 'styled-components';

interface AdditionalProps {
  $backgroundColor: string;
}

export const RecurringIcon = styled(FiRefreshCcw)`
  color: ${colors.richBlack};
  margin-left: 0.125rem;
`;

export const Container = styled(animated.div)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: hidden;
  min-width: 100%;
  min-height: 100%;
  align-items: stretch;
  border-radius: 5px;
`;

interface TextColorProps {
  isDarkUniverse: boolean;
}

const textColor = css<TextColorProps>`
  &,
  & a,
  & a:hover {
    color: ${({ isDarkUniverse }) =>
      isDarkUniverse ? colors.white : colors.richBlack};
  }
`;

export const FundName = styled(Typography)<TextColorProps>`
  ${textColor}
  font-family: 'GT Super', serif;
  font-size: 0.75rem;
  font-weight: 500;

  text-align: left;

  display: flex;
  align-items: center;
  gap: 0.5rem;

  a {
    text-decoration: none;

    display: flex;
    align-items: center;
    gap: 0.5rem;
    &:hover {
      text-decoration: underline;
      color: ${colors.richBlack};
    }
  }

  ${(p) => p.theme.breakpoints.up('sm')} {
    font-size: 1.125rem;
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    border-radius: 1.5rem;
  }
`;

export const FundDescription = styled(Typography)`
  font-family: 'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif;
  font-style: italic;
  font-size: 0.75rem;
  font-weight: 300;
  color: ${colors.richBlack};
  text-align: left;
  margin-top: ${(p) => p.theme.spacing(0.5)}px;

  ${(p) => p.theme.breakpoints.up('sm')} {
    margin-top: ${(p) => p.theme.spacing(1.25)}px;
    font-size: 0.875rem;
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    margin-top: ${(p) => p.theme.spacing(1)}px;
    font-size: 1rem;
  }
`;

export const Main = styled(animated.div)<AdditionalProps>`
  flex: 1 1 auto;
  padding: ${(p) => p.theme.spacing(1.25)}px;
  background-color: ${(p) => p.$backgroundColor};
  position: relative;
  min-height: 100%;

  ${(p) => p.theme.breakpoints.up('md')} {
    border-radius: 5px;
  }
`;

export const TabTitle = styled(Typography)`
  font-family: 'GT Super', serif;
  text-align: center;
  font-weight: 200;
  color: ${colors.richBlue};
  font-size: 1.125rem;
  margin-bottom: ${(p) => p.theme.spacing(2)}px;

  ${(p) => p.theme.breakpoints.up('sm')} {
    font-size: 1.125rem;
    margin-bottom: ${(p) => p.theme.spacing(3)}px;
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    border-radius: 1.5rem;
  }
`;

export const CardSummary = styled(animated.div)`
  position: relative;
  width: 100%;
  cursor: pointer;
  z-index: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: auto;

  .open & {
    z-index: 0;
  }

  & > div {
    flex: 1 1 auto;
  }

  & > svg {
    display: none;
    flex: 0 0 auto;

    ${(p) => p.theme.breakpoints.up('md')} {
      display: block;
    }
  }
`;

export const PendingOrdersIndicator = styled.span`
  display: inline-block;
  position: relative;
  width: 0.3125rem;
  height: 0.3125rem;
  background-color: ${colors.magenta};
  border-radius: 50%;
  margin-right: 0.25rem;
  top: -0.1rem;

  ${(p) => p.theme.breakpoints.up('sm')} {
    top: 0;
    width: 0.875rem;
    height: 0.875rem;
  }
`;

export const CardDetails = styled(animated.div)`
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 0;

  .open & {
    z-index: 1;
  }
`;

const Supplement = styled.div<AdditionalProps>`
  flex: 0 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${(p) => p.theme.spacing(1.25)}px;
  background-color: ${(p) => p.$backgroundColor};

  ${(p) => p.theme.breakpoints.up('md')} {
    border-radius: 5px;
    margin-left: ${(p) => p.theme.spacing(1.25)}px;
    min-width: 130px;
  }
  ${(p) => p.theme.breakpoints.up('lg')} {
    min-width: 180px;
  }
`;

export const Value = styled.p`
  font-size: 0.875rem;
  font-weight: 500;
  text-align: center;
  margin: 0;

  ${(p) => p.theme.breakpoints.up('sm')} {
    font-size: 1rem;
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    font-size: 1.25rem;
  }

  ${(p) => p.theme.breakpoints.up('lg')} {
    font-size: 1.5rem;
  }
`;

export const Label = styled.p`
  font-size: 0.5rem;
  font-weight: 300;
  text-transform: uppercase;
  text-align: center;
  margin: 0;

  .MuiIconButton-root {
    font-size: 0.7rem;
    margin-left: 2px;
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    font-size: 0.625rem;

    .MuiIconButton-root {
      font-size: 0.825rem;
    }
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    font-size: 0.75rem;

    .MuiIconButton-root {
      font-size: 0.95rem;
    }
  }
`;

export const TotalReturn = styled(Supplement)<TextColorProps>`
  ${textColor}
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  ${Label} {
    display: none;

    ${(p) => p.theme.breakpoints.up('md')} {
      display: block;
    }
  }
`;

export const CurrentValue = styled(Supplement)<TextColorProps>`
  ${textColor}
  display: none;

  ${(p) => p.theme.breakpoints.up('md')} {
    display: flex;
  }
`;

export const Actions = styled.div`
  display: none;
  flex-direction: column;
  justify-content: center;
  margin-left: ${(p) => p.theme.spacing(1.25)}px;
  min-width: 120px;

  ${(p) => p.theme.breakpoints.up('md')} {
    display: flex;
  }

  & > .MuiButton-root + .MuiButton-root {
    margin-top: ${(p) => p.theme.spacing(0.5)}px;
  }
`;

export const HeaderActions = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: ${(p) => p.theme.spacing(1.25)}px;

  * {
    flex: 1;
    &:last-child {
      margin-left: ${(p) => p.theme.spacing(1.5)}px;
    }
    &:first-child {
      margin-left: 0;
    }
  }

  ${(p) => p.theme.breakpoints.up('sm')} {
    width: 50%;
    margin-top: ${(p) => p.theme.spacing(4.5)}px;
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    width: 35%;
    margin-top: ${(p) => p.theme.spacing(2.5)}px;
  }
`;

export const CardHeader = styled(animated.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: ${(p) => p.theme.spacing(1.25)}px;
  padding-right: ${(p) => p.theme.spacing(1.25)}px;

  ${FundName} {
    text-align: center;
    font-size: 1.275rem;
  }
  ${FundDescription} {
    text-align: center;
    //font-size: 0.75rem;
    margin-top: ${(p) => p.theme.spacing(1.25)}px;
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    padding-left: ${(p) => p.theme.spacing(12.5)}px;
  }
`;

export const CloseButton = styled(animated(IconButton))`
  color: ${colors.white};
  background-color: ${colors.richBlack};
  font-size: 14px;
  padding: 0;
  position: absolute;
  top: ${(p) => p.theme.spacing(1.25)}px;
  right: ${(p) => p.theme.spacing(3.75)}px;

  ${(p) => p.theme.breakpoints.up('sm')} {
    font-size: 24px;
    top: ${(p) => p.theme.spacing(2.5)}px;
    right: ${(p) => p.theme.spacing(2.5)}px;
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    top: ${(p) => p.theme.spacing(5)}px;
    right: ${(p) => p.theme.spacing(5)}px;
  }

  &:hover,
  &:active {
    background-color: ${colors.richBlack};
  }
`;

export const TabSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  .MuiTabs-root {
    flex-shrink: 0;
    margin-top: ${(p) => p.theme.spacing(2.5)}px;
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    flex-direction: row;
    justify-content: space-between;

    .MuiTabs-root {
      margin-top: ${(p) => p.theme.spacing(5)}px;
      width: auto;
    }
  }
`;

export const ContentContainer = styled.div`
  background-color: ${colors.white};
  padding: ${(p) => p.theme.spacing(2.5, 2.5, 2.5)};
  margin-top: ${(p) => p.theme.spacing(1.25)}px;

  ${(p) => p.theme.breakpoints.up('md')} {
    min-height: 500px;
    margin-top: ${(p) => p.theme.spacing(5)}px;
    margin-left: ${(p) => p.theme.spacing(5)}px;
    flex-grow: 1;
    min-width: 0;
  }
`;

export const DownloadIconWrapper = styled.div`
  margin-left: ${(p) => p.theme.spacing(1)}px;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background-color: transparent;
  color: ${colors.richBlue};
  border-radius: 50%;
  font-size: 1.35rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DocumentLink = styled(Link).attrs({
  underline: 'none',
})`
  color: ${colors.richBlack};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.white};
  padding: ${(p) => p.theme.spacing(0.5, 0.5, 0.5, 1.5)};

  &:hover ${DownloadIconWrapper} {
    background-color: ${colors.richBlue};
    color: ${colors.white};
  }
`;

export const DocumentsContainer = styled.div`
  margin-top: ${(p) => p.theme.spacing(2.5)}px;

  ${(p) => p.theme.breakpoints.up('md')} {
    padding-left: ${(p) => p.theme.spacing(12.5)}px;
  }

  ${(p) => p.theme.breakpoints.up('sm')} {
    margin-top: ${(p) => p.theme.spacing(5)}px;
    display: flex;
    align-items: stretch;
    justify-content: center;

    ${DocumentLink} {
      flex: 1 1 calc(100% - ${(p) => p.theme.spacing(4)}px);
      max-width: 100%;
    }

    ${DocumentLink} + ${DocumentLink} {
      margin-left: ${(p) => p.theme.spacing(4)}px;
    }
  }

  ${(p) => p.theme.breakpoints.down('xs')} {
    ${DocumentLink} + ${DocumentLink} {
      margin-top: ${(p) => p.theme.spacing(1)}px;
    }
  }
`;
