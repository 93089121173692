import { LocationKeyedSwitch } from 'components/LocationKeyedSwitch';
import { SlideIn } from 'components/animations/SlideIn';
import { CashDepositTransferDetails } from 'generated/graphql';
import { Route } from 'react-router-dom';
import { AcknowledgmentStep } from './AcknowledgmentStep';
import { CancelledStep } from './CancelledStep';
import { CompletedStep } from './CompletedStep';
import { FailedStep } from './FailedStep';
import { NewPaymentStep } from './NewPaymentStep';
import { ProcessingStep } from './ProcessingStep';
import { SelectProviderStep } from './SelectProviderStep';
import { TemporaryUnavailable } from './TemporaryUnavailable';
import { TransferringStep } from './TransferringStep';
import { WaitingPayment } from './WaitingPayment';
import { WaitingPaymentTimeOut } from './WaitingPaymentTimeOut';

interface TrueLayerRoutesProps {
  amount: number;
  transferDetails: CashDepositTransferDetails;
  onClose: () => void;
  onContactSupport: () => void;
  onBrowseFunds: () => void;
  onDashboard: () => void;
  onTryAgain: () => void;
  onRetryPopup: () => void;
  waitingForCallback: boolean;
  onPayManually: () => void;
  onTryLater: () => void;
  onProviderSelected: (
    manual: boolean,
    afterPopup?: () => Promise<void> | void
  ) => void;
  showServerError: boolean;
  onScrollToTop: () => void;
  proceedOnComplete: boolean;
  autoInvested: boolean;
}

export function TrueLayerRoutes({
  amount,
  transferDetails,
  onClose,
  onContactSupport,
  onBrowseFunds,
  onDashboard,
  onTryAgain,
  onRetryPopup,
  waitingForCallback,
  onPayManually,
  onTryLater,
  onProviderSelected,
  showServerError,
  onScrollToTop,
  proceedOnComplete,
  autoInvested,
}: TrueLayerRoutesProps) {
  return (
    <LocationKeyedSwitch>
      <Route path="/truelayer/error-waiting-for-payment">
        <SlideIn>
          <WaitingPaymentTimeOut onContactSupport={onContactSupport} />
        </SlideIn>
      </Route>
      <Route path="/truelayer/waiting-for-payment">
        <SlideIn>
          <WaitingPayment onContactSupport={onContactSupport} />
        </SlideIn>
      </Route>
      <Route path="/truelayer/waiting">
        <SlideIn>
          <ProcessingStep />
        </SlideIn>
      </Route>
      <Route path="/truelayer/acknowledgment">
        <SlideIn>
          <AcknowledgmentStep
            amount={amount ?? 0}
            transferDetails={transferDetails}
            onClose={onClose}
          />
        </SlideIn>
      </Route>
      <Route path="/truelayer/new">
        <SlideIn>
          <NewPaymentStep
            onContactSupport={onContactSupport}
            onTryAgain={onRetryPopup}
            onPayManually={onPayManually}
            waitingForCallback={waitingForCallback}
          />
        </SlideIn>
      </Route>
      <Route path="/truelayer/cancelled">
        <SlideIn>
          <CancelledStep
            onContactSupport={onContactSupport}
            onStartAgain={onTryAgain}
          />
        </SlideIn>
      </Route>
      <Route path="/truelayer/failed">
        <SlideIn>
          <FailedStep
            onContactSupport={onContactSupport}
            onPayManually={onPayManually}
            amount={amount}
          />
        </SlideIn>
      </Route>
      <Route path="/truelayer/transferring">
        <SlideIn>
          <TransferringStep
            onBrowseFunds={onBrowseFunds}
            onDashboard={onDashboard}
            amount={amount}
            transferDetails={transferDetails}
          />
        </SlideIn>
      </Route>
      <Route path="/truelayer/completed">
        <SlideIn>
          <CompletedStep
            onBrowseFunds={onBrowseFunds}
            onDashboard={onDashboard}
            onProceed={onClose}
            canProceed={proceedOnComplete}
            amount={amount}
            autoInvested={autoInvested}
            transferDetails={transferDetails}
          />
        </SlideIn>
      </Route>
      <Route path="/truelayer/temporarilyUnavailable">
        <SlideIn>
          <TemporaryUnavailable
            onPayManually={onPayManually}
            onTryLater={onTryLater}
          />
        </SlideIn>
      </Route>
      <Route path="/truelayer/selectProvider">
        <SlideIn>
          <SelectProviderStep
            showServerError={showServerError}
            onSelected={onProviderSelected}
            onScrollToTop={onScrollToTop}
          />
        </SlideIn>
      </Route>
    </LocationKeyedSwitch>
  );
}
