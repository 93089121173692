import { useMemo } from 'react';
import { TextField, TextFieldProps } from './TextField';

interface DateFieldProps extends Omit<TextFieldProps, 'type'> {
  min?: string;
  max?: string;
}

export function DateField({ min, max, inputProps, ...other }: DateFieldProps) {
  const dateInputProps = useMemo(() => ({ min, max, ...inputProps }), [
    min,
    max,
    inputProps,
  ]);
  return (
    <TextField type="date" inputProps={dateInputProps} {...other} autoFocus />
  );
}
