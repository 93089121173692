import {
  StepFormWrapper,
  StepWrapper,
} from 'components/design-system/StepComponents/StepComponents';
import { SetupMfaMethodForm } from 'components/feature/setupMfa/SetupMfaMethodForm';
import { dashboardMyDetailsPath } from 'paths';
import { useHistory } from 'react-router-dom';

export function SetupMfa() {
  const history = useHistory();

  return (
    <StepFormWrapper>
      <StepWrapper>
        <SetupMfaMethodForm
          onProceed={() => history.push(dashboardMyDetailsPath)}
        />
      </StepWrapper>
    </StepFormWrapper>
  );
}
