import { useUserProfileQuery } from 'generated/graphql';
import { getPathSegmentForWrapperType } from 'helpers/accountHelpers';
import { NotFound } from 'pages/NotFound/NotFound';
import { generateDashboardAccountAddCashPath } from 'paths';
import { useHistory, useParams } from 'react-router';
import { QueryState } from './QueryState';

export function CashDepositDeepLink() {
  const history = useHistory();
  const { id } = useParams<{
    id: string;
  }>();

  const userProfileQuery = useUserProfileQuery(undefined, {
    refetchOnMount: true,
    staleTime: 0,
    onSuccess: (data) => {
      const selectedAccount = data.clientSummary?.accounts.find(
        (account) => account.id === id
      );
      if (selectedAccount) {
        history.push(
          generateDashboardAccountAddCashPath({
            wrapperType: getPathSegmentForWrapperType(
              selectedAccount.wrapperType
            ),
          })
        );
      }
    },
  });

  return (
    <QueryState {...userProfileQuery}>
      {({ data }) => {
        const selectedAccount = data?.clientSummary?.accounts.find(
          (account) => account.id === id
        );

        if (selectedAccount) {
          // The useEffectOnce will redirect to the correct page, and this won't be rendered
          return null;
        }

        return <NotFound />;
      }}
    </QueryState>
  );
}
