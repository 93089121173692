import { PerformanceSeriesKind } from 'generated/graphql';
import React, { useCallback } from 'react';
import { StyledSwitch } from './PerformanceKindSwitch.styles';

interface PerformanceKindSwitchProps {
  onChange: (newValue: PerformanceSeriesKind) => void;
  value: PerformanceSeriesKind;
}

export function PerformanceKindSwitch({
  value,
  onChange,
}: PerformanceKindSwitchProps) {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      onChange(
        e.target.checked
          ? PerformanceSeriesKind.GrowthProportion
          : PerformanceSeriesKind.ClosingValue
      ),
    [onChange]
  );

  return (
    <StyledSwitch
      checked={value === PerformanceSeriesKind.GrowthProportion}
      onChange={handleChange}
    />
  );
}
