import { CopyToClipboardButton } from 'components/Button/CopyToClipboardButton';
import { CustomButton } from 'components/Button/CustomButton';
import { Container } from 'components/Container/Container.style';
import { ReadOnlyInput } from 'components/Input';
import { QueryState } from 'components/QueryState';
import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import { GaEventNames } from 'constants/gaConstants';
import * as formatting from 'formatting';
import {
  AccountStatus,
  UserProfileQuery,
  WrapperType,
  useUserProfileQuery,
} from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { useHasActiveAccount } from 'hooks/useAccountStatus';
import { useIntercom } from 'react-use-intercom';
import { Base } from './Base';
import {
  DoubleCol,
  ReferralLinkContainer,
  SingleAction,
} from './MyDetails.style';

interface AccountFieldProps {
  id: number;
  secclId: string | undefined;
  wrapperType: WrapperType;
  status: AccountStatus;
  onSupport: () => void;
}

function AccountField({
  id,
  secclId,
  wrapperType,
  status,
  onSupport,
}: AccountFieldProps) {
  if (status === AccountStatus.Closing || status === AccountStatus.Closed) {
    return (
      <DoubleCol>
        <ReadOnlyInput
          id={`account${id}-read-only-input`}
          label={`${wrapperType} ID`}
          defaultValue={`${secclId} - ${status}`}
        />
        <CustomButton
          size="small"
          className="richBlue"
          onClick={() => onSupport()}
        >
          Talk to support
        </CustomButton>
      </DoubleCol>
    );
  } else {
    return (
      <ReadOnlyInput
        id={`account${id}-read-only-input`}
        label={`${wrapperType} ID`}
        defaultValue={secclId}
      />
    );
  }
}

type Accounts = NonNullable<UserProfileQuery['clientSummary']>['accounts'];
interface PureAccountDetailsProps {
  tilliterSince: string;
  annualFee: string | number;
  accountsInfo: Accounts | undefined;
  hasActiveAccount: boolean;
  personalReferralLink?: string | null;
  onClick: () => void;
  onSupport: () => void;
}

// ts-unused-exports:disable-next-line
export function PureAccountDetails({
  tilliterSince,
  annualFee,
  accountsInfo,
  hasActiveAccount,
  personalReferralLink,
  onClick,
  onSupport,
}: PureAccountDetailsProps) {
  const handleFaqClick = () => {
    trackGa({
      event: GaEventNames.selectContent,
      item_id: 'dashboard - my details - account',
      content_type: `fee info clicked`,
    });
  };

  return (
    <Base title="Account details">
      <Container padding="none">
        <ReadOnlyInput
          id="joinDate-read-only-input"
          label="Tilliter since"
          defaultValue={formatting.date(tilliterSince!)}
        />

        <DoubleCol>
          <ReadOnlyInput
            id="fee-read-only-input"
            label="TILLIT annual platform fee"
            defaultValue={formatting.percent(annualFee)}
          />

          <CustomButtonV2
            {...{
              component: 'a',
              target: '_blank',
              href: 'https://tillitinvest.com/faqs#fees',
              onClick: () => handleFaqClick(),
            }}
            $color="secondary"
          >
            More info on fees
          </CustomButtonV2>
        </DoubleCol>

        {personalReferralLink && (
          <ReferralLinkContainer>
            <ReadOnlyInput
              id="referral-link-read-only-input"
              label={`Your referral link`}
              defaultValue={personalReferralLink}
            />
            <CopyToClipboardButton text={personalReferralLink} size="medium" />
          </ReferralLinkContainer>
        )}

        {accountsInfo &&
          accountsInfo.map((account, i) => (
            <AccountField
              key={account.id}
              id={i}
              secclId={account.secclId!}
              wrapperType={account.wrapperType}
              status={account.status}
              onSupport={onSupport}
            />
          ))}

        {hasActiveAccount && (
          <SingleAction>
            <CustomButtonV2 $color="secondary" onClick={() => onClick()}>
              Close my account
            </CustomButtonV2>
          </SingleAction>
        )}
      </Container>
    </Base>
  );
}

export function AccountDetails() {
  const userProfileQuery = useUserProfileQuery();
  const userProfile = userProfileQuery.data?.userProfile;
  const clientData = userProfileQuery.data?.clientSummary;
  const hasActiveAccount = useHasActiveAccount();
  const { showNewMessages } = useIntercom();

  const handleClick = () => {
    showNewMessages(`I would like to close my account.`);
    trackGa({
      event: GaEventNames.selectContent,
      content_type: `talk to support`,
      item_id: 'dashboard - my details - close account clicked',
    });
  };

  const handleSupportClick = () => {
    showNewMessages();
    trackGa({
      event: GaEventNames.selectContent,
      item_id: 'talk to support',
      content_type: `dashboard - my details - account`,
    });
  };

  return (
    <QueryState {...userProfileQuery}>
      {() => (
        <PureAccountDetails
          tilliterSince={userProfile?.signupTimestampUtc}
          annualFee={clientData?.platformFeeProportion ?? 'Not available'}
          accountsInfo={clientData?.accounts}
          hasActiveAccount={hasActiveAccount!}
          personalReferralLink={userProfile?.personalReferralLink}
          onClick={handleClick}
          onSupport={handleSupportClick}
        />
      )}
    </QueryState>
  );
}
