import { StepFormContainer } from 'components/design-system/StepComponents/StepComponents';
import { Text, TextLarge } from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import styled from 'styled-components';

export const SelectInstrumentWrapper = styled.div`
  max-width: 20rem;
  display: inline;
`;

export const PercentageWrapper = styled.div``;

export const PercentageAmountWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
  justify-items: stretch;
  margin: 0.5rem 0;
`;

export const PercentageAmountOr = styled(TextLarge)`
  margin: 0;
`;

export const SelectFundsFormContainer = styled(StepFormContainer)`
  gap: 2rem;
  min-height: 20rem;
  margin: 1rem 0;
`;

export const OverAllocatedMsg = styled.div`
  ${Text} {
    color: ${colors.danger};
  }
`;

export const ShareClassWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
`;
