import { WrapperType } from 'generated/graphql';
import { getAssetNameOfAsset } from './breakdownGroupingHelpers';
import { IncludeAssetType } from './includeAssetHelpers';
import { useCombinedBasketBreakdown } from './useCombinedBasketBreakdown';

export {
  getAssetClassOfAsset,
  getAssetNameOfAsset,
  getBondStyleOfAsset,
  getEquityStyleOfAsset,
  getRegionOfAsset,
} from './breakdownGroupingHelpers';
export type { GetAssetGroupString } from './breakdownGroupingHelpers';
export type { IncludeAssetType } from './includeAssetHelpers';
export { useCombinedBasketBreakdown } from './useCombinedBasketBreakdown';
export type {
  CombinedBreakdown,
  CombinedBreakdownType,
} from './useCombinedBasketBreakdown';
export { useCombinedRebalancingBreakdown } from './useCombinedRebalancingBreakdown';
export { useGetCurrentBreakdown } from './useGetCurrentBreakdown';
export type { CurrentBreakdownType } from './useGetCurrentBreakdown';

export const useCombinedFundBreakdown = (
  selectedAccountId: string,
  accountType: WrapperType,
  includeAsset?: IncludeAssetType
) => {
  return useCombinedBasketBreakdown(
    selectedAccountId,
    accountType,
    getAssetNameOfAsset,
    includeAsset
  );
};
