import { useAuth } from 'context/AuthContext';
import {
  autoSaveInvestPath,
  autoSaveInvestPathAddFundsPath,
  autoSaveInvestPathMyFundsPath,
  dynamicPortfolioConstructionPath,
} from 'paths';
import { useRouteMatch } from 'react-router-dom';
import { ActiveModeFooter } from './ActiveModeFooter';
import { ResumeFooter } from './ResumeFooter/ResumeFooter';
import { useMode } from './useMode';

export function ModeFooter() {
  const { signedIn } = useAuth();
  const [mode] = useMode();

  const autoSaveInvestPageMatch = useRouteMatch<{
    wrapperType: string;
  }>([
    autoSaveInvestPath,
    autoSaveInvestPathAddFundsPath,
    autoSaveInvestPathMyFundsPath,
  ]);
  const isAutoSaveInvestPage = autoSaveInvestPageMatch !== null;

  const dynamicPortfolioConstructionPathMatch = useRouteMatch<{
    wrapperType: string;
  }>([dynamicPortfolioConstructionPath]);
  const isDynamicPortfolioConstructionPath =
    dynamicPortfolioConstructionPathMatch !== null;

  if (
    (mode === null &&
      !isAutoSaveInvestPage &&
      !isDynamicPortfolioConstructionPath) ||
    (isAutoSaveInvestPage && !signedIn)
  ) {
    return null;
  }

  if (
    mode?.mode === 'resume' &&
    !isAutoSaveInvestPage &&
    !isDynamicPortfolioConstructionPath
  ) {
    return <ResumeFooter />;
  }

  return <ActiveModeFooter />;
}
