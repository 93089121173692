import { LocationKeyedSwitch } from 'components/LocationKeyedSwitch';
import { PageContainer } from 'components/PageContainer';
import { AnimatePresence } from 'framer-motion';
import * as History from 'history';
import React from 'react';
import { MemoryRouter, Route } from 'react-router-dom';
import { ChangePasswordForm } from './ChangePasswordForm';
import { Confirmation } from './Confirmation';

interface ChangePasswordProps {
  onContinue: () => void;
}

export function ChangePassword({ onContinue }: ChangePasswordProps) {
  const initialRouterEntries: History.LocationDescriptor[] = [
    { pathname: '/changePasswordFrom' },
  ];

  return (
    <PageContainer>
      <MemoryRouter initialEntries={initialRouterEntries}>
        <AnimatePresence>
          <LocationKeyedSwitch>
            <Route path="/changePasswordFrom" component={ChangePasswordForm} />
            <Route path="/confirmation">
              <Confirmation onContinue={onContinue} />
            </Route>
          </LocationKeyedSwitch>
        </AnimatePresence>
      </MemoryRouter>
    </PageContainer>
  );
}
