import { WithdrawDialog } from 'components/Dialogs/Withdraw/WithdrawDialog';
import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import { GaEventNames } from 'constants/gaConstants';
import { currencyFull } from 'formatting';
import { WrapperType } from 'generated/graphql';
import { getPathSegmentForWrapperType } from 'helpers/accountHelpers';
import { trackGa } from 'helpers/track';
import { useModal } from 'mui-modal-provider';
import { generateDashboardAccountAddCashPath } from 'paths';
import { useHistory } from 'react-router';
import {
  Container,
  Main,
  PendingOrders,
  Title,
  TotalAmount,
} from './CashHoldingCard.styles';
import { Actions, Label, Value } from './HoldingCard.styles';

interface CashHoldingCardProps {
  accountId: string;
  accountType: WrapperType;
  closingAccount?: boolean;
  pendingOrders: number;
  availableAmount: number;
}

export function CashHoldingCard({
  accountId,
  accountType,
  closingAccount = false,
  pendingOrders,
  availableAmount,
}: CashHoldingCardProps) {
  const { showModal } = useModal();
  const withdrawalsDisabled = availableAmount <= 0;
  const history = useHistory();

  const handleWithdraw = () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'dashboard',
      item_id: 'withdraw cash button clicked - holdings section',
    });
    showModal(WithdrawDialog, {
      accountId,
    });
  };

  const handleAddCash = () => {
    trackGa({
      event: GaEventNames.viewItem,
      orderType: 'cash deposit',
      ecommerce: {
        items: [
          {
            item_id: 'cash',
            item_name: 'Cash deposit',
            affiliation: 'Tillit',
            currency: 'GBP',
            item_variant: accountType,
          },
        ],
      },
    });
    history.push(
      generateDashboardAccountAddCashPath({
        wrapperType: getPathSegmentForWrapperType(accountType),
      })
    );
  };

  return (
    <Container>
      <Main>
        <Title>Available cash</Title>
      </Main>

      {pendingOrders > 0 && (
        <PendingOrders>
          <Value>{currencyFull(pendingOrders)}</Value>
          <Label>Pending orders</Label>
        </PendingOrders>
      )}

      <TotalAmount>
        <Value>{currencyFull(availableAmount!)}</Value>
      </TotalAmount>

      <Actions>
        {!closingAccount && (
          <CustomButtonV2
            $color="primary"
            $size="small"
            $noHover={true}
            onClick={handleAddCash}
          >
            Add cash
          </CustomButtonV2>
        )}
        {accountType !== WrapperType.Sipp && (
          <CustomButtonV2
            $color="secondary"
            $noHover={true}
            $size="small"
            onClick={handleWithdraw}
            disabled={withdrawalsDisabled}
          >
            Withdraw
          </CustomButtonV2>
        )}
      </Actions>
    </Container>
  );
}
