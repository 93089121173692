import { Loading } from 'components/Loading';
import {
  FontStyle,
  FontWeight,
  TextNormal,
} from 'components/design-system/Text/Text';
import { Assumptions } from 'components/feature/MIFIDCostsAndCharges/Assumptions';
import {
  ChargeRow,
  TotalChargeRow,
} from 'components/feature/MIFIDCostsAndCharges/ChargeRow';
import { NoChargeInfo } from 'components/feature/MIFIDCostsAndCharges/NoChargeInfo';
import {
  Instrument,
  useGetTradeCostsAndChargesIllustrationQuery,
} from 'generated/graphql';
import { useState } from 'react';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';
import { PartialDeep } from 'type-fest';
import { getLongKidDocName } from '../_shared/copyHelpers';
import {
  ChargesSection,
  Content,
  SubSectionWrapper,
  TopBar,
  TotalsDisclaimer,
} from './_shared/ImportantInformation.styles';

interface MiFIDCostsAndChargesSectionProps {
  amountEntered: number;
  selectedInstrument: PartialDeep<Instrument>;
  trackPopoverClick: (popover: string) => void;
}

export const MiFIDCostsAndChargesSection = ({
  amountEntered,
  selectedInstrument,
  trackPopoverClick,
}: MiFIDCostsAndChargesSectionProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const {
    data: tradeIllustration,
    isLoading,
  } = useGetTradeCostsAndChargesIllustrationQuery(
    {
      trade: {
        amount: amountEntered,
        isin: selectedInstrument.isin!,
      },
    },
    {
      enabled: !!amountEntered,
    }
  );

  if (isLoading) {
    return <Loading />;
  } else if (!amountEntered) {
    return (
      <TextNormal>
        Enter a purchase amount to illustrate the costs and charges associated
        with this trade.
      </TextNormal>
    );
  } else {
    const oneOffs = tradeIllustration?.tradeCostsAndChargesIllustration?.oneOffCostsAndCharges.map(
      (charge) => (
        <ChargeRow
          charge={charge}
          trackPopoverClick={trackPopoverClick}
          oneOff
          amountEntered={amountEntered}
          showTooltip
        />
      )
    );

    const ongoing = tradeIllustration?.tradeCostsAndChargesIllustration?.ongoingCostsAndCharges.map(
      (charge) => (
        <ChargeRow
          charge={charge}
          trackPopoverClick={trackPopoverClick}
          amountEntered={amountEntered}
          showTooltip
        />
      )
    );

    const kiidDocName = getLongKidDocName(selectedInstrument?.instrumentType!);
    const kiidUrl = selectedInstrument?.kiidDocumentUrl;

    const hasAnyChargeInformation = !!oneOffs?.length || !!ongoing?.length;

    const finalAmountBeforeCharges =
      amountEntered +
      (tradeIllustration?.tradeCostsAndChargesIllustration.totalGrowth ?? 0);
    const finalAmountAfterCharges =
      tradeIllustration?.tradeCostsAndChargesIllustration.finalAmount ?? 0;
    const totalCharges =
      tradeIllustration?.tradeCostsAndChargesIllustration
        .totalCostsAndChargesOverPeriod ?? 0;

    return (
      <>
        {!oneOffs?.length ? null : (
          <>
            <SubSectionWrapper>
              <TextNormal $noMargin $fontWeight={FontWeight.medium}>
                One-offs
              </TextNormal>
            </SubSectionWrapper>
            <ChargesSection>{oneOffs}</ChargesSection>
          </>
        )}
        {!ongoing?.length ? null : (
          <>
            <SubSectionWrapper>
              <TextNormal
                $noMargin
                $fontWeight={FontWeight.medium}
                style={{ marginTop: '1rem' }}
              >
                Ongoing
              </TextNormal>
            </SubSectionWrapper>
            <ChargesSection>{ongoing}</ChargesSection>
          </>
        )}
        {!hasAnyChargeInformation && (
          <NoChargeInfo kiidUrl={kiidUrl} kiidDocName={kiidDocName} />
        )}
        {hasAnyChargeInformation && (
          <>
            <SubSectionWrapper>
              <TextNormal
                $noMargin
                $fontWeight={FontWeight.medium}
                style={{ marginTop: '1rem' }}
              >
                Totals
              </TextNormal>
              <TotalsDisclaimer $noMargin $fontStyle={FontStyle.italic}>
                (assuming 5% annual growth over 1 year)
              </TotalsDisclaimer>
              <ChargesSection>
                <TotalChargeRow
                  finalAmountBeforeCharges={finalAmountBeforeCharges}
                  finalAmountAfterCharges={finalAmountAfterCharges}
                  totalCharges={totalCharges}
                  variant="compact"
                />
              </ChargesSection>
            </SubSectionWrapper>
            <TopBar type="button" onClick={() => setIsExpanded(!isExpanded)}>
              <strong>What assumptions are being used?</strong>{' '}
              {isExpanded ? (
                <HiChevronUp size="1.5rem" />
              ) : (
                <HiChevronDown size="1.5rem" />
              )}
            </TopBar>
            <Content isExpanded={isExpanded}>
              <Assumptions
                amount={amountEntered}
                tradeIllustration={tradeIllustration}
              />
            </Content>
          </>
        )}
      </>
    );
  }
};
