import { Typography, useMediaQuery } from '@material-ui/core';
import { colors } from 'constants/colors';
import { GaEventNames } from 'constants/gaConstants';
import * as d3 from 'd3';
import { trackGa } from 'helpers/track';
import { ChartDimensions, useChartDimensions } from 'hooks/useChartDimensions';
import _ from 'lodash';
import React, { useMemo, useRef } from 'react';
import { useTheme } from 'styled-components';
import { horizontalAlignmentType } from './BaseGraph.styles';
import { DefaultGradient } from './DefaultGradient';
import { Legend } from './Legend';
import { MobileTooltip } from './MobileTooltip';
import { Tooltip, TooltipHandle } from './Tooltip';
import { YAxis, YAxisSize } from './YAxis';
import { ZeroLine } from './ZeroLine';

type XScale = d3.ScaleTime<number, number, never>;
type YScale = d3.ScaleLinear<number, number, never>;

type SvgGradientRenterFunction = (
  data: Series['data'],
  xScale: XScale,
  yScale: YScale
) => React.ReactElement<
  React.SVGProps<SVGGradientElement>,
  'linearGradient' | 'radialGradient'
>;

type SvgDefinitionsRenderFunction = (
  xScale: XScale,
  yScale: YScale
) => React.ReactNode;

export type TooltipRenderFunction = (
  tooltip: TooltipHandle,
  index: number
) => React.ReactNode;

type TickFormatFunction = (value: number, maxNumDecimals: number) => string;

type LegendRenderFunction = (data: Series[]) => React.ReactNode;

export type ExtrasRenderFunction = (
  xScale: XScale,
  yScale: YScale
) => React.ReactNode;

type ExtraPropsFactory = (
  xScale: XScale,
  yScale: YScale
) => React.SVGProps<SVGPathElement>;

export interface Series {
  name: string;
  data: Array<number | null>;
  curveFactory?: d3.CurveFactory;
  extraProps?: ExtraPropsFactory | React.SVGProps<SVGPathElement>;
  description?: React.ReactNode;
  legendEntry?: React.ReactNode;
}

export interface AreaSeries extends Series {
  fill?: string | SvgGradientRenterFunction;
}

export interface LineSeries extends Series {
  stroke?: string;
  strokeWidth?: string | number;
}

export interface BaseGraphProps {
  labels: Array<number>;
  legendAlignment?: horizontalAlignmentType;
  area: AreaSeries;
  lines?: Array<LineSeries>;
  renderSvgDefinitions?: SvgDefinitionsRenderFunction;
  tickFormat?: TickFormatFunction;
  renderTooltipContent?: TooltipRenderFunction;
  renderLegend?: boolean | LegendRenderFunction;
  renderExtras?: ExtrasRenderFunction;
  graphSettings?: Partial<ChartDimensions>;
  tooltipReference?: Series;
  YAxisFontSize?: YAxisSize;
  YAxisMaxTicksHints?: number;
  showMobileSpecificTooltip?: boolean;
  tolerance?: number;
  graphType: string;
  graphLocation: string;
}

export const lineColors = [colors.magenta, colors.richBlack];
export const darkUniverseLineColors = [colors.richBlack];

export function BaseGraph({
  labels,
  legendAlignment,
  area,
  lines = [],
  renderSvgDefinitions,
  tickFormat,
  renderTooltipContent = (_, index) => (
    <Typography>{area.data[index]}</Typography>
  ),
  renderLegend = true,
  renderExtras,
  graphSettings,
  tooltipReference = area,
  YAxisFontSize,
  YAxisMaxTicksHints,
  showMobileSpecificTooltip = false,
  tolerance = 1,
  graphType,
  graphLocation,
}: BaseGraphProps) {
  const [ref, dms] = useChartDimensions(graphSettings);
  const [currentX, setCurrentX] = React.useState<number | null>(null);
  const [toolTipViews, setToolTipViews] = React.useState<number>(0);

  const { darkUniverse } = useTheme();
  const theme = useTheme();

  const isMob = useMediaQuery(theme.breakpoints.down('sm'));
  const shouldShowMobileTooltip = isMob && showMobileSpecificTooltip;

  const xScale = useMemo(
    () =>
      d3
        .scaleUtc()
        .clamp(true)
        .domain(labels)
        .range(
          d3.range(
            0,
            (dms.boundedWidth || 1) + 0.5,
            (dms.boundedWidth || 1) / (labels.length - 1)
          )
        ),
    [dms.boundedWidth, labels]
  );

  const [yDomainMin, yDomainMax] = useMemo(() => {
    const flatDomain = area.data.concat(lines.map((l) => l.data).flat());
    return [_.min(flatDomain), _.max(flatDomain)] as [number, number];
  }, [area.data, lines]);

  const yScale = useMemo(() => {
    const diff = yDomainMax - yDomainMin;
    const baseMinMax = tolerance * 10;
    const min = Math.max(Math.abs(yDomainMin * 0.2), baseMinMax);
    const max = Math.max(Math.abs(yDomainMax * 0.2), baseMinMax);

    const domain =
      diff < tolerance
        ? [Math.max(0, yDomainMin - min), yDomainMax + max]
        : [yDomainMin, yDomainMax];

    return d3
      .scaleLinear()
      .domain(domain)
      .nice(7)
      .range([dms.boundedHeight, 0]);
  }, [dms.boundedHeight, tolerance, yDomainMax, yDomainMin]);

  const defaultCurveFactory = d3.curveMonotoneX;

  const areaGenerator = useMemo(
    () =>
      d3
        .area<Series['data'][number]>()
        .curve(area.curveFactory ?? defaultCurveFactory)
        .defined((d) => d !== null)
        .x((_, i) => xScale(labels[i]))
        .y0(() => {
          const ticks = _(yScale.ticks(7));

          if (ticks.every((t) => t > 0)) {
            return yScale(ticks.min()!);
          } else if (ticks.every((t) => t < 0)) {
            return yScale(ticks.max()!);
          } else {
            return yScale(0);
          }
        })
        .y1((d) => yScale(d!)),
    [area.curveFactory, defaultCurveFactory, labels, xScale, yScale]
  );

  const lineGenerator = useMemo(
    () =>
      d3
        .line<Series['data'][number]>()
        .defined((d) => d !== null)
        .x((_, i) => xScale(labels[i]))
        .y((d) => yScale(d!)),
    [labels, xScale, yScale]
  );

  const tooltip = useRef<TooltipHandle>(null);
  const boundariesElement = useRef<SVGSVGElement>(null);

  const handleMouseMove = (
    e: React.MouseEvent<SVGElement> | React.TouchEvent<SVGElement>
  ) => {
    const clientX =
      (e as React.MouseEvent<SVGElement>).clientX ??
      (e as React.TouchEvent<SVGElement>).touches[0].clientX;

    const label = xScale
      .invert(
        clientX -
          dms.marginLeft! -
          boundariesElement.current!.getBoundingClientRect().left
      )
      .getTime();
    const index = d3.bisectCenter(labels, label);

    const newX = xScale(labels[index]!);
    const newY = yScale(tooltipReference.data[index]!);

    if (currentX !== newX) {
      setCurrentX(newX);
      setToolTipViews(toolTipViews + 1);
    }

    tooltip.current?.update(
      newX,
      newY,
      renderTooltipContent(tooltip.current, index)
    );
  };

  const handleMouseEnter = () => {
    trackGa({
      event: GaEventNames.popover,
      content_type: `${graphLocation} - ${graphType}`,
    });
  };

  const handleMouseLeave = () => {
    tooltip.current?.hide();

    trackGa({
      event: GaEventNames.popoverInteractions,
      content_type: `${graphLocation} - ${graphType}`,
      value: toolTipViews,
    });
  };

  const defaultGradientFactory: SvgGradientRenterFunction = (data, x, y) => {
    const gradientOffset =
      (y(0) - y(_.max(data)!)) / (y(_.min(data)!) - y(_.max(data)!) || 1);

    return <DefaultGradient gradientOffset={gradientOffset} />;
  };

  const gradientId = useMemo(
    () =>
      typeof area.fill !== 'string' ? _.uniqueId('lineargradient-') : null,
    [area.fill]
  );

  const areaGradient = useMemo(() => {
    if (typeof area.fill === 'string') {
      return null;
    }

    const gradient =
      area.fill?.(area.data, xScale, yScale) ??
      defaultGradientFactory(area.data, xScale, yScale);

    return React.cloneElement(gradient, {
      id: gradientId!,
    });
  }, [area, gradientId, xScale, yScale]);

  const renderedArea = useMemo(() => {
    const extraProps =
      typeof area.extraProps === 'function'
        ? area.extraProps(xScale, yScale)
        : area.extraProps;

    return (
      <path
        {...extraProps}
        fill={areaGradient ? `url(#${gradientId})` : (area.fill as string)}
        d={areaGenerator(area.data)!}
      />
    );
  }, [area, areaGenerator, areaGradient, gradientId, xScale, yScale]);

  const renderedLines = useMemo(() => {
    return lines
      .filter((line) => !!line.data.length)
      .map(
        (
          { name, data, curveFactory, stroke, strokeWidth, extraProps: props },
          i
        ) => {
          const currentLineGenerator = lineGenerator.curve(
            curveFactory ?? defaultCurveFactory
          );
          const extraProps =
            typeof props === 'function' ? props(xScale, yScale) : props;
          return (
            <path
              {...extraProps}
              key={name}
              fill="none"
              stroke={
                stroke ??
                (darkUniverse ? darkUniverseLineColors[i] : lineColors[i])
              }
              strokeWidth={strokeWidth ?? '1px'}
              d={currentLineGenerator(data)!}
            />
          );
        }
      );
  }, [defaultCurveFactory, lineGenerator, lines, xScale, yScale, darkUniverse]);

  const renderedLegend = useMemo(() => {
    if (typeof renderLegend === 'function') {
      return renderLegend([area, ...lines]);
    } else if (renderLegend) {
      return (
        <Legend
          series={[area, ...lines]}
          primaryEntry={tooltipReference}
          legendAlignment={legendAlignment}
        />
      );
    }
    return null;
  }, [area, legendAlignment, lines, renderLegend, tooltipReference]);

  return (
    <div>
      <div ref={ref} style={{ minHeight: 150 }}>
        <svg
          style={{ display: 'block' }}
          ref={boundariesElement}
          width={dms.width}
          height={dms.height}
          onMouseMove={handleMouseMove}
          onMouseEnter={handleMouseEnter}
          onTouchStart={handleMouseEnter}
          onTouchMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
          onTouchEnd={handleMouseLeave}
        >
          <defs>
            {areaGradient &&
              React.cloneElement(areaGradient, {
                id: gradientId!,
              })}
            {renderSvgDefinitions?.(xScale, yScale)}
          </defs>

          <YAxis
            scale={yScale}
            graphDms={dms}
            tickFormat={tickFormat}
            maxTicksHint={YAxisMaxTicksHints}
            textSize={YAxisFontSize}
          />

          <g
            transform={`translate(${[dms.marginLeft, dms.marginTop].join(
              ','
            )})`}
          >
            {/* Zero line */}
            <ZeroLine y={yScale(0)} length={dms.boundedWidth - 20} />

            {/* Area graph */}
            {renderedArea}

            {/* Lines */}
            {renderedLines}

            {/* Extra stuff */}
            {renderExtras?.(xScale, yScale)}

            {!shouldShowMobileTooltip && (
              <Tooltip ref={tooltip} boundariesElement={boundariesElement} />
            )}

            {shouldShowMobileTooltip && (
              <MobileTooltip
                ref={tooltip}
                boundariesElement={boundariesElement}
              />
            )}
          </g>
        </svg>
      </div>

      {/* Legend */}
      {renderedLegend}
    </div>
  );
}
